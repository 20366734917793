// import React, { useState, useEffect } from 'react';
// import { useNavigate, useLocation, Link } from 'react-router-dom';
// import { EyeClosed, Eye } from 'lucide-react';
// import { useAuth } from './AuthProvider';
// import './assets/css/style-starter.css';
// import bcrypt from 'bcryptjs';
// import logo from './logoblack.png';
// import './Login.css';
// import VerificationPopup from './loginVerificationPopup.js';
// import Swal from "sweetalert2";


// function Login() {
//     const [email, setEmail] = useState('');
//     const [password, setPassword] = useState('');
//     const [newPassword, setNewPassword] = useState('');
//     const [confirmPassword, setConfirmPassword] = useState('');
//     const [compare, setCompare] = useState(null);
//     const [updatePassword, setUpdatePassword] = useState(false);
//     const [error, setError] = useState('');
//     const [step, setStep] = useState(1); // Step 1: Email input, Step 2: Password input
//     const [showPassword, setShowPassword] = useState(false); // Password visibility toggle
//     const navigate = useNavigate();
//     const location = useLocation();
//     const { login } = useAuth();

//     const [verificationNeeded, setVerificationNeeded] = useState(false);//9 jan 2025
//     const [loading, setLoading] = useState(false);
//     const [timeLeft, setTimeLeft] = useState(null);


//     const handleEmailSubmit = async (e) => {
//         e.preventDefault();
//         try {
//             const response = await fetch(`https://backendjaes.passiontrials.co.za/new_getUser_with_email/${email}`, {
//                 headers: { 'Content-Type': 'application/json' },
//             });

//             const data = await response.json();
//             if (data && data.length > 0) {
//                 setCompare(data[0]);
//                 if (data[0].Password === 'null' || data[0].Password === null) {
//                     setUpdatePassword(true);
//                 } else {
//                     setUpdatePassword(false);
//                 }
//                 setStep(2); // Move to step 2 after checking the password
//             } else {
//                 alert('User not found');
//             }
//         } catch (error) {
//             console.error('Error fetching user:', error);
//             alert('An error occurred during login');
//         }
//     };

//     const handlePasswordSubmit = async (e) => {
//         e.preventDefault();

//         // if (compare && !compare.AccountVerification) {
//         //     setVerificationNeeded(true); // Show verification popup
//         //     return; // Stop further login processing until verified
//         // }
//         const isVerified = await liveData();
//         if (!isVerified) {
//             setVerificationNeeded(true); // Show verification popup
//             return; // Stop further login processing until verified
//         }
//         if (updatePassword) {
//             handleUpdatePassword();
//         } else {

//             normalLogin(email, password);
//         }
//     };

//     const liveData = async () => {
//         const response = await fetch(`https://backendjaes.passiontrials.co.za/new_getUser_with_email/${email}`, {
//             headers: { 'Content-Type': 'application/json' },
//         });

//         const data = await response.json();
//         if (data && data.length > 0) {
//             console.log('the boolean', data[0].AccountVerification);
//             return data[0].AccountVerification;
//         };

//     };


//     const resendVerificationCode = async () => {
//         try {
//             const response = await fetch("https://backendjaes.passiontrials.co.za/resend_code", {
//                 method: "POST",
//                 headers: { "Content-Type": "application/json" },
//                 body: JSON.stringify({ email }),
//             });

//             const data = await response.json();
//             if (response.ok) {
//                 Swal.fire({
//                     title: "Code Sent",
//                     text: data.message,
//                     icon: "success",
//                 });
//                 setTimeLeft(120);
//             } else {
//                 Swal.fire({
//                     title: "Error",
//                     text: data.error || "Failed to resend code.",
//                     icon: "error",
//                 });
//             }
//         } catch (error) {
//             Swal.fire({
//                 title: "Error",
//                 text: "Something went wrong while resending the code.",
//                 icon: "error",
//             });
//         }
//     };
//     useEffect(() => {
//         if (verificationNeeded) {
//             // Start timer when popup is shown
//             setTimeLeft(120); // Set countdown duration (e.g., 120 seconds)
//         } else {
//             // Clear timer when popup is hidden
//             setTimeLeft(null);
//         }
//     }, [verificationNeeded]);

//     useEffect(() => {
//         if (timeLeft !== null) {
//             if (timeLeft > 0) {
//                 const timer = setTimeout(() => setTimeLeft((prev) => prev - 1), 1000);
//                 return () => clearTimeout(timer);
//             } else {
//                 resendVerificationCode(); // Auto-resend when timer reaches 0
//             }
//         }
//     }, [timeLeft]);


//     const normalLogin = async (email, password) => {
//         try {
//             const loginResponse = await fetch('https://backendjaes.passiontrials.co.za/new_loginn', {
//                 method: 'POST',
//                 headers: { 'Content-Type': 'application/json' },
//                 body: JSON.stringify({ Email: email, Password: password }),
//             });

//             const loginData = await loginResponse.json();

//             if (loginResponse.ok) {
//                 login(loginData.userId, loginData.Role);
//                 if (loginData.Role === 'Super Admin') {
//                     navigate('/NewAdminDashboard');

//                 }
//                 else if (loginData.Role === 'SalesAndServiceProvider') {
//                     navigate('/NewAdminDashboard');

//                 }
//                 else if (loginData.Role === 'Admin') {
//                     navigate('/RegularAdmin');

//                 } else if (loginData.Role === 'Transport') {
//                     navigate('/transport-admin');

//                 } else if (loginData.Role === 'Media') {
//                     navigate('/event-details');

//                 } else if (loginData.Role === 'reserve') {
//                     navigate('/ReserveUserDashboard');

//                 } else if (loginData.Role === 'TransportOnly') {
//                     navigate('/TransportuserDashboard');

//                 } else if (loginData.Role === 'User') { // Handle 'User' role
//                     navigate('/UserDashboard');
//                 } else {
//                     alert('Unknown role. Please contact support.'); // Optional fallback
//                 }
//             } else {
//                 alert(loginData.message || 'Failed to login');
//             }
//         } catch (error) {
//             console.error('Login error:', error);
//             alert('An error occurred during login');
//         }
//     };


//     const handleUpdatePassword = async () => {
//         if (newPassword !== confirmPassword) {
//             alert('Passwords do not match.');
//             return;
//         }

//         try {
//             const hashedPassword = await bcrypt.hash(newPassword, 10);

//             const response = await fetch(`https://backendjaes.passiontrials.co.za/updateUser_login/${compare.UserId}`, {
//                 method: 'PUT',
//                 headers: {
//                     'Content-Type': 'application/json',
//                 },
//                 body: JSON.stringify({
//                     ...compare,
//                     Password: hashedPassword,
//                     ConfirmedUser: 1,
//                 }),
//             });

//             const data = await response.json();

//             if (response.ok) {
//                 //alert('Password updated successfully. You will be signed in, in a moment...');
//                 setUpdatePassword(false);

//                 const isVerified = await liveData();
//                 if (!isVerified) {
//                     setVerificationNeeded(true);
//                 }
//                 normalLogin(email, newPassword);
//             } else {
//                 alert(data.message);
//             }
//         } catch (error) {
//             console.error('Error updating password:', error);
//         }
//     };

//     // const checkApartmentAndNavigate = async (userId) => {
//     //     try {
//     //         const res = await fetch(`https://backendjaes.passiontrials.co.za/check_apartment_paid/${userId}`);
//     //         const result = await res.json();

//     //         if (res.ok && result.hasPaid) {
//     //             navigate('/UserDashboard');
//     //         } else {
//     //             const from = location.state?.from?.pathname || '/';
//     //             const state = location.state?.from?.state || {};
//     //             navigate(from, { state });
//     //         }
//     //     } catch (error) {
//     //         console.error('Error checking user apartment status:', error);
//     //         const from = location.state?.form?.pathname || '/';
//     //         const state = location.state?.from?.state || {};
//     //         navigate(from, { state });
//     //     }
//     // };

//     const togglePasswordVisibility = () => {
//         setShowPassword((prevShowPassword) => !prevShowPassword);
//     };

//     return (
//         <div className="login-page">
//             {verificationNeeded && (
//                 <VerificationPopup
//                     email={email}
//                     onResend={resendVerificationCode}
//                     onVerify={() => setVerificationNeeded(false)} // Hide popup after verification
//                     timeLeft={timeLeft}
//                 />
//             )}
//             <div className="login-container">
//                 <img src={logo} alt="Logo" className="logo" />

//                 <form onSubmit={step === 1 ? handleEmailSubmit : handlePasswordSubmit} className="login-form">
//                     {step === 1 && (
//                         <div className="input-grouplogin">
//                             <input
//                                 type="email"
//                                 value={email}
//                                 onChange={(e) => setEmail(e.target.value)}
//                                 placeholder="Email or Phone Number"
//                                 required
//                                 className="input-fieldlogin"
//                             />
//                             <button type="submit" className="input-button">
//                                 <i className="arrow-icon">Continue ➔</i>
//                             </button>
//                         </div>
//                     )}

//                     {step === 2 && !updatePassword && (
//                         <div className="input-grouplogin">
//                             <input
//                                 type="email"
//                                 value={email}
//                                 onChange={(e) => setEmail(e.target.value)}
//                                 placeholder="Email"
//                                 required
//                                 className="input-fieldlogin"
//                                 readOnly
//                             />
//                             <div className="password-container">
//                                 <input
//                                     type={showPassword ? 'text' : 'password'}
//                                     value={password}
//                                     onChange={(e) => setPassword(e.target.value)}
//                                     placeholder="Password"
//                                     required
//                                     className="input-fieldlogin"

//                                 />
//                                 <span onClick={togglePasswordVisibility} className="eye-icon">
//                                     {showPassword ? <EyeClosed size={20} /> : <Eye size={20} />}
//                                 </span>
//                             </div>
//                             <button type="submit" className="input-button">
//                                 <i className="arrow-icon">Login ➔</i>
//                             </button>
//                         </div>
//                     )}

//                     {step === 2 && updatePassword && (
//                         <div className="input-grouplogin">
//                             <input
//                                 type="email"
//                                 value={email}
//                                 onChange={(e) => setEmail(e.target.value)}
//                                 placeholder="Email"
//                                 required
//                                 className="input-fieldlogin"
//                                 readOnly
//                             />
//                             <input
//                                 type="password"
//                                 value={newPassword}
//                                 onChange={(e) => setNewPassword(e.target.value)}
//                                 placeholder="New Password"
//                                 required
//                                 className="input-fieldlogin"
//                             />
//                             <input
//                                 type="password"
//                                 value={confirmPassword}
//                                 onChange={(e) => setConfirmPassword(e.target.value)}
//                                 placeholder="Confirm New Password"
//                                 required
//                                 className="input-fieldlogin"
//                             />
//                             <button type="submit" className="input-button">
//                                 <i className="arrow-icon">Update Password ➔</i>
//                             </button>
//                         </div>
//                     )}
//                 </form>

//                 <div className="options">
//                     <label>
//                         <input type="checkbox" />
//                         Keep me signed in
//                     </label>
//                     <div className="links">
//                         <Link to="/ForgotPassword">Forgot password?</Link>
//                         <Link to="/register">Sign Up</Link>
//                     </div>
//                 </div>
//             </div>
//         </div>
//     );
// }

// export default Login;







import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation, Link } from 'react-router-dom';
import { EyeClosed, Eye } from 'lucide-react';
import { useAuth } from './AuthProvider';
import './assets/css/style-starter.css';
import bcrypt from 'bcryptjs';
import logo from './logoblack.png';
import './Login.css';
import VerificationPopup from './loginVerificationPopup.js';
import Swal from "sweetalert2";


function Login() {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [compare, setCompare] = useState(null);
    const [updatePassword, setUpdatePassword] = useState(false);
    const [error, setError] = useState('');
    const [step, setStep] = useState(1); // Step 1: Email input, Step 2: Password input
    const [showPassword, setShowPassword] = useState(false); // Password visibility toggle
    const navigate = useNavigate();
    const location = useLocation();
    const { login } = useAuth();

    const [verificationNeeded, setVerificationNeeded] = useState(false);//9 jan 2025
    const [loading, setLoading] = useState(false);
    const [timeLeft, setTimeLeft] = useState(null);
    const [resendEnabled, setResendEnabled] = useState(false);

    const handleEmailSubmit = async (e) => {
        e.preventDefault();
        try {
            const response = await fetch(`https://backendjaes.passiontrials.co.za/new_getUser_with_email/${email}`, {
                headers: { 'Content-Type': 'application/json' },
            });

            const data = await response.json();
            if (data && data.length > 0) {
                setCompare(data[0]);
                if (data[0].Password === 'null' || data[0].Password === null) {
                    setUpdatePassword(true);
                } else {
                    setUpdatePassword(false);
                }
                setStep(2); // Move to step 2 after checking the password
            } else {
                alert('User not found');
            }
        } catch (error) {
            console.error('Error fetching user:', error);
            alert('An error occurred during login');
        }
    };

    const handlePasswordSubmit = async (e) => {
        e.preventDefault();

        // if (compare && !compare.AccountVerification) {
        //     setVerificationNeeded(true); // Show verification popup
        //     return; // Stop further login processing until verified
        // }
        const isVerified = await liveData();
        if (!isVerified) {
            setVerificationNeeded(true); // Show verification popup
            return; // Stop further login processing until verified
        }
        if (updatePassword) {
            handleUpdatePassword();
        } else {

            normalLogin(email, password);
        }
    };

    const liveData = async () => {
        const response = await fetch(`https://backendjaes.passiontrials.co.za/new_getUser_with_email/${email}`, {
            headers: { 'Content-Type': 'application/json' },
        });

        const data = await response.json();
        if (data && data.length > 0) {
            console.log('the boolean', data[0].AccountVerification);
            return data[0].AccountVerification;
        };

    };


    const resendVerificationCode = async () => {
        try {
            const response = await fetch("https://backendjaes.passiontrials.co.za/resend_code", {
                method: "POST",
                headers: { "Content-Type": "application/json" },
                body: JSON.stringify({ email }),
            });

            const data = await response.json();
            if (response.ok) {
                Swal.fire({
                    title: "Code Sent",
                    text: data.message,
                    icon: "success",
                });
                setTimeLeft(120);
                setResendEnabled(false);
            } else {
                Swal.fire({
                    title: "Error",
                    text: data.error || "Failed to resend code.",
                    icon: "error",
                });
            }
        } catch (error) {
            Swal.fire({
                title: "Error",
                text: "Something went wrong while resending the code.",
                icon: "error",
            });
        }
    };
    useEffect(() => {
        if (verificationNeeded) {
            // Start timer when popup is shown
            setTimeLeft(120); // Set countdown duration (e.g., 120 seconds)
        } else {
            // Clear timer when popup is hidden
            setTimeLeft(null);
        }
    }, [verificationNeeded]);

    useEffect(() => {
        if (timeLeft !== null) {
            if (timeLeft > 0) {
                const timer = setTimeout(() => setTimeLeft((prev) => prev - 1), 1000);
                return () => clearTimeout(timer);
            } else if (timeLeft === 0) {
                //  resendVerificationCode(); // Auto-resend when timer reaches 0
                setResendEnabled(true);
            }
        }
    }, [timeLeft]);


    const normalLogin = async (email, password) => {
        try {
            const loginResponse = await fetch('https://backendjaes.passiontrials.co.za/new_loginn', {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ Email: email, Password: password }),
            });

            const loginData = await loginResponse.json();

            if (loginResponse.ok) {
                login(loginData.userId, loginData.Role);
                if (loginData.Role === 'Super Admin') {
                    navigate('/NewAdminDashboard');

                }
                else if (loginData.Role === 'SalesAndServiceProvider') {
                    navigate('/NewAdminDashboard');

                }
                else if (loginData.Role === 'Admin') {
                    navigate('/RegularAdmin');

                } else if (loginData.Role === 'Transport') {
                    navigate('/transport-admin');

                } else if (loginData.Role === 'Media') {
                    navigate('/event-details');

                } else if (loginData.Role === 'reserve') {
                    navigate('/ReserveUserDashboard');

                } else if (loginData.Role === 'TransportOnly') {
                    navigate('/TransportuserDashboard');

                } else if (loginData.Role === 'User') { // Handle 'User' role
                    navigate('/UserDashboard');
                } else {
                    alert('Unknown role. Please contact support.'); // Optional fallback
                }
            } else {
                alert(loginData.message || 'Failed to login');
            }
        } catch (error) {
            console.error('Login error:', error);
            alert('An error occurred during login');
        }
    };


    const handleUpdatePassword = async () => {
        if (newPassword !== confirmPassword) {
            alert('Passwords do not match.');
            return;
        }

        try {
            const hashedPassword = await bcrypt.hash(newPassword, 10);

            const response = await fetch(`https://backendjaes.passiontrials.co.za/updateUser_login/${compare.UserId}`, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    ...compare,
                    Password: hashedPassword,
                    ConfirmedUser: 1,
                }),
            });

            const data = await response.json();

            if (response.ok) {
                //alert('Password updated successfully. You will be signed in, in a moment...');
                setUpdatePassword(false);

                const isVerified = await liveData();
                if (!isVerified) {
                    setVerificationNeeded(true);
                }
                normalLogin(email, newPassword);
            } else {
                alert(data.message);
            }
        } catch (error) {
            console.error('Error updating password:', error);
        }
    };

    // const checkApartmentAndNavigate = async (userId) => {
    //     try {
    //         const res = await fetch(`https://backendjaes.passiontrials.co.za/check_apartment_paid/${userId}`);
    //         const result = await res.json();

    //         if (res.ok && result.hasPaid) {
    //             navigate('/UserDashboard');
    //         } else {
    //             const from = location.state?.from?.pathname || '/';
    //             const state = location.state?.from?.state || {};
    //             navigate(from, { state });
    //         }
    //     } catch (error) {
    //         console.error('Error checking user apartment status:', error);
    //         const from = location.state?.form?.pathname || '/';
    //         const state = location.state?.from?.state || {};
    //         navigate(from, { state });
    //     }
    // };

    const togglePasswordVisibility = () => {
        setShowPassword((prevShowPassword) => !prevShowPassword);
    };

    return (
        <div className="login-page">
            {verificationNeeded && (
                <VerificationPopup
                    email={email}
                    onResend={resendVerificationCode}
                    onVerify={() => setVerificationNeeded(false)} // Hide popup after verification
                    timeLeft={timeLeft}
                />
            )}
            <div className="login-container">
                <img src={logo} alt="Logo" className="logo" />

                <form onSubmit={step === 1 ? handleEmailSubmit : handlePasswordSubmit} className="login-form">
                    {step === 1 && (
                        <div className="input-grouplogin">
                            <input
                                type="email"
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                                placeholder="Email or Phone Number"
                                required
                                className="input-fieldlogin"
                            />
                            <button type="submit" className="input-button">
                                <i className="arrow-icon">Continue ➔</i>
                            </button>
                        </div>
                    )}

                    {step === 2 && !updatePassword && (
                        <div className="input-grouplogin">
                            <input
                                type="email"
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                                placeholder="Email"
                                required
                                className="input-fieldlogin"
                                readOnly
                            />
                            <div className="password-container">
                                <input
                                    type={showPassword ? 'text' : 'password'}
                                    value={password}
                                    onChange={(e) => setPassword(e.target.value)}
                                    placeholder="Password"
                                    required
                                    className="input-fieldlogin"

                                />
                                <span onClick={togglePasswordVisibility} className="eye-icon">
                                    {showPassword ? <EyeClosed size={20} /> : <Eye size={20} />}
                                </span>
                            </div>
                            <button type="submit" className="input-button">
                                <i className="arrow-icon">Login ➔</i>
                            </button>
                        </div>
                    )}

                    {step === 2 && updatePassword && (
                        <div className="input-grouplogin">
                            <input
                                type="email"
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                                placeholder="Email"
                                required
                                className="input-fieldlogin"
                                readOnly
                            />
                            <input
                                type="password"
                                value={newPassword}
                                onChange={(e) => setNewPassword(e.target.value)}
                                placeholder="New Password"
                                required
                                className="input-fieldlogin"
                            />
                            <input
                                type="password"
                                value={confirmPassword}
                                onChange={(e) => setConfirmPassword(e.target.value)}
                                placeholder="Confirm New Password"
                                required
                                className="input-fieldlogin"
                            />
                            <button type="submit" className="input-button">
                                <i className="arrow-icon">Update Password ➔</i>
                            </button>
                        </div>
                    )}
                </form>

                <div className="options">
                    <label>
                        <input type="checkbox" />
                        Keep me signed in
                    </label>
                    <div className="links">
                        <Link to="/ForgotPassword">Forgot password?</Link>
                        <Link to="/register">Sign Up</Link>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Login;
