import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import houseIcon from './icons8-house-50.png';
import DoublehouseIcon from './159-512.85a5305283e4ba505b9e.png';
import Apartment from './icons8-company-50.png';
import Townhouse from './townhouse.jpg';
import Cottage from './cottage.jpg';
import Swal from "sweetalert2";

const Steps2Admin = () => {
  const [typeOfHouse, settypeOfHouse] = useState(null);
  const navigate = useNavigate();

  const handleNext = () => {
    if (typeOfHouse) {
      navigate("/Steps3Admin", { state: { typeOfHouse } });
    } else {
      Swal.fire({
        icon: "warning",
        title: "Oops...",
        text: "Please select an option before proceeding.",
        confirmButtonText: "OK",
      });
    }
  };

  const handleBack = () => {
    navigate(-1);
  };

  const handleOptionClick = (option) => {
    settypeOfHouse(option);
  };

  return (
    <div>
      <style>{`
        body {
          font-family: Arial, sans-serif;
          margin: 0;
          padding: 0;
          background-color: #fff;
        }
        .container {
          max-width: 700px;
          margin: 50px auto 0;
          padding: 20px;
          text-align: center;
        }
        .container h3 {
          font-size: 28px;
          margin-bottom: 30px;
          color: rgb(36, 35, 35);
        }
        .options-grid {
          display: grid;
          grid-template-columns: repeat(3, 1fr);
          gap: 20px;
          margin-bottom: 40px;
        }
        @media (max-width: 768px) {
          .options-grid {
            grid-template-columns: repeat(2, 1fr); /* Two per row for smaller screens */
            gap: 10px; /* Reduce gap for smaller screens */
          }
          .container {
            max-width: 100%; /* Adjust width for smaller screens */
            padding: 10px; /* Add padding on all sides */
            margin: 20px auto; /* Center horizontally and add vertical margin */
          }
          .container h3 {
            font-size: 24px; /* Reduce font size for smaller screens */
          }
          .progress-step1cont {
            margin-bottom: 80px; /* Move slightly upward for mobile devices */
          }
        }
        .option {
          border: 1px solid #ddd;
          border-radius: 8px;
          padding: 15px; /* Reduce padding for smaller screens */
          display: flex;
          align-items: center;
          justify-content: flex-start;
          gap: 10px;
          cursor: pointer;
          transition: border-color 0.3s;
        }
        .option:hover {
          border-color: #000;
        }
        .option img {
          max-width: 40px; /* Reduce icon size for smaller screens */
        }
        .option span {
          font-size: 16px; /* Reduce text size for smaller screens */
          color: #333;
          text-align: left;
        }
        .option.selected {
          border-color: #ff5a5f;
          box-shadow: 0 0 10px rgba(230, 28, 13, 0.79);
        }
        .progress-step1cont {
          position: fixed;
          bottom: 0;
          left: 0;
          right: 0;
          width: 100%;
          max-width: 1200px;
          margin: 0 auto;
          padding: 0 10px;
          box-sizing: border-box;
          background: #fff;
          z-index: 1000;
        }
        .progress-bar {
          height: 4px;
          background: #e0e0e0;
          margin-bottom: 5px;
          position: relative;
        }
        .progress {
          height: 100%;
          width: 33.32%;
          background: #000;
          transition: width 0.3s ease-in-out;
        }
        .buttons-step1cont {
          display: flex;
          justify-content: space-between;
          align-items: center;
          width: 100%;
          margin-top: 10px;
        }
        .back-button {
          text-decoration: underline;
          color: #ff5a5f;
          background: none;
          border: none;
          max-width: 50px;
          cursor: pointer;
          font-size: 16px;
          padding: 15px 0px;
        }
        .back-button:hover {
          text-decoration: underline;
          color: #fff;
          background: #ff5a5f;
          border: none;
          max-width: 50px;
          cursor: pointer;
          font-size: 16px;
          padding: 15px 0px;
        }
        .next-button {
          background: #ff5a5f;
          max-width: 70px;
          color: #fff;
          border: none;
          padding: 10px 20px;
          border-radius: 4px;
          font-size: 16px;
          cursor: pointer;
        }
        @media (max-width: 768px) {
          .buttons-step1cont {
            justify-content: space-between; /* Space buttons evenly */
            padding: 0 20px; /* Add padding to prevent buttons from touching edges */
          }
          .next-button {
            margin-left: 0; /* Remove margin for better alignment */
          }
        }
      `}</style>
      <div className="container">
        <h3>Which of these best describes your place?</h3>
        <div className="options-grid">
          <div
            className={`option ${typeOfHouse === "House" ? "selected" : ""}`}
            onClick={() => handleOptionClick("House")}
          >
            <img src={houseIcon} alt="House" />
            <span>House</span>
          </div>
          <div
            className={`option ${typeOfHouse === "Double Story" ? "selected" : ""}`}
            onClick={() => handleOptionClick("Double Story")}
          >
            <img src={DoublehouseIcon} alt="Apartment" />
            <span>Double Story</span>
          </div>
          <div
            className={`option ${typeOfHouse === "Apartment" ? "selected" : ""}`}
            onClick={() => handleOptionClick("Apartment")}
          >
            <img src={Apartment} alt="Cottage" />
            <span>Apartment</span>
          </div>
          <div
            className={`option ${typeOfHouse === "Townhouse" ? "selected" : ""}`}
            onClick={() => handleOptionClick("Townhouse")}
          >
            <img src={Townhouse} alt="Townhouse" />
            <span>Townhouse</span>
          </div>
          <div
            className={`option ${typeOfHouse === "Cottage" ? "selected" : ""}`}
            onClick={() => handleOptionClick("Cottage")}
          >
            <img src={Cottage} alt="Apartment" />
            <span>Cottage</span>
          </div>
        </div>
      </div>
      <div className="progress-step1cont">
        <div className="progress-bar">
          <div className="progress"></div>
        </div>
        <div className="buttons-step1cont">
          <button className="back-button" onClick={handleBack}>
            Back
          </button>
          <button className="next-button" onClick={handleNext}>
            Next
          </button>
        </div>
      </div>
    </div>
  );
};

export default Steps2Admin;