
import React, { useState, useEffect } from 'react';
// import './NsfasBookings.css';
import Swal from 'sweetalert2';

function NsfasApproval() {
    const [bookings, setBookings] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [loadingStates, setLoadingStates] = useState({});


    useEffect(() => {
        fetchBookings();
    }, []);

    const fetchBookings = () => {
        setLoading(true);
        fetch('https://backendjaes.passiontrials.co.za/api/nsfas-bookings')
            .then(res => res.json())
            .then(data => {
                setBookings(data);
                setLoading(false);
            })
            .catch(err => {
                console.error("Error fetching bookings:", err);
                setError("Failed to fetch bookings.");
                setLoading(false);
            });
    };

    const handleApproval = async (id, status, user, room, buttonType) => {
        try {
            setLoadingStates((prev) => ({ ...prev, [`${id}-${buttonType}`]: true }));

            const response = await fetch(`https://backendjaes.passiontrials.co.za/api/nsfas-bookings/${id}`, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ ApprovalStatus: status, Approval: status === 'Approved' ? 1 : 0 }),
            });

            if (!response.ok) {
                throw new Error('Failed to update approval status');
            }

            if (status === 'Approved') {
                await associateUserWithApartment(room, user);
                await markApartmentAsOccupied(room);

                const apartment = await fetchSpecifcApartment(room); // Wait for apartment data

                const buildingName = await fetchBuilding(room);

                await saveToWallet(apartment, user, 0, 0, room); // Ensure the data is correctly passed

                const singleBooker = await getLatestInfo(user);

                // if (singleBooker.PaidTransport == true) {

                //     await transport(user, buildingName);
                //     console.log('yes paid for transport');
                // }



            }

            if (status === 'Dismissed') {
                await dismissUser(room, user);
            }

            fetchBookings(); // Refresh bookings
        } catch (err) {
            console.error(`Error updating booking with ID ${id}:`, err.message);
        }
        finally {
            setLoadingStates((prev) => ({ ...prev, [`${id}-${buttonType}`]: false })); // Reset loader for specific button
        }
    };

    const fetchSpecifcApartment = async (id) => {
        try {
            const response = await fetch(`https://backendjaes.passiontrials.co.za/apartment_tb/single/${id}`);
            if (!response.ok) {
                throw new Error('Failed to fetch apartment data');
            }
            const data = await response.json();
            console.log("fetched data:", data.data[0]);
            return data.data[0]; // Properly return the first apartment object
        } catch (err) {
            console.error('Error fetching apartment data:', err.message);
            return null; // Return null if there is an error
        } finally {
            setLoading(false); // Ensure loading state is updated
        }
    };

    const fetchBuilding = async (id) => {
        try {
            const response = await fetch(`https://backendjaes.passiontrials.co.za/apartment_tb/singlewithjoint/${id}`);
            if (!response.ok) {
                throw new Error('Failed to fetch apartment data');
            }
            const data = await response.json();
            console.log("fetched data:", data.data[0]);
            return data.data[0]; // Properly return the first apartment object
        } catch (err) {
            console.error('Error fetching building & apartment data:', err.message);
            return null; // Return null if there is an error
        } finally {
            setLoading(false); // Ensure loading state is updated
        }
    };


    const getLatestInfo = async (user) => {
        try {
            const d = await fetch(`https://backendjaes.passiontrials.co.za/api/nsfas-bookings/${user}`, {
                headers: { 'Content-Type': 'application/json' },

            });
            const data = await d.json();
            if (data && data.length > 0) {

                console.log("User fetched data:", data[0]);
                return data[0]; // Return fetched data
            } else {
                throw new Error("No user data found for the given user ID.");
            }
        } catch (error) {
            console.log('Failed to retrieve eft info ');
        }

    };

    const isAssociated = async (user) => {
        try {
            const response = await fetch(`https://backendjaes.passiontrials.co.za/user_apartment_exists/${user}`, {
                headers: { 'Content-Type': 'application/json' },
            });

            if (!response.ok) {
                console.error("Failed to fetch association status:", response.statusText);
                throw new Error("Failed to fetch association status"); // Throw an error instead of returning false
            }

            const data = await response.json();
            return data.exists; // Return the "exists" boolean value only if the response is valid
        } catch (error) {
            console.error("Error occurred while checking if associated:", error.message);
            throw error; // Re-throw the error for handling elsewhere
        }
    };

    const markApartmentAsOccupied = async (roomid) => {
        try {
            // Ensure apartmentId exists before proceeding
            if (roomid) {
                // Decrement the Capacity2 in the apartment_tb table
                const decrementCapacity = await fetch(`https://backendjaes.passiontrials.co.za/decrement-capacity/${roomid}`, {
                    method: 'PUT',
                    headers: { 'Content-Type': 'application/json' }
                });

                if (!decrementCapacity.ok) {
                    const errorText = await decrementCapacity.text();
                    throw new Error(`Failed to decrement apartment capacity: ${errorText}`);
                }

                console.log('Apartment capacity has been decremented successfully');
            } else {
                console.log('No apartment or room found');
            }
        } catch (error) {
            throw new Error(`Error marking apartment as occupied: ${error.message}`);
        }
    };

    const associateUserWithApartment = async (room, user) => {
        try {
            //  const moveInDate = new Date(date);
            // const moveOutDate = new Date(moveInDate); // Clone the move-in date
            // moveOutDate.setMonth(moveOutDate.getMonth() + 1);

            // Format the moveOutDate to the required format
            // const formattedMoveOutDate = moveOutDate.toLocaleString('en-US', {
            //     day: 'numeric',
            //     month: 'long',
            //     year: 'numeric',
            //     hour: '2-digit',
            //     minute: '2-digit',
            //     second: '2-digit',
            //     hour12: true,
            // });

            const response = await fetch('https://backendjaes.passiontrials.co.za/user_apartment_tb', {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({
                    UserId: user,
                    ApartmentId: room,
                    // MoveInDate: moveInDate.toLocaleDateString('en-US', { day: 'numeric', month: 'long', year: 'numeric' }),
                    // MoveOutDate: formattedMoveOutDate, // Use the formatted date
                    MoveInDate: 'test',
                    MoveOutDate: 'test',
                    RentAmount: 0,
                    LeaseTerm: '1 Month',
                    IsActive: true,
                    DepositPaid: true,
                    ApartmentRating: 5,
                    Guest: 1, // Default guest number
                    Notes: 'NSFAS Booker',
                    TenantAgreementType: 'Month-to-Month',
                    ChosenPlan: "NSFAS",
                    ServiceFee: 0,
                    RentCost: 0,
                    PayableRent: 0
                })
            });

            if (!response.ok) {
                const errorText = await response.text();
                throw new Error(`Failed to associate user with apartment: ${errorText}`);
            }

            console.log('User associated with apartment successfully!');
        } catch (error) {
            throw new Error(`Error associating user with apartment: ${error.message}`);
        }
    };

    const dismissUser = async (roomid, userid) => {
        try {
            const isAss = await isAssociated(userid);

            if (isAss) {
                console.log("Deleted  NSFAS  user that was associated.");

            } else {
                console.log("Deleted NSFAS user is NOT associated with an apartment.");
            }


            const removeApartmentFromUser = await fetch(`https://backendjaes.passiontrials.co.za/api/nsfas-bookings/${userid}`, {
                method: 'DELETE',
                headers: { 'Content-Type': 'application/json' },
            });

            if (!removeApartmentFromUser.ok && removeApartmentFromUser.status !== 404) {
                const errorText = await removeApartmentFromUser.text();
                throw new Error(`Failed to delete user apartment record: ${errorText}`);
            }

            if (removeApartmentFromUser.ok) {
                fetchBookings();
                console.log('deleted and refreshed');


            }

            console.log('Dismiss process completed successfully');
        }
        catch (error) {
            console.error('Error dismissing user:', error.message);
        }

        // const removeApartmentFromUser = await fetch(`https://backendjaes.passiontrials.co.za/delete-user-apartment/${userid}`, {
        //     method: 'DELETE',
        //     headers: { 'Content-Type': 'application/json' },
        // });

        // if (!removeApartmentFromUser.ok) {
        //     if (removeApartmentFromUser.status !== 404) { // Ignore 404 errors as this might be expected
        //         const errorText = await removeApartmentFromUser.text();
        //         throw new Error(`Failed to delete user apartment record: ${errorText}`);
        //     } else {
        //         console.log('No apartment record found for the user, which is expected.');
        //     }
        // } else {
        //     console.log('Apartment has been removed from user successfully');
        // }

        // // Increment the Capacity2 for the ApartmentId
        // const incrementCapacity = await fetch(`https://backendjaes.passiontrials.co.za/increment-capacity/${roomid}`, {
        //     method: 'PUT',
        //     headers: { 'Content-Type': 'application/json' },
        // });

        // if (!incrementCapacity.ok) {
        //     if (incrementCapacity.status !== 404) { // Ignore 404 errors as this might be expected
        //         const errorText = await incrementCapacity.text();
        //         throw new Error(`Failed to increment apartment capacity: ${errorText}`);
        //     } else {
        //         console.log('No apartment record found to increment capacity, which may be expected.');
        //     }
        // } else {
        //     console.log('Apartment capacity has been incremented successfully');
        // }
    };

    if (loading) {
        return <p>Loading...</p>;
    }

    if (error) {
        return console.log('an error occured', error);
    }


    const saveToWallet = async (apartmentData, user, dailyRate, daysPaidFor, room) => {
        try {

            console.log('aparmtent info', apartmentData.ApartmentId, apartmentData.ApartmentPrice);
            const response = await fetch('https://backendjaes.passiontrials.co.za/wallet', {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({
                    UserId: user,
                    ApartmentId: room,
                    PaymentDate: new Date().toISOString().split('T')[0], // Format as 'YYYY-MM-DD'
                    AmountPaid: apartmentData.ApartmentPrice,
                    DailyRate: dailyRate.toFixed(2) || 0,
                    DaysPaidFor: daysPaidFor || 0,
                    MonthYear: new Date().toLocaleDateString('en-US', { month: 'long', year: 'numeric' }),
                    PaymentMethod: "EFT"
                })
            });
            if (!response.ok) {
                const errorText = await response.text();
                throw new Error(`Failed to save to wallet: ${errorText}`);
            }
            console.log('Wallet record saved successfully!');


            const removeFromList = await fetch(`https://backendjaes.passiontrials.co.za/api/nsfas-bookings/${user}`, {
                method: 'DELETE',
                headers: { 'Content-Type': 'application/json' },
            });

            if (!removeFromList.ok && removeFromList.status !== 404) {
                const errorText = await removeFromList.text();
                throw new Error(`Failed to delete user apartment record: ${errorText}`);
            }

            if (removeFromList.ok) {
                // fetchBookings();
                console.log('deleted and refreshed');


            }
        } catch (error) {
            throw new Error(`Error saving to wallet: ${error.message}`);
        }
    }

    // const transport = async (user, buildingDetails) => {

    //     const date = new Date();
    //     const currentMonth = date.toLocaleDateString('en-US', { month: 'long' });
    //     const currentYear = date.getFullYear();

    //     const paymentData = {
    //         UserId: user,
    //         Month: `${currentMonth} ${date.getFullYear()}`,
    //         Price: 250,
    //         Building: buildingDetails.BuildingName,
    //     };

    //     // Initialize all months to 0
    //     const months = {
    //         january: 0,
    //         february: 0,
    //         march: 0,
    //         april: 0,
    //         may: 0,
    //         june: 0,
    //         july: 0,
    //         august: 0,
    //         september: 0,
    //         october: 0,
    //         november: 0,
    //         december: 0,
    //     };

    //     // Set the current month to 'Paid'
    //     months[currentMonth] = 'Paid';

    //     // Add months data to paymentData
    //     Object.assign(paymentData, months);
    //     try {
    //         const response = await fetch('https://backendjaes.passiontrials.co.za/transport_monthly_payment', {
    //             method: 'POST',
    //             headers: { 'Content-Type': 'application/json' },
    //             body: JSON.stringify(paymentData),
    //         });

    //         const data = await response.json();
    //         if (response.ok) {
    //             console.log('Transport payment recorded successfully:', data);
    //         }


    //     }
    //     catch (error) {
    //         console.log('Failed record transport payment', error);

    //     }

    // };

    const handleDismissConfirmation = (id, user, room, btn) => {
        Swal.fire({
            title: 'Are you sure?',
            text: 'You are about to dismiss this user from the booking list. This action cannot be undone.User remains associated with room ,if approved.',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#d33',
            cancelButtonColor: '#3085d6',
            confirmButtonText: 'Yes, dismiss it!',
        }).then((result) => {
            if (result.isConfirmed) {
                handleApproval(id, 'Dismissed', user, room, 'none', btn);
                Swal.fire('Dismissed!', 'The booking has been dismissed.', 'success');
            }
        });
    };



    return (
        <div className="nsfas-bookings-container">
            <h2>NSFAS Bookings</h2>
            {bookings.length === 0 ? (
                <div>No bookings found.</div>
            ) :
                (<table className="nsfas-bookings-table">
                    <thead>
                        <tr>
                            <th>Booking ID</th>
                            <th>Name</th>
                            <th>Surname</th>
                            <th>Email</th>
                            <th>Approval Status</th>
                            <th>Actions</th>
                        </tr>
                    </thead>
                    <tbody>
                        {bookings.map(booking => (
                            <tr key={booking.BookingId}>
                                <td>{booking.BookNumber}</td>
                                <td>{booking.Name}</td>
                                <td>{booking.Surname}</td>
                                <td>{booking.Email}</td>
                                <td>{booking.ApprovalStatus}</td>
                                <td>
                                    <button
                                        onClick={() => handleApproval(booking.BookingId, "Approved", booking.UserId, booking.ApartmentId, "approve")}
                                        // disabled={booking.ApprovalStatus === "Approved"}
                                        disabled={booking.ApprovalStatus === 'Approved' || loadingStates[`${booking.BookingId}-approve`]}

                                        style={{ backgroundColor: 'green' }}

                                    >
                                        {loadingStates[`${booking.BookingId}-approve`] ? (
                                            <div className="loader"></div>
                                        ) : (
                                            'Approve'
                                        )}
                                    </button>
                                    <button
                                        onClick={() =>
                                            handleDismissConfirmation(booking.BookingId, booking.UserId, booking.ApartmentId, 'dismiss')
                                        }
                                        // disabled={booking.ApprovalStatus === "Dismissed"}
                                        disabled={booking.ApprovalStatus === 'Dismissed' || loadingStates[`${booking.BookingId}-dismiss`]}

                                    >
                                        {loadingStates[`${booking.BookingId}-dismiss`] ? (
                                            <div className="loader"></div>
                                        ) : (
                                            'Dismiss'
                                        )}                                    </button>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>)}
            <style>{`.nsfas-bookings-container {
    margin: 20px;
    padding: 20px;
    background-color: #f9f9f9;
    border-radius: 8px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

.nsfas-bookings-table {
    width: 100%;
    border-collapse: collapse;
    margin-top: 20px;
}

.nsfas-bookings-table th,
.nsfas-bookings-table td {
    padding: 12px 15px;
    text-align: left;
    border: 1px solid #ddd;
}

.nsfas-bookings-table th {
    background-color: #007bff;
    color: white;
}

.nsfas-bookings-table tr:nth-child(even) {
    background-color: #f2f2f2;
}

.nsfas-bookings-table button {
    padding: 8px 12px;
    margin: 0 5px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
}

.nsfas-bookings-table button:disabled {
    background-color: #ccc;
    cursor: not-allowed;
}

.nsfas-bookings-table button:hover:not(:disabled) {
    background-color: #0056b3;
    color: white;
}
         @keyframes spin {
                    0% { transform: rotate(0deg); }
                    100% { transform: rotate(360deg); }
                }
                .loader {
                    border: 3px solid #f3f3f3; /* Light grey */
                    border-top: 3px solid #3498db; /* Blue */
                    border-radius: 50%;
                    width: 16px;
                    height: 16px;
                    animation: spin 1s linear infinite;
                    display: inline-block;
                    margin-right: 8px;
                }
`}</style>
        </div>
    );
}

export default NsfasApproval;
