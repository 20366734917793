import React, { useState, useEffect} from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { useAuth } from "./AuthProvider"; // Import useAuth

import wifi from "./wi-fi-icon.png"; // Import images as needed
import tvIcon from "./smart-tv.png";
import kitchenIcon from "./gas-stove.png";
import washerIcon from "./washer.png";
import freeparcking from "./parking-area.png";
import freeparckingmeter from "./parking-meter.png";
import aircon from "./icons8-air-conditioner-64.png";
import workplace from "./workspace.png";

import pool from "./swimming-pool.png";
import hottub from "./hot-bath.png";
import patio from "./silhouette.png";
import bbq from "./bbq.png";
import fireplace from "./fireplace.png";
import Area from "./outside.png";
import pooltable from "./billiards.png";
import piano from "./grand-piano.png";
import exercise from "./exercise.png";
import beach from "./wave.png";
import OutShower from "./shower.png";

import SmokeAlarm from "./smoke-detector.png";
import FirsttKit from "./first-aid-kit.png";
import FireExting from "./fire-extinguisher.png";
import CarbonMon from "./siren.png";

const AmenityOption = ({ amenity, isSelected, toggleSelection }) => {
  return (
    <div
      className={`option ${isSelected ? "selected" : ""}`}
      onClick={() => toggleSelection(amenity.name)}
    >
      <img src={amenity.icon} alt={amenity.name} />
      <span>{amenity.name}</span>
    </div>
  );
};

const Steps3_5Admin = () => {
  const [selectedGuestFavorites, setSelectedGuestFavorites] = useState([]);
  const [selectedAdditionalAmenities, setSelectedAdditionalAmenities] = useState([]);
  const [selectedSafetyItems, setSelectedSafetyItems] = useState([]);
  const navigate = useNavigate();
  const location = useLocation();

  const { userId } = useAuth(); // Get the user object from the auth context

  const { typeOfHouse, typeOfPlace } = location.state || {};
  const toggleSelection = (amenityName, category) => {
    const setter =
      category === "guestFavorites"
        ? setSelectedGuestFavorites
        : category === "additionalAmenities"
        ? setSelectedAdditionalAmenities
        : setSelectedSafetyItems;

    setter((prev) =>
      prev.includes(amenityName)
        ? prev.filter((item) => item !== amenityName)
        : [...prev, amenityName]
    );
  };
  useEffect(() => {
    if (userId) { // Ensure userId is available before making the request
      fetch(`https://backendjaes.passiontrials.co.za/api/get-admin-amenities?AdminId=${userId}`)
        .then((response) => response.json())
        .then((data) => {
          setSelectedGuestFavorites(JSON.parse(data.GuestFavorites || "[]"));
          setSelectedAdditionalAmenities(JSON.parse(data.AdditionalAmenities || "[]"));
          setSelectedSafetyItems(JSON.parse(data.SafetyItems || "[]"));
        })
        .catch((error) => console.error("Error fetching data:", error));
    }
  }, [userId]); // Add userId as a dependency to re-run when it changes
  
  
  const handleNext = () => {
    if (
      selectedGuestFavorites.length === 0 &&
      selectedAdditionalAmenities.length === 0 &&
      selectedSafetyItems.length === 0
    ) {
      alert("Please select at least one item.");
      return;
    }
  
    // Make a POST request to save data
    fetch("https://backendjaes.passiontrials.co.za/api/update-admin-amenities", {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        AdminId: userId, // Replace with dynamic AdminId
        GuestFavorites: selectedGuestFavorites,
        AdditionalAmenities: selectedAdditionalAmenities,
        SafetyItems: selectedSafetyItems,
      }),
    })
      .then((response) => {
        if (response.ok) {
          navigate("/Steps4Admin",  {
            state: {
              typeOfHouse,
              typeOfPlace,
            },
          });
        } else {
          alert("Error saving data");
        }
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };
  

  const handleBack = () => {
    navigate(-1);
  };

  const guestFavorites = [
    { name: "Wifi", icon: wifi },
    { name: "TV", icon: tvIcon },
    { name: "Kitchen", icon: kitchenIcon },
    { name: "Washer", icon: washerIcon },
    { name: "Free parking on premises", icon: freeparcking },
    { name: "Paid parking on premises", icon: freeparckingmeter },
    { name: "Air conditioning", icon: aircon },
    { name: "Dedicated workspace", icon: workplace },
  ];

  const additionalAmenities = [
    { name: "Pool", icon: pool },
    { name: "Hot tub", icon: hottub },
    { name: "Patio", icon: patio },
    { name: "BBQ grill", icon: bbq },
    { name: "Outdoor dining area", icon: Area },
    { name: "Indoor fireplace", icon: fireplace },
    { name: "Pool table", icon: pooltable },
    { name: "Piano", icon: piano },
    { name: "Exercise equipment", icon: exercise },
    { name: "Beach access", icon: beach },
    { name: "Outdoor shower", icon: OutShower },
  ];

  const safetyItems = [
    { name: "Smoke alarm", icon: SmokeAlarm },
    { name: "First aid kit", icon: FirsttKit },
    { name: "Fire extinguisher", icon: FireExting },
    { name: "Carbon monoxide alarm", icon: CarbonMon },
  ];

  return (
    <div>
      <style>{`
        .container {
          max-width: 600px;
          margin: 50px auto;
          padding: 20px;
          font-family: Arial, sans-serif;
        }
          
        .container h2 {
          font-size: 29px;
          margin-bottom: 10px;
          color: #242323;
          text-align: left;
        }
        .container h3 {
          font-size: 18px;
          margin-bottom: 10px;
          color: #242323;
          text-align: left;
        }
        .container p {
          font-size: 20px;
          margin-bottom: 20px;
          color: #555;
          text-align: left;
        }
        .options-grid {
          display: grid;
          grid-template-columns: repeat(2, 1fr);
          gap: 20px;
          margin-bottom: 40px;
        }
        @media (min-width: 768px) {
          .options-grid {
            grid-template-columns: repeat(3, 1fr);
          }
        }
        .option {
          border: 1px solid #ff5a5f;
          border-radius: 8px;
          padding: 10px;
          cursor: pointer;
          transition: border-color 0.3s, box-shadow 0.3s;
          text-align: left;
          display: flex;
          flex-direction: column;
          align-items: flex-start;
        }
        .option:hover {
          border-color: #000;
          box-shadow: 0 0 10px rgba(255, 90, 96, 0.17);
        }
        .option.selected {
          border-color: #ff5a5f;
          box-shadow: 0 0 10px #ff5a5f;
          background-color:rgba(255, 90, 96, 0.17);
        }
        .option span {
          font-size: 16px;
          color: #333;
          text-align: left;
          width: 100%;
        }
        .progress-step1cont {
  position: fixed; /* Fix the container at a specific position */
  bottom: 0; /* Place it at the bottom of the viewport */
  left: 0;
  right: 0;
  width: 100%; /* Make sure it spans the full width */
  max-width: 1200px;
  margin: 0 auto; /* Center it horizontally */
  padding: 0 10px;
  box-sizing: border-box;
  background: #fff; /* Add a background to prevent overlap issues */
  z-index: 1000; /* Ensure it stays above other elements */
}
          .progress-bar {
          height: 4px;
          background: #e0e0e0;
          margin-bottom: 5px;
          position: relative;
        }
        .progress {
          height: 100%;
          width: 60%;
          background: #000;
          transition: width 0.3s ease-in-out;
        }
        .buttons-step1cont {
          display: flex;
          justify-content: space-between; /* Space between buttons */
          align-items: center;
          width: 100%;
          margin-top: 10px; /* Add some space above buttons */
        }
         .back-button {
          text-decoration: underline;
          color: #ff5a5f;
          background: none;
          border: none;
          max-width: 50px;
          cursor: pointer;
          font-size: 16px;
          padding: 15px 0px;
        }

        .back-button:hover {
          text-decoration: underline;
          color: #fff;
          background: #ff5a5f;
          border: none;
          max-width: 50px;
          cursor: pointer;
          font-size: 16px;
          padding: 15px 0px;
        }

        .next-button {
          background: #ff5a5f;
          max-width: 70px;
          color: #fff;
          border: none;
          padding: 10px 20px;
          border-radius: 4px;
          font-size: 16px;
          cursor: pointer;
        }     
@media (max-width: 768px) {
  .buttons-step1cont {
    justify-content: flex-start; /* Align all buttons to the left */
  }
  .next-button {
    margin-left: 150px; /* Add some spacing from the left edge */
  }
}
        .option img {
          width: 30px;
          height: 30px;
          flex-shrink: 0;
          align-self: flex-start;
        }
      `}</style>
      <div className="container">
        <h2>Tell guests what your place has to offer</h2>
        <p>You can add more amenities after you publish your listing.</p>

        <h3>Guest Favorites</h3>
        <div className="options-grid">
          {guestFavorites.map((amenity) => (
            <AmenityOption
              key={amenity.name}
              amenity={amenity}
              isSelected={selectedGuestFavorites.includes(amenity.name)}
              toggleSelection={(name) => toggleSelection(name, "guestFavorites")}
            />
          ))}
        </div>

        <h3>Do you have any standout amenities?</h3>
        <div className="options-grid">
          {additionalAmenities.map((amenity) => (
            <AmenityOption
              key={amenity.name}
              amenity={amenity}
              isSelected={selectedAdditionalAmenities.includes(amenity.name)}
              toggleSelection={(name) => toggleSelection(name, "additionalAmenities")}
            />
          ))}
        </div>

        <h3>Safety Items</h3>
        <div className="options-grid">
          {safetyItems.map((item) => (
            <AmenityOption
              key={item.name}
              amenity={item}
              isSelected={selectedSafetyItems.includes(item.name)}
              toggleSelection={(name) => toggleSelection(name, "safetyItems")}
            />
          ))}
        </div>
      </div>
      <div className="progress-step1cont">
        <div className="progress-bar">
          <div className="progress"></div>
        </div>
        <div className="buttons-step1cont">
          <button className="back-button" onClick={handleBack}>
            Back
          </button>
          <button className="next-button" onClick={handleNext}>
            Next
          </button>
        </div>
      </div>
    </div>
  );
};

export default Steps3_5Admin;

