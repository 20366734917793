// import React, { useState, useEffect } from 'react';
// import { useNavigate } from 'react-router-dom';
// import { useAuth } from '../AuthProvider';
// import './LoginModal.css';
// import VerificationPopup2 from './loginVerificationPopup.js';
// import Swal from "sweetalert2";
// import { X } from 'lucide-react'; // Importing an icon for close button

// function LoginModal({ clickedAction, onClose, onSuccess, date, apartment, apartmentandbuilding, totalAmount, serviceFee, chosenPlan, nextPaymentDate }) {
//     const [email, setEmail] = useState('');
//     const [password, setPassword] = useState('');
//     const [loading, setLoading] = useState(false);
//     const [error, setError] = useState('');
//     const [verificationNeeded, setVerificationNeeded] = useState(false);
//     const [timeLeft, setTimeLeft] = useState(null);

//     const navigate = useNavigate();
//     const { login } = useAuth();

//     const liveData = async () => {
//         const response = await fetch(`https://backendjaes.passiontrials.co.za/new_getUser_with_email/${email}`, {
//             headers: { 'Content-Type': 'application/json' },
//         });

//         const data = await response.json();
//         return data.length > 0 ? data[0].AccountVerification : false;
//     };

//     const resendVerificationCode = async () => {
//         try {
//             const response = await fetch("https://backendjaes.passiontrials.co.za/resend_code", {
//                 method: "POST",
//                 headers: { "Content-Type": "application/json" },
//                 body: JSON.stringify({ email }),
//             });

//             const data = await response.json();
//             if (response.ok) {
//                 Swal.fire({
//                     title: "Code Sent",
//                     text: data.message,
//                     icon: "success",
//                 });
//                 setTimeLeft(120);
//             } else {
//                 Swal.fire({
//                     title: "Error",
//                     text: data.error || "Failed to resend code.",
//                     icon: "error",
//                 });
//             }
//         } catch (error) {
//             Swal.fire({
//                 title: "Error",
//                 text: "Something went wrong while resending the code.",
//                 icon: "error",
//             });
//         }
//     };

//     useEffect(() => {
//         if (verificationNeeded) {
//             setTimeLeft(120);
//         } else {
//             setTimeLeft(null);
//         }
//     }, [verificationNeeded]);

//     useEffect(() => {
//         if (timeLeft !== null && timeLeft > 0) {
//             const timer = setTimeout(() => setTimeLeft(prev => prev - 1), 1000);
//             return () => clearTimeout(timer);
//         } else if (timeLeft === 0) {
//             resendVerificationCode();
//         }
//     }, [timeLeft]);

//     const handleSubmit = async (e) => {
//         e.preventDefault();
//         const isVerified = await liveData();

//         if (!isVerified) {
//             setVerificationNeeded(true);
//             return;
//         }

//         setLoading(true);
//         setError('');

//         try {
//             const response = await fetch('https://backendjaes.passiontrials.co.za/new_loginn', {
//                 method: 'POST',
//                 headers: { 'Content-Type': 'application/json' },
//                 body: JSON.stringify({ Email: email, Password: password }),
//             });

//             const data = await response.json();

//             if (response.ok) {
//                 login(data.userId, data.Role);
//                 onSuccess();
//                 onClose();

//                 if (clickedAction === 'downloadPDF') {
//                     onSuccess();
//                 } else if (clickedAction === 'pay') {
//                     navigate('/YocoPayment', {
//                         state: { date, apartment, apartmentandbuilding, totalAmount, serviceFee, chosenPlan, nextPaymentDate },
//                     });
//                 }
//             } else {
//                 setError(data.message || 'Login failed. Please try again.');
//             }
//         } catch (err) {
//             setError('An error occurred. Please try again later.');
//         } finally {
//             setLoading(false);
//         }
//     };

//     return (
//         <div className="login-modal-overlay">
//             {verificationNeeded && (
//                 <VerificationPopup2
//                     email={email}
//                     onResend={resendVerificationCode}
//                     onVerify={() => setVerificationNeeded(false)}
//                     timeLeft={timeLeft}
//                 />
//             )}
//             <div className="login-modal-content">
//                 <button className="close-btn" onClick={onClose}>
//                     <X size={20} />
//                 </button>
//                 <h2>Login</h2>
//                 {error && <p className="error-message">{error}</p>}
//                 <form onSubmit={handleSubmit}>
//                     <div className="form-group">
//                         <label>Email</label>
//                         <input
//                             type="email"
//                             value={email}
//                             onChange={(e) => setEmail(e.target.value)}
//                             placeholder="e.g maxwell@gmail.com"
//                             required
//                         />
//                     </div>
//                     <div className="form-group">
//                         <label>Password</label>
//                         <input
//                             type="password"
//                             value={password}
//                             onChange={(e) => setPassword(e.target.value)}
//                             placeholder="e.g @!Qwerty123"
//                             required
//                         />
//                     </div>
//                     <div className="button-group">
//                         <button type="submit" disabled={loading}>
//                             {loading ? 'Logging in...' : 'Login'}
//                         </button>
//                     </div>
//                 </form>
//             </div>
//             <style>{`.login-modal-overlay {
//     position: fixed;
//     top: 0;
//     left: 0;
//     right: 0;
//     bottom: 0;
//     background: rgba(0, 0, 0, 0.5);
//     display: flex;
//     justify-content: center;
//     align-items: center;
//     z-index: 1000;
// }

// .login-modal-content {
//     background: #fff;
//     padding: 20px;
//     border-radius: 10px;
//     width: 380px;
//     font-size: 14px;
//     box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.2);
//     position: relative; /* Needed for absolute positioning of the close button */
// }

// h2 {
//     text-align: left;
//     margin-bottom: 20px;
//     color: #333;
// }

// .form-group {
//     display: flex;
//     flex-direction: column;
//     align-items: flex-start; /* Align labels to the left */
//     margin-bottom: 15px;
// }

// .form-group label {
//     font-size: 14px;
//     font-weight: bold;
//     color: #444;
//     margin-bottom: 5px;
// }

// .form-group input {
//     width: 100%;
//     padding: 10px;
//     border: 1px solid #ddd;
//     border-radius: 5px;
//     font-size: 14px;
// }

// .button-group {
//     display: flex;
//     justify-content: center;
//     margin-top: 20px;
// }

// button {
//     width: 100%;
//     padding: 10px;
//     background-color: #ff5a5f;
//     color: #fff;
//     border: none;
//     border-radius: 5px;
//     cursor: pointer;
//     font-size: 14px;
//     transition: background 0.3s ease;
// }

// button:hover {
//     background-color: #e04e53;
// }

// button:disabled {
//     background-color: #ccc;
//     cursor: not-allowed;
// }

// /* Fix close button position */
// .close-btn {
//     position: absolute;
//     top: 15px;  /* Adjust to ensure proper spacing from the top */
//     right: 15px; /* Adjust to ensure proper spacing from the right */
//     background: transparent;
//     border: none;
//     font-size: 18px;
//     cursor: pointer;
//     color: #888;
//     display: flex;
//     align-items: center;
//     justify-content: center;
//     width: 50px;
//     height: 50px;
//     border-radius: 50%;
//     transition: color 0.3s ease, background 0.3s ease;
// }

// /* Add hover effect */
// .close-btn:hover {
//     color: #ff5a5f;
//     background: rgba(255, 90, 95, 0.1);
// }
// `}</style>
//         </div>
//     );
// }

// export default LoginModal;




import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../AuthProvider';
import './LoginModal.css';
import VerificationPopup2 from './loginVerificationPopup.js';
import Swal from "sweetalert2";

function LoginModal({ clickedAction, onClose, onSuccess, date, apartment, apartmentandbuilding, totalAmount, serviceFee, chosenPlan, nextPaymentDate }) {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState('');

    const [verificationNeeded, setVerificationNeeded] = useState(false);//9 jan 2025
    const [timeLeft, setTimeLeft] = useState(null);

    const [resendEnabled, setResendEnabled] = useState(false);

    const navigate = useNavigate();
    const { login } = useAuth();

    // const liveData = async () => {
    //     const response = await fetch(`https://backendjaes.passiontrials.co.za/new_getUser_with_email/${email}`, {
    //         headers: { 'Content-Type': 'application/json' },
    //     });

    //     const data = await response.json();
    //     if (data && data.length > 0) {
    //         console.log('the boolean', data[0].AccountVerification);
    //         return data[0].AccountVerification;
    //     } else if (!data.ok) {
    //         return 'User Not found';
    //     }

    // };
    const liveData = async () => {
        try {
            const response = await fetch(`https://backendjaes.passiontrials.co.za/new_getUser_with_email/${email}`, {
                headers: { 'Content-Type': 'application/json' },
            });

            if (!response.ok) {
                const errorData = await response.json();
                if (response.status === 404) {
                    console.error("API Response: No user or admin found");
                    return null; // Ensure we don't trigger the popup
                } else {
                    console.error(`Unexpected API response: ${errorData.message || "Unknown error"}`);
                    return null; // Avoid showing the verification popup if there's an issue
                }
            }

            const data = await response.json();

            if (data && data.length > 0) {
                console.log('Account Verification Status:', data[0].AccountVerification);
                return data[0].AccountVerification; // Return true or false
            } else {
                console.warn("API returned an empty array, treating as no user found.");
                return null; // Prevent the verification popup
            }
        } catch (error) {
            console.error("Network error while fetching user data:", error);
            return null; // Ensure we don't trigger the popup
        }
    };


    const resendVerificationCode = async () => {
        try {
            const response = await fetch("https://backendjaes.passiontrials.co.za/resend_code", {
                method: "POST",
                headers: { "Content-Type": "application/json" },
                body: JSON.stringify({ email }),
            });

            const data = await response.json();
            if (response.ok) {
                Swal.fire({
                    title: "Code Sent",
                    text: data.message,
                    icon: "success",
                });
                setTimeLeft(120);
                setResendEnabled(false);

            } else {
                Swal.fire({
                    title: "Error",
                    text: data.error || "Failed to resend code.",
                    icon: "error",
                });
            }
        } catch (error) {
            Swal.fire({
                title: "Error",
                text: "Something went wrong while resending the code.",
                icon: "error",
            });
        }
    };
    useEffect(() => {
        if (verificationNeeded) {
            // Start timer when popup is shown
            setTimeLeft(120); // Set countdown duration (e.g., 120 seconds)
        } else {
            // Clear timer when popup is hidden
            setTimeLeft(null);
        }
    }, [verificationNeeded]);

    useEffect(() => {
        if (timeLeft !== null) {
            if (timeLeft > 0) {
                const timer = setTimeout(() => setTimeLeft((prev) => prev - 1), 1000);
                return () => clearTimeout(timer);
            } else if (timeLeft === 0) {
                //resendVerificationCode(); // Auto-resend when timer reaches 0
                setResendEnabled(true);
            }
        }
    }, [timeLeft]);


    const handleSubmit = async (e) => {
        e.preventDefault();
        const isVerified = await liveData();

        // if (!isVerified) {
        //     setVerificationNeeded(true);
        //     return;
        // }
        if (isVerified === null) {
            console.warn("Skipping verification popup due to API failure or user not found.");
            setLoading(false);
            setError("User not found or system error. Please check your email.");
            return;
        }

        if (!isVerified) {
            setVerificationNeeded(true);
            setLoading(false);
            return;
        }


        setLoading(true);
        setError('');



        try {
            const response = await fetch('https://backendjaes.passiontrials.co.za/new_loginn', {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ Email: email, Password: password }),
            });

            const data = await response.json();

            if (response.ok) {
                // Update global auth state


                login(data.userId, data.Role);

                // Trigger onSuccess and onClose callbacks
                onSuccess();
                onClose();

                // Handle navigation based on user action
                if (clickedAction === 'downloadPDF') {
                    onSuccess(); // Trigger PDF download
                } else if (clickedAction === 'pay') {
                    navigate('/YocoPayment', {
                        state: { date, apartment, apartmentandbuilding, totalAmount, serviceFee, chosenPlan, nextPaymentDate },
                    });
                }
            } else {
                setError(data.message || 'Login failed. Please try again.');
            }
        } catch (err) {
            console.error('Login error:', err);
            setError('An error occurred. Please try again later.');
        } finally {
            setLoading(false);
        }
    };

    return (
        <div className="login-modal-overlay">
            {verificationNeeded && (
                <VerificationPopup2
                    email={email}
                    onResend={resendVerificationCode}
                    onVerify={() => setVerificationNeeded(false)} // Hide popup after verification
                    timeLeft={timeLeft}
                />
            )}
            <div className="login-modal-content">
                <h2>Login</h2>
                {error && <p style={{
                    color: "red",
                    fontSize: "12px",
                    marginTop: '10px',
                    textAlign: "center"
                }}>{error}</p>}
                <form onSubmit={handleSubmit}>
                    <div className="form-group">
                        <label>Email</label>
                        <input
                            type="email"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            placeholder="Enter your email"
                            required
                        />
                    </div>
                    <div className="form-group">
                        <label>Password</label>
                        <input
                            type="password"
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                            placeholder="Enter your password"
                            required
                        />
                    </div>
                    <div className="form-group">
                        <button type="button" onClick={onClose}>
                            Close
                        </button>
                        <button type="submit" disabled={loading}>
                            {loading ? 'Logging in...' : 'Login'}
                        </button>
                    </div>
                </form>
            </div>
        </div>
    );
}

export default LoginModal;
