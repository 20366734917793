import React from "react";
import { useNavigate } from "react-router-dom";

const StepsAdmin = () => {
  const navigate = useNavigate();

  const handleNext = () => {
    navigate("/steps2Admin");
  };

  return (
    <div>
      <style>{`
        .step1cont {
          display: flex;
          align-items: center;
          max-width: 1200px;
          width: 100%;
          margin: 10px auto;
          padding: 10px;
          box-sizing: border-box;
          flex-wrap: wrap;
        }

        @media (min-width: 768px) {
          .step1cont {
            flex-wrap: nowrap;
          }
          .video-section {
            order: 2;
            width: 60%;
          }
          .text-section {
            order: 1;
            width: 40%;
            margin-bottom: 100px;
          }
        }

        .text-section {
          order: 2;
          width: 100%;
          margin-bottom: 10px;
        }

        .text-section h2 {
          font-size: 14px;
          margin-bottom: 5px;
          color: #888;
          text-align: left;
        }

        .text-section h3 {
          font-size: 28px;
          margin-bottom: 10px;
          color: rgb(36, 35, 35);
          text-align: left;
        }

        .text-section p {
          font-size: 14px;
          color: #555;
          line-height: 1.4;
          text-align: left;
        }

        .video-section {
          order: 1;
          width: 100%;
          margin-bottom: 10px;
          display: flex;
          justify-content: flex-start;
          align-self: flex-start;
        }

        .video-section img {
          display: block;
          width: 100%;
          max-width: 100%;
          height: auto;
          object-fit: contain; /* Ensure the image fits without cropping */
          border-radius: 8px;
        }

        @media (max-width: 768px) {
          .video-section img {
            height: 200px; /* Adjusted height for mobile */
            object-fit: contain; /* Ensure the image fits without cropping */
          }
        }

        .progress-step1cont {
          position: fixed;
          bottom: 0;
          left: 0;
          right: 0;
          width: 100%;
          max-width: 1200px;
          margin: 0 auto;
          padding: 0 10px;
          box-sizing: border-box;
          background: #fff;
          z-index: 1000;
        }

        .progress-bar {
          height: 4px;
          background: #e0e0e0;
          margin-bottom: 5px;
          position: relative;
        }

        .progress {
          height: 100%;
          width: 16.66%;
          background: #000;
          transition: width 0.3s ease-in-out;
        }

        .buttons-step1cont {
          display: flex;
          justify-content: space-between;
          align-items: center;
          width: 100%;
          margin-top: 0px;
          position: relative;
        }

        .back-button {
          text-decoration: underline;
          color: #ff5a5f;
          background: none;
          border: none;
          max-width: 50px;
          cursor: pointer;
          font-size: 16px;
          padding: 15px 0px;
        }

        .back-button:hover {
          text-decoration: underline;
          color: #fff;
          background: #ff5a5f;
          border: none;
          max-width: 50px;
          cursor: pointer;
          font-size: 16px;
          padding: 15px 0px;
        }

        .next-button {
          background: #ff5a5f;
          max-width: 70px;
          color: #fff;
          border: none;
          padding: 10px 20px;
          border-radius: 4px;
          font-size: 16px;
          cursor: pointer;
        }

        @media (max-width: 768px) {
          .buttons-step1cont {
            justify-content: flex-start;
          }
          .next-button {
            margin-left: 150px;
          }
        }
      `}</style>

      <div className="step1cont">
        <div className="text-section">
          <h2>Step 1</h2>
          <h3>Tell us about your place</h3>
          <p>
            Start by sharing the details of your property. Let us know if tenants will rent the entire house or just a room, its location, and how many tenants it can accommodate. Simple and straightforward!
          </p>
        </div>
        <div className="video-section">
          <img
            src="https://media3.giphy.com/media/v1.Y2lkPTc5MGI3NjExM2c1MzZ3bHZyOW1tZjhpcTRrOXQ0YTVhZG5ubGhxNW04MWJjbWk1cCZlcD12MV9pbnRlcm5hbF9naWZfYnlfaWQmY3Q9Zw/W1fFapmqgqEf8RJ9TQ/giphy.gif"
            className="Image-module"
            aria-hidden="true"
            style={{ borderRadius: "8px" }}
            alt="Step 1"
          />
        </div>
      </div>

      <div className="progress-step1cont">
        <div className="progress-bar">
          <div className="progress"></div>
        </div>
        <div className="buttons-step1cont">
          <button className="back-button">Back</button>
          <button className="next-button" onClick={handleNext}>Next</button>
        </div>
      </div>
    </div>
  );
};

export default StepsAdmin;