// // import React, { useState } from 'react';
// // import './RegistrationModal.css'; // Make sure this CSS file is properly imported to style the modal
// // import { useNavigate, Link } from 'react-router-dom';
// // import LoginModal from './LoginModal.js'; // Import the Login Modal
// // import Swal from 'sweetalert2';


// // function RegistrationModal({ clickedAction, onSuccess, onClose, date, apartment, apartmentandbuilding, totalAmount, serviceFee, chosenPlan, nextPaymentDate }) {
// //     const navigate = useNavigate();
// //     const [formData, setFormData] = useState({
// //         Name: '',
// //         Surname: '',
// //         Email: '',
// //         PhoneNumber: '',
// //         Password: '',
// //         ConfirmPassword: '',
// //         IdNumber: '',
// //         PassportNumber: '',
// //         Country: '',
// //         Gender: '',
// //         DateOfBirth: '',
// //         TypeOfFunding: '',
// //         InstituteName: ''
// //     });
// //     const [faceImage, setFaceImage] = useState(null); // State to hold face image
// //     const [idImage, setIdImage] = useState(null); // State to hold ID image
// //     const [loading, setLoading] = useState(false);
// //     const [isLoginModalOpen, setIsLoginModalOpen] = useState(false); // State to manage login modal
// //     const [useID, setUseID] = useState(true);

// //     // Handle input changes
// //     const handleChange = (e) => {
// //         const { name, value } = e.target;

// //         if (useID && name === 'IdNumber' && value.length === 13) {
// //             const gender = handleGender(value);
// //             const dateOfBirth = handleDateOfBirth(value);

// //             setFormData({
// //                 ...formData,
// //                 [name]: value,
// //                 Gender: gender,
// //                 DateOfBirth: dateOfBirth
// //             });
// //         } else if (!useID && name === 'PassportNumber') {
// //             setFormData({
// //                 ...formData,
// //                 PassportNumber: value
// //             });
// //         } else {
// //             setFormData({ ...formData, [name]: value });
// //         }
// //     };

// //     function isNullOrUndefined(value) {
// //         return value == null || value == undefined || value == '';
// //     }
// //     // Function to extract gender from ID number
// //     const handleGender = (idNumber) => {
// //         const genderCode = idNumber.slice(6, 10);
// //         return parseInt(genderCode, 10) >= 5000 ? 'Male' : 'Female';
// //     };

// //     // Function to extract date of birth from ID number
// //     const handleDateOfBirth = (idNumber) => {
// //         const year = idNumber.slice(0, 2);
// //         const month = idNumber.slice(2, 4);
// //         const day = idNumber.slice(4, 6);
// //         const fullYear = parseInt(year, 10) >= 0 && parseInt(year, 10) <= 22 ? `20${year}` : `19${year}`;
// //         const final = `${fullYear}-${month}-${day}`;

// //         let formated = new Date(final).toLocaleDateString(`en-Us`, { day: "numeric", month: 'long', year: 'numeric' });
// //         return formated;
// //     };

// //     // Handle image file selection
// //     const handleImageChange = (e) => {
// //         if (e.target.name === 'FaceImage') {
// //             setFaceImage(e.target.files[0]); // Set face image
// //         } else if (e.target.name === 'ID_Image') {
// //             setIdImage(e.target.files[0]); // Set ID image
// //         }
// //     };

// //     // Handle form submission
// //     const handleSubmit = async (e) => {
// //         e.preventDefault();
// //         setLoading(true); // Start loading


// //         const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&#])[A-Za-z\d@$!%*?&#]{8,}$/;

// //         // Check if the passwords match
// //         if (formData.Password !== formData.ConfirmPassword) {
// //             alert("Passwords do not match!");
// //             setLoading(false); // Stop loading
// //             return;
// //         }

// //         // Check if the password meets the strength requirements
// //         if (!passwordRegex.test(formData.Password)) {
// //             alert("Password must be at least 8 characters long, include at least one uppercase letter, one lowercase letter, one number, and one special character.");
// //             setLoading(false); // Stop loading
// //             return;
// //         }

// //         const formDataToSend = new FormData();
// //         Object.keys(formData).forEach((key) => {
// //             if (key === 'IdNumber' && !useID) {
// //                 return; // Do not append IdNumber if Passport is selected
// //             }
// //             if (key === 'PassportNumber' && useID) {
// //                 return; // Do not append PassportNumber if ID is selected
// //             }
// //             formDataToSend.append(key, formData[key]);
// //         });


// //         // if (useID) {
// //         //     formDataToSend.append('IdNumber', formData.IdNumber);
// //         //     formDataToSend.append('Gender', formData.Gender);
// //         //     formDataToSend.append('DateOfBirth', formData.DateOfBirth);
// //         // } else {
// //         //     formDataToSend.append('PassportNumber', formData.PassportNumber);
// //         //     formDataToSend.append('Country', formData.Country);
// //         //     formDataToSend.append('Gender', formData.Gender);
// //         //     formDataToSend.append('DateOfBirth', formData.DateOfBirth);
// //         // }

// //         if (faceImage) {
// //             formDataToSend.append('FaceImage', faceImage);
// //         }

// //         if (idImage) {
// //             formDataToSend.append('ID_Image', idImage);
// //         }

// //         try {
// //             const response = await fetch('https://backendjaes.passiontrials.co.za/new_user_register', {
// //                 method: 'POST',
// //                 body: formDataToSend
// //             });

// //             const data = await response.json();
// //             if (response.ok) {
// //                 alert(data.message);
// //                 // Show login modal after successful registration
// //                 setIsLoginModalOpen(true);
// //             } else {
// //                 alert('Registration failed: ' + data.message);
// //             }
// //         } catch (error) {
// //             console.error('Error during registration:', error);
// //             alert('An error occurred during registration.');
// //         } finally {
// //             setLoading(false); // Stop loading after process is complete
// //         }
// //     };

// //     const handleLoginSuccess = () => {
// //         if (clickedAction === 'pay') {

// //             if (formData.TypeOfFunding == 'NSFAS') {


// //                 Swal.fire({
// //                     title: "Booking Approval",
// //                     text: "An email will be sent to you shortly",
// //                     icon: "success"
// //                 });
// //             } else {
// //                 navigate('/YocoPayment', { state: { date, apartment, apartmentandbuilding, totalAmount, serviceFee, chosenPlan, nextPaymentDate } });

// //             }
// //         } else if (clickedAction === 'downloadPDF') {
// //             onSuccess(); // Call to trigger `generatePDF()` in the main Reserve component
// //             setIsLoginModalOpen(false); // Close the login modal
// //             onClose(); // Close the registration modal
// //         }
// //     };

// //     return (
// //         <div className="modal-overlay">
// //             <div className="modal-content">
// //                 <h2>Personal Details</h2>
// //                 <form onSubmit={handleSubmit} encType="multipart/form-data">
// //                     {/* Group First and Last Name */}
// //                     <div className="form-group">
// //                         <input
// //                             type="text"
// //                             name="Name"
// //                             value={formData.Name}
// //                             onChange={handleChange}
// //                             placeholder="First Name"
// //                             required
// //                         />
// //                         <input
// //                             type="text"
// //                             name="Surname"
// //                             value={formData.Surname}
// //                             onChange={handleChange}
// //                             placeholder="Last Name"
// //                             required
// //                         />
// //                     </div>

// //                     {/* Group Email and Phone Number */}
// //                     <div className="form-group">
// //                         <input
// //                             type="email"
// //                             name="Email"
// //                             value={formData.Email}
// //                             onChange={handleChange}
// //                             placeholder="Email"
// //                             required
// //                         />
// //                         <input
// //                             type="text"
// //                             name="PhoneNumber"
// //                             value={formData.PhoneNumber}
// //                             onChange={handleChange}
// //                             placeholder="Phone Number"
// //                             required
// //                         />
// //                     </div>

// //                     <div className="form-group">
// //                         <label>Use ID Number or Passport?</label>
// //                         <select onChange={(e) => setUseID(e.target.value === 'ID')} value={useID ? 'ID' : 'Passport'}>
// //                             <option value="ID">ID Number</option>
// //                             <option value="Passport">Passport</option>
// //                         </select>
// //                     </div>

// //                     {useID ? (
// //                         <div className="form-group">
// //                             <input
// //                                 type="text"
// //                                 name="IdNumber"
// //                                 value={formData.IdNumber}
// //                                 onChange={handleChange}
// //                                 placeholder="e.g 2010145742086"
// //                                 maxLength="13"
// //                                 required
// //                             />
// //                         </div>
// //                     ) : (
// //                         <div className="form-group">
// //                             <input
// //                                 type="text"
// //                                 name="PassportNumber"
// //                                 value={formData.PassportNumber}
// //                                 onChange={handleChange}
// //                                 placeholder="Passport Number"
// //                                 required
// //                             />
// //                             <input
// //                                 type="text"
// //                                 name="Country"
// //                                 value={formData.Country}
// //                                 onChange={handleChange}
// //                                 placeholder="Country"
// //                                 required
// //                             />
// //                         </div>
// //                     )}
// //                     {/* Gender and Date of Birth */}
// //                     <div className="form-group">
// //                         {useID ? (
// //                             <>
// //                                 <input
// //                                     type="text"
// //                                     name="Gender"
// //                                     value={formData.Gender}
// //                                     placeholder="Gender"
// //                                     readOnly
// //                                 />
// //                                 <input
// //                                     type="text"
// //                                     name="DateOfBirth"
// //                                     value={formData.DateOfBirth}
// //                                     placeholder="Date of Birth"
// //                                     readOnly
// //                                 />
// //                             </>
// //                         ) : (
// //                             <>
// //                                                      <div className="gender-group-wrapper">
// //     <label className="gender-label">Gender:</label>
// //     <div className="gender-button-group">
// //         <label 
// //             className={`gender-button ${formData.Gender === 'Male' ? 'selected' : ''}`}
// //             onClick={() => handleChange({ target: { name: 'Gender', value: 'Male' } })}
// //         >
// //             <input 
// //                 type="radio" 
// //                 name="Gender" 
// //                 value="Male" 
// //                 checked={formData.Gender === 'Male'} 
// //                 onChange={handleChange} 
// //             />
// //             Male
// //         </label>
// //         <label 
// //             className={`gender-button ${formData.Gender === 'Female' ? 'selected' : ''}`}
// //             onClick={() => handleChange({ target: { name: 'Gender', value: 'Female' } })}
// //         >
// //             <input 
// //                 type="radio" 
// //                 name="Gender" 
// //                 value="Female" 
// //                 checked={formData.Gender === 'Female'} 
// //                 onChange={handleChange} 
// //             />
// //             Female
// //         </label>
// //     </div>

// //     <style>{`
// //         .gender-group-wrapper {
// //             display: flex;
// //             flex-direction: column;
// //             margin-bottom: 1em;
// //         }

// //         .gender-label {
// //             margin-bottom: 0.5em;
// //             color: #888;
// //             font-weight: bold;
// //         }

// //         .gender-button-group {
// //             display: flex;
// //             gap: 1em;
// //         }

// //         .gender-button {
// //             padding: 0.5em 1em;
// //             border: 2px solid #ccc;
// //             border-radius: 5px;
// //             cursor: pointer;
// //             font-weight: bold;
// //             text-align: center;
// //             transition: border-color 0.3s;
// //         }

// //         .gender-button input {
// //             display: none; /* Hide the original radio input */
// //         }

// //         .gender-button.selected {
// //             border-color: #ff5a5f; /* Change border color when selected */
// //             background-color: #f0f8ff;
// //         }
// //     `}</style>
// // </div>

// //                                 <div className='form-group full-width'>
// //                                     <label style={{ marginTop: "-10px" }}>Date of Birth:</label>
// //                                     <input
// //                                         type="date"
// //                                         name="DateOfBirth"
// //                                         value={formData.DateOfBirth}
// //                                         onChange={handleChange}
// //                                         placeholder="Date of Birth"
// //                                         required
// //                                     />
// //                                 </div>
// //                             </>
// //                         )}
// //                     </div>
// //                     <div className="form-group">
// //                         <label>Type of Funding:</label>
// //                         <select
// //                             name="TypeOfFunding"
// //                             value={formData.TypeOfFunding}
// //                             onChange={handleChange}
// //                             required
// //                         >
// //                             <option value="">Select Funding Type</option>
// //                             <option value="Self Funded">Self Funded</option>
// //                             <option value="Bursary">Bursary</option>
// //                             <option value="NSFAS">NSFAS</option>
// //                         </select>
// //                     </div>
// //                     <div className="form-group">
// //                         <label>Institution:</label>
// //                         <select
// //                             name="InstituteName"
// //                             value={formData.InstituteName}
// //                             onChange={handleChange}
// //                             required
// //                         >
// //                               <option value="">Select Institution</option>
// //                                     <option value="Ukzn Howard">UKZN Howard</option>
// //                                     <option value="DUT Steve Biko">DUT</option>
// //                         </select>
// //                     </div>

// //                     {/* Group Password and Confirm Password */}
// //                     <div className="form-group">
// //                         <input
// //                             type="password"
// //                             name="Password"
// //                             value={formData.Password}
// //                             onChange={handleChange}
// //                             placeholder="Password"
// //                             required
// //                         />
// //                         <input
// //                             type="password"
// //                             name="ConfirmPassword"
// //                             value={formData.ConfirmPassword}
// //                             onChange={handleChange}
// //                             placeholder="Confirm Password"
// //                             required
// //                         />
// //                     </div>

// //                     {/* Add file input for uploading face image 
// //                     <div className="form-group">
// //                         <label>Upload Face Photo:</label>
// //                         <input
// //                             type="file"
// //                             name="FaceImage"
// //                             accept="image/*"
// //                             onChange={handleImageChange}
// //                             required
// //                         />
// //                     </div>*/}

// //                     {/* Add file input for uploading ID image
// //                     <div className="form-group">
// //                         <label>Upload ID Image:</label>
// //                         <input
// //                             type="file"
// //                             name="ID_Image"
// //                             accept="image/*"
// //                             onChange={handleImageChange}
// //                             required
// //                         />
// //                     </div> */}

// //                     <div className="form-group">
// //                         <button type="button" onClick={onClose}>Cancel</button>
// //                         <button type="submit" disabled={loading}>
// //                             {loading ? 'Registering...' : 'Register'}
// //                         </button>
// //                     </div>

                    
// //                     <p>Already have an account? <a  onClick={() => setIsLoginModalOpen(true)} className="login-link">Login</a></p>
// //                 </form>

// //                 {/* Show the login modal after clicking the Login button */}
// //                 {isLoginModalOpen && (
// //                     <LoginModal
// //                     clickedAction={clickedAction}
// //                         onClose={() => setIsLoginModalOpen(false)}
// //                         onSuccess={handleLoginSuccess}
// //                         date={date}
// //                         apartment={apartment}
// //                         apartmentandbuilding={apartmentandbuilding}
// //                         totalAmount={totalAmount}
// //                         serviceFee={serviceFee}
// //                         chosenPlan={chosenPlan}
// //                         nextPaymentDate={nextPaymentDate}
// //                     />
// //                 )}
// //             </div>
// //         </div>
// //     );
// // }

// // export default RegistrationModal;


// import React, { useState } from 'react';
// import './RegistrationModal.css'; // Make sure this CSS file is properly imported to style the modal
// import { useNavigate, Link } from 'react-router-dom';
// import LoginModal from './LoginModal.js'; // Import the Login Modal
// import Swal from 'sweetalert2';
// import { EyeClosed, Eye } from 'lucide-react';




// function RegistrationModal({ clickedAction, onSuccess, onClose, date, apartment, apartmentandbuilding, totalCost, serviceFee, chosenDefault, nextPaymentDate }) {
//     const navigate = useNavigate();
//     const [formData, setFormData] = useState({
//         Name: '',
//         Surname: '',
//         Email: '',
//         PhoneNumber: '',
//         Password: '',
//         ConfirmPassword: '',
//         IdNumber: '',
//         PassportNumber: '',
//         Country: '',
//         Gender: '',
//         DateOfBirth: '',
//         TypeOfFunding: '',
//         InstituteName: '',
//         NsfasReferenceNumber: '',
//         HomeAddress: '',
//         BurseryOrganizationName: '',
//         BurseryReferenceNumber: '',
//         BurseryType: '',
//         BurseryStartDate: '',
//         BurseryEndDate: '',
//         CoverageDetails: '',
//         BursaryProofImage: ''
//     });
//     const [faceImage, setFaceImage] = useState(null); // State to hold face image
//     const [idImage, setIdImage] = useState(null); // State to hold ID image
//     const [loading, setLoading] = useState(false);
//     const [isLoginModalOpen, setIsLoginModalOpen] = useState(false); // State to manage login modal
//     const [useID, setUseID] = useState(true);
//     const [currentStep, setCurrentStep] = useState(1);
//     const [showPassword, setShowPassword] = useState(false); // Password visibility toggle
//     const [showConfirmPassword, setShowConfirmPassword] = useState(false);
//     const [proofImage, setProofImage] = useState(null);
//     const [errors, setErrors] = useState('');



//     // Handle input changes
//     const handleChange = (e) => {
//         const { name, value } = e.target;

//         if (useID && name === 'IdNumber' && value.length === 13) {
//             const gender = handleGender(value);
//             const dateOfBirth = handleDateOfBirth(value);

//             setFormData({
//                 ...formData,
//                 [name]: value,
//                 Gender: gender,
//                 DateOfBirth: dateOfBirth
//             });
//             setErrors('');
//         } else if (!useID && name === 'PassportNumber') {
//             setFormData({
//                 ...formData,
//                 PassportNumber: value
//             });
//             setErrors('');
//         } else {
//             setFormData({ ...formData, [name]: value });
//             setErrors('');
//         }
//     };

//     function isNullOrUndefined(value) {
//         return value == null || value == undefined || value == '';
//     }
//     // Function to extract gender from ID number

//     const validateStep = () => {
//         let newErrors = '';

//         if (currentStep === 1) {
//             if (!formData.Name || !formData.Surname || !formData.Email || !formData.PhoneNumber) {
//                 newErrors = 'All fields are required in this step.';
//             } else if (!/\S+@\S+\.\S+/.test(formData.Email)) {
//                 newErrors = 'Invalid email format.';
//             }
//         } else if (currentStep === 2) {
//             if (!formData.IdNumber && !formData.PassportNumber) {
//                 newErrors = 'You must provide either an ID Number or Passport Number.';
//             } else if (formData.IdNumber && formData.IdNumber.length !== 13) {
//                 newErrors = 'ID Number must be exactly 13 digits.';
//             }
//         } else if (currentStep === 3) {
//             if (!formData.TypeOfFunding || !formData.InstituteName) {
//                 newErrors = 'Please select a funding type and institution.';
//             }
//             if (!formData.HomeAddress) {
//                 newErrors = 'Please fill in your home address.';
//             }
//             if (
//                 formData.TypeOfFunding.toLowerCase() === 'nsfas' &&
//                 !formData.NsfasReferenceNumber
//             ) {
//                 newErrors = 'NSFAS Reference Number is required.';
//             }

//             if (formData.TypeOfFunding.toLowerCase() === 'bursary') {
//                 if (
//                     !formData.BurseryOrganizationName ||
//                     !formData.BurseryReferenceNumber ||
//                     !formData.BurseryType ||
//                     !proofImage
//                 ) {
//                     newErrors = 'All bursary details are required.';
//                 }
//             }
//         } else if (currentStep === 4) {
//             if (!formData.Password || !formData.ConfirmPassword) {
//                 newErrors = 'Password and Confirm Password are required.';
//             } else if (formData.Password !== formData.ConfirmPassword) {
//                 newErrors = 'Passwords do not match.';
//             } else if (formData.Password.length < 8) {
//                 newErrors = 'Password must be at least 8 characters.';
//             }
//             else if (!/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&#])[A-Za-z\d@$!%*?&#]{8,}$/.test(formData.Password)) {
//                 newErrors = 'Password must include uppercase, lowercase, number, and special character.';
//             }


//         }

//         setErrors(newErrors);
//         return !newErrors; // Return true if no errors
//     };


//     const handleGender = (idNumber) => {
//         const genderCode = idNumber.slice(6, 10);
//         return parseInt(genderCode, 10) >= 5000 ? 'Male' : 'Female';
//     };

//     // Function to extract date of birth from ID number
//     const handleDateOfBirth = (idNumber) => {
//         const year = idNumber.slice(0, 2);
//         const month = idNumber.slice(2, 4);
//         const day = idNumber.slice(4, 6);
//         const fullYear = parseInt(year, 10) >= 0 && parseInt(year, 10) <= 22 ? `20${year}` : `19${year}`;
//         const final = `${fullYear}-${month}-${day}`;

//         let formated = new Date(final).toLocaleDateString(`en-Us`, { day: "numeric", month: 'long', year: 'numeric' });
//         return formated;
//     };

//     // Handle image file selection
//     const handleImageChange = (e) => {
//         if (e.target.name === 'FaceImage') {
//             setFaceImage(e.target.files[0]); // Set face image
//         } else if (e.target.name === 'ID_Image') {
//             setIdImage(e.target.files[0]); // Set ID image
//         }
//     };
//     const handleProofImageChange = (e) => {
//         if (e.target.name === 'BursaryProofImage') {
//             setProofImage(e.target.files[0]);
//         }
//     };

//     // Handle form submission
//     const handleSubmit = async (e) => {
//         e.preventDefault();
//         setLoading(true); // Start loading
//         if (!validateStep()) {
//             setLoading(false);
//             return; // Stop submission if validation fails
//         }

//         const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&#])[A-Za-z\d@$!%*?&#]{8,}$/;

//         // Check if the passwords match
//         if (formData.Password !== formData.ConfirmPassword) {
//             alert("Passwords do not match!");
//             setLoading(false); // Stop loading
//             return;
//         }

//         // Check if the password meets the strength requirements
//         if (!passwordRegex.test(formData.Password)) {
//             alert("Password must be at least 8 characters long, include at least one uppercase letter, one lowercase letter, one number, and one special character.");
//             setLoading(false); // Stop loading
//             return;
//         }

//         const formDataToSend = new FormData();
//         Object.keys(formData).forEach((key) => {
//             if (key === 'IdNumber' && !useID) {
//                 return; // Do not append IdNumber if Passport is selected
//             }
//             if (key === 'PassportNumber' && useID) {
//                 return; // Do not append PassportNumber if ID is selected
//             }
//             formDataToSend.append(key, formData[key]);
//         });


//         // if (useID) {
//         //     formDataToSend.append('IdNumber', formData.IdNumber);
//         //     formDataToSend.append('Gender', formData.Gender);
//         //     formDataToSend.append('DateOfBirth', formData.DateOfBirth);
//         // } else {
//         //     formDataToSend.append('PassportNumber', formData.PassportNumber);
//         //     formDataToSend.append('Country', formData.Country);
//         //     formDataToSend.append('Gender', formData.Gender);
//         //     formDataToSend.append('DateOfBirth', formData.DateOfBirth);
//         // }

//         if (faceImage) {
//             formDataToSend.append('FaceImage', faceImage);
//         }

//         if (idImage) {
//             formDataToSend.append('ID_Image', idImage);
//         }
//         if (proofImage) {
//             formDataToSend.append('BursaryProofImage', proofImage);
//         }
//         if (validateStep()) {

//             try {
//                 const response = await fetch('https://backendjaes.passiontrials.co.za/new_user_register', {
//                     method: 'POST',
//                     body: formDataToSend
//                 });

//                 const data = await response.json();
//                 if (response.ok) {
//                     alert(data.message);
//                     // Show login modal after successful registration
//                     setIsLoginModalOpen(true);
//                 } else {
//                     alert('Registration failed: ' + data.message);
//                 }
//             } catch (error) {
//                 console.error('Error during registration:', error);
//                 alert('An error occurred during registration.');
//             } finally {
//                 setLoading(false); // Stop loading after process is complete
//             }
//         }
//     };

//     const handleLoginSuccess = () => {
//         if (clickedAction === 'pay') {

//             if (formData.TypeOfFunding == 'NSFAS') {


//                 Swal.fire({
//                     title: "Booking Approval",
//                     text: "An email will be sent to you shortly",
//                     icon: "success"
//                 });
//             } else {
//                 navigate('/YocoPayment', { state: { date, apartment, apartmentandbuilding, totalCost, serviceFee, chosenDefault, nextPaymentDate } });

//             }
//         } else if (clickedAction === 'downloadPDF') {
//             onSuccess(); // Call to trigger `generatePDF()` in the main Reserve component
//             setIsLoginModalOpen(false); // Close the login modal
//             onClose(); // Close the registration modal
//         }
//     };

//     const handleNextStep = () => {
//         if (validateStep()) {
//             setCurrentStep((prev) => prev + 1);
//         }
//     };
//     const handlePreviousStep = () => setCurrentStep((prev) => prev - 1);

//     const togglePasswordVisibility = () => {
//         setShowPassword((prevShowPassword) => !prevShowPassword);
//     };
//     const togglePasswordVisibility2 = () => {
//         setShowConfirmPassword((prevShowPassword) => !prevShowPassword);
//     };

//     return (
//         <div className="modal-overlay">
//             <div className="modal-content">
//                 <form onSubmit={handleSubmit} encType="multipart/form-data">
//                     {currentStep === 1 && (
//                         <>
//                             <h2>Personal Details</h2>
//                             <div className="form-group">
//                                 <input
//                                     type="text"
//                                     name="Name"
//                                     value={formData.Name}
//                                     onChange={handleChange}
//                                     placeholder="First Name"
//                                     required
//                                 />
//                                 <input
//                                     type="text"
//                                     name="Surname"
//                                     value={formData.Surname}
//                                     onChange={handleChange}
//                                     placeholder="Last Name"
//                                     required
//                                 />
//                             </div>

//                             <div className="form-group">
//                                 <input
//                                     type="email"
//                                     name="Email"
//                                     value={formData.Email}
//                                     onChange={handleChange}
//                                     placeholder="Email"
//                                     required
//                                 />
//                                 <input
//                                     type="text"
//                                     name="PhoneNumber"
//                                     value={formData.PhoneNumber}
//                                     onChange={handleChange}
//                                     placeholder="Phone Number"
//                                     required
//                                 />
//                             </div>
//                             <p>
//                                 Already have an account?{' '}
//                                 <a onClick={() => setIsLoginModalOpen(true)} className="login-link">
//                                     Login
//                                 </a>
//                             </p>
//                             {errors && <p style={{
//                                 color: "red",
//                                 fontSize: "12px",
//                                 marginTop: '10px',
//                                 textAlign: "center"
//                             }}>{errors}</p>}

//                             <button type="button" onClick={handleNextStep}>
//                                 Next
//                             </button>

//                         </>
//                     )}

//                     {currentStep === 2 && (
//                         <>
//                             <h2>Identification</h2>

//                             <div className="id-passport-form-group">
//                                 <label className="id-passport-label">Use ID Number or Passport?</label>
//                                 <select onChange={(e) => setUseID(e.target.value === 'ID')} value={useID ? 'ID' : 'Passport'} className="id-passport-select">
//                                     <option value="ID">ID Number</option>
//                                     <option value="Passport">Passport</option>
//                                 </select>
//                                 <style>{`
//         .id-passport-form-group {
//             display: flex;
//             flex-direction: column;
//             margin-bottom: 1em;
//         }

//         .id-passport-label {
//             margin-bottom: 0.5em;
//             font-weight: bold;
//         }

//         .id-passport-select {
//             padding: 0.5em;
//             font-size: 1em;
//         }
//     `}</style>
//                             </div>

//                             {useID ? (
//                                 <div className="form-group">
//                                     <input
//                                         type="text"
//                                         name="IdNumber"
//                                         value={formData.IdNumber}
//                                         onChange={handleChange}
//                                         placeholder="e.g 2010145742086"
//                                         maxLength="13"
//                                         required
//                                     />
//                                 </div>
//                             ) : (
//                                 <div className="form-group">
//                                     <input
//                                         type="text"
//                                         name="PassportNumber"
//                                         value={formData.PassportNumber}
//                                         onChange={handleChange}
//                                         placeholder="Passport Number"
//                                         required
//                                     />
//                                     <input
//                                         type="text"
//                                         name="Country"
//                                         value={formData.Country}
//                                         onChange={handleChange}
//                                         placeholder="Country"
//                                         required
//                                     />
//                                 </div>
//                             )}
//                             {/* Gender and Date of Birth */}
//                             <div className="form-group">
//                                 {useID ? (
//                                     <>
//                                         <input
//                                             type="text"
//                                             name="Gender"
//                                             value={formData.Gender}
//                                             placeholder="Gender"
//                                             readOnly
//                                         />
//                                         <input
//                                             type="text"
//                                             name="DateOfBirth"
//                                             value={formData.DateOfBirth}
//                                             placeholder="Date of Birth"
//                                             readOnly
//                                         />
//                                     </>
//                                 ) : (
//                                     <>
//                                         <div className="gender-group-wrapper">
//                                             <label className="gender-label">Gender:</label>
//                                             <div className="gender-button-group">
//                                                 <label
//                                                     className={`gender-button ${formData.Gender === 'Male' ? 'selected' : ''}`}
//                                                     onClick={() => handleChange({ target: { name: 'Gender', value: 'Male' } })}
//                                                 >
//                                                     <input
//                                                         type="radio"
//                                                         name="Gender"
//                                                         value="Male"
//                                                         checked={formData.Gender === 'Male'}
//                                                         onChange={handleChange}
//                                                     />
//                                                     Male
//                                                 </label>
//                                                 <label
//                                                     className={`gender-button ${formData.Gender === 'Female' ? 'selected' : ''}`}
//                                                     onClick={() => handleChange({ target: { name: 'Gender', value: 'Female' } })}
//                                                 >
//                                                     <input
//                                                         type="radio"
//                                                         name="Gender"
//                                                         value="Female"
//                                                         checked={formData.Gender === 'Female'}
//                                                         onChange={handleChange}
//                                                     />
//                                                     Female
//                                                 </label>
//                                             </div>

//                                             <style>{`
//         .gender-group-wrapper {
//             display: flex;
//             flex-direction: column;
//             margin-bottom: 1em;
//         }

//         .gender-label {
//             margin-bottom: 0.5em;
//             color: #888;
//             font-weight: bold;
//         }

//         .gender-button-group {
//             display: flex;
//             gap: 1em;
//         }

//         .gender-button {
//             padding: 0.5em 1em;
//             border: 2px solid #ccc;
//             border-radius: 5px;
//             cursor: pointer;
//             font-weight: bold;
//             text-align: center;
//             transition: border-color 0.3s;
//         }

//         .gender-button input {
//             display: none; /* Hide the original radio input */
//         }

//         .gender-button.selected {
//             border-color: #ff5a5f; /* Change border color when selected */
//             background-color: #f0f8ff;
//         }
//     `}</style>
//                                         </div>

//                                         <div className='form-group full-width'>
//                                             <label style={{ marginTop: "-10px" }}>Date of Birth:</label>
//                                             <input
//                                                 type="date"
//                                                 name="DateOfBirth"
//                                                 value={formData.DateOfBirth}
//                                                 onChange={handleChange}
//                                                 placeholder="Date of Birth"
//                                                 required
//                                             />
//                                         </div>
//                                     </>
//                                 )}
//                             </div>
//                             <div className="form-grid2">
//                                 <button type="button" onClick={handlePreviousStep}>
//                                     Back
//                                 </button>
//                                 <button type="button" onClick={handleNextStep}>
//                                     Next
//                                 </button>
//                             </div>
//                             {errors && <p style={{
//                                 color: "red",
//                                 fontSize: "12px",
//                                 marginTop: '10px',
//                                 textAlign: "center"
//                             }}>{errors}</p>}
//                         </>
//                     )}

//                     {currentStep === 3 && (
//                         <>
//                             <h2>Funding Information</h2>
//                             <div className="form-group">
//                                 <select
//                                     name="TypeOfFunding"
//                                     value={formData.TypeOfFunding}
//                                     onChange={handleChange}
//                                     required
//                                 >
//                                     <option value="">Select Funding Type</option>
//                                     <option value="Self Funded">Self Funded</option>
//                                     <option value="Bursary">Bursary</option>
//                                     <option value="NSFAS">NSFAS</option>
//                                 </select>
//                             </div>
//                             <div className="form-group">
//                                 <select
//                                     name="InstituteName"
//                                     value={formData.InstituteName}
//                                     onChange={handleChange}
//                                     required
//                                 >
//                                     <option value="">Select Institution</option>
//                                     <option value="Ukzn Howard">UKZN Howard</option>
//                                     <option value="DUT Steve Biko">DUT</option>
//                                 </select>
//                             </div>
//                             <div className="amber-form-group">
//                                 <div className="bookYourPerfectAccommodation-module__title amber-form-group">
//                                     <label>Home Address <span style={{ color: 'red' }}>*</span></label>
//                                     <input name='HomeAddress' type="text" placeholder="Enter home address here." value={formData.HomeAddress} onChange={handleChange} required />
//                                 </div>
//                             </div>

//                             {formData.TypeOfFunding.toLowerCase() === "nsfas" && (
//                                 <>
//                                     <div className="amber-form-group">
//                                         <div className="bookYourPerfectAccommodation-module__title amber-form-group">
//                                             <label>NSFAS Reference Number <span style={{ color: 'red' }}>*</span></label>
//                                             <input name='NsfasReferenceNumber' type="text" placeholder="Enter NSFAS reference number here." value={formData.NsfasReferenceNumber} onChange={handleChange} required />
//                                         </div>
//                                     </div>


//                                 </>
//                             )}

//                             {formData.TypeOfFunding.toLowerCase() === "bursary" && (
//                                 <>
//                                     <div className="amber-form-group">
//                                         <div className="bookYourPerfectAccommodation-module__title amber-form-group">
//                                             <label>Bursary Name <span style={{ color: 'red' }}>*</span></label>
//                                             <input name='BurseryOrganizationName' type="text" placeholder="Enter bursary organization name" value={formData.BurseryOrganizationName} onChange={handleChange} required />
//                                         </div>
//                                         <div className="bookYourPerfectAccommodation-module__title amber-form-group">
//                                             <label>Reference Number <span style={{ color: 'red' }}>*</span></label>
//                                             <input name='BurseryReferenceNumber' type="text" placeholder="Enter bursary reference number" value={formData.BurseryReferenceNumber} onChange={handleChange} required />
//                                         </div>
//                                         <div className="bookYourPerfectAccommodation-module__title amber-form-group">
//                                             <label>Bursary Type <span style={{ color: 'red' }}>*</span></label>
//                                             <input name='BurseryType' type="text" placeholder="Full bursary , partial bursary , Etc..." value={formData.BurseryType} onChange={handleChange} required />
//                                         </div>
//                                     </div>
//                                     <div className="amber-form-group">
//                                         <div className="bookYourPerfectAccommodation-module__title amber-form-group">
//                                             <label>Bursary Start Date <span style={{ color: 'red' }}>*</span></label>
//                                             <input name='BurseryStartDate' type="text" placeholder="Enter the date funding starts " value={formData.BurseryStartDate} onChange={handleChange} required />
//                                         </div>
//                                         <div className="bookYourPerfectAccommodation-module__title amber-form-group">
//                                             <label>Bursary End Date <span style={{ color: 'red' }}>*</span></label>
//                                             <input name='BurseryEndDate' type="text" placeholder="Enter the end of your funding for the year. " value={formData.BurseryEndDate} onChange={handleChange} required />
//                                         </div>
//                                         <div className="bookYourPerfectAccommodation-module__title amber-form-group">
//                                             <label>Coverage Details <span style={{ color: 'red' }}>*</span></label>
//                                             <input name='CoverageDetails' type="text" placeholder="Accomodation, tution , food , transport Etc ... " value={formData.CoverageDetails} onChange={handleChange} required />
//                                         </div>
//                                     </div>
//                                     <div className="amber-form-group">
//                                         <div className="bookYourPerfectAccommodation-module__title amber-form-group">
//                                             <label>Upload Proof of Bursary <span style={{ color: 'red' }}>*</span></label>
//                                             <input
//                                                 type="file"
//                                                 name="BursaryProofImage"
//                                                 accept="image/*"
//                                                 onChange={handleProofImageChange}
//                                                 required
//                                             />
//                                         </div>
//                                     </div>



//                                 </>

//                             )}

//                             < div className="form-grid2">
//                                 <button type="button" onClick={handlePreviousStep}>
//                                     Back
//                                 </button>
//                                 <button type="button" onClick={handleNextStep}>
//                                     Next
//                                 </button>
//                             </div>
//                             {errors && <p style={{
//                                 color: "red",
//                                 fontSize: "12px",
//                                 marginTop: '10px',
//                                 textAlign: "center"
//                             }}>{errors}</p>}
//                         </>
//                     )}


//                     {currentStep === 4 && (
//                         <>
//                             <h2> Security</h2>

//                             <div className="form-group">
//                                 <input
//                                     type={showPassword ? 'text' : 'password'}

//                                     name="Password"
//                                     value={formData.Password}
//                                     onChange={handleChange}
//                                     placeholder="Password"
//                                     required
//                                 />
//                                 <span onClick={togglePasswordVisibility} style={{ marginTop: '-24px', right: '24px' }} className="eye-icon">
//                                     {showPassword ? <Eye size={20} /> : <EyeClosed size={20} />}
//                                 </span>

//                             </div>
//                             <div className="form-group">

//                                 <input
//                                     type={showConfirmPassword ? 'text' : 'password'}

//                                     name="ConfirmPassword"
//                                     value={formData.ConfirmPassword}
//                                     onChange={handleChange}
//                                     placeholder="Confirm Password"
//                                     required
//                                 />
//                                 <span onClick={togglePasswordVisibility2} style={{ marginTop: '28px', right: '24px' }} className="eye-icon">
//                                     {showConfirmPassword ? <Eye size={20} /> : <EyeClosed size={20} />}
//                                 </span>
//                             </div>


//                             <div className="form-group">
//                                 <button type="button" onClick={onClose}>Cancel</button>
//                                 <button type="submit" disabled={loading}>
//                                     {loading ? 'Registering...' : 'Register'}
//                                 </button>
//                             </div>


//                             <p>Already have an account? <a onClick={() => setIsLoginModalOpen(true)} className="login-link">Login</a></p>
//                             {errors && <p style={{
//                                 color: "red",
//                                 fontSize: "12px",
//                                 marginTop: '10px',
//                                 textAlign: "center"
//                             }}>{errors}</p>}
//                         </>
//                     )}



//                     {/* Add file input for uploading face image 
//                     <div className="form-group">
//                         <label>Upload Face Photo:</label>
//                         <input
//                             type="file"
//                             name="FaceImage"
//                             accept="image/*"
//                             onChange={handleImageChange}
//                             required
//                         />
//                     </div>*/}

//                     {/* Add file input for uploading ID image
//                     <div className="form-group">
//                         <label>Upload ID Image:</label>
//                         <input
//                             type="file"
//                             name="ID_Image"
//                             accept="image/*"
//                             onChange={handleImageChange}
//                             required
//                         />
//                     </div> */}


//                 </form>

//                 {/* Show the login modal after clicking the Login button */}
//                 {isLoginModalOpen && (
//                     <LoginModal
//                         clickedAction={clickedAction}
//                         onClose={() => setIsLoginModalOpen(false)}
//                         onSuccess={handleLoginSuccess}
//                         date={date}
//                         apartment={apartment}
//                         apartmentandbuilding={apartmentandbuilding}
//                         totalCost={totalCost}
//                         serviceFee={serviceFee}
//                         chosenDefault={chosenDefault}
//                         nextPaymentDate={nextPaymentDate}
//                     />
//                 )}
//             </div>
//         </div>
//     );
// }

// export default RegistrationModal;

import React, { useState, useEffect } from 'react';
import './RegistrationModal.css'; // Make sure this CSS file is properly imported to style the modal
import { useNavigate, Link } from 'react-router-dom';
import LoginModal from './LoginModal.js'; // Import the Login Modal
import Swal from 'sweetalert2';
import { EyeClosed, Eye } from 'lucide-react';




function RegistrationModal({ clickedAction, onSuccess, onClose, date, apartment, apartmentandbuilding, totalCost, serviceFee, chosenDefault, nextPaymentDate }) {
    const navigate = useNavigate();
    const [formData, setFormData] = useState({
        Name: '',
        Surname: '',
        Email: '',
        PhoneNumber: '',
        Password: '',
        ConfirmPassword: '',
        IdNumber: '',
        PassportNumber: '',
        Country: '',
        Gender: '',
        DateOfBirth: '',
        TypeOfFunding: '',
        InstituteName: '',
        NsfasReferenceNumber: '',
        HomeAddress: '',
        BurseryOrganizationName: '',
        BurseryReferenceNumber: '',
        BurseryType: '',
        BurseryStartDate: '',
        BurseryEndDate: '',
        CoverageDetails: '',
        BursaryProofImage: ''
    });
    const [faceImage, setFaceImage] = useState(null); // State to hold face image
    const [idImage, setIdImage] = useState(null); // State to hold ID image
    const [loading, setLoading] = useState(false);
    const [isLoginModalOpen, setIsLoginModalOpen] = useState(false); // State to manage login modal
    const [useID, setUseID] = useState(true);
    const [currentStep, setCurrentStep] = useState(1);
    const [showPassword, setShowPassword] = useState(false); // Password visibility toggle
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);
    const [proofImage, setProofImage] = useState(null);
    const [errors, setErrors] = useState('');
    const [loading2, setLoading2] = useState(false);


    //verification process  15 jan 2025
    const [verificationCode, setVerificationCode] = useState('');
    const [showVerificationPopup, setShowVerificationPopup] = useState(false);
    const [inputs, setInputs] = useState(["", "", "", ""]);
    const [timeLeft, setTimeLeft] = useState(null);
    const [resendEnabled, setResendEnabled] = useState(false);

    const [coverageDetails, setCoverageDetails] = useState([]);

    const handleCheckboxChange = (e) => {
        const { value, checked } = e.target;

        setCoverageDetails((prev) =>
            checked ? [...prev, value] : prev.filter((item) => item !== value)
        );
    };

    // Handle input changes
    const handleChange = (e) => {
        const { name, value } = e.target;

        if (useID && name === 'IdNumber' && value.length === 13) {
            const gender = handleGender(value);
            const dateOfBirth = handleDateOfBirth(value);

            setFormData({
                ...formData,
                [name]: value,
                Gender: gender,
                DateOfBirth: dateOfBirth
            });
            setErrors('');
        } else if (!useID && name === 'PassportNumber') {
            setFormData({
                ...formData,
                PassportNumber: value
            });
            setErrors('');
        } else {
            setFormData({ ...formData, [name]: value });
            setErrors('');
        }
    };

    const handleResendCode = async () => {
        setLoading2(true);
        try {
            const response = await fetch("https://backendjaes.passiontrials.co.za/resend_code", {
                method: "POST",
                headers: { "Content-Type": "application/json" },
                body: JSON.stringify({ email: formData.Email }),
            });

            const data = await response.json();
            if (response.ok) {
                Swal.fire({
                    title: "Code Resent!",
                    text: data.message,
                    icon: "info",
                    confirmButtonText: "OK",
                });
                //  console.log("Code resent successfully:", data.message);
                setTimeLeft(120);
                setResendEnabled(false);
            } else {
                Swal.fire({
                    title: "Error!",
                    text: 'Failed to resend code' || data.error,
                    icon: "error",
                    confirmButtonText: "Try Again",
                });
            }
        } catch (error) {
            Swal.fire({
                title: "Error!",
                text: "Failed to resend verification code.",
                icon: "error",
                confirmButtonText: "Try Again",
            });
        } finally {
            setLoading2(false);
        }
    };


    useEffect(() => {// 10 jan 2025
        if (timeLeft !== null) {
            if (timeLeft > 0) {
                const timer = setTimeout(() => setTimeLeft((prev) => prev - 1), 1000);
                return () => clearTimeout(timer);
            } else if (timeLeft === 0) {
                // Trigger auto-resend and reset the timer
                // handleResendCode();
                setResendEnabled(true);
            }
        }
    }, [timeLeft]);

    useEffect(() => {
        if (showVerificationPopup) {
            // Initialize the timer to 30 seconds when the popup is shown
            setTimeLeft(120);
            setResendEnabled(false);

        } else {
            // Clear the timer when the popup is closed
            setTimeLeft(null);
        }
    }, [showVerificationPopup]);


    const handleInputChange = (index, value) => {// 10 jan 2025
        if (!isNaN(value) && value.length <= 1) {
            const newInputs = [...inputs];
            newInputs[index] = value;
            setInputs(newInputs);

            // Automatically focus the next box
            if (value && index < 3) {
                document.getElementById(`input-${index + 1}`).focus();
            }
        }
    };

    function isNullOrUndefined(value) {
        return value == null || value == undefined || value == '';
    }
    // Function to extract gender from ID number

    const validateStep = () => {
        let newErrors = '';

        if (currentStep === 1) {
            if (!formData.Name || !formData.Surname || !formData.Email || !formData.PhoneNumber) {
                newErrors = 'All fields are required in this step.';
            } else if (!/\S+@\S+\.\S+/.test(formData.Email)) {
                newErrors = 'Invalid email format.';
            }
        } else if (currentStep === 2) {
            if (!formData.IdNumber && !formData.PassportNumber) {
                newErrors = 'You must provide either an ID Number or Passport Number.';
            } else if (formData.IdNumber && formData.IdNumber.length !== 13) {
                newErrors = 'ID Number must be exactly 13 digits.';
            } else if (!/^\d*$/.test(formData.IdNumber)) {
                newErrors = 'ID Number must contain only numbers.';
            }

            else if (!useID) {
                // Passport validation
                if (!formData.PassportNumber) {
                    newErrors = 'Passport number is required.';
                }
                if (!formData.Country) {
                    newErrors = 'Country is required when using a passport.';
                }
                if (!formData.Gender) {
                    newErrors = 'Gender is required when using a passport.';
                }
                if (!formData.DateOfBirth) {
                    newErrors = 'Date of Birth is required when using a passport.';
                }
            }
        } else if (currentStep === 3) {
            if (!formData.TypeOfFunding || !formData.InstituteName) {
                newErrors = 'Please select a funding type and institution.';
            }
            if (!formData.HomeAddress) {
                newErrors = 'Please fill in your home address.';
            }
            if (
                formData.TypeOfFunding.toLowerCase() === 'nsfas' &&
                !formData.NsfasReferenceNumber
            ) {
                newErrors = 'NSFAS Reference Number is required.';
            }

            if (formData.TypeOfFunding.toLowerCase() === 'bursary') {
                if (
                    !formData.BurseryOrganizationName ||
                    !formData.BurseryReferenceNumber ||
                    !formData.BurseryType ||
                    !formData.BurseryStartDate ||
                    !formData.BurseryEndDate ||
                    !proofImage ||
                    coverageDetails.length === 0

                ) {
                    newErrors = 'All bursary details are required.';
                }
            }
        } else if (currentStep === 4) {
            if (!formData.Password || !formData.ConfirmPassword) {
                newErrors = 'Password and Confirm Password are required.';
            } else if (formData.Password !== formData.ConfirmPassword) {
                newErrors = 'Passwords do not match.';
            } else if (formData.Password.length < 8) {
                newErrors = 'Password must be at least 8 characters.';
            }
            else if (!/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&#])[A-Za-z\d@$!%*?&#]{8,}$/.test(formData.Password)) {
                newErrors = 'Password must include uppercase, lowercase, number, and special character.';
            }


        }

        setErrors(newErrors);
        return !newErrors; // Return true if no errors
    };


    const handleGender = (idNumber) => {
        const genderCode = idNumber.slice(6, 10);
        return parseInt(genderCode, 10) >= 5000 ? 'Male' : 'Female';
    };

    // Function to extract date of birth from ID number
    const handleDateOfBirth = (idNumber) => {
        const year = idNumber.slice(0, 2);
        const month = idNumber.slice(2, 4);
        const day = idNumber.slice(4, 6);
        const fullYear = parseInt(year, 10) >= 0 && parseInt(year, 10) <= 22 ? `20${year}` : `19${year}`;
        const final = `${fullYear}-${month}-${day}`;

        let formated = new Date(final).toLocaleDateString(`en-Us`, { day: "numeric", month: 'long', year: 'numeric' });
        return formated;
    };

    // Handle image file selection
    const handleImageChange = (e) => {
        if (e.target.name === 'FaceImage') {
            setFaceImage(e.target.files[0]); // Set face image
        } else if (e.target.name === 'ID_Image') {
            setIdImage(e.target.files[0]); // Set ID image
        }
    };
    const handleProofImageChange = (e) => {
        if (e.target.name === 'BursaryProofImage') {
            setProofImage(e.target.files[0]);
        }
    };

    // Handle form submission
    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true); // Start loading
        if (!validateStep()) {
            setLoading(false);
            return; // Stop submission if validation fails
        }

        const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&#])[A-Za-z\d@$!%*?&#]{8,}$/;

        // Check if the passwords match
        if (formData.Password !== formData.ConfirmPassword) {
            alert("Passwords do not match!");
            setLoading(false); // Stop loading
            return;
        }

        // Check if the password meets the strength requirements
        if (!passwordRegex.test(formData.Password)) {
            alert("Password must be at least 8 characters long, include at least one uppercase letter, one lowercase letter, one number, and one special character.");
            setLoading(false); // Stop loading
            return;
        }

        const formDataToSend = new FormData();
        Object.keys(formData).forEach((key) => {
            if (key === 'IdNumber' && !useID) {
                return; // Do not append IdNumber if Passport is selected
            }
            if (key === 'PassportNumber' && useID) {
                return; // Do not append PassportNumber if ID is selected
            }

            if (key === 'CoverageDetails') {
                formDataToSend.append('CoverageDetails', coverageDetails.join(', ')); // Send as a string
            } else if (formData[key] !== '' && formData[key] !== null) {
                formDataToSend.append(key, formData[key]);
            }

            // formDataToSend.append(key, formData[key]);
        });


        // if (useID) {
        //     formDataToSend.append('IdNumber', formData.IdNumber);
        //     formDataToSend.append('Gender', formData.Gender);
        //     formDataToSend.append('DateOfBirth', formData.DateOfBirth);
        // } else {
        //     formDataToSend.append('PassportNumber', formData.PassportNumber);
        //     formDataToSend.append('Country', formData.Country);
        //     formDataToSend.append('Gender', formData.Gender);
        //     formDataToSend.append('DateOfBirth', formData.DateOfBirth);
        // }

        if (faceImage) {
            formDataToSend.append('FaceImage', faceImage);
        }

        if (idImage) {
            formDataToSend.append('ID_Image', idImage);
        }
        if (proofImage) {
            formDataToSend.append('BursaryProofImage', proofImage);
        }

        if (validateStep()) {

            try {
                const response = await fetch('https://backendjaes.passiontrials.co.za/new_user_register', {
                    method: 'POST',
                    body: formDataToSend
                });

                const data = await response.json();
                if (response.ok) {
                    // alert(data.message);
                    Swal.fire({
                        position: "top-end",
                        icon: "success",
                        title: "Registration successful. Please verify your account.",
                        showConfirmButton: false,
                        timer: 5500
                    });
                    // Show login modal after successful registration
                    // setShowRegistrationModal(true);
                    setVerificationCode(data.verificationCode);
                    setShowVerificationPopup(true);
                    // setIsLoginModalOpen(true);
                } else {
                    // alert('Registration failed: ' + data.message);
                    Swal.fire({
                        position: "top-end",
                        icon: "error",
                        title: data.message || "Registration failed.",
                        showConfirmButton: false,
                        timer: 5500
                    });
                }
            } catch (error) {
                console.error('Error during registration:', error);
                // alert('An error occurred during registration.');
                Swal.fire({
                    position: "top-end",
                    icon: "error",
                    title: "Error during registration",
                    showConfirmButton: false,
                    timer: 5500
                });
            } finally {
                setLoading(false); // Stop loading after process is complete
            }
        }
    };

    //15 jan 2025
    const handleVerificationCodeSubmit = async (code) => {
        const response = await fetch('https://backendjaes.passiontrials.co.za/verify_code', {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ email: formData.Email, codeInput: code, })
        });


        const data = await response.json();

        if (response.ok) {
            Swal.fire({
                position: "top-end",
                icon: "success",
                title: "Verification successful!",
                showConfirmationButton: false,
                timer: 5500
            });

            setShowVerificationPopup(false);
            // navigate('/login')
            setIsLoginModalOpen(true);

        }
        else {

            Swal.fire({
                position: "top-end",
                icon: "error",
                title: "Invalid verification code. Please try again.",
                showConfirmationButton: false,
                timer: 5500
            });
        }
    };

    const handleVerify = () => {
        const code = inputs.join("");
        handleVerificationCodeSubmit(code);
    };
    const checkIdNumberExists = async (idNumber) => {
        try {
            const response = await fetch('https://backendjaes.passiontrials.co.za/check_idnumber', {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ idNumber }),
            });

            const data = await response.json();
            return data.exists; // Return true if the ID exists, false otherwise
        } catch (error) {
            console.error('Error checking ID number:', error);
            return false; // Default to false in case of an error
        }
    };

    const checkEmailExists = async (email) => {
        try {
            const response = await fetch('https://backendjaes.passiontrials.co.za/check_email', {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ email }),
            });

            const data = await response.json();
            console.log('does this exist', data.exists);
            return data.exists; // Return true if the email exists, false otherwise
        } catch (error) {
            console.error('Error checking email existence:', error);
            return false; // Default to false in case of an error
        }
    };
    const handleLoginSuccess = () => {
        if (clickedAction === 'pay') {

            if (formData.TypeOfFunding == 'NSFAS') {


                Swal.fire({
                    title: "Booking Approval",
                    text: "An email will be sent to you shortly",
                    icon: "success"
                });
            } else {
                navigate('/YocoPayment', { state: { date, apartment, apartmentandbuilding, totalCost, serviceFee, chosenDefault, nextPaymentDate } });

            }
        } else if (clickedAction === 'downloadPDF') {
            onSuccess(); // Call to trigger `generatePDF()` in the main Reserve component
            setIsLoginModalOpen(false); // Close the login modal
            onClose(); // Close the registration modal
        }
    };

    const handleNextStep = async () => {
        if (currentStep === 1) {
            // Check if email exists before running the rest of the validation
            const emailExists = await checkEmailExists(formData.Email);
            if (emailExists) {
                setErrors('This email is already registered. Please use another email or log in.');
                return; // Prevent moving to the next step
            }
        }

        if (currentStep === 2) {
            // Check if email exists before running the rest of the validation

            if (useID && formData.IdNumber.length === 13) {
                const idExists = await checkIdNumberExists(formData.IdNumber);
                if (idExists) {
                    setErrors('This ID number is already registered.');
                    return; // Prevent moving to the next step
                }
            }

        }



        if (validateStep()) {
            setCurrentStep((prev) => prev + 1);
        }
    };
    const handlePreviousStep = () => setCurrentStep((prev) => prev - 1);

    const togglePasswordVisibility = () => {
        setShowPassword((prevShowPassword) => !prevShowPassword);
    };
    const togglePasswordVisibility2 = () => {
        setShowConfirmPassword((prevShowPassword) => !prevShowPassword);
    };

    return (
        <div className="modal-overlay">
            <div className="modal-content">
                <form onSubmit={handleSubmit} encType="multipart/form-data">
                    {currentStep === 1 && (
                        <>
                            <h2>Personal Details</h2>
                            <div className="form-group">
                                <input
                                    type="text"
                                    name="Name"
                                    value={formData.Name}
                                    onChange={handleChange}
                                    placeholder="First Name"
                                    required
                                />
                                <input
                                    type="text"
                                    name="Surname"
                                    value={formData.Surname}
                                    onChange={handleChange}
                                    placeholder="Last Name"
                                    required
                                />
                            </div>

                            <div className="form-group">
                                <input
                                    type="email"
                                    name="Email"
                                    value={formData.Email}
                                    onChange={handleChange}
                                    placeholder="Email"
                                    required
                                />
                                <input
                                    type="text"
                                    name="PhoneNumber"
                                    value={formData.PhoneNumber}
                                    onChange={handleChange}
                                    placeholder="Phone Number"
                                    required
                                />
                            </div>
                            <p>
                                Already have an account?{' '}
                                <a onClick={() => setIsLoginModalOpen(true)} className="login-link">
                                    Login
                                </a>
                            </p>
                            {errors && <p style={{
                                color: "red",
                                fontSize: "12px",
                                marginTop: '10px',
                                textAlign: "center"
                            }}>{errors}</p>}

                            <button type="button" onClick={handleNextStep}>
                                Next
                            </button>

                        </>
                    )}

                    {currentStep === 2 && (
                        <>
                            <h2>Identification</h2>

                            <div className="id-passport-form-group">
                                <label className="id-passport-label">Use ID Number or Passport?</label>
                                <select onChange={(e) => setUseID(e.target.value === 'ID')} value={useID ? 'ID' : 'Passport'} className="id-passport-select">
                                    <option value="ID">ID Number</option>
                                    <option value="Passport">Passport</option>
                                </select>
                                <style>{`
        .id-passport-form-group {
            display: flex;
            flex-direction: column;
            margin-bottom: 1em;
        }

        .id-passport-label {
            margin-bottom: 0.5em;
            font-weight: bold;
        }

        .id-passport-select {
            padding: 0.5em;
            font-size: 1em;
        }
    `}</style>
                            </div>

                            {useID ? (
                                <div className="form-group">
                                    <input
                                        type="text"
                                        name="IdNumber"
                                        value={formData.IdNumber}
                                        onChange={handleChange}
                                        placeholder="ID Number (13 digits)"
                                        maxLength="13"
                                        required
                                    />
                                </div>
                            ) : (
                                <div className="form-group">
                                    <input
                                        type="text"
                                        name="PassportNumber"
                                        value={formData.PassportNumber}
                                        onChange={handleChange}
                                        placeholder="Passport Number"
                                        required
                                    />
                                    <input
                                        type="text"
                                        name="Country"
                                        value={formData.Country}
                                        onChange={handleChange}
                                        placeholder="Country"
                                        required
                                    />
                                </div>
                            )}
                            {/* Gender and Date of Birth */}
                            <div className="form-group">
                                {useID ? (
                                    <>
                                        <input
                                            type="text"
                                            name="Gender"
                                            value={formData.Gender}
                                            placeholder="Gender"
                                            readOnly
                                        />
                                        <input
                                            type="text"
                                            name="DateOfBirth"
                                            value={formData.DateOfBirth}
                                            placeholder="Date of Birth"
                                            readOnly
                                        />
                                    </>
                                ) : (
                                    <>
                                        <div className="gender-group-wrapper">
                                            <label className="gender-label">Gender:</label>
                                            <div className="gender-button-group">
                                                <label
                                                    className={`gender-button ${formData.Gender === 'Male' ? 'selected' : ''}`}
                                                    onClick={() => handleChange({ target: { name: 'Gender', value: 'Male' } })}
                                                >
                                                    <input
                                                        type="radio"
                                                        name="Gender"
                                                        value="Male"
                                                        checked={formData.Gender === 'Male'}
                                                        onChange={handleChange}
                                                    />
                                                    Male
                                                </label>
                                                <label
                                                    className={`gender-button ${formData.Gender === 'Female' ? 'selected' : ''}`}
                                                    onClick={() => handleChange({ target: { name: 'Gender', value: 'Female' } })}
                                                >
                                                    <input
                                                        type="radio"
                                                        name="Gender"
                                                        value="Female"
                                                        checked={formData.Gender === 'Female'}
                                                        onChange={handleChange}
                                                    />
                                                    Female
                                                </label>
                                            </div>

                                            <style>{`
        .gender-group-wrapper {
            display: flex;
            flex-direction: column;
            margin-bottom: 1em;
        }

        .gender-label {
            margin-bottom: 0.5em;
            color: #888;
            font-weight: bold;
        }

        .gender-button-group {
            display: flex;
            gap: 1em;
        }

        .gender-button {
            padding: 0.5em 1em;
            border: 2px solid #ccc;
            border-radius: 5px;
            cursor: pointer;
            font-weight: bold;
            text-align: center;
            transition: border-color 0.3s;
        }

        .gender-button input {
            display: none; /* Hide the original radio input */
        }

        .gender-button.selected {
            border-color: #ff5a5f; /* Change border color when selected */
            background-color: #f0f8ff;
        }
    `}</style>
                                        </div>

                                        <div className='form-group full-width'>
                                            <label style={{ marginTop: "-10px" }}>Date of Birth:</label>
                                            <input
                                                type="date"
                                                name="DateOfBirth"
                                                value={formData.DateOfBirth}
                                                onChange={handleChange}
                                                placeholder="Date of Birth"
                                                required
                                            />
                                        </div>
                                    </>
                                )}
                            </div>
                            <div className="form-grid2">
                                <button type="button" onClick={handlePreviousStep}>
                                    Back
                                </button>
                                <button type="button" onClick={handleNextStep}>
                                    Next
                                </button>
                            </div>
                            {errors && <p style={{
                                color: "red",
                                fontSize: "12px",
                                marginTop: '10px',
                                textAlign: "center"
                            }}>{errors}</p>}
                        </>
                    )}

                    {currentStep === 3 && (
                        <>
                            <h2>Funding Information</h2>
                            <div className="form-group">
                                <select
                                    name="TypeOfFunding"
                                    value={formData.TypeOfFunding}
                                    onChange={handleChange}
                                    required
                                >
                                    <option value="">Select Funding Type</option>
                                    <option value="Self Funded">Self Funded</option>
                                    <option value="Bursary">Bursary</option>
                                    <option value="NSFAS">NSFAS</option>
                                </select>
                            </div>
                            <div className="form-group">
                                <select
                                    name="InstituteName"
                                    value={formData.InstituteName}
                                    onChange={handleChange}
                                    required
                                >
                                    <option value="">Select Institution</option>
                                    <option value="Ukzn Howard">UKZN Howard</option>
                                    <option value="UKZN Medical School">UKZN Medical School</option>
                                    <option value="Umbilo College">Umbilo College</option>
                                    <option value="Coastal College">Coastal College</option>
                                    <option value="Ukzn Westville">Ukzn Westville</option>
                                </select>
                            </div>
                            <div className="amber-form-group">
                                <div className="bookYourPerfectAccommodation-module__title amber-form-group">
                                    <label>Home Address <span style={{ color: 'red' }}>*</span></label>
                                    <input name='HomeAddress' type="text" placeholder="Enter home address here." value={formData.HomeAddress} onChange={handleChange} required />
                                </div>
                            </div>

                            {formData.TypeOfFunding.toLowerCase() === "nsfas" && (
                                <>
                                    <div className="amber-form-group">
                                        <div className="bookYourPerfectAccommodation-module__title amber-form-group">
                                            <label>NSFAS Reference Number <span style={{ color: 'red' }}>*</span></label>
                                            <input name='NsfasReferenceNumber' type="text" placeholder="Enter NSFAS reference number here." value={formData.NsfasReferenceNumber} onChange={handleChange} required />
                                        </div>
                                    </div>


                                </>
                            )}

                            {formData.TypeOfFunding.toLowerCase() === "bursary" && (
                                <>
                                    <div className="amber-form-group">
                                        <div className="bookYourPerfectAccommodation-module__title amber-form-group">
                                            <label>Bursary Name <span style={{ color: 'red' }}>*</span></label>
                                            <input name='BurseryOrganizationName' type="text" placeholder="Enter bursary organization name" value={formData.BurseryOrganizationName} onChange={handleChange} required />
                                        </div>
                                        <div className="bookYourPerfectAccommodation-module__title amber-form-group">
                                            <label>Reference Number <span style={{ color: 'red' }}>*</span></label>
                                            <input name='BurseryReferenceNumber' type="text" placeholder="Enter bursary reference number" value={formData.BurseryReferenceNumber} onChange={handleChange} required />
                                        </div>
                                        <div className="bookYourPerfectAccommodation-module__title amber-form-group">
                                            <label>Bursary Type <span style={{ color: 'red' }}>*</span></label>
                                            {/* <input name='BurseryType' type="text" placeholder="Full bursary , partial bursary , Etc..." value={formData.BurseryType} onChange={handleChange} required /> */}
                                            <select
                                                name="BurseryType"
                                                value={formData.BurseryType}
                                                onChange={handleChange}
                                                required
                                                className="dropdown-select"
                                            >
                                                <option value="">Select Bursary Type</option>
                                                <option value="Full Bursary">Full Bursary</option>
                                                <option value="Partial Bursary">Partial Bursary</option>
                                                <option value="Top-up Bursary">Top-up Bursary</option>
                                                <option value="Fixed Bursary">Fixed Bursary</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div className="amber-form-group">
                                        <div className="bookYourPerfectAccommodation-module__title amber-form-group">
                                            <label>Bursary Start Date <span style={{ color: 'red' }}>*</span></label>
                                            {/* <input name='BurseryStartDate' type="text" placeholder="Enter the date funding starts " value={formData.BurseryStartDate} onChange={handleChange} required /> */}
                                            <input
                                                name="BurseryStartDate"
                                                type="date"
                                                value={formData.BurseryStartDate}

                                                onChange={handleChange}
                                                required
                                            />
                                        </div>
                                        <div className="bookYourPerfectAccommodation-module__title amber-form-group">
                                            <label>Bursary End Date <span style={{ color: 'red' }}>*</span></label>
                                            <input
                                                name="BurseryEndDate"
                                                type="date"
                                                value={formData.BurseryEndDate}
                                                onChange={handleChange}
                                                required
                                            />
                                        </div>
                                        <div className="bookYourPerfectAccommodation-module__title amber-form-group">
                                            <label>Coverage Details <span style={{ color: 'red' }}>*</span></label>
                                            {/* <input name='CoverageDetails' type="text" placeholder="Accomodation, tution , food , transport Etc ... " value={formData.CoverageDetails} onChange={handleChange} required /> */}
                                            <div class="checkbox-grid">

                                                <div class="checkbox-item">
                                                    <input type="checkbox" id="Tuition" value="Tuition" onChange={handleCheckboxChange} />
                                                    <label for="Tuition">Tuition</label>
                                                </div>
                                                <div class="checkbox-item">
                                                    <input type="checkbox" id="Accommodation" value="Accommodation" onChange={handleCheckboxChange} />
                                                    <label for="Accommodation">Accommodation</label>
                                                </div>
                                                <div class="checkbox-item">
                                                    <input type="checkbox" id="Living Allowance" value="Living Allowance" onChange={handleCheckboxChange} />
                                                    <label for="Living Allowance">Living Allowance</label>
                                                </div>
                                                <div class="checkbox-item">
                                                    <input type="checkbox" id="Transportation" value="Transportation" onChange={handleCheckboxChange} />
                                                    <label for="Transportation">Transportation</label>
                                                </div>
                                                <div class="checkbox-item">
                                                    <input type="checkbox" id="Study material" value="Study material" onChange={handleCheckboxChange} />
                                                    <label for="Study material">Study material</label>
                                                </div>
                                                <div class="checkbox-item">
                                                    <input type="checkbox" id="Special equipment" value="Special equipment" onChange={handleCheckboxChange} />
                                                    <label for="Special equipment">Special equipment</label>
                                                </div>


                                            </div>
                                            <style>{`:root {
  --background: #fff;
  --text-color: #414856;
  --highlight-color: #4f29f0;
  --border-color: #c3c8de;
  --border-radius: 10px;
  --gap: 15px; /* Gap between items */
}

.checkbox-grid {
  display: flex;
  flex-wrap: wrap;
  gap: var(--gap);
  justify-content: center;
  padding: 20px;
}

.checkbox-item {
  display: inline-table;
  align-items: center;
  justify-content: center;
  flex: 1 1 calc(20% - var(--gap)); /* Responsive width based on available space */
  max-width: calc(20% - var(--gap)); /* Ensures consistency across all items */
  padding: 10px;
  background: var(--background);
  color: var(--text-color);
  border: 2px solid var(--border-color);
  border-radius: var(--border-radius);
  cursor: pointer;
  text-align: center;
  transition: background 0.3s ease, color 0.3s ease, box-shadow 0.3s ease;
}

.checkbox-item input {
  display: none; /* Hide the checkbox itself */
}

.checkbox-item label {
  cursor: pointer;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
}

.checkbox-item input:checked + label {
  background: var(--highlight-color);
  color: var(--background);
  box-shadow: 0 5px 15px rgba(79, 41, 240, 0.3);
}
`}</style>
                                        </div>
                                    </div>
                                    <div className="amber-form-group">
                                        <div className="bookYourPerfectAccommodation-module__title amber-form-group">
                                            <label>Upload Proof of Bursary <span style={{ color: 'red' }}>*</span></label>
                                            <input
                                                type="file"
                                                name="BursaryProofImage"
                                                accept="image/*"
                                                onChange={handleProofImageChange}
                                                required
                                            />
                                        </div>
                                    </div>



                                </>

                            )}

                            < div className="form-grid2">
                                <button type="button" onClick={handlePreviousStep}>
                                    Back
                                </button>
                                <button type="button" onClick={handleNextStep}>
                                    Next
                                </button>
                            </div>
                            {errors && <p style={{
                                color: "red",
                                fontSize: "12px",
                                marginTop: '10px',
                                textAlign: "center"
                            }}>{errors}</p>}
                        </>
                    )}


                    {currentStep === 4 && (
                        <>
                            <h2> Security</h2>

                            <div className="form-group">
                                <input
                                    type={showPassword ? 'text' : 'password'}

                                    name="Password"
                                    value={formData.Password}
                                    onChange={handleChange}
                                    placeholder="Password"
                                    required
                                />
                                <span onClick={togglePasswordVisibility} style={{ marginTop: '-24px', right: '24px' }} className="eye-icon">
                                    {showPassword ? <Eye size={20} /> : <EyeClosed size={20} />}
                                </span>

                            </div>
                            <div className="form-group">

                                <input
                                    type={showConfirmPassword ? 'text' : 'password'}

                                    name="ConfirmPassword"
                                    value={formData.ConfirmPassword}
                                    onChange={handleChange}
                                    placeholder="Confirm Password"
                                    required
                                />
                                <span onClick={togglePasswordVisibility2} style={{ marginTop: '28px', right: '24px' }} className="eye-icon">
                                    {showConfirmPassword ? <Eye size={20} /> : <EyeClosed size={20} />}
                                </span>
                            </div>


                            <div className="form-group">
                                <button type="button" onClick={onClose}>Cancel</button>
                                <button type="submit" disabled={loading}>
                                    {loading ? 'Registering...' : 'Register'}
                                </button>
                            </div>


                            <p>Already have an account? <a onClick={() => setIsLoginModalOpen(true)} className="login-link">Login</a></p>
                            {errors && <p style={{
                                color: "red",
                                fontSize: "12px",
                                marginTop: '10px',
                                textAlign: "center"
                            }}>{errors}</p>}
                        </>
                    )}



                    {/* Add file input for uploading face image 
                    <div className="form-group">
                        <label>Upload Face Photo:</label>
                        <input
                            type="file"
                            name="FaceImage"
                            accept="image/*"
                            onChange={handleImageChange}
                            required
                        />
                    </div>*/}

                    {/* Add file input for uploading ID image
                    <div className="form-group">
                        <label>Upload ID Image:</label>
                        <input
                            type="file"
                            name="ID_Image"
                            accept="image/*"
                            onChange={handleImageChange}
                            required
                        />
                    </div> */}


                </form>

                {/* Show the login modal after clicking the Login button */}
                {isLoginModalOpen && (
                    <LoginModal
                        clickedAction={clickedAction}
                        onClose={() => setIsLoginModalOpen(false)}
                        onSuccess={handleLoginSuccess}
                        date={date}
                        apartment={apartment}
                        apartmentandbuilding={apartmentandbuilding}
                        totalCost={totalCost}
                        serviceFee={serviceFee}
                        chosenDefault={chosenDefault}
                        nextPaymentDate={nextPaymentDate}
                    />
                )}
            </div>
            {showVerificationPopup && (
                <div className="verification-popup">
                    <div className="popup-content">
                        <h2>Enter Verification Code</h2>
                        <p>Please enter the 4-digit code sent to your email.</p>
                        {/* <input
                            type="text"
                            placeholder="Enter code"
                            value={codeInput}
                            onChange={(e) => setCodeInput(e.target.value)}
                            maxLength={4}
                        /> */}
                        <div className="input-boxes">
                            {inputs.map((value, index) => (
                                <input
                                    key={index}
                                    id={`input-${index}`}
                                    type="text"
                                    maxLength={1}
                                    value={value}
                                    onChange={(e) => handleInputChange(index, e.target.value)}
                                    onKeyDown={(e) => {
                                        if (e.key === "Backspace" && index > 0 && !value) {
                                            document.getElementById(`input-${index - 1}`).focus();
                                        }
                                    }}
                                    className="verification-input"
                                />
                            ))}
                        </div>

                        {/* <button
                                className="verify-button"
                                onClick={handleVerificationCodeSubmit}
                                disabled={loading2}
                            >
                                {loading2 ? "Verifying..." : "Verify"}
                            </button> */}
                        {!resendEnabled && (
                            <button
                                className="verify-button"
                                onClick={handleVerify}
                                disabled={inputs.some((input) => input === "")} // Disable if inputs are incomplete
                            >
                                Verify
                            </button>
                        )}

                        {resendEnabled && (
                            <button
                                className="resend-button"
                                onClick={handleResendCode}
                                disabled={loading2}
                            >
                                {loading2 ? "Resending..." : "Resend Code"}
                            </button>
                        )}

                        <p className="resend-timer">
                            {/* {resendDisabled
                                ? `Wait ${String(timeLeft).padStart(2, "0")} seconds to resend the code.`
                                : (
                                    <button className="resend-button" onClick={handleResendCode}>
                                        Resend Code
                                    </button>
                                )} */}
                            {resendEnabled
                                ? "Didn't receive a code? Click Resend."
                                : `Resending a new code in ${String(timeLeft).padStart(2, "0")} seconds...`}

                        </p>
                    </div>
                    <style>{`
                 .verification-popup {
          position: fixed;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          background: rgba(0, 0, 0, 0.5);
          display: flex;
          justify-content: center;
          align-items: center;
          z-index: 1000;
        }
        .popup-content {
          background: white;
          padding: 20px;
          border-radius: 10px;
          text-align: center;
          box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
        }
        .input-boxes {
          display: flex;
          justify-content: center;
          gap: 10px;
          margin: 20px 0;
        }
        .verification-input {
          width: 50px;
          height: 50px;
          text-align: center;
          font-size: 20px;
          border: 1px solid #ccc;
          border-radius: 5px;
        }
        .verify-button {
          padding: 10px 20px;
          background-color: #28a745;
          color: white;
          border: none;
          border-radius: 5px;
          cursor: pointer;
          font-weight: bold;
        }
        .resend-timer {
          margin-top: 15px;
          font-size: 14px;
          color: #555;
        }
        .resend-button {
          background: none;
          border: none;
          color: #007bff;
          cursor: pointer;
          text-decoration: underline;
        }  
        .resend-button: hover{
          color: #ffffff;

        }
               `}</style>
                </div>
            )}
        </div>
    );
}

export default RegistrationModal;
