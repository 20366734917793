import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useAuth } from "./AuthProvider"; // Ensure to import the useAuth hook
import taxi from "./introcar.png";
import clock from "./icons8-clock-50.png";
import headset from "./icons8-headset-50.png";
import globe from "./icons8-get-cash-50.png";
import card from "./icons8-card-50.png";
import leaf from "./icons8-schedule-50.png";
import tree from "./icons8-service-50.png";
import plug from "./icons8-discount-50.png";
import thumb from "./Thumbs up .jpg";
import grad from "./icons8-cap-64.png";
import sectionm from "./113.jpg";
import minscaled from "./IMAGE 1.jpg";
import ridecoversection from "./richbusinesswoman.jpg";
import RegistrationModal from "./components/TransportRegistrationModal.js"; // Adjust the path based on your folder structure

const IntroTransport = () => {
  const [showRegistrationModal, setShowRegistrationModal] = useState(false); // State to show the registration modal
  const [activeIndex, setActiveIndex] = useState(null); // Track which question is active

  const { isLoggedIn } = useAuth(); // Use the isLoggedIn and role from useAuth
  const [showModal, setShowModal] = useState(false);
  const navigate = useNavigate();
  const handleJoinClick = () => {
    if (isLoggedIn) {
      navigate("/userDashboard");
    } else {
      setShowRegistrationModal(true);
    }
  };
  useEffect(() => {
    // Dynamically import the CSS for this component
    import("./cabstyle.css");

    // Optional cleanup for when the component unmounts
    return () => {
      // CSS will remain applied until explicitly removed, typically no cleanup needed
    };
  }, []);

  const closeModal = () => {
    setShowModal(false);
  };

  const faqData = [
    {
      question: "How can I pay for my ride?",
      answer:
        "You can pay via EFT or online through our secure payment platform. Choose the method that works best for you when booking.",
    },
    {
      question: "Can I contact my driver?",
      answer:
        "No, direct contact with drivers is not available. Please ensure you're at the pickup point 30 minutes before departure to avoid any delays.",
    },
    {
      question: "Why do I need to be present 30 minutes before departure?",
      answer:
        "Being present ensures a smooth boarding process and helps us stay on schedule for all passengers.",
    },
  ];
  

  const toggleFAQ = (index) => {
    setActiveIndex((prevIndex) => (prevIndex === index ? null : index));
  };
  return (
    <body  style={{
        fontFamily: '"Arial Black", Arial, sans-serif', // Replace with the closest matching font
        
      }}>
    <div class="wp-block-cover pt-32 pb-104 md:pt-56 md:pb-144"><span aria-hidden="true"
            class="wp-block-cover__background has-gray-600-background-color has-background-dim-100 has-background-dim"></span>
        <div class="wp-block-cover__inner-container is-layout-constrained wp-block-cover-is-layout-constrained">
            <div class="wp-block-group mb-104 md:mb-144 is-layout-flow wp-block-group-is-layout-flow">
            <h1
  className="wp-block-heading has-text-align-center has-xxlarge-font-size"
  id="h-your-black-cab-app"
  style={{
    fontFamily: '"Arial Black", Arial, sans-serif', // Replace with the closest matching font
    fontWeight: 'bold', // Ensures boldness
    color: '#625D5D', // Matches the white text color
    fontSize:"25px"
  }}
>
	Campus shuttle
</h1>




                <p class="has-text-align-center has-medium-3-font-size mt-24 mb-40"  style={{
    fontFamily: '"Arial", Arial, sans-serif', // Replace with the closest matching font
  // Ensures boldness
  fontWeight:"100",
    color: '#625D5D', // Matches the white text color
  }}>	The most affordable transport for students</p>



                <div
                    class="wp-block-buttons mb-56 is-content-justification-center is-layout-flex wp-container-core-buttons-is-layout-1 wp-block-buttons-is-layout-flex">
                    <div class="wp-block-button" data-button-position="your black cab app"><a
                            class="wp-block-button__link wp-element-button"
                            onClick={handleJoinClick}
                            style={{fontSize:'16px'}}>	Book ride</a></div>
                </div>



                <figure class="wp-block-image size-full is-resized rounded-24 md:rounded-50"><img
                        fetchpriority="high" decoding="async" width="2336" height="958"
                        src={minscaled} alt="" class="wp-image-15811"
                        style={{width:"1068px"}}
                        sizes="(max-width: 2336px) 90vw, 2336px"/></figure>
            </div>



            <div class="wp-block-group mb-104 md:mb-176 is-layout-flow wp-block-group-is-layout-flow">
                <div
                    class="wp-block-columns mb-40 md:mb-56 is-layout-flex wp-container-core-columns-is-layout-1 wp-block-columns-is-layout-flex">
                    <div class="wp-block-column col-10 offset-1 is-layout-flow wp-block-column-is-layout-flow">
                        <h2 class="wp-block-heading has-text-align-center has-large-3-font-size"  style={{
    fontFamily: '"Arial Black", Arial, sans-serif', // Replace with the closest matching font
    fontWeight: 'bold', // Ensures boldness
    color: '#625D5D', // Matches the white text color
  }}
                            id="h-your-favourite-ride-on-demand">Your ride to campus made simple! </h2>
                    </div>
                </div>



                <div
                    class="wp-block-columns is-layout-flex wp-container-core-columns-is-layout-2 wp-block-columns-is-layout-flex">
                    <div
                        class="wp-block-column col-8 md:col-3 offset-2 md:offset-1 is-layout-flow wp-block-column-is-layout-flow">
                        <div class="wp-block-group alignfull md:pe-40 is-layout-flow wp-block-group-is-layout-flow">
                            <figure class="wp-block-image aligncenter size-full"><img decoding="async" width="50"
                                    height="50" src={taxi} alt=""
                                    class="wp-image-15815"/></figure>



                            <p class="has-text-align-center"  style={{
    fontFamily: '"Arial Black", Arial, sans-serif', // Replace with the closest matching font
    fontWeight: 'bold',
    fontSize: '16px', // Ensures boldness
    color: '#625D5D', // Matches the white text color
  }}>Our drivers are trained, and our cars are regularly checked for your safety</p>
                        </div>
                    </div>



                    <div
                        class="wp-block-column col-8 md:col-4 offset-2 md:offset-0 mt-64 md:mt-0 is-layout-flow wp-block-column-is-layout-flow">
                        <div
                            class="wp-block-group alignfull px-8 md:px-64 is-layout-flow wp-block-group-is-layout-flow">
                            <figure class="wp-block-image aligncenter size-full"><img decoding="async" width="50"
                                    height="50" src={clock} alt=""
                                    class="wp-image-15819"/></figure>



                            <p class="has-text-align-center" style={{
    fontFamily: '"Arial Black", Arial, sans-serif', // Replace with the closest matching font
    fontWeight: 'bold',
    fontSize: '16px', // Ensures boldness
    color: '#625D5D', // Matches the white text color
  }}>shuttles runs on a set schedule, making it easy and convenient for you</p>
                        </div>
                    </div>



                    <div
                        class="wp-block-column col-8 md:col-3 offset-2 md:offset-0 mt-64 md:mt-0 is-layout-flow wp-block-column-is-layout-flow">
                        <div
                            class="wp-block-group alignfull px-8 md:pe-0 md:ps-40 is-layout-flow wp-block-group-is-layout-flow">
                            <figure class="wp-block-image aligncenter size-full"><img loading="lazy"
                                    decoding="async" width="50" height="50"
                                    src={headset} alt=""
                                    class="wp-image-15816"/></figure>



                            <p class="has-text-align-center" style={{
    fontFamily: '"Arial Black", Arial, sans-serif', // Replace with the closest matching font
    fontWeight: 'bold',
    fontSize: '16px', // Ensures boldness
    color: '#625D5D', // Matches the white text color
  }}>Stay in control and track every ride, with help from
                                live support 24/7</p>
                        </div>
                    </div>



                    <div
                        class="wp-block-column col-8 md:col-4 offset-2 mt-64 is-layout-flow wp-block-column-is-layout-flow">
                        <div
                            class="wp-block-group alignfull px-8 md:pe-56 md:ps-88 is-layout-flow wp-block-group-is-layout-flow">
                            <figure class="wp-block-image aligncenter size-full"><img loading="lazy"
                                    decoding="async" width="50" height="50"
                                    src={grad} alt=""
                                    class="wp-image-15818"/></figure>



                            <p class="has-text-align-center" style={{
    fontFamily: '"Arial Black", Arial, sans-serif', // Replace with the closest matching font
    fontWeight: 'bold',
    fontSize: '16px', // Ensures boldness
    color: '#625D5D', // Matches the white text color
  }}>No financial stress for NSFAS beneficiaries — your transport is fully covered.</p>
                        </div>
                    </div>



                    <div
                        class="wp-block-column col-8 md:col-4 offset-2 md:offset-0 mt-64 is-layout-flow wp-block-column-is-layout-flow">
                        <div
                            class="wp-block-group alignfull px-8 md:pe-88 md:ps-56 is-layout-flow wp-block-group-is-layout-flow">
                            <figure class="wp-block-image aligncenter size-full"><img loading="lazy"
                                    decoding="async" width="50" height="50"
                                    src={card} alt=""
                                    class="wp-image-15817"/></figure>



                            <p class="has-text-align-center" style={{
    fontFamily: '"Arial Black", Arial, sans-serif', // Replace with the closest matching font
    fontWeight: 'bold',
    fontSize: '16px', // Ensures boldness
    color: '#625D5D', // Matches the white text color
  }}>Pay once a month and ride as much as you want. </p>
                        </div>
                    </div>
                </div>
            </div>



            <div class="wp-block-group mb-104 md:mb-176 is-layout-flow wp-block-group-is-layout-flow">
                <div
                    class="wp-block-columns is-layout-flex wp-container-core-columns-is-layout-3 wp-block-columns-is-layout-flex">
                    <div class="wp-block-column md:col-5 md:pt-24 is-layout-flow wp-block-column-is-layout-flow">
                        <h2 class="wp-block-heading has-large-3-font-size" id="h-driven-by-quality" style={{
    fontFamily: '"Arial Black", Arial, sans-serif', // Replace with the closest matching font
    fontWeight: 'bold', // Ensures boldness
    color: '#625D5D', // Matches the white text color
    textAlign:'left'
  }}>Driven by
                            quality</h2>



                        <ul
                            class="is-style-square-primary-500 li-mt-24 md:li-mt-32 has-white-color has-text-color has-link-color wp-block-list wp-elements-50f2a78256df4d3876cf92a67a6be716">
                            <li style={{
    fontFamily: '"Arial", Arial, sans-serif', // Ensures boldness
    color: '#625D5D', 
    textAlign:'left'// Matches the white text color
  }}>All JAES drivers are fully licensed, thoroughly vetted and background checked. </li>



                            <li style={{
    fontFamily: '"Arial", Arial, sans-serif', // Replace with the closest matching font
    fontWeight: 'bold', // Ensures boldness
    color: '#625D5D',
    textAlign:'left' // Matches the white text color
  }}>All vehicles are GPS tracked to ensure we always know their location for your safety</li>



                            <li style={{
    fontFamily: '"Arial", Arial, sans-serif', // Replace with the closest matching font
    fontWeight: 'bold', // Ensures boldness
    color: '#625D5D',
    textAlign:'left' // Matches the white text color
  }}>All cars are modern, ensuring a comfortable and reliable ride every time</li>
                        </ul>
                    </div>



                    <div
                        class="wp-block-column md:col-7 mt-64 md:mt-0 is-layout-flow wp-block-column-is-layout-flow">
                        <figure class="wp-block-image aligncenter size-full is-resized md:hidden"><img
                                loading="lazy" decoding="async" width="764" height="1156"
                                src={thumb} alt=""
                                class="wp-image-15821" style={{width:"382px", borderRadius:'20px'}}
                                sizes="auto, (max-width: 764px) 100vw, 764px"/></figure>



                        <figure class="wp-block-image size-large is-resized max-md:hidden md:ms-72"><img
                                loading="lazy" decoding="async" width="1024" height="880"
                                src={thumb} alt=""
                                class="wp-image-15820" style={{width:"596px", borderRadius:'20px'}}
                                sizes="auto, (max-width: 1024px) 100vw, 1024px"/></figure>
                    </div>
                </div>
            </div>



            <div class="wp-block-group mb-104 md:mb-176 is-layout-flow wp-block-group-is-layout-flow">
                <h2 class="wp-block-heading has-text-align-center max-md:has-text-align-left has-large-3-font-size"
                    id="h-environmentally-friendly" style={{
                        fontFamily: '"Arial Black", Arial, sans-serif', // Replace with the closest matching font
                        fontWeight: 'bold', // Ensures boldness
                        color: '#625D5D', // Matches the white text color
                      }}>Affordable & Transparent Pricing</h2>



                <div
                    class="wp-block-columns mb-56 md:mb-64 is-layout-flex wp-container-core-columns-is-layout-4 wp-block-columns-is-layout-flex">
                    <div class="wp-block-column md:col-8 md:offset-2 is-layout-flow wp-block-column-is-layout-flow">
                        <p style={{
fontFamily: "'Helvetica', 'Montserrat', sans-serif",
fontWeight: 'bold', // Ensures boldness
    color: '#625D5D', // Matches the white text color
  }}
                            class="has-text-align-center max-md:has-text-align-left has-medium-2-font-size md:mt-24 md:px-4">
                            We keep things simple and affordable with a flat monthly rate & a once-off service fee—no hidden fees, no surprises.
                            
                            </p>
                    </div>
                </div>



                <div
                    class="wp-block-columns is-layout-flex wp-container-core-columns-is-layout-5 wp-block-columns-is-layout-flex">
                    <div class="wp-block-column md:col-4 is-layout-flow wp-block-column-is-layout-flow">
                        <figure class="wp-block-image size-full"><img loading="lazy" decoding="async" width="50"
                                height="50" src={leaf} alt=""
                                class="wp-image-15814"/></figure>



                        <h3 style={{
    fontFamily: '"Arial Black", Arial, sans-serif', // Replace with the closest matching font
    fontWeight: 'bold', // Ensures boldness
    color: '#625D5D', // Matches the white text color
  }} class="wp-block-heading has-large-font-size mt-16" id="h-carbon-neutral"><strong>Monthly fee

  </strong></h3>



                        <p style={{
    fontFamily: '"Arial Black", Arial, sans-serif', // Replace with the closest matching font
    fontWeight: 'bold', 
    fontSize: '14px',// Ensures boldness
    color: '#625D5D', // Matches the white text color
  }} class="mt-8 md:mt-16 md:pe-48">  R550 Per Month</p>
                    </div>



                    <div
                        class="wp-block-column md:col-4 mt-56 md:mt-0 is-layout-flow wp-block-column-is-layout-flow">
                        <figure class="wp-block-image size-full"><img loading="lazy" decoding="async" width="50"
                                height="50" src={tree} alt=""
                                class="wp-image-15812"/></figure>



                        <h3 style={{
    fontFamily: '"Arial Black", Arial, sans-serif', // Replace with the closest matching font
    fontWeight: 'bold', // Ensures boldness
    color: '#625D5D', // Matches the white text color
  }} class="wp-block-heading has-large-font-size mt-16" id="h-greener-cities">
                            <strong><strong>Service fee

</strong></strong></h3>



                        <p style={{
    fontFamily: '"Arial Black", Arial, sans-serif', // Replace with the closest matching font
    fontWeight: 'bold', 
    fontSize: '14px', // Ensures boldness
    color: '#625D5D', // Matches the white text color
  }} class="mt-8 md:mt-16">R450 once-off service fee </p>
                    </div>



                    <div
                        class="wp-block-column md:col-4 mt-56 md:mt-0 is-layout-flow wp-block-column-is-layout-flow">
                        <div class="wp-block-group alignfull md:ps-48 is-layout-flow wp-block-group-is-layout-flow">
                            <figure class="wp-block-image size-full"><img loading="lazy" decoding="async" width="50"
                                    height="50" src={plug} alt=""
                                    class="wp-image-15813"/></figure>



                            <h3 style={{
    fontFamily: '"Arial Black", Arial, sans-serif', // Replace with the closest matching font
    fontWeight: 'bold', // Ensures boldness
    color: '#625D5D', // Matches the white text color
  }} class="wp-block-heading has-large-font-size mt-16" id="h-electric-taxis">
                                <strong><strong><strong>Discount

</strong></strong></strong></h3>



                            <p style={{
    fontFamily: '"Arial Black", Arial, sans-serif', // Replace with the closest matching font
    fontWeight: 'bold', 
    fontSize: '14px', // Ensures boldness
    color: '#625D5D', // Matches the white text color
  }} class="mt-8 md:mt-16">R250 once-off service fee for For JAES residents</p>
                        </div>
                    </div>
                </div>
            </div>



            <div class="wp-block-group is-layout-flow wp-block-group-is-layout-flow">
                <div class="wp-block-cover is-light rounded-24 md:rounded-50 py-32 md:pt-64 md:pb-72"><span
                        aria-hidden="true"
                        class="wp-block-cover__background has-primary-500-background-color has-background-dim-100 has-background-dim"></span>
                    <div class="wp-block-cover__inner-container is-layout-flow wp-block-cover-is-layout-flow">
                        <h2 class="wp-block-heading has-text-align-center has-white-color has-text-color has-link-color has-large-3-font-size mb-24 md:mb-40 wp-elements-b34f777ce537e4ca004b5db7f132b26d"
                            id="h-start-riding-today">Start riding today!</h2>



                        <div
                            class="wp-block-buttons is-content-justification-center is-layout-flex wp-container-core-buttons-is-layout-2 wp-block-buttons-is-layout-flex">
                            <div class="wp-block-button" data-button-position="start riding today"><a 
                                    class="wp-block-button__link has-gray-600-color has-white-background-color has-text-color has-background has-link-color wp-element-button"
                                    onClick={handleJoinClick}><span style={{
                                        fontFamily: '"Arial Black", Arial, sans-serif', // Replace with the closest matching font
                                        fontWeight: 'bold', // Ensures boldness
                                        fontSize: '20px',
                                        color: '#ff5a5f', // Matches the white text color
                                      }}>Book Ride</span> </a></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <style>{`
		.row {
    display: block;
    width: 100%;
    padding: 0;
    margin: 0
}

.row:after {
    content: '';
    display: table;
    clear: both
}

.row>.cell:first-child {
    margin-left: 0 !important
}

.row>.cell:last-child {
    margin-right: 0 !important
}
    @font-face {
    font-family: 'Helvetica';
    font-weight: 400;
    font-style: normal;
}

@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;700&display=swap');

body {
    font-family: 'Helvetica', 'Montserrat', sans-serif;
}


@font-face {
    font-family: 'Graphik Gett';
    font-weight: 300;
    font-style: normal;
    font-display: swap;
    src: url("file:///wp-content/themes/gett/build/fonts/graphikgett/GraphikGett-Light.woff2") format("woff2"), url("file:///wp-content/themes/gett/build/fonts/graphikgett/GraphikGett-Light.woff") format("woff")
}

@font-face {
    font-family: 'Graphik Gett';
    font-weight: 400;
    font-style: normal;
    font-display: swap;
    src: url("file:///wp-content/themes/gett/build/fonts/graphikgett/GraphikGett-Regular.woff2") format("woff2"), url("file:///wp-content/themes/gett/build/fonts/graphikgett/GraphikGett-Regular.woff") format("woff")
}

@font-face {
    font-family: 'Graphik Gett';
    font-weight: 500;
    font-style: normal;
    font-display: swap;
    src: url("file:///wp-content/themes/gett/build/fonts/graphikgett/GraphikGett-Medium.woff2") format("woff2"), url("file:///wp-content/themes/gett/build/fonts/graphikgett/GraphikGett-Medium.woff") format("woff")
}

@font-face {
    font-family: 'Graphik Gett';
    font-weight: 600;
    font-style: normal;
    font-display: swap;
    src: url("file:///wp-content/themes/gett/build/fonts/graphikgett/GraphikGett-Semibold.woff2") format("woff2"), url("file:///wp-content/themes/gett/build/fonts/graphikgett/GraphikGett-Semibold.woff") format("woff")
}

@font-face {
    font-family: 'Graphik Gett';
    font-weight: 700;
    font-style: normal;
    font-display: swap;
    src: url("file:///wp-content/themes/gett/build/fonts/graphikgett/GraphikGett-Bold.woff2") format("woff2"), url("file:///wp-content/themes/gett/build/fonts/graphikgett/GraphikGett-Bold.woff") format("woff")
}

*,
::before,
::after {
    box-sizing: border-box;
    border-width: 0;
    border-style: solid;
    border-color: #dbe0e3
}

html {
    scroll-padding-top: 68px
}

@media (min-width: 1080px) {
    html {
        scroll-padding-top: 96px
    }
}

html body,
html ul,
html ol,
html button,
html input,
html textarea,
html table {
    margin: 0;
    padding: 0;
    font-family: "Graphik Gett", sans-serif;
    font-weight: 400;
    color:#e5e6e8
}

body {
    font-size: 100%
}

body.navigation-active {
    overflow: hidden
}

::-moz-selection {
    background: #ff5a5f;
    color: #ffffff
}

::selection {
    background: #ff5a5f;
    color: #ffffff
}

:focus-visible {
    outline: 2px solid #ff5a5f;
    outline-offset: 4px
}

h1,
h2,
h3,
h4,
h5,
h5,
p {
    margin: 0;
    padding: 0
}

.grecaptcha-badge {
    visibility: hidden
}

ul {
    list-style: none
}

a {
    color: #e5e6e8;
    text-decoration: none
}

a:not(.btn):not(.wp-block-button__link):hover {
    text-decoration: underline
}

img {
    vertical-align: middle
}

i {
    display: inline-block
}

input,
textarea {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    border-radius: 0
}

table {
    border: none;
    border-collapse: inherit;
    border-spacing: 0;
    border-color: inherit;
    vertical-align: inherit;
    font-weight: inherit;
    -webkit-border-horizontal-spacing: 0;
    -webkit-border-vertical-spacing: 0
}

.gt-container {
    width: 100%;
    padding: 0 20px;
    max-width: 1200px
}

@media only screen and (min-width: 768px) {
    .gt-container {
        width: 90%;
        padding: 0;
        margin: 0 auto
    }
}

@media only screen and (min-width: 1024px) {
    .gt-container {
        width: 80%
    }
}

.cf:after {
    content: '';
    clear: both;
    overflow: hidden;
    display: block;
    visibility: hidden;
    margin: 0;
    padding: 0;
    height: 0;
    width: 0
}

.middle,
.lb.video iframe {
    transform: translate(-50%, -50%);
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-filter: blur(0);
    filter: blur(0)
}

.sprite {
    background-image: url("../../../../../wp-content/themes/gett/build/images/sprite.png");
    transition: background 0.3s ease
}

.sprite-hover {
    background-image: url("../../../../../wp-content/themes/gett/build/images/sprite-hover.png")
}

.loader {
    width: 30px;
    height: 30px;
    border-radius: 100%;
    position: relative;
    margin: 0 auto;
    -webkit-animation-name: loader;
    animation-name: loader;
    -webkit-animation-duration: 1s;
    animation-duration: 1s;
    -webkit-animation-timing-function: linear;
    animation-timing-function: linear;
    -webkit-animation-iteration-count: infinite;
    animation-iteration-count: infinite;
    -webkit-animation-direction: normal;
    animation-direction: normal;
    -webkit-animation-delay: 0s;
    animation-delay: 0s;
    -webkit-animation-play-state: running;
    animation-play-state: running;
    -webkit-animation-fill-mode: forwards;
    animation-fill-mode: forwards
}

@-webkit-keyframes loader {
    0% {
        transform: rotateY(0deg);
        background: #fbb623
    }

    50% {
        transform: rotateY(180deg);
        background: #fff
    }

    100% {
        transform: rotateY(360deg);
        background: #fbb623
    }
}

@keyframes loader {
    0% {
        transform: rotateY(0deg);
        background: #fbb623
    }

    50% {
        transform: rotateY(180deg);
        background: #fff
    }

    100% {
        transform: rotateY(360deg);
        background: #fbb623
    }
}

.btn-close {
    width: 27px;
    height: 27px;
    background: url("../../../../../wp-content/themes/gett/build/images/btn-close.png") no-repeat;
    position: absolute;
    top: 130px;
    left: 50px;
    cursor: pointer;
    font: 0/0 a;
    color: transparent
}

.black-mask,
.lb.video {
    background: #000
}

.btn-vertical {
    border-color:rgb(251, 35, 35)
}

.btn-vertical:hover {
    background:rgb(251, 42, 35)
}

.background-covered {
    background-size: cover;
    background-position: center
}

.span10 {
    width: 10%
}

.span20 {
    width: 20%
}

.span25 {
    width: 25%
}

.span30 {
    width: 30%
}

.span40 {
    width: 40%
}

.span50 {
    width: 50%
}

.span60 {
    width: 60%
}

.span66 {
    width: 66.66%
}

.span75 {
    width: 75%
}

.span80 {
    width: 80%
}

.span100 {
    width: 100%
}

.workarea {
    margin: 0 auto;
    max-width: 800px;
    width: 100%
}

.arrows-down {
    width: 40px;
    height: 55px;
    position: absolute;
    left: 50%;
    margin-left: -20px;
    bottom: 20px
}

.arrows-down path {
    stroke: #fff;
    fill: transparent;
    stroke-width: 1px;
    animation: arrow 2s infinite;
    -webkit-animation: arrow 2s infinite
}

@keyframes arrow {
    0% {
        opacity: 0
    }

    40% {
        opacity: 1
    }

    80% {
        opacity: 0
    }

    100% {
        opacity: 0
    }
}

@-webkit-keyframes arrow

/*Safari and Chrome*/
    {
    0% {
        opacity: 0
    }

    40% {
        opacity: 1
    }

    80% {
        opacity: 0
    }

    100% {
        opacity: 0
    }
}

.arrows-down path.a1 {
    animation-delay: -1s;
    -webkit-animation-delay: -1s
}

.arrows-down path.a2 {
    animation-delay: -0.5s;
    -webkit-animation-delay: -0.5s
}

.arrows-down path.a3 {
    animation-delay: 0s;
    -webkit-animation-delay: 0s
}

form {
    position: relative
}

form .loader-wrapper {
    display: none;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(255, 255, 255, 0.5)
}

form .loader-wrapper>div {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -200%)
}

form#faqForm .loader-wrapper>div {
    right: 20px;
    left: auto;
    transform: translateY(-50%)
}

@-webkit-keyframes ball-scale {
    0% {
        transform: scale(0)
    }

    100% {
        transform: scale(1);
        opacity: 0
    }
}

@keyframes ball-scale {
    0% {
        transform: scale(0)
    }

    100% {
        transform: scale(1);
        opacity: 0
    }
}

.loader-ball-scale div {
    background-color: #fbb623;
    width: 15px;
    height: 15px;
    border-radius: 100%;
    margin: 2px;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
    display: inline-block;
    height: 60px;
    width: 60px;
    -webkit-animation: ball-scale 1s 0s ease-in-out infinite;
    animation: ball-scale 1s 0s ease-in-out infinite
}

.oauth-access {
    position: absolute;
    left: 50%;
    z-index: 9999
}

#captcha {
    display: inline-block;
    float: left
}

header[style*='background'],
#section1[style*='background'] {
    position: relative;
    overflow: hidden
}

header[style*='background']:before,
#section1[style*='background']:before {
    content: '';
    display: block;
    width: 105%;
    height: 105%;
    position: absolute;
    top: -2%;
    left: -2%;
    background-color: rgba(0, 0, 0, 0.3)
}

.entry-content {
    min-width: 320px;
    max-width: 100vw;
    overflow-x: hidden
}

@media screen and (max-width: 1025px) and (min-height: 767px) {

    .home .section6-2 .video,
    .home .section6-1 .middle,
    .home .section6-1 .lb.video iframe,
    .lb.video .home .section6-1 iframe {
        position: static !important;
        transform: none !important
    }
}

header.nothing {
    max-height: 50px;
    background: #fff !important
}

.checkboxes {
    float: left;
    width: 100%;
    padding-top: 10px;
    padding-bottom: 10px;
    margin-top: 5px;
    margin-bottom: 30px;
    border-top: 4px solid #fbb623;
    outline: none !important;
    transition: all 0.6s
}

.inputs-wrapper.alignleft.span50 .input:last-child .checkboxes {
    border: none !important
}

.checkboxes.error {
    border-top: 4px solid #f00
}

.checkboxes .form-free-text {
    display: block;
    padding-top: 5px;
    margin-bottom: 10px;
    font-size: 22px;
    font-weight: bold;
    font-style: italic;
    color: #666
}

.checkboxes .radio-wrapper {
    float: left;
    width: 100%;
    padding: 6px 0
}

.checkboxes .radio-wrapper input {
    left: -5%;
    margin-left: 10%
}

.checkboxes .radio-wrapper input.visible-checkbox {
    appearance: checkbox;
    -webkit-appearance: checkbox;
    -moz-appearance: checkbox
}

.checkboxes .radio-wrapper label {
    margin-left: 10px
}

@media screen and (max-width: 800px) {
    .checkboxes .radio-wrapper label {
        margin-left: 3px !important
    }
}

.checkboxes .radio-wrapper input[type='radio'] {
    float: left;
    max-width: 20px;
    height: 20px;
    font-size: 15px !important;
    -webkit-appearance: radio;
    box-sizing: border-box
}

.input.radio .form-sub-free-text {
    display: block;
    float: left;
    width: 100%;
    padding-bottom: 10px;
    margin: 0 0 15px 0;
    border-bottom: 1px solid #666
}

.input .form-sub-free-text {
    display: block;
    float: left;
    width: 100%;
    padding-bottom: 10px;
    margin: 15px 0 0 3px
}

.input.radio {
    float: left;
    width: 50%
}

@media screen and (max-width: 800px) {
    .input.radio {
        width: 100% !important
    }
}

.radio-questions .input {
    float: left;
    min-height: 300px
}

.input.submit {
    float: right;
    width: 100%
}

input#if_tlc {
    font-size: 18px !important
}

input#tlc_number {
    display: none
}

.videoBG,
.videoBG_wrapper {
    width: 100% !important;
    height: 100% !important
}

.videoBG video {
    height: auto !important;
    width: auto !important;
    min-height: 100% !important;
    min-width: 100% !important;
    top: 50% !important;
    left: 50% !important;
    transform: translate(-50%, -50%)
}

@media screen and (max-width: 800px) {
    .videoBG {
        z-index: 999 !important
    }
}

.light-btn {
    color: #fdb924;
    font-size: 1.1em;
    font-weight: bold;
    letter-spacing: 0.5px;
    background-color: #ffffff;
    padding: 15px;
    display: inline-block;
    border-radius: 4px;
    transition: all 0.2s ease-in-out
}

.light-btn:hover {
    opacity: 0.8
}

.iti--container {
    z-index: 9999 !important
}

body.top-bar main {
    padding-top: 68px
}

@media (min-width: 1080px) {
    body.top-bar main {
        padding-top: 96px
    }
}

#onetrust-banner-sdk div {
    min-width: auto !important
}

#onetrust-banner-sdk .ot-sdk-container {
    padding: 13px 0 !important;
    width: 100% !important
}

#onetrust-banner-sdk .ot-sdk-row {
    display: flex !important;
    align-items: center;
    max-width: 1200px !important;
    margin: 0 auto !important
}

@media (max-width: 781px) {
    #onetrust-banner-sdk .ot-sdk-row {
        flex-wrap: wrap
    }
}

#onetrust-banner-sdk .ot-sdk-show-settings {
    text-transform: lowercase
}

#onetrust-group-container {
    padding: 0 1rem !important;
    width: 100% !important
}

@media (min-width: 782px) {
    #onetrust-group-container {
        width: auto !important
    }
}

#onetrust-policy {
    margin: 0 !important
}

#onetrust-policy-text {
    margin: 0 !important
}

#onetrust-policy-text a {
    color: #134bc3 !important;
    font-weight: 500 !important;
    text-decoration: underline !important;
    margin: 0 !important;
    cursor: pointer !important
}

#onetrust-policy-text a:hover {
    color: #5384ee !important
}

#onetrust-button-group-parent {
    width: 100% !important;
    position: initial !important;
    transform: none !important;
    padding: 0 1rem !important;
    margin: 13px 0 0 !important
}

@media (min-width: 782px) {
    #onetrust-button-group-parent {
        width: auto !important;
        margin: 0 !important
    }
}

#onetrust-button-group {
    display: flex !important;
    gap: 1rem;
    width: 100% !important;
    margin: 0 !important
}

#onetrust-reject-all-handler,
#onetrust-accept-btn-handler {
    height: 40px !important;
    width: 50% !important;
    min-width: 150px !important;
    border-radius: 10px !important;
    padding: 0.5rem !important;
    margin: 0 !important
}

@media (min-width: 782px) {

    #onetrust-reject-all-handler,
    #onetrust-accept-btn-handler {
        width: auto !important
    }
}

#onetrust-reject-all-handler {
    color: #ff5a5f !important;
    background: none !important
}

.site-navigation .menu .menu-item {
    position: relative
}

.site-navigation .menu .menu-item.menu-item-has-children>a {
    position: relative
}

.site-navigation .menu .menu-item.menu-item-has-children>a::after {
    content: '';
    background-image: url("file:///wp-content/themes/gett/build/images/arrow-nav.svg");
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center
}

@media (min-width: 1080px) {
    .site-navigation .menu .menu-item.menu-item-has-children>a::after {
        display: inline-block;
        width: 10px;
        height: 6.25px
    }
}

@media (max-width: 1079px) {
    .site-navigation .menu>.menu-item.menu-item-has-children>a::after {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        width: 10px;
        height: 6.25px;
        right: 0
    }
}

.site-navigation a:hover {
    text-decoration: none !important
}

.site-navigation button {
    background: none;
    border: none
}

.site-navigation .navigation-container ul.menu li {
    position: relative
}

@media (min-width: 1080px) {
    .site-navigation .navigation-container ul.menu li.menu-item-has-children>a:hover+.sub-menu {
        display: block !important
    }
}

@media (max-width: 1079px) {
    .site-navigation .navigation-container ul.menu li.menu-item-has-children.sub-menu-open>a::after {
        transform: translateY(-50%) rotate(180deg)
    }
}

.site-navigation .navigation-container ul.menu li.menu-item-has-children.sub-menu-open>.sub-menu {
    display: block !important
}

.site-navigation .navigation-container ul.menu li.current-menu-item>a,
.site-navigation .navigation-container ul.menu li.current-menu-parent>a {
    font-weight: 700
}

@media (max-width: 1079px) {
    .site-navigation .navigation-container ul.menu>li.menu-item-has-children>a::after {
        width: 10px;
        height: 6.25px
    }
}

@media (max-width: 1079px) {
    .site-navigation .navigation-container ul.menu>li:not(:last-child) {
        border-bottom: solid 0.5px #dbe0e3
    }

    .site-navigation .navigation-container ul.menu>li a {
        display: block
    }

    .site-navigation .navigation-container ul.menu>li>a {
        font-size: 1.125rem;
        font-weight: 500;
        padding: 1.5rem 0
    }
}

@media (max-width: 1079px) {
    .site-navigation .navigation-container ul.menu>li .sub-menu {
        display: none
    }
}

@media (min-width: 1080px) {
    .site-navigation .navigation-container ul.menu>li .sub-menu li {
        margin-top: 1rem
    }
}

@media (max-width: 1079px) {
    .site-navigation .navigation-container ul.menu>li>.sub-menu>li {
        padding-bottom: 1.75rem
    }

    .site-navigation .navigation-container ul.menu>li>.sub-menu>li.menu-item-has-children>a::after {
        position: initial;
        display: inline-block;
        width: 7px;
        height: 5px;
        margin: 0 0 .1875rem .5rem
    }
}

@media (max-width: 1079px) {
    .site-navigation .navigation-container ul.menu>li>.sub-menu .sub-menu {
        padding-top: 1.5rem
    }
}

.site-navigation .navigation-container ul.menu>li>.sub-menu .sub-menu li:not(:first-of-type) {
    margin-top: 1rem
}

@media (max-width: 1079px) {
    .site-navigation .navigation-container ul.menu>li>.sub-menu .sub-menu li a {
        color: #999999
    }
}

#masthead {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 10;
    padding: 1.375rem 0 1.0625rem;
    height: 68px
}

@media (min-width: 1080px) {
    #masthead {
        height: 96px
    }
}

#masthead #open-navigation {
    height: 48px;
    padding: .875rem;
    margin: -.875rem;
    cursor: pointer
}

#masthead .mobile-top-bar-button {
    height: 36px;
    min-width: 120px;
    border-radius: .625rem
}

@media (max-width: 1079px) {
    #masthead .navigation-container {
        position: absolute;
        top: 0;
        height: 100vh;
        width: 90%;
        max-width: 450px;
        overflow-y: auto;
        padding: 1.0625rem 1.6875rem 4.5rem;
        background-color: #fff;
        transition: transform 0.25s ease-in-out;
        right: 0;
        transform: translateX(100%);
        border-top-left-radius: 1.5rem;
        border-bottom-left-radius: 1.5rem
    }
}

@media (max-width: 1079px) {
    #masthead .navigation-container .close-container {
        text-align: end
    }

    #masthead .navigation-container .close-container #close-navigation {
        padding: .625rem .9375rem;
        margin: -.625rem -.9375rem
    }
}

@media (min-width: 1080px) {
    #masthead .navigation-container ul.menu a:hover {
        font-weight: 700
    }
}

@media (min-width: 1080px) {
    #masthead .navigation-container ul.menu>li {
        display: inline-block;
        -webkit-margin-end: 1rem;
        margin-inline-end: 1rem
    }

    #masthead .navigation-container ul.menu>li:first-of-type {
        -webkit-margin-start: 1rem;
        margin-inline-start: 1rem
    }
}

@media (min-width: 1280px) {
    #masthead .navigation-container ul.menu>li {
        -webkit-margin-end: 1.5rem;
        margin-inline-end: 1.5rem
    }
}

@media (min-width: 1080px) {
    #masthead .navigation-container ul.menu>li.menu-item-has-children>a::after {
        vertical-align: middle;
        -webkit-margin-start: .5rem;
        margin-inline-start: .5rem
    }
}

#masthead .navigation-container ul.menu>li>a {
    display: block;
    margin-right: .125rem;
    margin-left: .125rem
}

@media (min-width: 1080px) {
    #masthead .navigation-container ul.menu>li>a {
        display: inline-block;
        padding-bottom: 1rem;
        margin-bottom: -1rem
    }

    #masthead .navigation-container ul.menu>li>a:hover {
        margin-right: 0;
        margin-left: 0
    }
}

#masthead .navigation-container ul.menu>li .sub-menu {
    display: none
}

@media (min-width: 1080px) {
    #masthead .navigation-container ul.menu>li .sub-menu {
        position: absolute;
        padding: 1.5rem 0;
        width: 245px;
        background-color: #fff;
        border-radius: .75rem;
        box-shadow: 0 0 10px 0 rgba(29, 35, 42, 0.12);
        transition: all 0.2s ease-in-out
    }

    #masthead .navigation-container ul.menu>li .sub-menu:hover {
        display: block
    }
}

@media (min-width: 1080px) {
    #masthead .navigation-container ul.menu>li .sub-menu li:first-of-type {
        margin-top: 0
    }
}

@media (min-width: 1080px) {
    #masthead .navigation-container ul.menu>li .sub-menu li.menu-item-has-children>a::after {
        position: absolute;
        top: 50%;
        right: 15px;
        bottom: 5px;
        transform: translateY(-50%) rotate(-90deg)
    }
}

#masthead .navigation-container ul.menu>li .sub-menu li a {
    display: block
}

@media (min-width: 1080px) {
    #masthead .navigation-container ul.menu>li .sub-menu li a {
        padding: 0 1.5rem
    }
}

@media (min-width: 1080px) {
    #masthead .navigation-container ul.menu>li>.sub-menu {
        margin-top: .25rem
    }

    #masthead .navigation-container ul.menu>li>.sub-menu .sub-menu {
        top: 0;
        left: 245px
    }
}

@media (max-width: 1079px) {
    #masthead .navigation-container .buttons-container {
        margin-top: 1rem
    }

    #masthead .navigation-container .buttons-container a {
        width: 100%
    }

    #masthead .navigation-container .buttons-container a.btn-primary {
        margin-bottom: 1.5rem
    }
}

@media (min-width: 1080px) {
    #masthead .navigation-container .buttons-container .login-btn {
        font-weight: 700;
        border: 0;
        height: auto;
        min-width: auto
    }

    #masthead .navigation-container .buttons-container .login-btn:hover {
        color: #ff5a5f;
        background-color: transparent !important;
        text-decoration: underline !important
    }
}

#masthead .country-picker .current-site p {
    font-weight: bold
}

@media (min-width: 1080px) {
    #masthead .country-picker ul.menu {
        line-height: 0
    }
}

#masthead .country-picker .open-country-picker {
    line-height: 1;
    width: 100%
}

@media (min-width: 1080px) {
    #masthead .country-picker .open-country-picker {
        font-size: 0
    }
}

#masthead .country-picker .sub-menu li a {
    display: flex !important;
    align-items: center
}

#masthead .country-picker .sub-menu li a p:hover {
    font-weight: 700
}

@media (max-width: 1079px) {
    #masthead.navigation-active::before {
        content: '';
        position: absolute;
        top: 0;
        width: 100vw;
        height: 100vh;
        background-color: rgba(38, 50, 56, 0.7)
    }

    #masthead.navigation-active .navigation-container {
        transform: none !important
    }
}

#footer.small-footer .footer-bottom-container {
    border-top: solid 1px #d9d9d9
}

#footer .navigation-container {
    border-top: solid 1px #d9d9d9
}

#footer .navigation-container nav {
    width: 100%
}

#footer .navigation-container ul.menu li.menu-item-has-children>a {
    cursor: initial
}

#footer .navigation-container ul.menu li:not(.menu-item-has-children) a:hover {
    text-decoration: underline !important
}

@media (min-width: 1080px) {
    #footer .navigation-container ul.menu>li {
        width: 20.5%;
        -webkit-padding-end: 1.25rem;
        padding-inline-end: 1.25rem
    }

    #footer .navigation-container ul.menu>li.menu-item-has-children>a::after {
        display: none
    }

    #footer .navigation-container ul.menu>li>.sub-menu {
        padding-top: .5rem
    }
}

#footer .navigation-container ul.menu>li>a {
    font-weight: 500
}

@media (min-width: 1080px) {
    #footer .navigation-container ul.menu>li>a {
        font-weight: 600
    }
}

@media (min-width: 1080px) {
    #footer .footer-bottom-container {
        text-align: initial;
        border-top: solid 1px #d9d9d9
    }
}

#footer .footer-bottom-container a:hover {
    text-decoration: underline !important
}

#footer .footer-bottom-container ul li {
    display: inline-block;
    margin: 0 .75rem .5rem
}

@media (min-width: 1080px) {
    #footer .footer-bottom-container ul li {
        margin: 0;
        -webkit-margin-end: 1rem;
        margin-inline-end: 1rem
    }
}

#footer .footer-bottom-container ul li a {
    color: #4d5a60
}

#ot-sdk-btn.ot-sdk-show-settings {
    border: unset !important
}

#ot-sdk-btn.ot-sdk-show-settings:hover {
    background-color: unset !important;
    text-decoration: underline !important
}

.lb.video .btn-close {
    top: 27px
}

.lb.video iframe {
    transform: translate(-50%, -50%);
    width: 60%;
    height: 80%
}

#secondary {
    float: right;
    padding: 50px 0 50px 20px
}

#secondary h3 {
    padding: 0 20px;
    font-weight: 100;
    font-size: 1.8em
}

#secondary .block {
    padding: 20px;
    width: 100%
}

#secondary .block .inner {
    background: #fff;
    border: 1px solid #e1e4e5;
    border-bottom-width: 2px;
    border-radius: 2px;
    transition: border-color .2s ease-in-out;
    -webkit-font-smoothing: antialiased
}

#secondary .block .inner:hover {
    border-color:rgb(207, 32, 26)
}

#secondary .block .inner .header {
    margin-right: 1px
}

#secondary .block .inner .content,
#secondary .block .inner .footer {
    padding: 20px
}

#secondary .block .inner .header a,
#secondary .block .inner .content a {
    color:rgb(251, 49, 35)
}

#secondary .block .inner.twitter .header {
    padding: 20px
}

#secondary .block .inner.twitter .header .avatar {
    height: 28px;
    border-radius: 3px;
    padding-right: 3px;
    float: left
}

#secondary .block .inner.twitter .header .date {
    float: right;
    color: #aaa;
    font-size: 0.9em
}

#secondary .block .inner.twitter .header h4 .names {
    vertical-align: top;
    padding-left: 35px;
    display: block
}

#secondary .block .inner.twitter .header h4 .names a {
    display: block
}

#secondary .block .inner.twitter .header h4 .names a .name {
    font-weight: bold;
    display: block;
    font-size: 0.9em;
    line-height: 1;
    color: #000
}

#secondary .block .inner.twitter .header h4 .names a .at {
    color: #aaa;
    font-size: 0.6em;
    font-weight: 100;
    line-height: 1.6;
    display: block
}

#secondary .block .inner.twitter .content {
    padding-top: 0;
    padding-bottom: 30px;
    font-size: 1.1em;
    line-height: 1.5;
    border-bottom: 1px solid #e1e4e5
}

#secondary .block .inner.twitter .footer .icon {
    color: #fff;
    height: 50px;
    width: 50px;
    border-radius: 100%;
    background: #4eaaeb;
    font-size: 1.7em;
    text-align: center;
    vertical-align: middle;
    line-height: 50px;
    margin-top: -20px;
    transform: translateY(-50%)
}

#secondary .block .inner.instagram .header {
    overflow: hidden;
    position: relative
}

#secondary .block .inner.instagram .header img {
    width: 100%;
    height: auto
}

#secondary .block .inner.instagram .header .title {
    position: absolute;
    left: 0;
    bottom: 0;
    padding: 10px 20px 10px 20px;
    color: #fff;
    background: -moz-linear-gradient(top, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.37) 34%, rgba(0, 0, 0, 0.7) 100%);
    background: -webkit-gradient(linear, left top, left bottom, color-stop(0%, rgba(0, 0, 0, 0)), color-stop(34%, rgba(0, 0, 0, 0.37)), color-stop(100%, rgba(0, 0, 0, 0.7)));
    background: -webkit-linear-gradient(top, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.37) 34%, rgba(0, 0, 0, 0.7) 100%);
    background: -o-linear-gradient(top, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.37) 34%, rgba(0, 0, 0, 0.7) 100%);
    background: -ms-linear-gradient(top, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.37) 34%, rgba(0, 0, 0, 0.7) 100%);
    background: linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.37) 34%, rgba(0, 0, 0, 0.7) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#00000000', endColorstr='#b3000000', GradientType=0)
}

#secondary .block .inner.instagram .header .title h4 {
    font-weight: 100;
    font-size: 1.1em;
    line-height: 1.5;
    padding-bottom: 10px;
    text-shadow: 0 0 2px rgba(0, 0, 0, 0.3)
}

#secondary .block .inner.instagram .header .title .date {
    font-size: 0.9em
}

#secondary .block .inner.instagram .footer .icon {
    color: #fff;
    height: 50px;
    width: 50px;
    border-radius: 100%;
    background: #363636;
    font-size: 1.7em;
    text-align: center;
    vertical-align: middle;
    line-height: 50px;
    margin-top: -20px;
    transform: translateY(-50%)
}

#secondary .block .inner.instagram .footer .avatar {
    float: left;
    border-radius: 100%;
    height: 42px;
    width: 42px
}

#secondary .block .inner.instagram .footer .author-inner {
    padding-left: 52px
}

#secondary .block .inner.instagram .footer .author-inner .name {
    color: #1d80f4;
    font-weight: bold;
    padding-bottom: 5px
}

#secondary .block .inner.instagram .footer .author-inner .category {
    color: #aaa;
    font-size: 0.8em
}

#secondary .block .inner.website .header {
    padding: 0;
    overflow: hidden;
    position: relative
}

#secondary .block .inner.website .header img {
    width: auto;
    min-width: 100%;
    height: 200px;
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%)
}

#secondary .block .inner.website .header .title {
    padding: 220px 20px 0px 20px
}

#secondary .block .inner.website .header .title h4 {
    font-weight: 100;
    font-size: 1.8em;
    line-height: 1.3;
    padding-bottom: 12px
}

#secondary .block .inner.website .header .title .date {
    color: #aaa;
    font-size: 0.9em
}

#secondary .block .inner.website .content {
    padding-bottom: 30px;
    border-bottom: 1px solid #e1e4e5
}

#secondary .block .inner.website .footer .icon {
    color: #000;
    height: 50px;
    width: 50px;
    border-radius: 100%;
    background: #fbb623;
    font-size: 3.3em;
    text-align: center;
    vertical-align: middle;
    line-height: 0;
    margin-top: -20px;
    transform: translateY(-50%)
}

#secondary .block .inner.website .footer i {
    display: block;
    margin-top: -3px;
    margin-left: 0px
}

#secondary .block .inner.website .footer .avatar {
    float: left;
    -webkit-border-radius: 100%;
    -moz-border-radius: 100%;
    -ms-border-radius: 100%;
    border-radius: 100%
}

#secondary .block .inner.website .footer .author-inner {
    padding-left: 52px
}

#secondary .block .inner.website .footer .author-inner .name {
    color: #1d80f4;
    font-weight: bold;
    padding-bottom: 5px
}

#secondary .block .inner.website .footer .author-inner .category {
    color: #aaa;
    font-size: 0.8em
}

@media screen and (min-width: 1600px) {
    #contact .form .workarea .form-inputs>div textarea {
        height: 247px
    }
}

@media screen and (max-width: 1280px) {
    #page .workarea {
        width: calc(100% - 40px)
    }

    #page .workarea .entry-content {
        padding-top: 0
    }

    #page .workarea img {
        max-width: 100%
    }

    #contact .phones-numbers .workarea {
        width: 100%
    }
}

@media screen and (max-width: 1152px) {
    #media .workarea {
        width: 100%;
        padding: 0 20px
    }

    #legals .workarea {
        width: 100%;
        max-width: 800px;
        padding: 0 20px
    }
}

@media screen and (max-width: 980px) {
    #contact .form .workarea {
        width: 100%;
        padding: 0 20px
    }
}

@media screen and (max-width: 880px) {
    .radio-questions .input {
        min-height: initial !important;
        margin-bottom: 20px
    }
}

@media screen and (max-width: 880px) {
    div.radio-wrapper label {
        display: inline-block;
        max-width: 80%
    }
}

@media screen and (max-width: 768px) {
    #media #media-kit .carousel-container .controls.controls-hide {
        display: block
    }
}

@media screen and (max-width: 480px) {

    #contact,
    #media,
    #faq {
        padding-top: 0
    }

    #contact header,
    #media header,
    #faq header {
        height: auto;
        background-attachment: scroll
    }

    #contact .workarea,
    #media .workarea,
    #faq .workarea {
        padding-left: 20px;
        padding-right: 20px
    }

    #media .carousel-container {
        overflow-x: hidden
    }

    #media .media-nav {
        display: none
    }

    #media #press-releases .press-release-list {
        width: 100%;
        float: none;
        padding-left: 0 !important;
        padding-right: 0 !important
    }

    #media #contact-social ul li {
        float: none;
        width: 100%;
        padding-bottom: 20px
    }

    #legals .media-nav ul li,
    #media .media-nav ul li {
        vertical-align: middle
    }

    #legals .media-nav ul li a,
    #media .media-nav ul li a {
        font-size: 0.95em
    }
}

.items.cf.last {
    margin-top: 60px
}

.wp-block-group:not(.alignfull) {
    padding: 0 1rem;
    max-width: 540px;
    margin: 0 auto
}

@media (min-width: 782px) {
    .wp-block-group:not(.alignfull) {
        max-width: 1200px
    }
}

@media (min-width: 1080px) {
    .wp-block-group:not(.alignfull) {
        width: calc(100% - 48px)
    }
}

.wp-block-columns {
    flex-wrap: wrap !important;
    margin: 0 -1rem
}

.wp-block-columns .wp-block-column {
    padding: 0 1rem;
    margin-left: 0;
    margin-right: 0
}

@media (min-width: 600px) and (max-width: 781px) {
    .wp-block-columns:not(.is-not-stacked-on-mobile)>.wp-block-column:not(:only-child) {
        flex-basis: 100% !important
    }
}

.wp-block-cover .wp-block-embed {
    min-height: auto
}

.wp-block-cover,
.wp-block-cover-image {
    height: auto;
    min-height: auto;
    padding: 0
}

.wp-block-cover.is-light .wp-block-cover__inner-container,
.wp-block-cover-image.is-light .wp-block-cover__inner-container {
    color: var(--wp--preset--color--gray-600) !important
}

.wp-block-cover .wp-block-cover__inner-container,
.wp-block-cover-image .wp-block-cover__inner-container {
    width: 100% !important
}

.wp-block-buttons .wp-block-button {
    margin: 0
}

.alignleft,
.alignright {
    margin-left: 0 !important;
    margin-right: 0 !important
}

.wp-block-image,
figure {
    margin-top: 0 !important;
    margin-bottom: 0 !important
}

.wp-block-image img {
    height: auto
}

.wp-block-image.aligncenter {
    margin-left: auto;
    margin-right: auto
}

.overflow-hidden {
    overflow: hidden
}

.block.lottie-container svg {
    display: block
}

.has-document-link a,
a.document-link {
    color: #134bc3;
    font-weight: 500;
    text-decoration: underline
}

.has-document-link a:hover,
a.document-link:hover {
    color: #5384ee
}

.shadow {
    box-shadow: 0px 0px 90px -35px rgba(0, 0, 0, 0.2)
}

.z-1 {
    z-index: 1
}

.inline-block {
    display: inline-block
}

.position-relative {
    position: relative
}

.min-h-svh {
    min-height: 100svh
}

.h-100 {
    height: 100%
}

.mw-100 {
    max-width: 100%
}

.pointer-events-none {
    pointer-events: none
}

.square-image {
    display: block;
    position: relative;
    padding-bottom: 100%
}

.square-image img {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    height: 100%;
    -o-object-fit: cover;
    object-fit: cover;
    -o-object-position: center;
    object-position: center
}

.wp-block-table:not(.is-style-stripes) table td,
.wp-block-table:not(.is-style-stripes) table th {
    padding: .25rem;
    border: 1px solid #e5e6e8
}

@media (min-width: 782px) {

    .wp-block-table:not(.is-style-stripes) table td,
    .wp-block-table:not(.is-style-stripes) table th {
        padding: .5rem
    }
}

.hover\:underline:hover {
    text-decoration: underline
}

@media (max-width: 781px) {
    .max-md\:min-h-0 {
        min-height: 0 !important
    }

    .max-md\:no-cover-image>.wp-block-cover__image-background {
        display: none !important
    }
}

@media (min-width: 782px) {
    .md\:shadow {
        box-shadow: 0px 0px 90px -35px rgba(0, 0, 0, 0.2)
    }

    .md\:block {
        display: block
    }
}

ul[class*='is-style-'] {
    list-style: none;
    padding: 0
}

ul[class*='is-style-'] li {
    position: relative;
    margin-top: 1rem;
    -webkit-padding-start: 26px;
    padding-inline-start: 26px
}

ul[class*='is-style-'] li::before {
    left: 0
}

ul[class*='is-style-'] li::before {
    content: '';
    width: 10px;
    height: 10px;
    position: absolute;
    top: 8px
}

@media (min-width: 782px) {
    ul[class*='is-style-'] li::before {
        top: 9px
    }
}

ul[class*='is-style-square-'] li::before {
    border-radius: 2.5px
}

ul.is-style-square-primary-500 li::before {
    background-color: #ff5a5f
}

ul.is-style-square-gray-600 li::before {
    background-color: #e5e6e8
}

ul.is-style-arrow li::before {
    background: url("file:///wp-content/themes/gett/build/images/list-arrow.svg") no-repeat
}

ul.is-style-star li::before {
    background: url("file:///wp-content/themes/gett/build/images/list-star.svg") no-repeat
}

.loading {
    color: transparent !important;
    font-size: 0 !important;
    padding-top: 12px;
    padding-bottom: 12px;
    cursor: auto;
    pointer-events: none
}

.loading::after {
    content: '';
    display: block;
    width: 24px;
    height: 24px;
    margin: 0 auto;
    background-image: url('data:image/svg+xml,%3Csvg width="20" height="20" xmlns="http://www.w3.org/2000/svg"%3E%3Cpath d="M18.7866233 12.8979017c-.2161335.6556505-.9228542 1.0119494-1.5785047.7958158-.6556505-.2161335-1.0119494-.9228541-.7958159-1.5785047C16.6351009 11.4393449 16.75 10.7282549 16.75 10c0-3.72792206-3.0220779-6.75-6.75-6.75-3.72792206 0-6.75 3.02207794-6.75 6.75 0 3.7279221 3.02207794 6.75 6.75 6.75.6903559 0 1.25.5596441 1.25 1.25s-.5596441 1.25-1.25 1.25C4.89136606 19.25.75 15.1086339.75 10 .75 4.89136606 4.89136606.75 10 .75c5.1086339 0 9.25 4.14136606 9.25 9.25 0 .9950089-.1575834 1.9702644-.4633767 2.8979017z" fill="%23FFF" fill-rule="evenodd"/%3E%3C/svg%3E');
    background-size: 100%;
    background-repeat: no-repeat;
    -webkit-animation: spin 2s linear infinite;
    animation: spin 2s linear infinite
}

.loading.loading-large::after {
    width: 64px;
    height: 64px
}

.loading.loading-position-start::after {
    margin: 0
}

.loading * {
    display: none !important
}

@-webkit-keyframes spin {
    100% {
        -webkit-transform: rotate(360deg)
    }
}

@keyframes spin {
    100% {
        transform: rotate(360deg)
    }
}

.loading.loading-orange::after,
.btn.loading.btn-secondary:not(.btn-color-white)::after,
.btn.loading.btn-primary.btn-color-white::after,
.wp-block-button.is-style-secondary>.wp-block-button__link:not(.has-white-background-color)::after,
.wp-block-button:not(.is-style-secondary)>.wp-block-button__link.has-white-background-color::after {
    -webkit-filter: invert(41%) sepia(49%) saturate(943%) hue-rotate(358deg) brightness(99%) contrast(108%);
    filter: invert(41%) sepia(49%) saturate(943%) hue-rotate(358deg) brightness(99%) contrast(108%)
}

.has-primary-orange-to-pink-gradient-background {
    background: #ff5a5f;
    background: linear-gradient(90deg, #ff5a5f 40%, #ff0094 150%)
}

h1,
h2,
h3,
h4,
h5,
h6 {
    overflow-wrap: initial;
    margin: 0
}

.has-small-font-size {
    font-weight: 400;
    line-height: 1.57;
    letter-spacing: 0.04px
}

.has-regular-16-font-size {
    font-weight: 400;
    line-height: 1.5;
    letter-spacing: 0.06px
}

.has-regular-font-size,
p,
ul[class*='is-style-'] {
    font-size: var(--wp--preset--font-size--regular);
    font-weight: 400;
    line-height: 1.5;
    letter-spacing: 0.06px
}

.has-regular-font-size,
p {
    margin-top: 1rem !important
}

.has-medium-font-size,
.has-medium-2-font-size {
    font-weight: 400;
    line-height: 1.3;
    letter-spacing: 0.07px
}

.has-medium-3-font-size {
    font-weight: 400;
    line-height: 1.3;
    letter-spacing: 0.08px
}

.has-large-font-size,
h1,
h2,
h3,
h4,
h5,
h6 {
    font-size: var(--wp--preset--font-size--large);
    font-weight: 400;
    line-height: 1.3;
    letter-spacing: 0.09px
}

.has-large-2-font-size,
.has-large-3-font-size {
    font-weight: 700;
    line-height: 1.125
}

.has-xlarge-font-size,
.has-xxlarge-font-size {
    font-weight: 700;
    line-height: 1.08;
    letter-spacing: 0.16px
}

.has-huge-font-size {
    font-weight: 700;
    line-height: 1.08;
    letter-spacing: 0.16px
}

.has-huge-2-font-size {
    font-weight: 700;
    line-height: 1.04;
    letter-spacing: 0.2px
}

.font-medium {
    font-weight: 500
}

.font-semibold {
    font-weight: 600
}

.font-bold {
    font-weight: 700
}

.underline {
    text-decoration: underline
}

@media (max-width: 781px) {
    .max-md\:has-text-align-left {
        text-align: left !important
    }

    .max-md\:has-text-align-center {
        text-align: center
    }

    .max-md\:has-text-align-right {
        text-align: right
    }

    .max-md\:has-text-align-start {
        text-align: start
    }
}

@media (min-width: 1080px) {
    .has-medium-2-font-size {
        letter-spacing: 0.08px
    }

    .has-medium-3-font-size {
        line-height: 1.25;
        letter-spacing: 0.09px
    }

    .has-large-font-size,
    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
        line-height: 1.125;
        letter-spacing: 0.12px
    }

    .has-large-2-font-size {
        line-height: 1.05;
        letter-spacing: 0.16px
    }

    .has-large-3-font-size {
        line-height: 1.083;
        letter-spacing: 0.16px
    }

    .has-xlarge-font-size {
        line-height: 1.035;
        letter-spacing: 0.19px
    }

    .has-xxlarge-font-size {
        line-height: 1.03;
        letter-spacing: 0.2px
    }

    .has-huge-font-size,
    .has-huge-2-font-size {
        line-height: 1.02;
        letter-spacing: 0.29px
    }
}

.form-design fieldset {
    margin: 0
}

.form-design legend {
    padding: 0
}

.form-design .input-wrapper {
    position: relative;
    margin-top: 1.5rem
}

.form-design .input-wrapper.submit {
    margin-top: 2.5rem
}

.form-design .input-wrapper input[type='text'],
.form-design .input-wrapper input[type='email'],
.form-design .input-wrapper input[type='tel'],
.form-design .input-wrapper input[type='number'],
.form-design .input-wrapper input[type='date'],
.form-design .input-wrapper textarea,
.form-design .input-wrapper select {
    width: 100%;
    height: 48px;
    padding: 0 1rem;
    font-size: 1rem;
    font-weight: 400;
    color: #e5e6e8;
    background-color: #fff;
    border: 1px solid #8d969a;
    border-radius: .75rem
}

.form-design .input-wrapper input[type='text']:-webkit-autofill,
.form-design .input-wrapper input[type='email']:-webkit-autofill,
.form-design .input-wrapper input[type='tel']:-webkit-autofill,
.form-design .input-wrapper input[type='number']:-webkit-autofill,
.form-design .input-wrapper input[type='date']:-webkit-autofill,
.form-design .input-wrapper textarea:-webkit-autofill,
.form-design .input-wrapper select:-webkit-autofill {
    -webkit-box-shadow: 0 0 0 30px #fff inset !important
}

.form-design .input-wrapper.file-input.filled .file-attached {
    display: flex !important
}

.form-design .input-wrapper textarea {
    height: auto;
    resize: none;
    padding-top: 20px
}

.form-design .input-wrapper textarea+label {
    top: 15px;
    transform: initial;
    background: #fff
}

.form-design .input-wrapper.slider .slider-input-container {
    position: relative;
    margin-top: 40px
}

.form-design .input-wrapper.slider .slider-input-container input[type='range'] {
    -webkit-appearance: none;
    display: block;
    width: 100%;
    height: 1px;
    margin: 0;
    background: #d9d9d9;
    outline: none
}

.form-design .input-wrapper.slider .slider-input-container input[type='range']::-webkit-slider-thumb {
    -webkit-appearance: none;
    appearance: none;
    width: 14px;
    height: 14px;
    border-radius: 4px;
    background: #ff5a5f;
    cursor: pointer
}

.form-design .input-wrapper.slider .slider-input-container input[type='range']::-moz-range-thumb {
    width: 14px;
    height: 14px;
    border-radius: 4px;
    background: #ff5a5f;
    cursor: pointer
}

.form-design .input-wrapper.slider .slider-input-container .slider-progress-bar {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 0;
    height: 2px;
    background-color: #ff5a5f
}

.form-design .input-wrapper.slider .slider-input-container output {
    position: absolute;
    bottom: 15px;
    left: 0;
    font-size: 0.75em;
    font-weight: 600;
    transform: translateX(-50%)
}

.form-design .input-wrapper.slider .slider-range-labels {
    position: relative;
    display: flex;
    justify-content: space-between;
    margin-top: 9px;
    font-size: 0.75em
}

.form-design .input-wrapper.slider .slider-range-labels .slider-median {
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%)
}

.form-design .input-wrapper.tel .iti {
    display: block
}

.form-design .input-wrapper.tel .iti.iti--allow-dropdown .iti__selected-flag {
    min-width: 90px
}

    .lg\:pe-104,
    .lg\:px-104 {
        -webkit-padding-end: 6.5rem !important;
        padding-inline-end: 6.5rem !important
    }

    .lg\:li-mt-144 li {
        margin-top: 9rem !important
    }

    .lg\:mt-144,
    .lg\:my-144 {
        margin-top: 9rem !important
    }

    .lg\:mb-144,
    .lg\:my-144 {
        margin-bottom: 9rem !important
    }

    .lg\:pt-144,
    .lg\:py-144 {
        padding-top: 9rem !important
    }

    .lg\:pb-144,
    .lg\:py-144 {
        padding-bottom: 9rem !important
    }

    .lg\:ms-144,
    .lg\:mx-144 {
        -webkit-margin-start: 9rem !important;
        margin-inline-start: 9rem !important
    }

    .lg\:me-144,
    .lg\:mx-144 {
        -webkit-margin-end: 9rem !important;
        margin-inline-end: 9rem !important
    }

    .lg\:ps-144,
    .lg\:px-144 {
        -webkit-padding-start: 9rem !important;
        padding-inline-start: 9rem !important
    }

    .lg\:pe-144,
    .lg\:px-144 {
        -webkit-padding-end: 9rem !important;
        padding-inline-end: 9rem !important
    }

    .lg\:li-mt-176 li {
        margin-top: 11rem !important
    }

    .lg\:mt-176,
    .lg\:my-176 {
        margin-top: 11rem !important
    }

    .lg\:mb-176,
    .lg\:my-176 {
        margin-bottom: 11rem !important
    }

    .lg\:pt-176,
    .lg\:py-176 {
        padding-top: 11rem !important
    }

    .lg\:pb-176,
    .lg\:py-176 {
        padding-bottom: 11rem !important
    }

    .lg\:ms-176,
    .lg\:mx-176 {
        -webkit-margin-start: 11rem !important;
        margin-inline-start: 11rem !important
    }

    .lg\:me-176,
    .lg\:mx-176 {
        -webkit-margin-end: 11rem !important;
        margin-inline-end: 11rem !important
    }

    .lg\:ps-176,
    .lg\:px-176 {
        -webkit-padding-start: 11rem !important;
        padding-inline-start: 11rem !important
    }

    .lg\:pe-176,
    .lg\:px-176 {
        -webkit-padding-end: 11rem !important;
        padding-inline-end: 11rem !important
    }

    .lg\:li-mt-200 li {
        margin-top: 12.5rem !important
    }

    .lg\:mt-200,
    .lg\:my-200 {
        margin-top: 12.5rem !important
    }

    .lg\:mb-200,
    .lg\:my-200 {
        margin-bottom: 12.5rem !important
    }

    .lg\:pt-200,
    .lg\:py-200 {
        padding-top: 12.5rem !important
    }

    .lg\:pb-200,
    .lg\:py-200 {
        padding-bottom: 12.5rem !important
    }

    .lg\:ms-200,
    .lg\:mx-200 {
        -webkit-margin-start: 12.5rem !important;
        margin-inline-start: 12.5rem !important
    }

    .lg\:me-200,
    .lg\:mx-200 {
        -webkit-margin-end: 12.5rem !important;
        margin-inline-end: 12.5rem !important
    }

    .lg\:ps-200,
    .lg\:px-200 {
        -webkit-padding-start: 12.5rem !important;
        padding-inline-start: 12.5rem !important
    }

    .lg\:pe-200,
    .lg\:px-200 {
        -webkit-padding-end: 12.5rem !important;
        padding-inline-end: 12.5rem !important
    }

    .lg\:li-mt-240 li {
        margin-top: 15rem !important
    }

    .lg\:mt-240,
    .lg\:my-240 {
        margin-top: 15rem !important
    }

    .lg\:mb-240,
    .lg\:my-240 {
        margin-bottom: 15rem !important
    }

    .lg\:pt-240,
    .lg\:py-240 {
        padding-top: 15rem !important
    }

    .lg\:pb-240,
    .lg\:py-240 {
        padding-bottom: 15rem !important
    }

    .lg\:ms-240,
    .lg\:mx-240 {
        -webkit-margin-start: 15rem !important;
        margin-inline-start: 15rem !important
    }

    .lg\:me-240,
    .lg\:mx-240 {
        -webkit-margin-end: 15rem !important;
        margin-inline-end: 15rem !important
    }

    .lg\:ps-240,
    .lg\:px-240 {
        -webkit-padding-start: 15rem !important;
        padding-inline-start: 15rem !important
    }

    .lg\:pe-240,
    .lg\:px-240 {
        -webkit-padding-end: 15rem !important;
        padding-inline-end: 15rem !important
    }
}
`}	</style>
	<style>{`
		@charset "UTF-8";

.wp-block-archives {
  box-sizing: border-box
}

.wp-block-archives-dropdown label {
  display: block
}

.wp-block-avatar {
  line-height: 0
}

.wp-block-avatar,
.wp-block-avatar img {
  box-sizing: border-box
}

.wp-block-avatar.aligncenter {
  text-align: center
}

.wp-block-audio {
  box-sizing: border-box
}

.wp-block-audio :where(figcaption) {
  margin-bottom: 1em;
  margin-top: .5em
}

.wp-block-audio audio {
  min-width: 300px;
  width: 100%
}

.wp-block-button__link {
  box-sizing: border-box;
  cursor: pointer;
  display: inline-block;
  text-align: center;
  word-break: break-word
}

.wp-block-button__link.aligncenter {
  text-align: center
}

.wp-block-button__link.alignright {
  text-align: right
}

:where(.wp-block-button__link) {
  border-radius: 9999px;
  box-shadow: none;
  padding: calc(.667em + 2px) calc(1.333em + 2px);
  text-decoration: none
}

.wp-block-button[style*=text-decoration] .wp-block-button__link {
  text-decoration: inherit
}

.wp-block-buttons>.wp-block-button.has-custom-width {
  max-width: none
}

.wp-block-buttons>.wp-block-button.has-custom-width .wp-block-button__link {
  width: 100%
}

.wp-block-buttons>.wp-block-button.has-custom-font-size .wp-block-button__link {
  font-size: inherit
}

.wp-block-buttons>.wp-block-button.wp-block-button__width-25 {
  width: calc(25% - var(--wp--style--block-gap, .5em)*.75)
}

.wp-block-buttons>.wp-block-button.wp-block-button__width-50 {
  width: calc(50% - var(--wp--style--block-gap, .5em)*.5)
}

.wp-block-buttons>.wp-block-button.wp-block-button__width-75 {
  width: calc(75% - var(--wp--style--block-gap, .5em)*.25)
}

.wp-block-buttons>.wp-block-button.wp-block-button__width-100 {
  flex-basis: 100%;
  width: 100%
}

.wp-block-buttons.is-vertical>.wp-block-button.wp-block-button__width-25 {
  width: 25%
}

.wp-block-buttons.is-vertical>.wp-block-button.wp-block-button__width-50 {
  width: 50%
}

.wp-block-buttons.is-vertical>.wp-block-button.wp-block-button__width-75 {
  width: 75%
}

.wp-block-button.is-style-squared,
.wp-block-button__link.wp-block-button.is-style-squared {
  border-radius: 0
}

.wp-block-button.no-border-radius,
.wp-block-button__link.no-border-radius {
  border-radius: 0 !important
}

:root :where(.wp-block-button .wp-block-button__link.is-style-outline),
:root :where(.wp-block-button.is-style-outline>.wp-block-button__link) {
  border: 2px solid;
  padding: .667em 1.333em
}

:root :where(.wp-block-button .wp-block-button__link.is-style-outline:not(.has-text-color)),
:root :where(.wp-block-button.is-style-outline>.wp-block-button__link:not(.has-text-color)) {
  color: currentColor
}

:root :where(.wp-block-button .wp-block-button__link.is-style-outline:not(.has-background)),
:root :where(.wp-block-button.is-style-outline>.wp-block-button__link:not(.has-background)) {
  background-color: initial;
  background-image: none
}

.wp-block-buttons.is-vertical {
  flex-direction: column
}

.wp-block-buttons.is-vertical>.wp-block-button:last-child {
  margin-bottom: 0
}

.wp-block-buttons>.wp-block-button {
  display: inline-block;
  margin: 0
}

.wp-block-buttons.is-content-justification-left {
  justify-content: flex-start
}

.wp-block-buttons.is-content-justification-left.is-vertical {
  align-items: flex-start
}

.wp-block-buttons.is-content-justification-center {
  justify-content: center
}

.wp-block-buttons.is-content-justification-center.is-vertical {
  align-items: center
}

.wp-block-buttons.is-content-justification-right {
  justify-content: flex-end
}

.wp-block-buttons.is-content-justification-right.is-vertical {
  align-items: flex-end
}

.wp-block-buttons.is-content-justification-space-between {
  justify-content: space-between
}

.wp-block-buttons.aligncenter {
  text-align: center
}

.wp-block-buttons:not(.is-content-justification-space-between, .is-content-justification-right, .is-content-justification-left, .is-content-justification-center) .wp-block-button.aligncenter {
  margin-left: auto;
  margin-right: auto;
  width: 100%
}

.wp-block-buttons[style*=text-decoration] .wp-block-button,
.wp-block-buttons[style*=text-decoration] .wp-block-button__link {
  text-decoration: inherit
}

.wp-block-buttons.has-custom-font-size .wp-block-button__link {
  font-size: inherit
}

.wp-block-button.aligncenter,
.wp-block-calendar {
  text-align: center
}

.wp-block-calendar td,
.wp-block-calendar th {
  border: 1px solid;
  padding: .25em
}

.wp-block-calendar th {
  font-weight: 400
}

.wp-block-calendar caption {
  background-color: inherit
}

.wp-block-calendar table {
  border-collapse: collapse;
  width: 100%
}

.wp-block-columns.are-vertically-aligned-top {
  align-items: flex-start
}

.wp-block-columns.are-vertically-aligned-center {
  align-items: center
}

.wp-block-columns.are-vertically-aligned-bottom {
  align-items: flex-end
}

@media (max-width:781px) {
  .wp-block-columns:not(.is-not-stacked-on-mobile)>.wp-block-column {
    flex-basis: 100% !important
  }
}

@media (min-width:782px) {
  .wp-block-columns:not(.is-not-stacked-on-mobile)>.wp-block-column {
    flex-basis: 0;
    flex-grow: 1
  }

  .wp-block-columns:not(.is-not-stacked-on-mobile)>.wp-block-column[style*=flex-basis] {
    flex-grow: 0
  }
}

.wp-block-columns.is-not-stacked-on-mobile {
  flex-wrap: nowrap !important
}

.wp-block-columns.is-not-stacked-on-mobile>.wp-block-column {
  flex-basis: 0;
  flex-grow: 1
}

.wp-block-columns.is-not-stacked-on-mobile>.wp-block-column[style*=flex-basis] {
  flex-grow: 0
}

:where(.wp-block-columns) {
  margin-bottom: 1.75em
}

:where(.wp-block-columns.has-background) {
  padding: 1.25em 2.375em
}

.wp-block-column {
  flex-grow: 1;
  min-width: 0;
  overflow-wrap: break-word;
  word-break: break-word
}

.wp-block-column.is-vertically-aligned-top {
  align-self: flex-start
}

.wp-block-column.is-vertically-aligned-center {
  align-self: center
}

.wp-block-column.is-vertically-aligned-bottom {
  align-self: flex-end
}

.wp-block-column.is-vertically-aligned-stretch {
  align-self: stretch
}

.wp-block-column.is-vertically-aligned-bottom,
.wp-block-column.is-vertically-aligned-center,
.wp-block-column.is-vertically-aligned-top {
  width: 100%
}

.wp-block-post-comments {
  box-sizing: border-box
}

.wp-block-post-comments .alignleft {
  float: left
}

.wp-block-post-comments .alignright {
  float: right
}

.wp-block-post-comments .navigation:after {
  clear: both;
  content: "";
  display: table
}

.wp-block-post-comments .commentlist {
  clear: both;
  list-style: none;
  margin: 0;
  padding: 0
}

.wp-block-post-comments .commentlist .comment {
  min-height: 2.25em;
  padding-left: 3.25em
}

.wp-block-post-comments .commentlist .comment p {
  font-size: 1em;
  line-height: 1.8;
  margin: 1em 0
}

.wp-block-post-comments .commentlist .children {
  list-style: none;
  margin: 0;
  padding: 0
}

.wp-block-post-comments .comment-author {
  line-height: 1.5
}

.wp-block-post-comments .comment-author .avatar {
  border-radius: 1.5em;
  display: block;
  float: left;
  height: 2.5em;
  margin-right: .75em;
  margin-top: .5em;
  width: 2.5em
}

.wp-block-post-comments .comment-author cite {
  font-style: normal
}

.wp-block-post-comments .comment-meta {
  font-size: .875em;
  line-height: 1.5
}

.wp-block-post-comments .comment-meta b {
  font-weight: 400
}

.wp-block-post-comments .comment-meta .comment-awaiting-moderation {
  display: block;
  margin-bottom: 1em;
  margin-top: 1em
}

.wp-block-post-comments .comment-body .commentmetadata {
  font-size: .875em
}

.wp-block-post-comments .comment-form-author label,
.wp-block-post-comments .comment-form-comment label,
.wp-block-post-comments .comment-form-email label,
.wp-block-post-comments .comment-form-url label {
  display: block;
  margin-bottom: .25em
}

.wp-block-post-comments .comment-form input:not([type=submit]):not([type=checkbox]),
.wp-block-post-comments .comment-form textarea {
  box-sizing: border-box;
  display: block;
  width: 100%
}

.wp-block-post-comments .comment-form-cookies-consent {
  display: flex;
  gap: .25em
}

.wp-block-post-comments .comment-form-cookies-consent #wp-comment-cookies-consent {
  margin-top: .35em
}

.wp-block-post-comments .comment-reply-title {
  margin-bottom: 0
}

.wp-block-post-comments .comment-reply-title :where(small) {
  font-size: var(--wp--preset--font-size--medium, smaller);
  margin-left: .5em
}

.wp-block-post-comments .reply {
  font-size: .875em;
  margin-bottom: 1.4em
}

.wp-block-post-comments input:not([type=submit]),
.wp-block-post-comments textarea {
  border: 1px solid #949494;
  font-family: inherit;
  font-size: 1em
}

.wp-block-post-comments input:not([type=submit]):not([type=checkbox]),
.wp-block-post-comments textarea {
  padding: calc(.667em + 2px)
}

:where(.wp-block-post-comments input[type=submit]) {
  border: none
}

.wp-block-comments-pagination>.wp-block-comments-pagination-next,
.wp-block-comments-pagination>.wp-block-comments-pagination-numbers,
.wp-block-comments-pagination>.wp-block-comments-pagination-previous {
  margin-bottom: .5em;
  margin-right: .5em
}

.wp-block-comments-pagination>.wp-block-comments-pagination-next:last-child,
.wp-block-comments-pagination>.wp-block-comments-pagination-numbers:last-child,
.wp-block-comments-pagination>.wp-block-comments-pagination-previous:last-child {
  margin-right: 0
}

.wp-block-comments-pagination .wp-block-comments-pagination-previous-arrow {
  display: inline-block;
  margin-right: 1ch
}

.wp-block-comments-pagination .wp-block-comments-pagination-previous-arrow:not(.is-arrow-chevron) {
  transform: scaleX(1)
}

.wp-block-comments-pagination .wp-block-comments-pagination-next-arrow {
  display: inline-block;
  margin-left: 1ch
}

.wp-block-comments-pagination .wp-block-comments-pagination-next-arrow:not(.is-arrow-chevron) {
  transform: scaleX(1)
}

.wp-block-comments-pagination.aligncenter {
  justify-content: center
}

.wp-block-comment-template {
  box-sizing: border-box;
  list-style: none;
  margin-bottom: 0;
  max-width: 100%;
  padding: 0
}

.wp-block-comment-template li {
  clear: both
}

.wp-block-comment-template ol {
  list-style: none;
  margin-bottom: 0;
  max-width: 100%;
  padding-left: 2rem
}

.wp-block-comment-template.alignleft {
  float: left
}

.wp-block-comment-template.aligncenter {
  margin-left: auto;
  margin-right: auto;
  width: -moz-fit-content;
  width: fit-content
}

.wp-block-comment-template.alignright {
  float: right
}

.wp-block-comment-date {
  box-sizing: border-box
}

.comment-awaiting-moderation {
  display: block;
  font-size: .875em;
  line-height: 1.5
}

.wp-block-comment-author-name,
.wp-block-comment-content,
.wp-block-comment-edit-link,
.wp-block-comment-reply-link {
  box-sizing: border-box
}

.wp-block-cover,
.wp-block-cover-image {
  align-items: center;
  background-position: 50%;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  min-height: 430px;
  overflow: hidden;
  overflow: clip;
  padding: 1em;
  position: relative
}

.wp-block-cover .has-background-dim:not([class*=-background-color]),
.wp-block-cover-image .has-background-dim:not([class*=-background-color]),
.wp-block-cover-image.has-background-dim:not([class*=-background-color]),
.wp-block-cover.has-background-dim:not([class*=-background-color]) {
  background-color: #000
}

.wp-block-cover .has-background-dim.has-background-gradient,
.wp-block-cover-image .has-background-dim.has-background-gradient {
  background-color: initial
}

.wp-block-cover-image.has-background-dim:before,
.wp-block-cover.has-background-dim:before {
  background-color: inherit;
  content: ""
}

.wp-block-cover .wp-block-cover__background,
.wp-block-cover .wp-block-cover__gradient-background,
.wp-block-cover-image .wp-block-cover__background,
.wp-block-cover-image .wp-block-cover__gradient-background,
.wp-block-cover-image.has-background-dim:not(.has-background-gradient):before,
.wp-block-cover.has-background-dim:not(.has-background-gradient):before {
  bottom: 0;
  left: 0;
  opacity: .5;
  position: absolute;
  right: 0;
  top: 0;
  z-index: 1
}

.wp-block-cover-image.has-background-dim.has-background-dim-10 .wp-block-cover__background,
.wp-block-cover-image.has-background-dim.has-background-dim-10 .wp-block-cover__gradient-background,
.wp-block-cover-image.has-background-dim.has-background-dim-10:not(.has-background-gradient):before,
.wp-block-cover.has-background-dim.has-background-dim-10 .wp-block-cover__background,
.wp-block-cover.has-background-dim.has-background-dim-10 .wp-block-cover__gradient-background,
.wp-block-cover.has-background-dim.has-background-dim-10:not(.has-background-gradient):before {
  opacity: .1
}

.wp-block-cover-image.has-background-dim.has-background-dim-20 .wp-block-cover__background,
.wp-block-cover-image.has-background-dim.has-background-dim-20 .wp-block-cover__gradient-background,
.wp-block-cover-image.has-background-dim.has-background-dim-20:not(.has-background-gradient):before,
.wp-block-cover.has-background-dim.has-background-dim-20 .wp-block-cover__background,
.wp-block-cover.has-background-dim.has-background-dim-20 .wp-block-cover__gradient-background,
.wp-block-cover.has-background-dim.has-background-dim-20:not(.has-background-gradient):before {
  opacity: .2
}

.wp-block-cover-image.has-background-dim.has-background-dim-30 .wp-block-cover__background,
.wp-block-cover-image.has-background-dim.has-background-dim-30 .wp-block-cover__gradient-background,
.wp-block-cover-image.has-background-dim.has-background-dim-30:not(.has-background-gradient):before,
.wp-block-cover.has-background-dim.has-background-dim-30 .wp-block-cover__background,
.wp-block-cover.has-background-dim.has-background-dim-30 .wp-block-cover__gradient-background,
.wp-block-cover.has-background-dim.has-background-dim-30:not(.has-background-gradient):before {
  opacity: .3
}

.wp-block-cover-image.has-background-dim.has-background-dim-40 .wp-block-cover__background,
.wp-block-cover-image.has-background-dim.has-background-dim-40 .wp-block-cover__gradient-background,
.wp-block-cover-image.has-background-dim.has-background-dim-40:not(.has-background-gradient):before,
.wp-block-cover.has-background-dim.has-background-dim-40 .wp-block-cover__background,
.wp-block-cover.has-background-dim.has-background-dim-40 .wp-block-cover__gradient-background,
.wp-block-cover.has-background-dim.has-background-dim-40:not(.has-background-gradient):before {
  opacity: .4
}

.wp-block-cover-image.has-background-dim.has-background-dim-50 .wp-block-cover__background,
.wp-block-cover-image.has-background-dim.has-background-dim-50 .wp-block-cover__gradient-background,
.wp-block-cover-image.has-background-dim.has-background-dim-50:not(.has-background-gradient):before,
.wp-block-cover.has-background-dim.has-background-dim-50 .wp-block-cover__background,
.wp-block-cover.has-background-dim.has-background-dim-50 .wp-block-cover__gradient-background,
.wp-block-cover.has-background-dim.has-background-dim-50:not(.has-background-gradient):before {
  opacity: .5
}

.wp-block-cover-image.has-background-dim.has-background-dim-60 .wp-block-cover__background,
.wp-block-cover-image.has-background-dim.has-background-dim-60 .wp-block-cover__gradient-background,
.wp-block-cover-image.has-background-dim.has-background-dim-60:not(.has-background-gradient):before,
.wp-block-cover.has-background-dim.has-background-dim-60 .wp-block-cover__background,
.wp-block-cover.has-background-dim.has-background-dim-60 .wp-block-cover__gradient-background,
.wp-block-cover.has-background-dim.has-background-dim-60:not(.has-background-gradient):before {
  opacity: .6
}

.wp-block-cover-image.has-background-dim.has-background-dim-70 .wp-block-cover__background,
.wp-block-cover-image.has-background-dim.has-background-dim-70 .wp-block-cover__gradient-background,
.wp-block-cover-image.has-background-dim.has-background-dim-70:not(.has-background-gradient):before,
.wp-block-cover.has-background-dim.has-background-dim-70 .wp-block-cover__background,
.wp-block-cover.has-background-dim.has-background-dim-70 .wp-block-cover__gradient-background,
.wp-block-cover.has-background-dim.has-background-dim-70:not(.has-background-gradient):before {
  opacity: .7
}

.wp-block-cover-image.has-background-dim.has-background-dim-80 .wp-block-cover__background,
.wp-block-cover-image.has-background-dim.has-background-dim-80 .wp-block-cover__gradient-background,
.wp-block-cover-image.has-background-dim.has-background-dim-80:not(.has-background-gradient):before,
.wp-block-cover.has-background-dim.has-background-dim-80 .wp-block-cover__background,
.wp-block-cover.has-background-dim.has-background-dim-80 .wp-block-cover__gradient-background,
.wp-block-cover.has-background-dim.has-background-dim-80:not(.has-background-gradient):before {
  opacity: .8
}

.wp-block-cover-image.has-background-dim.has-background-dim-90 .wp-block-cover__background,
.wp-block-cover-image.has-background-dim.has-background-dim-90 .wp-block-cover__gradient-background,
.wp-block-cover-image.has-background-dim.has-background-dim-90:not(.has-background-gradient):before,
.wp-block-cover.has-background-dim.has-background-dim-90 .wp-block-cover__background,
.wp-block-cover.has-background-dim.has-background-dim-90 .wp-block-cover__gradient-background,
.wp-block-cover.has-background-dim.has-background-dim-90:not(.has-background-gradient):before {
  opacity: .9
}

.wp-block-cover-image.has-background-dim.has-background-dim-100 .wp-block-cover__background,
.wp-block-cover-image.has-background-dim.has-background-dim-100 .wp-block-cover__gradient-background,
.wp-block-cover-image.has-background-dim.has-background-dim-100:not(.has-background-gradient):before,
.wp-block-cover.has-background-dim.has-background-dim-100 .wp-block-cover__background,
.wp-block-cover.has-background-dim.has-background-dim-100 .wp-block-cover__gradient-background,
.wp-block-cover.has-background-dim.has-background-dim-100:not(.has-background-gradient):before {
  opacity: 1
}

.wp-block-cover .wp-block-cover__background.has-background-dim.has-background-dim-0,
.wp-block-cover .wp-block-cover__gradient-background.has-background-dim.has-background-dim-0,
.wp-block-cover-image .wp-block-cover__background.has-background-dim.has-background-dim-0,
.wp-block-cover-image .wp-block-cover__gradient-background.has-background-dim.has-background-dim-0 {
  opacity: 0
}

.wp-block-cover .wp-block-cover__background.has-background-dim.has-background-dim-10,
.wp-block-cover .wp-block-cover__gradient-background.has-background-dim.has-background-dim-10,
.wp-block-cover-image .wp-block-cover__background.has-background-dim.has-background-dim-10,
.wp-block-cover-image .wp-block-cover__gradient-background.has-background-dim.has-background-dim-10 {
  opacity: .1
}

.wp-block-cover .wp-block-cover__background.has-background-dim.has-background-dim-20,
.wp-block-cover .wp-block-cover__gradient-background.has-background-dim.has-background-dim-20,
.wp-block-cover-image .wp-block-cover__background.has-background-dim.has-background-dim-20,
.wp-block-cover-image .wp-block-cover__gradient-background.has-background-dim.has-background-dim-20 {
  opacity: .2
}

.wp-block-cover .wp-block-cover__background.has-background-dim.has-background-dim-30,
.wp-block-cover .wp-block-cover__gradient-background.has-background-dim.has-background-dim-30,
.wp-block-cover-image .wp-block-cover__background.has-background-dim.has-background-dim-30,
.wp-block-cover-image .wp-block-cover__gradient-background.has-background-dim.has-background-dim-30 {
  opacity: .3
}

.wp-block-cover .wp-block-cover__background.has-background-dim.has-background-dim-40,
.wp-block-cover .wp-block-cover__gradient-background.has-background-dim.has-background-dim-40,
.wp-block-cover-image .wp-block-cover__background.has-background-dim.has-background-dim-40,
.wp-block-cover-image .wp-block-cover__gradient-background.has-background-dim.has-background-dim-40 {
  opacity: .4
}

.wp-block-cover .wp-block-cover__background.has-background-dim.has-background-dim-50,
.wp-block-cover .wp-block-cover__gradient-background.has-background-dim.has-background-dim-50,
.wp-block-cover-image .wp-block-cover__background.has-background-dim.has-background-dim-50,
.wp-block-cover-image .wp-block-cover__gradient-background.has-background-dim.has-background-dim-50 {
  opacity: .5
}

.wp-block-cover .wp-block-cover__background.has-background-dim.has-background-dim-60,
.wp-block-cover .wp-block-cover__gradient-background.has-background-dim.has-background-dim-60,
.wp-block-cover-image .wp-block-cover__background.has-background-dim.has-background-dim-60,
.wp-block-cover-image .wp-block-cover__gradient-background.has-background-dim.has-background-dim-60 {
  opacity: .6
}

.wp-block-cover .wp-block-cover__background.has-background-dim.has-background-dim-70,
.wp-block-cover .wp-block-cover__gradient-background.has-background-dim.has-background-dim-70,
.wp-block-cover-image .wp-block-cover__background.has-background-dim.has-background-dim-70,
.wp-block-cover-image .wp-block-cover__gradient-background.has-background-dim.has-background-dim-70 {
  opacity: .7
}

.wp-block-cover .wp-block-cover__background.has-background-dim.has-background-dim-80,
.wp-block-cover .wp-block-cover__gradient-background.has-background-dim.has-background-dim-80,
.wp-block-cover-image .wp-block-cover__background.has-background-dim.has-background-dim-80,
.wp-block-cover-image .wp-block-cover__gradient-background.has-background-dim.has-background-dim-80 {
  opacity: .8
}

.wp-block-cover .wp-block-cover__background.has-background-dim.has-background-dim-90,
.wp-block-cover .wp-block-cover__gradient-background.has-background-dim.has-background-dim-90,
.wp-block-cover-image .wp-block-cover__background.has-background-dim.has-background-dim-90,
.wp-block-cover-image .wp-block-cover__gradient-background.has-background-dim.has-background-dim-90 {
  opacity: .9
}

.wp-block-cover .wp-block-cover__background.has-background-dim.has-background-dim-100,
.wp-block-cover .wp-block-cover__gradient-background.has-background-dim.has-background-dim-100,
.wp-block-cover-image .wp-block-cover__background.has-background-dim.has-background-dim-100,
.wp-block-cover-image .wp-block-cover__gradient-background.has-background-dim.has-background-dim-100 {
  opacity: 1
}

.wp-block-cover-image.alignleft,
.wp-block-cover-image.alignright,
.wp-block-cover.alignleft,
.wp-block-cover.alignright {
  max-width: 420px;
  width: 100%
}

.wp-block-cover-image.aligncenter,
.wp-block-cover-image.alignleft,
.wp-block-cover-image.alignright,
.wp-block-cover.aligncenter,
.wp-block-cover.alignleft,
.wp-block-cover.alignright {
  display: flex
}

.wp-block-cover .wp-block-cover__inner-container,
.wp-block-cover-image .wp-block-cover__inner-container {
  color: inherit;
  width: 100%;
  z-index: 1
}

.has-modal-open .wp-block-cover .wp-block-cover__inner-container,
.has-modal-open .wp-block-cover-image .wp-block-cover__inner-container {
  z-index: auto
}

.wp-block-cover-image.is-position-top-left,
.wp-block-cover.is-position-top-left {
  align-items: flex-start;
  justify-content: flex-start
}

.wp-block-cover-image.is-position-top-center,
.wp-block-cover.is-position-top-center {
  align-items: flex-start;
  justify-content: center
}

.wp-block-cover-image.is-position-top-right,
.wp-block-cover.is-position-top-right {
  align-items: flex-start;
  justify-content: flex-end
}

.wp-block-cover-image.is-position-center-left,
.wp-block-cover.is-position-center-left {
  align-items: center;
  justify-content: flex-start
}

.wp-block-cover-image.is-position-center-center,
.wp-block-cover.is-position-center-center {
  align-items: center;
  justify-content: center
}

.wp-block-cover-image.is-position-center-right,
.wp-block-cover.is-position-center-right {
  align-items: center;
  justify-content: flex-end
}

.wp-block-cover-image.is-position-bottom-left,
.wp-block-cover.is-position-bottom-left {
  align-items: flex-end;
  justify-content: flex-start
}

.wp-block-cover-image.is-position-bottom-center,
.wp-block-cover.is-position-bottom-center {
  align-items: flex-end;
  justify-content: center
}

.wp-block-cover-image.is-position-bottom-right,
.wp-block-cover.is-position-bottom-right {
  align-items: flex-end;
  justify-content: flex-end
}

.wp-block-cover-image.has-custom-content-position.has-custom-content-position .wp-block-cover__inner-container,
.wp-block-cover.has-custom-content-position.has-custom-content-position .wp-block-cover__inner-container {
  margin: 0
}

.wp-block-cover-image.has-custom-content-position.has-custom-content-position.is-position-bottom-left .wp-block-cover__inner-container,
.wp-block-cover-image.has-custom-content-position.has-custom-content-position.is-position-bottom-right .wp-block-cover__inner-container,
.wp-block-cover-image.has-custom-content-position.has-custom-content-position.is-position-center-left .wp-block-cover__inner-container,
.wp-block-cover-image.has-custom-content-position.has-custom-content-position.is-position-center-right .wp-block-cover__inner-container,
.wp-block-cover-image.has-custom-content-position.has-custom-content-position.is-position-top-left .wp-block-cover__inner-container,
.wp-block-cover-image.has-custom-content-position.has-custom-content-position.is-position-top-right .wp-block-cover__inner-container,
.wp-block-cover.has-custom-content-position.has-custom-content-position.is-position-bottom-left .wp-block-cover__inner-container,
.wp-block-cover.has-custom-content-position.has-custom-content-position.is-position-bottom-right .wp-block-cover__inner-container,
.wp-block-cover.has-custom-content-position.has-custom-content-position.is-position-center-left .wp-block-cover__inner-container,
.wp-block-cover.has-custom-content-position.has-custom-content-position.is-position-center-right .wp-block-cover__inner-container,
.wp-block-cover.has-custom-content-position.has-custom-content-position.is-position-top-left .wp-block-cover__inner-container,
.wp-block-cover.has-custom-content-position.has-custom-content-position.is-position-top-right .wp-block-cover__inner-container {
  margin: 0;
  width: auto
}

.wp-block-cover .wp-block-cover__image-background,
.wp-block-cover video.wp-block-cover__video-background,
.wp-block-cover-image .wp-block-cover__image-background,
.wp-block-cover-image video.wp-block-cover__video-background {
  border: none;
  bottom: 0;
  box-shadow: none;
  height: 100%;
  left: 0;
  margin: 0;
  max-height: none;
  max-width: none;
  object-fit: cover;
  outline: none;
  padding: 0;
  position: absolute;
  right: 0;
  top: 0;
  width: 100%
}

.wp-block-cover-image.has-parallax,
.wp-block-cover.has-parallax,
.wp-block-cover__image-background.has-parallax,
video.wp-block-cover__video-background.has-parallax {
  background-attachment: fixed;
  background-repeat: no-repeat;
  background-size: cover
}

@supports (-webkit-touch-callout:inherit) {

  .wp-block-cover-image.has-parallax,
  .wp-block-cover.has-parallax,
  .wp-block-cover__image-background.has-parallax,
  video.wp-block-cover__video-background.has-parallax {
    background-attachment: scroll
  }
}

@media (prefers-reduced-motion:reduce) {

  .wp-block-cover-image.has-parallax,
  .wp-block-cover.has-parallax,
  .wp-block-cover__image-background.has-parallax,
  video.wp-block-cover__video-background.has-parallax {
    background-attachment: scroll
  }
}

.wp-block-cover-image.is-repeated,
.wp-block-cover.is-repeated,
.wp-block-cover__image-background.is-repeated,
video.wp-block-cover__video-background.is-repeated {
  background-repeat: repeat;
  background-size: auto
}

.wp-block-cover__image-background,
.wp-block-cover__video-background {
  z-index: 0
}

.wp-block-cover-image-text,
.wp-block-cover-image-text a,
.wp-block-cover-image-text a:active,
.wp-block-cover-image-text a:focus,
.wp-block-cover-image-text a:hover,
.wp-block-cover-text,
.wp-block-cover-text a,
.wp-block-cover-text a:active,
.wp-block-cover-text a:focus,
.wp-block-cover-text a:hover,
section.wp-block-cover-image h2,
section.wp-block-cover-image h2 a,
section.wp-block-cover-image h2 a:active,
section.wp-block-cover-image h2 a:focus,
section.wp-block-cover-image h2 a:hover {
  color: #fff
}

.wp-block-cover-image .wp-block-cover.has-left-content {
  justify-content: flex-start
}

.wp-block-cover-image .wp-block-cover.has-right-content {
  justify-content: flex-end
}

.wp-block-cover-image.has-left-content .wp-block-cover-image-text,
.wp-block-cover.has-left-content .wp-block-cover-text,
section.wp-block-cover-image.has-left-content>h2 {
  margin-left: 0;
  text-align: left
}

.wp-block-cover-image.has-right-content .wp-block-cover-image-text,
.wp-block-cover.has-right-content .wp-block-cover-text,
section.wp-block-cover-image.has-right-content>h2 {
  margin-right: 0;
  text-align: right
}

.wp-block-cover .wp-block-cover-text,
.wp-block-cover-image .wp-block-cover-image-text,
section.wp-block-cover-image>h2 {
  font-size: 2em;
  line-height: 1.25;
  margin-bottom: 0;
  max-width: 840px;
  padding: .44em;
  text-align: center;
  z-index: 1
}

:where(.wp-block-cover-image:not(.has-text-color)),
:where(.wp-block-cover:not(.has-text-color)) {
  color: #fff
}

:where(.wp-block-cover-image.is-light:not(.has-text-color)),
:where(.wp-block-cover.is-light:not(.has-text-color)) {
  color: #000
}

:root :where(.wp-block-cover h1:not(.has-text-color)),
:root :where(.wp-block-cover h2:not(.has-text-color)),
:root :where(.wp-block-cover h3:not(.has-text-color)),
:root :where(.wp-block-cover h4:not(.has-text-color)),
:root :where(.wp-block-cover h5:not(.has-text-color)),
:root :where(.wp-block-cover h6:not(.has-text-color)),
:root :where(.wp-block-cover p:not(.has-text-color)) {
  color: inherit
}

.wp-block-details {
  box-sizing: border-box
}

.wp-block-details summary {
  cursor: pointer
}

.wp-block-embed.alignleft,
.wp-block-embed.alignright,
.wp-block[data-align=left]>[data-type="core/embed"],
.wp-block[data-align=right]>[data-type="core/embed"] {
  max-width: 360px;
  width: 100%
}

.wp-block-embed.alignleft .wp-block-embed__wrapper,
.wp-block-embed.alignright .wp-block-embed__wrapper,
.wp-block[data-align=left]>[data-type="core/embed"] .wp-block-embed__wrapper,
.wp-block[data-align=right]>[data-type="core/embed"] .wp-block-embed__wrapper {
  min-width: 280px
}

.wp-block-cover .wp-block-embed {
  min-height: 240px;
  min-width: 320px
}

.wp-block-embed {
  overflow-wrap: break-word
}

.wp-block-embed :where(figcaption) {
  margin-bottom: 1em;
  margin-top: .5em
}

.wp-block-embed iframe {
  max-width: 100%
}

.wp-block-embed__wrapper {
  position: relative
}

.wp-embed-responsive .wp-has-aspect-ratio .wp-block-embed__wrapper:before {
  content: "";
  display: block;
  padding-top: 50%
}

.wp-embed-responsive .wp-has-aspect-ratio iframe {
  bottom: 0;
  height: 100%;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  width: 100%
}

.wp-embed-responsive .wp-embed-aspect-21-9 .wp-block-embed__wrapper:before {
  padding-top: 42.85%
}

.wp-embed-responsive .wp-embed-aspect-18-9 .wp-block-embed__wrapper:before {
  padding-top: 50%
}

.wp-embed-responsive .wp-embed-aspect-16-9 .wp-block-embed__wrapper:before {
  padding-top: 56.25%
}

.wp-embed-responsive .wp-embed-aspect-4-3 .wp-block-embed__wrapper:before {
  padding-top: 75%
}

.wp-embed-responsive .wp-embed-aspect-1-1 .wp-block-embed__wrapper:before {
  padding-top: 100%
}

.wp-embed-responsive .wp-embed-aspect-9-16 .wp-block-embed__wrapper:before {
  padding-top: 177.77%
}

.wp-embed-responsive .wp-embed-aspect-1-2 .wp-block-embed__wrapper:before {
  padding-top: 200%
}

.wp-block-file {
  box-sizing: border-box
}

.wp-block-file:not(.wp-element-button) {
  font-size: .8em
}

.wp-block-file.aligncenter {
  text-align: center
}

.wp-block-file.alignright {
  text-align: right
}

.wp-block-file *+.wp-block-file__button {
  margin-left: .75em
}

:where(.wp-block-file) {
  margin-bottom: 1.5em
}

.wp-block-file__embed {
  margin-bottom: 1em
}

:where(.wp-block-file__button) {
  border-radius: 2em;
  display: inline-block;
  padding: .5em 1em
}

:where(.wp-block-file__button):is(a):active,
:where(.wp-block-file__button):is(a):focus,
:where(.wp-block-file__button):is(a):hover,
:where(.wp-block-file__button):is(a):visited {
  box-shadow: none;
  color: #fff;
  opacity: .85;
  text-decoration: none
}

.wp-block-form-input__label {
  display: flex;
  flex-direction: column;
  gap: .25em;
  margin-bottom: .5em;
  width: 100%
}

.wp-block-form-input__label.is-label-inline {
  align-items: center;
  flex-direction: row;
  gap: .5em
}

.wp-block-form-input__label.is-label-inline .wp-block-form-input__label-content {
  margin-bottom: .5em
}

.wp-block-form-input__label:has(input[type=checkbox]) {
  flex-direction: row;
  width: -moz-fit-content;
  width: fit-content
}

.wp-block-form-input__label:has(input[type=checkbox]) .wp-block-form-input__label-content {
  margin: 0
}

.wp-block-form-input__label:has(.wp-block-form-input__label-content+input[type=checkbox]) {
  flex-direction: row-reverse
}

.wp-block-form-input__label-content {
  width: -moz-fit-content;
  width: fit-content
}

.wp-block-form-input__input {
  font-size: 1em;
  margin-bottom: .5em;
  padding: 0 .5em
}

.wp-block-form-input__input[type=date],
.wp-block-form-input__input[type=datetime-local],
.wp-block-form-input__input[type=datetime],
.wp-block-form-input__input[type=email],
.wp-block-form-input__input[type=month],
.wp-block-form-input__input[type=number],
.wp-block-form-input__input[type=password],
.wp-block-form-input__input[type=search],
.wp-block-form-input__input[type=tel],
.wp-block-form-input__input[type=text],
.wp-block-form-input__input[type=time],
.wp-block-form-input__input[type=url],
.wp-block-form-input__input[type=week] {
  border: 1px solid;
  line-height: 2;
  min-height: 2em
}

textarea.wp-block-form-input__input {
  min-height: 10em
}

.blocks-gallery-grid:not(.has-nested-images),
.wp-block-gallery:not(.has-nested-images) {
  display: flex;
  flex-wrap: wrap;
  list-style-type: none;
  margin: 0;
  padding: 0
}

.blocks-gallery-grid:not(.has-nested-images) .blocks-gallery-image,
.blocks-gallery-grid:not(.has-nested-images) .blocks-gallery-item,
.wp-block-gallery:not(.has-nested-images) .blocks-gallery-image,
.wp-block-gallery:not(.has-nested-images) .blocks-gallery-item {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  justify-content: center;
  margin: 0 1em 1em 0;
  position: relative;
  width: calc(50% - 1em)
}

.blocks-gallery-grid:not(.has-nested-images) .blocks-gallery-image:nth-of-type(2n),
.blocks-gallery-grid:not(.has-nested-images) .blocks-gallery-item:nth-of-type(2n),
.wp-block-gallery:not(.has-nested-images) .blocks-gallery-image:nth-of-type(2n),
.wp-block-gallery:not(.has-nested-images) .blocks-gallery-item:nth-of-type(2n) {
  margin-right: 0
}

.blocks-gallery-grid:not(.has-nested-images) .blocks-gallery-image figure,
.blocks-gallery-grid:not(.has-nested-images) .blocks-gallery-item figure,
.wp-block-gallery:not(.has-nested-images) .blocks-gallery-image figure,
.wp-block-gallery:not(.has-nested-images) .blocks-gallery-item figure {
  align-items: flex-end;
  display: flex;
  height: 100%;
  justify-content: flex-start;
  margin: 0
}

.blocks-gallery-grid:not(.has-nested-images) .blocks-gallery-image img,
.blocks-gallery-grid:not(.has-nested-images) .blocks-gallery-item img,
.wp-block-gallery:not(.has-nested-images) .blocks-gallery-image img,
.wp-block-gallery:not(.has-nested-images) .blocks-gallery-item img {
  display: block;
  height: auto;
  max-width: 100%;
  width: auto
}

.blocks-gallery-grid:not(.has-nested-images) .blocks-gallery-image figcaption,
.blocks-gallery-grid:not(.has-nested-images) .blocks-gallery-item figcaption,
.wp-block-gallery:not(.has-nested-images) .blocks-gallery-image figcaption,
.wp-block-gallery:not(.has-nested-images) .blocks-gallery-item figcaption {
  background: linear-gradient(0deg, #000000b3, #0000004d 70%, #0000);
  bottom: 0;
  box-sizing: border-box;
  color: #fff;
  font-size: .8em;
  margin: 0;
  max-height: 100%;
  overflow: auto;
  padding: 3em .77em .7em;
  position: absolute;
  text-align: center;
  width: 100%;
  z-index: 2
}

.blocks-gallery-grid:not(.has-nested-images) .blocks-gallery-image figcaption img,
.blocks-gallery-grid:not(.has-nested-images) .blocks-gallery-item figcaption img,
.wp-block-gallery:not(.has-nested-images) .blocks-gallery-image figcaption img,
.wp-block-gallery:not(.has-nested-images) .blocks-gallery-item figcaption img {
  display: inline
}

.blocks-gallery-grid:not(.has-nested-images) figcaption,
.wp-block-gallery:not(.has-nested-images) figcaption {
  flex-grow: 1
}

.blocks-gallery-grid:not(.has-nested-images).is-cropped .blocks-gallery-image a,
.blocks-gallery-grid:not(.has-nested-images).is-cropped .blocks-gallery-image img,
.blocks-gallery-grid:not(.has-nested-images).is-cropped .blocks-gallery-item a,
.blocks-gallery-grid:not(.has-nested-images).is-cropped .blocks-gallery-item img,
.wp-block-gallery:not(.has-nested-images).is-cropped .blocks-gallery-image a,
.wp-block-gallery:not(.has-nested-images).is-cropped .blocks-gallery-image img,
.wp-block-gallery:not(.has-nested-images).is-cropped .blocks-gallery-item a,
.wp-block-gallery:not(.has-nested-images).is-cropped .blocks-gallery-item img {
  flex: 1;
  height: 100%;
  object-fit: cover;
  width: 100%
}

.blocks-gallery-grid:not(.has-nested-images).columns-1 .blocks-gallery-image,
.blocks-gallery-grid:not(.has-nested-images).columns-1 .blocks-gallery-item,
.wp-block-gallery:not(.has-nested-images).columns-1 .blocks-gallery-image,
.wp-block-gallery:not(.has-nested-images).columns-1 .blocks-gallery-item {
  margin-right: 0;
  width: 100%
}

@media (min-width:600px) {

  .blocks-gallery-grid:not(.has-nested-images).columns-3 .blocks-gallery-image,
  .blocks-gallery-grid:not(.has-nested-images).columns-3 .blocks-gallery-item,
  .wp-block-gallery:not(.has-nested-images).columns-3 .blocks-gallery-image,
  .wp-block-gallery:not(.has-nested-images).columns-3 .blocks-gallery-item {
    margin-right: 1em;
    width: calc(33.33333% - .66667em)
  }

  .blocks-gallery-grid:not(.has-nested-images).columns-4 .blocks-gallery-image,
  .blocks-gallery-grid:not(.has-nested-images).columns-4 .blocks-gallery-item,
  .wp-block-gallery:not(.has-nested-images).columns-4 .blocks-gallery-image,
  .wp-block-gallery:not(.has-nested-images).columns-4 .blocks-gallery-item {
    margin-right: 1em;
    width: calc(25% - .75em)
  }

  .blocks-gallery-grid:not(.has-nested-images).columns-5 .blocks-gallery-image,
  .blocks-gallery-grid:not(.has-nested-images).columns-5 .blocks-gallery-item,
  .wp-block-gallery:not(.has-nested-images).columns-5 .blocks-gallery-image,
  .wp-block-gallery:not(.has-nested-images).columns-5 .blocks-gallery-item {
    margin-right: 1em;
    width: calc(20% - .8em)
  }

  .blocks-gallery-grid:not(.has-nested-images).columns-6 .blocks-gallery-image,
  .blocks-gallery-grid:not(.has-nested-images).columns-6 .blocks-gallery-item,
  .wp-block-gallery:not(.has-nested-images).columns-6 .blocks-gallery-image,
  .wp-block-gallery:not(.has-nested-images).columns-6 .blocks-gallery-item {
    margin-right: 1em;
    width: calc(16.66667% - .83333em)
  }

  .blocks-gallery-grid:not(.has-nested-images).columns-7 .blocks-gallery-image,
  .blocks-gallery-grid:not(.has-nested-images).columns-7 .blocks-gallery-item,
  .wp-block-gallery:not(.has-nested-images).columns-7 .blocks-gallery-image,
  .wp-block-gallery:not(.has-nested-images).columns-7 .blocks-gallery-item {
    margin-right: 1em;
    width: calc(14.28571% - .85714em)
  }

  .blocks-gallery-grid:not(.has-nested-images).columns-8 .blocks-gallery-image,
  .blocks-gallery-grid:not(.has-nested-images).columns-8 .blocks-gallery-item,
  .wp-block-gallery:not(.has-nested-images).columns-8 .blocks-gallery-image,
  .wp-block-gallery:not(.has-nested-images).columns-8 .blocks-gallery-item {
    margin-right: 1em;
    width: calc(12.5% - .875em)
  }

  .blocks-gallery-grid:not(.has-nested-images).columns-1 .blocks-gallery-image:nth-of-type(1n),
  .blocks-gallery-grid:not(.has-nested-images).columns-1 .blocks-gallery-item:nth-of-type(1n),
  .blocks-gallery-grid:not(.has-nested-images).columns-2 .blocks-gallery-image:nth-of-type(2n),
  .blocks-gallery-grid:not(.has-nested-images).columns-2 .blocks-gallery-item:nth-of-type(2n),
  .blocks-gallery-grid:not(.has-nested-images).columns-3 .blocks-gallery-image:nth-of-type(3n),
  .blocks-gallery-grid:not(.has-nested-images).columns-3 .blocks-gallery-item:nth-of-type(3n),
  .blocks-gallery-grid:not(.has-nested-images).columns-4 .blocks-gallery-image:nth-of-type(4n),
  .blocks-gallery-grid:not(.has-nested-images).columns-4 .blocks-gallery-item:nth-of-type(4n),
  .blocks-gallery-grid:not(.has-nested-images).columns-5 .blocks-gallery-image:nth-of-type(5n),
  .blocks-gallery-grid:not(.has-nested-images).columns-5 .blocks-gallery-item:nth-of-type(5n),
  .blocks-gallery-grid:not(.has-nested-images).columns-6 .blocks-gallery-image:nth-of-type(6n),
  .blocks-gallery-grid:not(.has-nested-images).columns-6 .blocks-gallery-item:nth-of-type(6n),
  .blocks-gallery-grid:not(.has-nested-images).columns-7 .blocks-gallery-image:nth-of-type(7n),
  .blocks-gallery-grid:not(.has-nested-images).columns-7 .blocks-gallery-item:nth-of-type(7n),
  .blocks-gallery-grid:not(.has-nested-images).columns-8 .blocks-gallery-image:nth-of-type(8n),
  .blocks-gallery-grid:not(.has-nested-images).columns-8 .blocks-gallery-item:nth-of-type(8n),
  .wp-block-gallery:not(.has-nested-images).columns-1 .blocks-gallery-image:nth-of-type(1n),
  .wp-block-gallery:not(.has-nested-images).columns-1 .blocks-gallery-item:nth-of-type(1n),
  .wp-block-gallery:not(.has-nested-images).columns-2 .blocks-gallery-image:nth-of-type(2n),
  .wp-block-gallery:not(.has-nested-images).columns-2 .blocks-gallery-item:nth-of-type(2n),
  .wp-block-gallery:not(.has-nested-images).columns-3 .blocks-gallery-image:nth-of-type(3n),
  .wp-block-gallery:not(.has-nested-images).columns-3 .blocks-gallery-item:nth-of-type(3n),
  .wp-block-gallery:not(.has-nested-images).columns-4 .blocks-gallery-image:nth-of-type(4n),
  .wp-block-gallery:not(.has-nested-images).columns-4 .blocks-gallery-item:nth-of-type(4n),
  .wp-block-gallery:not(.has-nested-images).columns-5 .blocks-gallery-image:nth-of-type(5n),
  .wp-block-gallery:not(.has-nested-images).columns-5 .blocks-gallery-item:nth-of-type(5n),
  .wp-block-gallery:not(.has-nested-images).columns-6 .blocks-gallery-image:nth-of-type(6n),
  .wp-block-gallery:not(.has-nested-images).columns-6 .blocks-gallery-item:nth-of-type(6n),
  .wp-block-gallery:not(.has-nested-images).columns-7 .blocks-gallery-image:nth-of-type(7n),
  .wp-block-gallery:not(.has-nested-images).columns-7 .blocks-gallery-item:nth-of-type(7n),
  .wp-block-gallery:not(.has-nested-images).columns-8 .blocks-gallery-image:nth-of-type(8n),
  .wp-block-gallery:not(.has-nested-images).columns-8 .blocks-gallery-item:nth-of-type(8n) {
    margin-right: 0
  }
}

.blocks-gallery-grid:not(.has-nested-images) .blocks-gallery-image:last-child,
.blocks-gallery-grid:not(.has-nested-images) .blocks-gallery-item:last-child,
.wp-block-gallery:not(.has-nested-images) .blocks-gallery-image:last-child,
.wp-block-gallery:not(.has-nested-images) .blocks-gallery-item:last-child {
  margin-right: 0
}

.blocks-gallery-grid:not(.has-nested-images).alignleft,
.blocks-gallery-grid:not(.has-nested-images).alignright,
.wp-block-gallery:not(.has-nested-images).alignleft,
.wp-block-gallery:not(.has-nested-images).alignright {
  max-width: 420px;
  width: 100%
}

.blocks-gallery-grid:not(.has-nested-images).aligncenter .blocks-gallery-item figure,
.wp-block-gallery:not(.has-nested-images).aligncenter .blocks-gallery-item figure {
  justify-content: center
}

.wp-block-gallery:not(.is-cropped) .blocks-gallery-item {
  align-self: flex-start
}

figure.wp-block-gallery.has-nested-images {
  align-items: normal
}

.wp-block-gallery.has-nested-images figure.wp-block-image:not(#individual-image) {
  margin: 0;
  width: calc(50% - var(--wp--style--unstable-gallery-gap, 16px)/2)
}

.wp-block-gallery.has-nested-images figure.wp-block-image {
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  justify-content: center;
  max-width: 100%;
  position: relative
}

.wp-block-gallery.has-nested-images figure.wp-block-image>a,
.wp-block-gallery.has-nested-images figure.wp-block-image>div {
  flex-direction: column;
  flex-grow: 1;
  margin: 0
}

.wp-block-gallery.has-nested-images figure.wp-block-image img {
  display: block;
  height: auto;
  max-width: 100% !important;
  width: auto
}

.wp-block-gallery.has-nested-images figure.wp-block-image figcaption,
.wp-block-gallery.has-nested-images figure.wp-block-image:has(figcaption):before {
  bottom: 0;
  left: 0;
  max-height: 100%;
  position: absolute;
  right: 0
}

.wp-block-gallery.has-nested-images figure.wp-block-image:has(figcaption):before {
  -webkit-backdrop-filter: blur(3px);
  backdrop-filter: blur(3px);
  content: "";
  height: 100%;
  -webkit-mask-image: linear-gradient(0deg, #000 20%, #0000);
  mask-image: linear-gradient(0deg, #000 20%, #0000);
  max-height: 40%
}

.wp-block-gallery.has-nested-images figure.wp-block-image figcaption {
  background: linear-gradient(0deg, #0006, #0000);
  box-sizing: border-box;
  color: #fff;
  font-size: 13px;
  margin: 0;
  overflow: auto;
  padding: 1em;
  scrollbar-color: #0000 #0000;
  scrollbar-gutter: stable both-edges;
  scrollbar-width: thin;
  text-align: center;
  text-shadow: 0 0 1.5px #000;
  will-change: transform
}

.wp-block-gallery.has-nested-images figure.wp-block-image figcaption::-webkit-scrollbar {
  height: 12px;
  width: 12px
}

.wp-block-gallery.has-nested-images figure.wp-block-image figcaption::-webkit-scrollbar-track {
  background-color: initial
}

.wp-block-gallery.has-nested-images figure.wp-block-image figcaption::-webkit-scrollbar-thumb {
  background-clip: padding-box;
  background-color: initial;
  border: 3px solid #0000;
  border-radius: 8px
}

.wp-block-gallery.has-nested-images figure.wp-block-image figcaption:focus-within::-webkit-scrollbar-thumb,
.wp-block-gallery.has-nested-images figure.wp-block-image figcaption:focus::-webkit-scrollbar-thumb,
.wp-block-gallery.has-nested-images figure.wp-block-image figcaption:hover::-webkit-scrollbar-thumb {
  background-color: #fffc
}

.wp-block-gallery.has-nested-images figure.wp-block-image figcaption:focus,
.wp-block-gallery.has-nested-images figure.wp-block-image figcaption:focus-within,
.wp-block-gallery.has-nested-images figure.wp-block-image figcaption:hover {
  scrollbar-color: #fffc #0000
}

@media (hover:none) {
  .wp-block-gallery.has-nested-images figure.wp-block-image figcaption {
    scrollbar-color: #fffc #0000
  }
}

.wp-block-gallery.has-nested-images figure.wp-block-image figcaption img {
  display: inline
}

.wp-block-gallery.has-nested-images figure.wp-block-image figcaption a {
  color: inherit
}

.wp-block-gallery.has-nested-images figure.wp-block-image.has-custom-border img {
  box-sizing: border-box
}

.wp-block-gallery.has-nested-images figure.wp-block-image.has-custom-border>a,
.wp-block-gallery.has-nested-images figure.wp-block-image.has-custom-border>div,
.wp-block-gallery.has-nested-images figure.wp-block-image.is-style-rounded>a,
.wp-block-gallery.has-nested-images figure.wp-block-image.is-style-rounded>div {
  flex: 1 1 auto
}

.wp-block-gallery.has-nested-images figure.wp-block-image.has-custom-border figcaption,
.wp-block-gallery.has-nested-images figure.wp-block-image.is-style-rounded figcaption {
  background: none;
  color: inherit;
  flex: initial;
  margin: 0;
  padding: 10px 10px 9px;
  position: relative;
  text-shadow: none
}

.wp-block-gallery.has-nested-images figure.wp-block-image.has-custom-border:before,
.wp-block-gallery.has-nested-images figure.wp-block-image.is-style-rounded:before {
  content: none
}

.wp-block-gallery.has-nested-images figcaption {
  flex-basis: 100%;
  flex-grow: 1;
  text-align: center
}

.wp-block-gallery.has-nested-images:not(.is-cropped) figure.wp-block-image:not(#individual-image) {
  margin-bottom: auto;
  margin-top: 0
}

.wp-block-gallery.has-nested-images.is-cropped figure.wp-block-image:not(#individual-image) {
  align-self: inherit
}

.wp-block-gallery.has-nested-images.is-cropped figure.wp-block-image:not(#individual-image)>a,
.wp-block-gallery.has-nested-images.is-cropped figure.wp-block-image:not(#individual-image)>div:not(.components-drop-zone) {
  display: flex
}

.wp-block-gallery.has-nested-images.is-cropped figure.wp-block-image:not(#individual-image) a,
.wp-block-gallery.has-nested-images.is-cropped figure.wp-block-image:not(#individual-image) img {
  flex: 1 0 0%;
  height: 100%;
  object-fit: cover;
  width: 100%
}

.wp-block-gallery.has-nested-images.columns-1 figure.wp-block-image:not(#individual-image) {
  width: 100%
}

@media (min-width:600px) {
  .wp-block-gallery.has-nested-images.columns-3 figure.wp-block-image:not(#individual-image) {
    width: calc(33.33333% - var(--wp--style--unstable-gallery-gap, 16px)*.66667)
  }

  .wp-block-gallery.has-nested-images.columns-4 figure.wp-block-image:not(#individual-image) {
    width: calc(25% - var(--wp--style--unstable-gallery-gap, 16px)*.75)
  }

  .wp-block-gallery.has-nested-images.columns-5 figure.wp-block-image:not(#individual-image) {
    width: calc(20% - var(--wp--style--unstable-gallery-gap, 16px)*.8)
  }

  .wp-block-gallery.has-nested-images.columns-6 figure.wp-block-image:not(#individual-image) {
    width: calc(16.66667% - var(--wp--style--unstable-gallery-gap, 16px)*.83333)
  }

  .wp-block-gallery.has-nested-images.columns-7 figure.wp-block-image:not(#individual-image) {
    width: calc(14.28571% - var(--wp--style--unstable-gallery-gap, 16px)*.85714)
  }

  .wp-block-gallery.has-nested-images.columns-8 figure.wp-block-image:not(#individual-image) {
    width: calc(12.5% - var(--wp--style--unstable-gallery-gap, 16px)*.875)
  }

  .wp-block-gallery.has-nested-images.columns-default figure.wp-block-image:not(#individual-image) {
    width: calc(33.33% - var(--wp--style--unstable-gallery-gap, 16px)*.66667)
  }

  .wp-block-gallery.has-nested-images.columns-default figure.wp-block-image:not(#individual-image):first-child:nth-last-child(2),
  .wp-block-gallery.has-nested-images.columns-default figure.wp-block-image:not(#individual-image):first-child:nth-last-child(2)~figure.wp-block-image:not(#individual-image) {
    width: calc(50% - var(--wp--style--unstable-gallery-gap, 16px)*.5)
  }

  .wp-block-gallery.has-nested-images.columns-default figure.wp-block-image:not(#individual-image):first-child:last-child {
    width: 100%
  }
}

.wp-block-gallery.has-nested-images.alignleft,
.wp-block-gallery.has-nested-images.alignright {
  max-width: 420px;
  width: 100%
}

.wp-block-gallery.has-nested-images.aligncenter {
  justify-content: center
}

.wp-block-group {
  box-sizing: border-box
}

:where(.wp-block-group.wp-block-group-is-layout-constrained) {
  position: relative
}

h1.has-background,
h2.has-background,
h3.has-background,
h4.has-background,
h5.has-background,
h6.has-background {
  padding: 1.25em 2.375em
}

h1.has-text-align-left[style*=writing-mode]:where([style*=vertical-lr]),
h1.has-text-align-right[style*=writing-mode]:where([style*=vertical-rl]),
h2.has-text-align-left[style*=writing-mode]:where([style*=vertical-lr]),
h2.has-text-align-right[style*=writing-mode]:where([style*=vertical-rl]),
h3.has-text-align-left[style*=writing-mode]:where([style*=vertical-lr]),
h3.has-text-align-right[style*=writing-mode]:where([style*=vertical-rl]),
h4.has-text-align-left[style*=writing-mode]:where([style*=vertical-lr]),
h4.has-text-align-right[style*=writing-mode]:where([style*=vertical-rl]),
h5.has-text-align-left[style*=writing-mode]:where([style*=vertical-lr]),
h5.has-text-align-right[style*=writing-mode]:where([style*=vertical-rl]),
h6.has-text-align-left[style*=writing-mode]:where([style*=vertical-lr]),
h6.has-text-align-right[style*=writing-mode]:where([style*=vertical-rl]) {
  rotate: 180deg
}

.wp-block-image a {
  display: inline-block
}

.wp-block-image img {
  box-sizing: border-box;
  height: auto;
  max-width: 100%;
  vertical-align: bottom
}

@media (prefers-reduced-motion:no-preference) {
  .wp-block-image img.hide {
    visibility: hidden
  }

  .wp-block-image img.show {
    animation: show-content-image .4s
  }
}

.wp-block-image[style*=border-radius] img,
.wp-block-image[style*=border-radius]>a {
  border-radius: inherit
}

.wp-block-image.has-custom-border img {
  box-sizing: border-box
}

.wp-block-image.aligncenter {
  text-align: center
}

.wp-block-image.alignfull a,
.wp-block-image.alignwide a {
  width: 100%
}

.wp-block-image.alignfull img,
.wp-block-image.alignwide img {
  height: auto;
  width: 100%
}

.wp-block-image .aligncenter,
.wp-block-image .alignleft,
.wp-block-image .alignright,
.wp-block-image.aligncenter,
.wp-block-image.alignleft,
.wp-block-image.alignright {
  display: table
}

.wp-block-image .aligncenter>figcaption,
.wp-block-image .alignleft>figcaption,
.wp-block-image .alignright>figcaption,
.wp-block-image.aligncenter>figcaption,
.wp-block-image.alignleft>figcaption,
.wp-block-image.alignright>figcaption {
  caption-side: bottom;
  display: table-caption
}

.wp-block-image .alignleft {
  float: left;
  margin: .5em 1em .5em 0
}

.wp-block-image .alignright {
  float: right;
  margin: .5em 0 .5em 1em
}

.wp-block-image .aligncenter {
  margin-left: auto;
  margin-right: auto
}

.wp-block-image :where(figcaption) {
  margin-bottom: 1em;
  margin-top: .5em
}

.wp-block-image.is-style-circle-mask img {
  border-radius: 9999px
}

@supports ((-webkit-mask-image:none) or (mask-image:none)) or (-webkit-mask-image:none) {
  .wp-block-image.is-style-circle-mask img {
    border-radius: 0;
    -webkit-mask-image: url('data:image/svg+xml;utf8,<svg viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg"><circle cx="50" cy="50" r="50"/></svg>');
    mask-image: url('data:image/svg+xml;utf8,<svg viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg"><circle cx="50" cy="50" r="50"/></svg>');
    mask-mode: alpha;
    -webkit-mask-position: center;
    mask-position: center;
    -webkit-mask-repeat: no-repeat;
    mask-repeat: no-repeat;
    -webkit-mask-size: contain;
    mask-size: contain
  }
}

:root :where(.wp-block-image.is-style-rounded img, .wp-block-image .is-style-rounded img) {
  border-radius: 9999px
}

.wp-block-image figure {
  margin: 0
}

.wp-lightbox-container {
  display: flex;
  flex-direction: column;
  position: relative
}

.wp-lightbox-container img {
  cursor: zoom-in
}

.wp-lightbox-container img:hover+button {
  opacity: 1
}

.wp-lightbox-container button {
  align-items: center;
  -webkit-backdrop-filter: blur(16px) saturate(180%);
  backdrop-filter: blur(16px) saturate(180%);
  background-color: #5a5a5a40;
  border: none;
  border-radius: 4px;
  cursor: zoom-in;
  display: flex;
  height: 20px;
  justify-content: center;
  opacity: 0;
  padding: 0;
  position: absolute;
  right: 16px;
  text-align: center;
  top: 16px;
  transition: opacity .2s ease;
  width: 20px;
  z-index: 100
}

.wp-lightbox-container button:focus-visible {
  outline: 3px auto #5a5a5a40;
  outline: 3px auto -webkit-focus-ring-color;
  outline-offset: 3px
}

.wp-lightbox-container button:hover {
  cursor: pointer;
  opacity: 1
}

.wp-lightbox-container button:focus {
  opacity: 1
}

.wp-lightbox-container button:focus,
.wp-lightbox-container button:hover,
.wp-lightbox-container button:not(:hover):not(:active):not(.has-background) {
  background-color: #5a5a5a40;
  border: none
}

.wp-lightbox-overlay {
  box-sizing: border-box;
  cursor: zoom-out;
  height: 100vh;
  left: 0;
  overflow: hidden;
  position: fixed;
  top: 0;
  visibility: hidden;
  width: 100%;
  z-index: 100000
}

.wp-lightbox-overlay .close-button {
  align-items: center;
  cursor: pointer;
  display: flex;
  justify-content: center;
  min-height: 40px;
  min-width: 40px;
  padding: 0;
  position: absolute;
  right: calc(env(safe-area-inset-right) + 16px);
  top: calc(env(safe-area-inset-top) + 16px);
  z-index: 5000000
}

.wp-lightbox-overlay .close-button:focus,
.wp-lightbox-overlay .close-button:hover,
.wp-lightbox-overlay .close-button:not(:hover):not(:active):not(.has-background) {
  background: none;
  border: none
}

.wp-lightbox-overlay .lightbox-image-container {
  height: var(--wp--lightbox-container-height);
  left: 50%;
  overflow: hidden;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  transform-origin: top left;
  width: var(--wp--lightbox-container-width);
  z-index: 9999999999
}

.wp-lightbox-overlay .wp-block-image {
  align-items: center;
  box-sizing: border-box;
  display: flex;
  height: 100%;
  justify-content: center;
  margin: 0;
  position: relative;
  transform-origin: 0 0;
  width: 100%;
  z-index: 3000000
}

.wp-lightbox-overlay .wp-block-image img {
  height: var(--wp--lightbox-image-height);
  min-height: var(--wp--lightbox-image-height);
  min-width: var(--wp--lightbox-image-width);
  width: var(--wp--lightbox-image-width)
}

.wp-lightbox-overlay .wp-block-image figcaption {
  display: none
}

.wp-lightbox-overlay button {
  background: none;
  border: none
}

.wp-lightbox-overlay .scrim {
  background-color: #fff;
  height: 100%;
  opacity: .9;
  position: absolute;
  width: 100%;
  z-index: 2000000
}

.wp-lightbox-overlay.active {
  animation: turn-on-visibility .25s both;
  visibility: visible
}

.wp-lightbox-overlay.active img {
  animation: turn-on-visibility .35s both
}

.wp-lightbox-overlay.show-closing-animation:not(.active) {
  animation: turn-off-visibility .35s both
}

.wp-lightbox-overlay.show-closing-animation:not(.active) img {
  animation: turn-off-visibility .25s both
}

@media (prefers-reduced-motion:no-preference) {
  .wp-lightbox-overlay.zoom.active {
    animation: none;
    opacity: 1;
    visibility: visible
  }

  .wp-lightbox-overlay.zoom.active .lightbox-image-container {
    animation: lightbox-zoom-in .4s
  }

  .wp-lightbox-overlay.zoom.active .lightbox-image-container img {
    animation: none
  }

  .wp-lightbox-overlay.zoom.active .scrim {
    animation: turn-on-visibility .4s forwards
  }

  .wp-lightbox-overlay.zoom.show-closing-animation:not(.active) {
    animation: none
  }

  .wp-lightbox-overlay.zoom.show-closing-animation:not(.active) .lightbox-image-container {
    animation: lightbox-zoom-out .4s
  }

  .wp-lightbox-overlay.zoom.show-closing-animation:not(.active) .lightbox-image-container img {
    animation: none
  }

  .wp-lightbox-overlay.zoom.show-closing-animation:not(.active) .scrim {
    animation: turn-off-visibility .4s forwards
  }
}

@keyframes show-content-image {
  0% {
    visibility: hidden
  }

  99% {
    visibility: hidden
  }

  to {
    visibility: visible
  }
}

@keyframes turn-on-visibility {
  0% {
    opacity: 0
  }

  to {
    opacity: 1
  }
}

@keyframes turn-off-visibility {
  0% {
    opacity: 1;
    visibility: visible
  }

  99% {
    opacity: 0;
    visibility: visible
  }

  to {
    opacity: 0;
    visibility: hidden
  }
}

@keyframes lightbox-zoom-in {
  0% {
    transform: translate(calc((-100vw + var(--wp--lightbox-scrollbar-width))/2 + var(--wp--lightbox-initial-left-position)), calc(-50vh + var(--wp--lightbox-initial-top-position))) scale(var(--wp--lightbox-scale))
  }

  to {
    transform: translate(-50%, -50%) scale(1)
  }
}

@keyframes lightbox-zoom-out {
  0% {
    transform: translate(-50%, -50%) scale(1);
    visibility: visible
  }

  99% {
    visibility: visible
  }

  to {
    transform: translate(calc((-100vw + var(--wp--lightbox-scrollbar-width))/2 + var(--wp--lightbox-initial-left-position)), calc(-50vh + var(--wp--lightbox-initial-top-position))) scale(var(--wp--lightbox-scale));
    visibility: hidden
  }
}

ol.wp-block-latest-comments {
  box-sizing: border-box;
  margin-left: 0
}

:where(.wp-block-latest-comments:not([style*=line-height] .wp-block-latest-comments__comment)) {
  line-height: 1.1
}

:where(.wp-block-latest-comments:not([style*=line-height] .wp-block-latest-comments__comment-excerpt p)) {
  line-height: 1.8
}

.has-dates :where(.wp-block-latest-comments:not([style*=line-height])),
.has-excerpts :where(.wp-block-latest-comments:not([style*=line-height])) {
  line-height: 1.5
}

.wp-block-latest-comments .wp-block-latest-comments {
  padding-left: 0
}

.wp-block-latest-comments__comment {
  list-style: none;
  margin-bottom: 1em
}

.has-avatars .wp-block-latest-comments__comment {
  list-style: none;
  min-height: 2.25em
}

.has-avatars .wp-block-latest-comments__comment .wp-block-latest-comments__comment-excerpt,
.has-avatars .wp-block-latest-comments__comment .wp-block-latest-comments__comment-meta {
  margin-left: 3.25em
}

.wp-block-latest-comments__comment-excerpt p {
  font-size: .875em;
  margin: .36em 0 1.4em
}

.wp-block-latest-comments__comment-date {
  display: block;
  font-size: .75em
}

.wp-block-latest-comments .avatar,
.wp-block-latest-comments__comment-avatar {
  border-radius: 1.5em;
  display: block;
  float: left;
  height: 2.5em;
  margin-right: .75em;
  width: 2.5em
}

.wp-block-latest-comments[class*=-font-size] a,
.wp-block-latest-comments[style*=font-size] a {
  font-size: inherit
}

.wp-block-latest-posts {
  box-sizing: border-box
}

.wp-block-latest-posts.alignleft {
  margin-right: 2em
}

.wp-block-latest-posts.alignright {
  margin-left: 2em
}

.wp-block-latest-posts.wp-block-latest-posts__list {
  list-style: none
}

.wp-block-latest-posts.wp-block-latest-posts__list li {
  clear: both;
  overflow-wrap: break-word
}

.wp-block-latest-posts.is-grid {
  display: flex;
  flex-wrap: wrap
}

.wp-block-latest-posts.is-grid li {
  margin: 0 1.25em 1.25em 0;
  width: 100%
}

@media (min-width:600px) {
  .wp-block-latest-posts.columns-2 li {
    width: calc(50% - .625em)
  }

  .wp-block-latest-posts.columns-2 li:nth-child(2n) {
    margin-right: 0
  }

  .wp-block-latest-posts.columns-3 li {
    width: calc(33.33333% - .83333em)
  }

  .wp-block-latest-posts.columns-3 li:nth-child(3n) {
    margin-right: 0
  }

  .wp-block-latest-posts.columns-4 li {
    width: calc(25% - .9375em)
  }

  .wp-block-latest-posts.columns-4 li:nth-child(4n) {
    margin-right: 0
  }

  .wp-block-latest-posts.columns-5 li {
    width: calc(20% - 1em)
  }

  .wp-block-latest-posts.columns-5 li:nth-child(5n) {
    margin-right: 0
  }

  .wp-block-latest-posts.columns-6 li {
    width: calc(16.66667% - 1.04167em)
  }

  .wp-block-latest-posts.columns-6 li:nth-child(6n) {
    margin-right: 0
  }
}

:root :where(.wp-block-latest-posts.is-grid) {
  padding: 0
}

:root :where(.wp-block-latest-posts.wp-block-latest-posts__list) {
  padding-left: 0
}

.wp-block-latest-posts__post-author,
.wp-block-latest-posts__post-date {
  display: block;
  font-size: .8125em
}

.wp-block-latest-posts__post-excerpt {
  margin-bottom: 1em;
  margin-top: .5em
}

.wp-block-latest-posts__featured-image a {
  display: inline-block
}

.wp-block-latest-posts__featured-image img {
  height: auto;
  max-width: 100%;
  width: auto
}

.wp-block-latest-posts__featured-image.alignleft {
  float: left;
  margin-right: 1em
}

.wp-block-latest-posts__featured-image.alignright {
  float: right;
  margin-left: 1em
}

.wp-block-latest-posts__featured-image.aligncenter {
  margin-bottom: 1em;
  text-align: center
}

ol,
ul {
  box-sizing: border-box
}

:root :where(.wp-block-list.has-background) {
  padding: 1.25em 2.375em
}

.wp-block-loginout,
.wp-block-media-text {
  box-sizing: border-box
}

.wp-block-media-text {
  /*!rtl:begin:ignore*/
  direction: ltr;
  /*!rtl:end:ignore*/
  display: grid;
  grid-template-columns: 50% 1fr;
  grid-template-rows: auto
}

.wp-block-media-text.has-media-on-the-right {
  grid-template-columns: 1fr 50%
}

.wp-block-media-text.is-vertically-aligned-top>.wp-block-media-text__content,
.wp-block-media-text.is-vertically-aligned-top>.wp-block-media-text__media {
  align-self: start
}

.wp-block-media-text.is-vertically-aligned-center>.wp-block-media-text__content,
.wp-block-media-text.is-vertically-aligned-center>.wp-block-media-text__media,
.wp-block-media-text>.wp-block-media-text__content,
.wp-block-media-text>.wp-block-media-text__media {
  align-self: center
}

.wp-block-media-text.is-vertically-aligned-bottom>.wp-block-media-text__content,
.wp-block-media-text.is-vertically-aligned-bottom>.wp-block-media-text__media {
  align-self: end
}

.wp-block-media-text>.wp-block-media-text__media {
  /*!rtl:begin:ignore*/
  grid-column: 1;
  grid-row: 1;
  /*!rtl:end:ignore*/
  margin: 0
}

.wp-block-media-text>.wp-block-media-text__content {
  direction: ltr;
  /*!rtl:begin:ignore*/
  grid-column: 2;
  grid-row: 1;
  /*!rtl:end:ignore*/
  padding: 0 8%;
  word-break: break-word
}

.wp-block-media-text.has-media-on-the-right>.wp-block-media-text__media {
  /*!rtl:begin:ignore*/
  grid-column: 2;
  grid-row: 1
    /*!rtl:end:ignore*/
}

.wp-block-media-text.has-media-on-the-right>.wp-block-media-text__content {
  /*!rtl:begin:ignore*/
  grid-column: 1;
  grid-row: 1
    /*!rtl:end:ignore*/
}

.wp-block-media-text__media a {
  display: inline-block
}

.wp-block-media-text__media img,
.wp-block-media-text__media video {
  height: auto;
  max-width: unset;
  vertical-align: middle;
  width: 100%
}

.wp-block-media-text.is-image-fill>.wp-block-media-text__media {
  background-size: cover;
  height: 100%;
  min-height: 250px
}

.wp-block-media-text.is-image-fill>.wp-block-media-text__media>a {
  display: block;
  height: 100%
}

.wp-block-media-text.is-image-fill>.wp-block-media-text__media img {
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
  clip: rect(0, 0, 0, 0);
  border: 0
}

.wp-block-media-text.is-image-fill-element>.wp-block-media-text__media {
  height: 100%;
  min-height: 250px;
  position: relative
}

.wp-block-media-text.is-image-fill-element>.wp-block-media-text__media>a {
  display: block;
  height: 100%
}

.wp-block-media-text.is-image-fill-element>.wp-block-media-text__media img {
  height: 100%;
  object-fit: cover;
  position: absolute;
  width: 100%
}

@media (max-width:600px) {
  .wp-block-media-text.is-stacked-on-mobile {
    grid-template-columns: 100% !important
  }

  .wp-block-media-text.is-stacked-on-mobile>.wp-block-media-text__media {
    grid-column: 1;
    grid-row: 1
  }

  .wp-block-media-text.is-stacked-on-mobile>.wp-block-media-text__content {
    grid-column: 1;
    grid-row: 2
  }
}

.wp-block-navigation {
  position: relative;
  --navigation-layout-justification-setting: flex-start;
  --navigation-layout-direction: row;
  --navigation-layout-wrap: wrap;
  --navigation-layout-justify: flex-start;
  --navigation-layout-align: center
}

.wp-block-navigation ul {
  margin-bottom: 0;
  margin-left: 0;
  margin-top: 0;
  padding-left: 0
}

.wp-block-navigation ul,
.wp-block-navigation ul li {
  list-style: none;
  padding: 0
}

.wp-block-navigation .wp-block-navigation-item {
  align-items: center;
  display: flex;
  position: relative
}

.wp-block-navigation .wp-block-navigation-item .wp-block-navigation__submenu-container:empty {
  display: none
}

.wp-block-navigation .wp-block-navigation-item__content {
  display: block
}

.wp-block-navigation .wp-block-navigation-item__content.wp-block-navigation-item__content {
  color: inherit
}

.wp-block-navigation.has-text-decoration-underline .wp-block-navigation-item__content,
.wp-block-navigation.has-text-decoration-underline .wp-block-navigation-item__content:active,
.wp-block-navigation.has-text-decoration-underline .wp-block-navigation-item__content:focus {
  text-decoration: underline
}

.wp-block-navigation.has-text-decoration-line-through .wp-block-navigation-item__content,
.wp-block-navigation.has-text-decoration-line-through .wp-block-navigation-item__content:active,
.wp-block-navigation.has-text-decoration-line-through .wp-block-navigation-item__content:focus {
  text-decoration: line-through
}

.wp-block-navigation :where(a),
.wp-block-navigation :where(a:active),
.wp-block-navigation :where(a:focus) {
  text-decoration: none
}

.wp-block-navigation .wp-block-navigation__submenu-icon {
  align-self: center;
  background-color: inherit;
  border: none;
  color: currentColor;
  display: inline-block;
  font-size: inherit;
  height: .6em;
  line-height: 0;
  margin-left: .25em;
  padding: 0;
  width: .6em
}

.wp-block-navigation .wp-block-navigation__submenu-icon svg {
  display: inline-block;
  stroke: currentColor;
  height: inherit;
  margin-top: .075em;
  width: inherit
}

.wp-block-navigation.is-vertical {
  --navigation-layout-direction: column;
  --navigation-layout-justify: initial;
  --navigation-layout-align: flex-start
}

.wp-block-navigation.no-wrap {
  --navigation-layout-wrap: nowrap
}

.wp-block-navigation.items-justified-center {
  --navigation-layout-justification-setting: center;
  --navigation-layout-justify: center
}

.wp-block-navigation.items-justified-center.is-vertical {
  --navigation-layout-align: center
}

.wp-block-navigation.items-justified-right {
  --navigation-layout-justification-setting: flex-end;
  --navigation-layout-justify: flex-end
}

.wp-block-navigation.items-justified-right.is-vertical {
  --navigation-layout-align: flex-end
}

.wp-block-navigation.items-justified-space-between {
  --navigation-layout-justification-setting: space-between;
  --navigation-layout-justify: space-between
}

.wp-block-navigation .has-child .wp-block-navigation__submenu-container {
  align-items: normal;
  background-color: inherit;
  color: inherit;
  display: flex;
  flex-direction: column;
  height: 0;
  left: -1px;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  top: 100%;
  transition: opacity .1s linear;
  visibility: hidden;
  width: 0;
  z-index: 2
}

.wp-block-navigation .has-child .wp-block-navigation__submenu-container>.wp-block-navigation-item>.wp-block-navigation-item__content {
  display: flex;
  flex-grow: 1
}

.wp-block-navigation .has-child .wp-block-navigation__submenu-container>.wp-block-navigation-item>.wp-block-navigation-item__content .wp-block-navigation__submenu-icon {
  margin-left: auto;
  margin-right: 0
}

.wp-block-navigation .has-child .wp-block-navigation__submenu-container .wp-block-navigation-item__content {
  margin: 0
}

@media (min-width:782px) {
  .wp-block-navigation .has-child .wp-block-navigation__submenu-container .wp-block-navigation__submenu-container {
    left: 100%;
    top: -1px
  }

  .wp-block-navigation .has-child .wp-block-navigation__submenu-container .wp-block-navigation__submenu-container:before {
    background: #0000;
    content: "";
    display: block;
    height: 100%;
    position: absolute;
    right: 100%;
    width: .5em
  }

  .wp-block-navigation .has-child .wp-block-navigation__submenu-container .wp-block-navigation__submenu-icon {
    margin-right: .25em
  }

  .wp-block-navigation .has-child .wp-block-navigation__submenu-container .wp-block-navigation__submenu-icon svg {
    transform: rotate(-90deg)
  }
}

.wp-block-navigation .has-child .wp-block-navigation-submenu__toggle[aria-expanded=true]~.wp-block-navigation__submenu-container,
.wp-block-navigation .has-child:not(.open-on-click):hover>.wp-block-navigation__submenu-container,
.wp-block-navigation .has-child:not(.open-on-click):not(.open-on-hover-click):focus-within>.wp-block-navigation__submenu-container {
  height: auto;
  min-width: 200px;
  opacity: 1;
  overflow: visible;
  visibility: visible;
  width: auto
}

.wp-block-navigation.has-background .has-child .wp-block-navigation__submenu-container {
  left: 0;
  top: 100%
}

@media (min-width:782px) {
  .wp-block-navigation.has-background .has-child .wp-block-navigation__submenu-container .wp-block-navigation__submenu-container {
    left: 100%;
    top: 0
  }
}

.wp-block-navigation-submenu {
  display: flex;
  position: relative
}

.wp-block-navigation-submenu .wp-block-navigation__submenu-icon svg {
  stroke: currentColor
}

button.wp-block-navigation-item__content {
  background-color: initial;
  border: none;
  color: currentColor;
  font-family: inherit;
  font-size: inherit;
  font-style: inherit;
  font-weight: inherit;
  letter-spacing: inherit;
  line-height: inherit;
  text-align: left;
  text-transform: inherit
}

.wp-block-navigation-submenu__toggle {
  cursor: pointer
}

.wp-block-navigation-item.open-on-click .wp-block-navigation-submenu__toggle {
  padding-left: 0;
  padding-right: .85em
}

.wp-block-navigation-item.open-on-click .wp-block-navigation-submenu__toggle+.wp-block-navigation__submenu-icon {
  margin-left: -.6em;
  pointer-events: none
}

.wp-block-navigation-item.open-on-click button.wp-block-navigation-item__content:not(.wp-block-navigation-submenu__toggle) {
  padding: 0
}

.wp-block-navigation .wp-block-page-list,
.wp-block-navigation__container,
.wp-block-navigation__responsive-close,
.wp-block-navigation__responsive-container,
.wp-block-navigation__responsive-container-content,
.wp-block-navigation__responsive-dialog {
  gap: inherit
}

:where(.wp-block-navigation.has-background .wp-block-navigation-item a:not(.wp-element-button)),
:where(.wp-block-navigation.has-background .wp-block-navigation-submenu a:not(.wp-element-button)) {
  padding: .5em 1em
}

:where(.wp-block-navigation .wp-block-navigation__submenu-container .wp-block-navigation-item a:not(.wp-element-button)),
:where(.wp-block-navigation .wp-block-navigation__submenu-container .wp-block-navigation-submenu a:not(.wp-element-button)),
:where(.wp-block-navigation .wp-block-navigation__submenu-container .wp-block-navigation-submenu button.wp-block-navigation-item__content),
:where(.wp-block-navigation .wp-block-navigation__submenu-container .wp-block-pages-list__item button.wp-block-navigation-item__content) {
  padding: .5em 1em
}

.wp-block-navigation.items-justified-right .wp-block-navigation__container .has-child .wp-block-navigation__submenu-container,
.wp-block-navigation.items-justified-right .wp-block-page-list>.has-child .wp-block-navigation__submenu-container,
.wp-block-navigation.items-justified-space-between .wp-block-page-list>.has-child:last-child .wp-block-navigation__submenu-container,
.wp-block-navigation.items-justified-space-between>.wp-block-navigation__container>.has-child:last-child .wp-block-navigation__submenu-container {
  left: auto;
  right: 0
}

.wp-block-navigation.items-justified-right .wp-block-navigation__container .has-child .wp-block-navigation__submenu-container .wp-block-navigation__submenu-container,
.wp-block-navigation.items-justified-right .wp-block-page-list>.has-child .wp-block-navigation__submenu-container .wp-block-navigation__submenu-container,
.wp-block-navigation.items-justified-space-between .wp-block-page-list>.has-child:last-child .wp-block-navigation__submenu-container .wp-block-navigation__submenu-container,
.wp-block-navigation.items-justified-space-between>.wp-block-navigation__container>.has-child:last-child .wp-block-navigation__submenu-container .wp-block-navigation__submenu-container {
  left: -1px;
  right: -1px
}

@media (min-width:782px) {

  .wp-block-navigation.items-justified-right .wp-block-navigation__container .has-child .wp-block-navigation__submenu-container .wp-block-navigation__submenu-container,
  .wp-block-navigation.items-justified-right .wp-block-page-list>.has-child .wp-block-navigation__submenu-container .wp-block-navigation__submenu-container,
  .wp-block-navigation.items-justified-space-between .wp-block-page-list>.has-child:last-child .wp-block-navigation__submenu-container .wp-block-navigation__submenu-container,
  .wp-block-navigation.items-justified-space-between>.wp-block-navigation__container>.has-child:last-child .wp-block-navigation__submenu-container .wp-block-navigation__submenu-container {
    left: auto;
    right: 100%
  }
}

.wp-block-navigation:not(.has-background) .wp-block-navigation__submenu-container {
  background-color: #fff;
  border: 1px solid #00000026
}

.wp-block-navigation.has-background .wp-block-navigation__submenu-container {
  background-color: inherit
}

.wp-block-navigation:not(.has-text-color) .wp-block-navigation__submenu-container {
  color: #000
}

.wp-block-navigation__container {
  align-items: var(--navigation-layout-align, initial);
  display: flex;
  flex-direction: var(--navigation-layout-direction, initial);
  flex-wrap: var(--navigation-layout-wrap, wrap);
  justify-content: var(--navigation-layout-justify, initial);
  list-style: none;
  margin: 0;
  padding-left: 0
}

.wp-block-navigation__container .is-responsive {
  display: none
}

.wp-block-navigation__container:only-child,
.wp-block-page-list:only-child {
  flex-grow: 1
}

@keyframes overlay-menu__fade-in-animation {
  0% {
    opacity: 0;
    transform: translateY(.5em)
  }

  to {
    opacity: 1;
    transform: translateY(0)
  }
}

.wp-block-navigation__responsive-container {
  bottom: 0;
  display: none;
  left: 0;
  position: fixed;
  right: 0;
  top: 0
}

.wp-block-navigation__responsive-container :where(.wp-block-navigation-item a) {
  color: inherit
}

.wp-block-navigation__responsive-container .wp-block-navigation__responsive-container-content {
  align-items: var(--navigation-layout-align, initial);
  display: flex;
  flex-direction: var(--navigation-layout-direction, initial);
  flex-wrap: var(--navigation-layout-wrap, wrap);
  justify-content: var(--navigation-layout-justify, initial)
}

.wp-block-navigation__responsive-container:not(.is-menu-open.is-menu-open) {
  background-color: inherit !important;
  color: inherit !important
}

.wp-block-navigation__responsive-container.is-menu-open {
  animation: overlay-menu__fade-in-animation .1s ease-out;
  animation-fill-mode: forwards;
  background-color: inherit;
  display: flex;
  flex-direction: column;
  overflow: auto;
  padding: clamp(1rem, var(--wp--style--root--padding-top), 20rem) clamp(1rem, var(--wp--style--root--padding-right), 20rem) clamp(1rem, var(--wp--style--root--padding-bottom), 20rem) clamp(1rem, var(--wp--style--root--padding-left), 20em);
  z-index: 100000
}

@media (prefers-reduced-motion:reduce) {
  .wp-block-navigation__responsive-container.is-menu-open {
    animation-delay: 0s;
    animation-duration: 1ms
  }
}

.wp-block-navigation__responsive-container.is-menu-open .wp-block-navigation__responsive-container-content {
  align-items: var(--navigation-layout-justification-setting, inherit);
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  overflow: visible;
  padding-top: calc(2rem + 24px)
}

.wp-block-navigation__responsive-container.is-menu-open .wp-block-navigation__responsive-container-content,
.wp-block-navigation__responsive-container.is-menu-open .wp-block-navigation__responsive-container-content .wp-block-navigation__container,
.wp-block-navigation__responsive-container.is-menu-open .wp-block-navigation__responsive-container-content .wp-block-page-list {
  justify-content: flex-start
}

.wp-block-navigation__responsive-container.is-menu-open .wp-block-navigation__responsive-container-content .wp-block-navigation__submenu-icon {
  display: none
}

.wp-block-navigation__responsive-container.is-menu-open .wp-block-navigation__responsive-container-content .has-child .wp-block-navigation__submenu-container {
  border: none;
  height: auto;
  min-width: 200px;
  opacity: 1;
  overflow: initial;
  padding-left: 2rem;
  padding-right: 2rem;
  position: static;
  visibility: visible;
  width: auto
}

.wp-block-navigation__responsive-container.is-menu-open .wp-block-navigation__responsive-container-content .wp-block-navigation__container,
.wp-block-navigation__responsive-container.is-menu-open .wp-block-navigation__responsive-container-content .wp-block-navigation__submenu-container {
  gap: inherit
}

.wp-block-navigation__responsive-container.is-menu-open .wp-block-navigation__responsive-container-content .wp-block-navigation__submenu-container {
  padding-top: var(--wp--style--block-gap, 2em)
}

.wp-block-navigation__responsive-container.is-menu-open .wp-block-navigation__responsive-container-content .wp-block-navigation-item__content {
  padding: 0
}

.wp-block-navigation__responsive-container.is-menu-open .wp-block-navigation__responsive-container-content .wp-block-navigation-item,
.wp-block-navigation__responsive-container.is-menu-open .wp-block-navigation__responsive-container-content .wp-block-navigation__container,
.wp-block-navigation__responsive-container.is-menu-open .wp-block-navigation__responsive-container-content .wp-block-page-list {
  align-items: var(--navigation-layout-justification-setting, initial);
  display: flex;
  flex-direction: column
}

.wp-block-navigation__responsive-container.is-menu-open .wp-block-navigation-item,
.wp-block-navigation__responsive-container.is-menu-open .wp-block-navigation-item .wp-block-navigation__submenu-container,
.wp-block-navigation__responsive-container.is-menu-open .wp-block-navigation__container,
.wp-block-navigation__responsive-container.is-menu-open .wp-block-page-list {
  background: #0000 !important;
  color: inherit !important
}

.wp-block-navigation__responsive-container.is-menu-open .wp-block-navigation__submenu-container.wp-block-navigation__submenu-container.wp-block-navigation__submenu-container.wp-block-navigation__submenu-container {
  left: auto;
  right: auto
}

@media (min-width:600px) {
  .wp-block-navigation__responsive-container:not(.hidden-by-default):not(.is-menu-open) {
    background-color: inherit;
    display: block;
    position: relative;
    width: 100%;
    z-index: auto
  }

  .wp-block-navigation__responsive-container:not(.hidden-by-default):not(.is-menu-open) .wp-block-navigation__responsive-container-close {
    display: none
  }

  .wp-block-navigation__responsive-container.is-menu-open .wp-block-navigation__submenu-container.wp-block-navigation__submenu-container.wp-block-navigation__submenu-container.wp-block-navigation__submenu-container {
    left: 0
  }
}

.wp-block-navigation:not(.has-background) .wp-block-navigation__responsive-container.is-menu-open {
  background-color: #fff
}

.wp-block-navigation:not(.has-text-color) .wp-block-navigation__responsive-container.is-menu-open {
  color: #000
}

.wp-block-navigation__toggle_button_label {
  font-size: 1rem;
  font-weight: 700
}

.wp-block-navigation__responsive-container-close,
.wp-block-navigation__responsive-container-open {
  background: #0000;
  border: none;
  color: currentColor;
  cursor: pointer;
  margin: 0;
  padding: 0;
  text-transform: inherit;
  vertical-align: middle
}

.wp-block-navigation__responsive-container-close svg,
.wp-block-navigation__responsive-container-open svg {
  fill: currentColor;
  display: block;
  height: 24px;
  pointer-events: none;
  width: 24px
}

.wp-block-navigation__responsive-container-open {
  display: flex
}

.wp-block-navigation__responsive-container-open.wp-block-navigation__responsive-container-open.wp-block-navigation__responsive-container-open {
  font-family: inherit;
  font-size: inherit;
  font-weight: inherit
}

@media (min-width:600px) {
  .wp-block-navigation__responsive-container-open:not(.always-shown) {
    display: none
  }
}

.wp-block-navigation__responsive-container-close {
  position: absolute;
  right: 0;
  top: 0;
  z-index: 2
}

.wp-block-navigation__responsive-container-close.wp-block-navigation__responsive-container-close.wp-block-navigation__responsive-container-close {
  font-family: inherit;
  font-size: inherit;
  font-weight: inherit
}

.wp-block-navigation__responsive-close {
  width: 100%
}

.has-modal-open .wp-block-navigation__responsive-close {
  margin-left: auto;
  margin-right: auto;
  max-width: var(--wp--style--global--wide-size, 100%)
}

.wp-block-navigation__responsive-close:focus {
  outline: none
}

.is-menu-open .wp-block-navigation__responsive-close,
.is-menu-open .wp-block-navigation__responsive-container-content,
.is-menu-open .wp-block-navigation__responsive-dialog {
  box-sizing: border-box
}

.wp-block-navigation__responsive-dialog {
  position: relative
}

.has-modal-open .admin-bar .is-menu-open .wp-block-navigation__responsive-dialog {
  margin-top: 46px
}

@media (min-width:782px) {
  .has-modal-open .admin-bar .is-menu-open .wp-block-navigation__responsive-dialog {
    margin-top: 32px
  }
}

html.has-modal-open {
  overflow: hidden
}

.wp-block-navigation .wp-block-navigation-item__label {
  overflow-wrap: break-word
}

.wp-block-navigation .wp-block-navigation-item__description {
  display: none
}

.link-ui-tools {
  border-top: 1px solid #f0f0f0;
  padding: 8px
}

.link-ui-block-inserter {
  padding-top: 8px
}

.link-ui-block-inserter__back {
  margin-left: 8px;
  text-transform: uppercase
}

.wp-block-navigation .wp-block-page-list {
  align-items: var(--navigation-layout-align, initial);
  background-color: inherit;
  display: flex;
  flex-direction: var(--navigation-layout-direction, initial);
  flex-wrap: var(--navigation-layout-wrap, wrap);
  justify-content: var(--navigation-layout-justify, initial)
}

.wp-block-navigation .wp-block-navigation-item {
  background-color: inherit
}

.is-small-text {
  font-size: .875em
}

.is-regular-text {
  font-size: 1em
}

.is-large-text {
  font-size: 2.25em
}

.is-larger-text {
  font-size: 3em
}

.has-drop-cap:not(:focus):first-letter {
  float: left;
  font-size: 8.4em;
  font-style: normal;
  font-weight: 100;
  line-height: .68;
  margin: .05em .1em 0 0;
  text-transform: uppercase
}

body.rtl .has-drop-cap:not(:focus):first-letter {
  float: none;
  margin-left: .1em
}

p.has-drop-cap.has-background {
  overflow: hidden
}

:root :where(p.has-background) {
  padding: 1.25em 2.375em
}

:where(p.has-text-color:not(.has-link-color)) a {
  color: inherit
}

p.has-text-align-left[style*="writing-mode:vertical-lr"],
p.has-text-align-right[style*="writing-mode:vertical-rl"] {
  rotate: 180deg
}

.wp-block-post-author {
  box-sizing: border-box;
  display: flex;
  flex-wrap: wrap
}

.wp-block-post-author__byline {
  font-size: .5em;
  margin-bottom: 0;
  margin-top: 0;
  width: 100%
}

.wp-block-post-author__avatar {
  margin-right: 1em
}

.wp-block-post-author__bio {
  font-size: .7em;
  margin-bottom: .7em
}

.wp-block-post-author__content {
  flex-basis: 0;
  flex-grow: 1
}

.wp-block-post-author__name {
  margin: 0
}

.wp-block-post-author-biography {
  box-sizing: border-box
}

:where(.wp-block-post-comments-form) input:not([type=submit]),
:where(.wp-block-post-comments-form) textarea {
  border: 1px solid #949494;
  font-family: inherit;
  font-size: 1em
}

:where(.wp-block-post-comments-form) input:where(:not([type=submit]):not([type=checkbox])),
:where(.wp-block-post-comments-form) textarea {
  padding: calc(.667em + 2px)
}

.wp-block-post-comments-form {
  box-sizing: border-box
}

.wp-block-post-comments-form[style*=font-weight] :where(.comment-reply-title) {
  font-weight: inherit
}

.wp-block-post-comments-form[style*=font-family] :where(.comment-reply-title) {
  font-family: inherit
}

.wp-block-post-comments-form[class*=-font-size] :where(.comment-reply-title),
.wp-block-post-comments-form[style*=font-size] :where(.comment-reply-title) {
  font-size: inherit
}

.wp-block-post-comments-form[style*=line-height] :where(.comment-reply-title) {
  line-height: inherit
}

.wp-block-post-comments-form[style*=font-style] :where(.comment-reply-title) {
  font-style: inherit
}

.wp-block-post-comments-form[style*=letter-spacing] :where(.comment-reply-title) {
  letter-spacing: inherit
}

.wp-block-post-comments-form :where(input[type=submit]) {
  box-shadow: none;
  cursor: pointer;
  display: inline-block;
  overflow-wrap: break-word;
  text-align: center
}

.wp-block-post-comments-form .comment-form input:not([type=submit]):not([type=checkbox]):not([type=hidden]),
.wp-block-post-comments-form .comment-form textarea {
  box-sizing: border-box;
  display: block;
  width: 100%
}

.wp-block-post-comments-form .comment-form-author label,
.wp-block-post-comments-form .comment-form-email label,
.wp-block-post-comments-form .comment-form-url label {
  display: block;
  margin-bottom: .25em
}

.wp-block-post-comments-form .comment-form-cookies-consent {
  display: flex;
  gap: .25em
}

.wp-block-post-comments-form .comment-form-cookies-consent #wp-comment-cookies-consent {
  margin-top: .35em
}

.wp-block-post-comments-form .comment-reply-title {
  margin-bottom: 0
}

.wp-block-post-comments-form .comment-reply-title :where(small) {
  font-size: var(--wp--preset--font-size--medium, smaller);
  margin-left: .5em
}

.wp-block-post-content {
  display: flow-root
}

.wp-block-post-date {
  box-sizing: border-box
}

:where(.wp-block-post-excerpt) {
  box-sizing: border-box;
  margin-bottom: var(--wp--style--block-gap);
  margin-top: var(--wp--style--block-gap)
}

.wp-block-post-excerpt__excerpt {
  margin-bottom: 0;
  margin-top: 0
}

.wp-block-post-excerpt__more-text {
  margin-bottom: 0;
  margin-top: var(--wp--style--block-gap)
}

.wp-block-post-excerpt__more-link {
  display: inline-block
}

.wp-block-post-featured-image {
  margin-left: 0;
  margin-right: 0
}

.wp-block-post-featured-image a {
  display: block;
  height: 100%
}

.wp-block-post-featured-image :where(img) {
  box-sizing: border-box;
  height: auto;
  max-width: 100%;
  vertical-align: bottom;
  width: 100%
}

.wp-block-post-featured-image.alignfull img,
.wp-block-post-featured-image.alignwide img {
  width: 100%
}

.wp-block-post-featured-image .wp-block-post-featured-image__overlay.has-background-dim {
  background-color: #000;
  inset: 0;
  position: absolute
}

.wp-block-post-featured-image {
  position: relative
}

.wp-block-post-featured-image .wp-block-post-featured-image__overlay.has-background-gradient {
  background-color: initial
}

.wp-block-post-featured-image .wp-block-post-featured-image__overlay.has-background-dim-0 {
  opacity: 0
}

.wp-block-post-featured-image .wp-block-post-featured-image__overlay.has-background-dim-10 {
  opacity: .1
}

.wp-block-post-featured-image .wp-block-post-featured-image__overlay.has-background-dim-20 {
  opacity: .2
}

.wp-block-post-featured-image .wp-block-post-featured-image__overlay.has-background-dim-30 {
  opacity: .3
}

.wp-block-post-featured-image .wp-block-post-featured-image__overlay.has-background-dim-40 {
  opacity: .4
}

.wp-block-post-featured-image .wp-block-post-featured-image__overlay.has-background-dim-50 {
  opacity: .5
}

.wp-block-post-featured-image .wp-block-post-featured-image__overlay.has-background-dim-60 {
  opacity: .6
}

.wp-block-post-featured-image .wp-block-post-featured-image__overlay.has-background-dim-70 {
  opacity: .7
}

.wp-block-post-featured-image .wp-block-post-featured-image__overlay.has-background-dim-80 {
  opacity: .8
}

.wp-block-post-featured-image .wp-block-post-featured-image__overlay.has-background-dim-90 {
  opacity: .9
}

.wp-block-post-featured-image .wp-block-post-featured-image__overlay.has-background-dim-100 {
  opacity: 1
}

.wp-block-post-featured-image:where(.alignleft, .alignright) {
  width: 100%
}

.wp-block-post-navigation-link .wp-block-post-navigation-link__arrow-previous {
  display: inline-block;
  margin-right: 1ch
}

.wp-block-post-navigation-link .wp-block-post-navigation-link__arrow-previous:not(.is-arrow-chevron) {
  transform: scaleX(1)
}

.wp-block-post-navigation-link .wp-block-post-navigation-link__arrow-next {
  display: inline-block;
  margin-left: 1ch
}

.wp-block-post-navigation-link .wp-block-post-navigation-link__arrow-next:not(.is-arrow-chevron) {
  transform: scaleX(1)
}

.wp-block-post-navigation-link.has-text-align-left[style*="writing-mode: vertical-lr"],
.wp-block-post-navigation-link.has-text-align-right[style*="writing-mode: vertical-rl"] {
  rotate: 180deg
}

.wp-block-post-terms {
  box-sizing: border-box
}

.wp-block-post-terms .wp-block-post-terms__separator {
  white-space: pre-wrap
}

.wp-block-post-time-to-read,
.wp-block-post-title {
  box-sizing: border-box
}

.wp-block-post-title {
  word-break: break-word
}

.wp-block-post-title :where(a) {
  display: inline-block;
  font-family: inherit;
  font-size: inherit;
  font-style: inherit;
  font-weight: inherit;
  letter-spacing: inherit;
  line-height: inherit;
  text-decoration: inherit
}

.wp-block-post-author-name {
  box-sizing: border-box
}

.wp-block-preformatted {
  box-sizing: border-box;
  white-space: pre-wrap
}

:where(.wp-block-preformatted.has-background) {
  padding: 1.25em 2.375em
}

.wp-block-pullquote {
  box-sizing: border-box;
  margin: 0 0 1em;
  overflow-wrap: break-word;
  padding: 4em 0;
  text-align: center
}

.wp-block-pullquote blockquote,
.wp-block-pullquote cite,
.wp-block-pullquote p {
  color: inherit
}

.wp-block-pullquote blockquote {
  margin: 0
}

.wp-block-pullquote p {
  margin-top: 0
}

.wp-block-pullquote p:last-child {
  margin-bottom: 0
}

.wp-block-pullquote.alignleft,
.wp-block-pullquote.alignright {
  max-width: 420px
}

.wp-block-pullquote cite,
.wp-block-pullquote footer {
  position: relative
}

.wp-block-pullquote .has-text-color a {
  color: inherit
}

.wp-block-pullquote.has-text-align-left blockquote {
  text-align: left
}

.wp-block-pullquote.has-text-align-right blockquote {
  text-align: right
}

.wp-block-pullquote.is-style-solid-color {
  border: none
}

.wp-block-pullquote.is-style-solid-color blockquote {
  margin-left: auto;
  margin-right: auto;
  max-width: 60%
}

.wp-block-pullquote.is-style-solid-color blockquote p {
  font-size: 2em;
  margin-bottom: 0;
  margin-top: 0
}

.wp-block-pullquote.is-style-solid-color blockquote cite {
  font-style: normal;
  text-transform: none
}

.wp-block-pullquote cite {
  color: inherit
}

.wp-block-post-template {
  list-style: none;
  margin-bottom: 0;
  margin-top: 0;
  max-width: 100%;
  padding: 0
}

.wp-block-post-template.is-flex-container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 1.25em
}

.wp-block-post-template.is-flex-container>li {
  margin: 0;
  width: 100%
}

@media (min-width:600px) {
  .wp-block-post-template.is-flex-container.is-flex-container.columns-2>li {
    width: calc(50% - .625em)
  }

  .wp-block-post-template.is-flex-container.is-flex-container.columns-3>li {
    width: calc(33.33333% - .83333em)
  }

  .wp-block-post-template.is-flex-container.is-flex-container.columns-4>li {
    width: calc(25% - .9375em)
  }

  .wp-block-post-template.is-flex-container.is-flex-container.columns-5>li {
    width: calc(20% - 1em)
  }

  .wp-block-post-template.is-flex-container.is-flex-container.columns-6>li {
    width: calc(16.66667% - 1.04167em)
  }
}

@media (max-width:600px) {
  .wp-block-post-template-is-layout-grid.wp-block-post-template-is-layout-grid.wp-block-post-template-is-layout-grid.wp-block-post-template-is-layout-grid {
    grid-template-columns: 1fr
  }
}

.wp-block-post-template-is-layout-constrained>li>.alignright,
.wp-block-post-template-is-layout-flow>li>.alignright {
  float: right;
  margin-inline-end: 0;
  margin-inline-start: 2em
}

.wp-block-post-template-is-layout-constrained>li>.alignleft,
.wp-block-post-template-is-layout-flow>li>.alignleft {
  float: left;
  margin-inline-end: 2em;
  margin-inline-start: 0
}

.wp-block-post-template-is-layout-constrained>li>.aligncenter,
.wp-block-post-template-is-layout-flow>li>.aligncenter {
  margin-inline-end: auto;
  margin-inline-start: auto
}

.wp-block-query-pagination.is-content-justification-space-between>.wp-block-query-pagination-next:last-of-type {
  margin-inline-start: auto
}

.wp-block-query-pagination.is-content-justification-space-between>.wp-block-query-pagination-previous:first-child {
  margin-inline-end: auto
}

.wp-block-query-pagination .wp-block-query-pagination-previous-arrow {
  display: inline-block;
  margin-right: 1ch
}

.wp-block-query-pagination .wp-block-query-pagination-previous-arrow:not(.is-arrow-chevron) {
  transform: scaleX(1)
}

.wp-block-query-pagination .wp-block-query-pagination-next-arrow {
  display: inline-block;
  margin-left: 1ch
}

.wp-block-query-pagination .wp-block-query-pagination-next-arrow:not(.is-arrow-chevron) {
  transform: scaleX(1)
}

.wp-block-query-pagination.aligncenter {
  justify-content: center
}

.wp-block-query-title,
.wp-block-quote {
  box-sizing: border-box
}

.wp-block-quote {
  overflow-wrap: break-word
}

.wp-block-quote.is-large:where(:not(.is-style-plain)),
.wp-block-quote.is-style-large:where(:not(.is-style-plain)) {
  margin-bottom: 1em;
  padding: 0 1em
}

.wp-block-quote.is-large:where(:not(.is-style-plain)) p,
.wp-block-quote.is-style-large:where(:not(.is-style-plain)) p {
  font-size: 1.5em;
  font-style: italic;
  line-height: 1.6
}

.wp-block-quote.is-large:where(:not(.is-style-plain)) cite,
.wp-block-quote.is-large:where(:not(.is-style-plain)) footer,
.wp-block-quote.is-style-large:where(:not(.is-style-plain)) cite,
.wp-block-quote.is-style-large:where(:not(.is-style-plain)) footer {
  font-size: 1.125em;
  text-align: right
}

.wp-block-quote>cite {
  display: block
}

.wp-block-read-more {
  display: block;
  width: -moz-fit-content;
  width: fit-content
}

.wp-block-read-more:where(:not([style*=text-decoration])) {
  text-decoration: none
}

.wp-block-read-more:where(:not([style*=text-decoration])):active,
.wp-block-read-more:where(:not([style*=text-decoration])):focus {
  text-decoration: none
}

ul.wp-block-rss {
  list-style: none;
  padding: 0
}

ul.wp-block-rss.wp-block-rss {
  box-sizing: border-box
}

ul.wp-block-rss.alignleft {
  margin-right: 2em
}

ul.wp-block-rss.alignright {
  margin-left: 2em
}

ul.wp-block-rss.is-grid {
  display: flex;
  flex-wrap: wrap;
  list-style: none;
  padding: 0
}

ul.wp-block-rss.is-grid li {
  margin: 0 1em 1em 0;
  width: 100%
}

@media (min-width:600px) {
  ul.wp-block-rss.columns-2 li {
    width: calc(50% - 1em)
  }

  ul.wp-block-rss.columns-3 li {
    width: calc(33.33333% - 1em)
  }

  ul.wp-block-rss.columns-4 li {
    width: calc(25% - 1em)
  }

  ul.wp-block-rss.columns-5 li {
    width: calc(20% - 1em)
  }

  ul.wp-block-rss.columns-6 li {
    width: calc(16.66667% - 1em)
  }
}

.wp-block-rss__item-author,
.wp-block-rss__item-publish-date {
  display: block;
  font-size: .8125em
}

.wp-block-search__button {
  margin-left: 10px;
  word-break: normal
}

.wp-block-search__button.has-icon {
  line-height: 0
}

.wp-block-search__button svg {
  height: 1.25em;
  min-height: 24px;
  min-width: 24px;
  width: 1.25em;
  fill: currentColor;
  vertical-align: text-bottom
}

:where(.wp-block-search__button) {
  border: 1px solid #ccc;
  padding: 6px 10px
}

.wp-block-search__inside-wrapper {
  display: flex;
  flex: auto;
  flex-wrap: nowrap;
  max-width: 100%
}

.wp-block-search__label {
  width: 100%
}

.wp-block-search__input {
  appearance: none;
  border: 1px solid #949494;
  flex-grow: 1;
  margin-left: 0;
  margin-right: 0;
  min-width: 3rem;
  padding: 8px;
  text-decoration: unset !important
}

.wp-block-search.wp-block-search__button-only .wp-block-search__button {
  flex-shrink: 0;
  margin-left: 0;
  max-width: 100%
}

.wp-block-search.wp-block-search__button-only .wp-block-search__button[aria-expanded=true] {
  max-width: calc(100% - 100px)
}

.wp-block-search.wp-block-search__button-only .wp-block-search__inside-wrapper {
  min-width: 0 !important;
  transition-property: width
}

.wp-block-search.wp-block-search__button-only .wp-block-search__input {
  flex-basis: 100%;
  transition-duration: .3s
}

.wp-block-search.wp-block-search__button-only.wp-block-search__searchfield-hidden,
.wp-block-search.wp-block-search__button-only.wp-block-search__searchfield-hidden .wp-block-search__inside-wrapper {
  overflow: hidden
}

.wp-block-search.wp-block-search__button-only.wp-block-search__searchfield-hidden .wp-block-search__input {
  border-left-width: 0 !important;
  border-right-width: 0 !important;
  flex-basis: 0;
  flex-grow: 0;
  margin: 0;
  min-width: 0 !important;
  padding-left: 0 !important;
  padding-right: 0 !important;
  width: 0 !important
}

:where(.wp-block-search__input) {
  font-family: inherit;
  font-size: inherit;
  font-style: inherit;
  font-weight: inherit;
  letter-spacing: inherit;
  line-height: inherit;
  text-transform: inherit
}

:where(.wp-block-search__button-inside .wp-block-search__inside-wrapper) {
  border: 1px solid #949494;
  box-sizing: border-box;
  padding: 4px
}

:where(.wp-block-search__button-inside .wp-block-search__inside-wrapper) .wp-block-search__input {
  border: none;
  border-radius: 0;
  padding: 0 4px
}

:where(.wp-block-search__button-inside .wp-block-search__inside-wrapper) .wp-block-search__input:focus {
  outline: none
}

:where(.wp-block-search__button-inside .wp-block-search__inside-wrapper) :where(.wp-block-search__button) {
  padding: 4px 8px
}

.wp-block-search.aligncenter .wp-block-search__inside-wrapper {
  margin: auto
}

.wp-block[data-align=right] .wp-block-search.wp-block-search__button-only .wp-block-search__inside-wrapper {
  float: right
}

.wp-block-separator {
  border: none;
  border-top: 2px solid
}

:root :where(.wp-block-separator.is-style-dots) {
  height: auto;
  line-height: 1;
  text-align: center
}

:root :where(.wp-block-separator.is-style-dots):before {
  color: currentColor;
  content: "···";
  font-family: serif;
  font-size: 1.5em;
  letter-spacing: 2em;
  padding-left: 2em
}

.wp-block-separator.is-style-dots {
  background: none !important;
  border: none !important
}

.wp-block-site-logo {
  box-sizing: border-box;
  line-height: 0
}

.wp-block-site-logo a {
  display: inline-block;
  line-height: 0
}

.wp-block-site-logo.is-default-size img {
  height: auto;
  width: 120px
}

.wp-block-site-logo img {
  height: auto;
  max-width: 100%
}

.wp-block-site-logo a,
.wp-block-site-logo img {
  border-radius: inherit
}

.wp-block-site-logo.aligncenter {
  margin-left: auto;
  margin-right: auto;
  text-align: center
}

:root :where(.wp-block-site-logo.is-style-rounded) {
  border-radius: 9999px
}

.wp-block-site-tagline,
.wp-block-site-title {
  box-sizing: border-box
}

.wp-block-site-title :where(a) {
  color: inherit;
  font-family: inherit;
  font-size: inherit;
  font-style: inherit;
  font-weight: inherit;
  letter-spacing: inherit;
  line-height: inherit;
  text-decoration: inherit
}

.wp-block-social-links {
  background: none;
  box-sizing: border-box;
  margin-left: 0;
  padding-left: 0;
  padding-right: 0;
  text-indent: 0
}

.wp-block-social-links .wp-social-link a,
.wp-block-social-links .wp-social-link a:hover {
  border-bottom: 0;
  box-shadow: none;
  text-decoration: none
}

.wp-block-social-links .wp-social-link svg {
  height: 1em;
  width: 1em
}

.wp-block-social-links .wp-social-link span:not(.screen-reader-text) {
  font-size: .65em;
  margin-left: .5em;
  margin-right: .5em
}

.wp-block-social-links.has-small-icon-size {
  font-size: 16px
}

.wp-block-social-links,
.wp-block-social-links.has-normal-icon-size {
  font-size: 24px
}

.wp-block-social-links.has-large-icon-size {
  font-size: 36px
}

.wp-block-social-links.has-huge-icon-size {
  font-size: 48px
}

.wp-block-social-links.aligncenter {
  display: flex;
  justify-content: center
}

.wp-block-social-links.alignright {
  justify-content: flex-end
}

.wp-block-social-link {
  border-radius: 9999px;
  display: block;
  height: auto;
  transition: transform .1s ease
}

@media (prefers-reduced-motion:reduce) {
  .wp-block-social-link {
    transition-delay: 0s;
    transition-duration: 0s
  }
}

.wp-block-social-link a {
  align-items: center;
  display: flex;
  line-height: 0;
  transition: transform .1s ease
}

.wp-block-social-link:hover {
  transform: scale(1.1)
}

.wp-block-social-links .wp-block-social-link.wp-social-link {
  display: inline-block;
  margin: 0;
  padding: 0
}

.wp-block-social-links .wp-block-social-link.wp-social-link .wp-block-social-link-anchor,
.wp-block-social-links .wp-block-social-link.wp-social-link .wp-block-social-link-anchor svg,
.wp-block-social-links .wp-block-social-link.wp-social-link .wp-block-social-link-anchor:active,
.wp-block-social-links .wp-block-social-link.wp-social-link .wp-block-social-link-anchor:hover,
.wp-block-social-links .wp-block-social-link.wp-social-link .wp-block-social-link-anchor:visited {
  color: currentColor;
  fill: currentColor
}

:where(.wp-block-social-links:not(.is-style-logos-only)) .wp-social-link {
  background-color: #f0f0f0;
  color: #444
}

:where(.wp-block-social-links:not(.is-style-logos-only)) .wp-social-link-amazon {
  background-color: #f90;
  color: #fff
}

:where(.wp-block-social-links:not(.is-style-logos-only)) .wp-social-link-bandcamp {
  background-color: #1ea0c3;
  color: #fff
}

:where(.wp-block-social-links:not(.is-style-logos-only)) .wp-social-link-behance {
  background-color: #0757fe;
  color: #fff
}

:where(.wp-block-social-links:not(.is-style-logos-only)) .wp-social-link-bluesky {
  background-color: #0a7aff;
  color: #fff
}

:where(.wp-block-social-links:not(.is-style-logos-only)) .wp-social-link-codepen {
  background-color: #1e1f26;
  color: #fff
}

:where(.wp-block-social-links:not(.is-style-logos-only)) .wp-social-link-deviantart {
  background-color: #02e49b;
  color: #fff
}

:where(.wp-block-social-links:not(.is-style-logos-only)) .wp-social-link-dribbble {
  background-color: #e94c89;
  color: #fff
}

:where(.wp-block-social-links:not(.is-style-logos-only)) .wp-social-link-dropbox {
  background-color: #4280ff;
  color: #fff
}

:where(.wp-block-social-links:not(.is-style-logos-only)) .wp-social-link-etsy {
  background-color: #f45800;
  color: #fff
}

:where(.wp-block-social-links:not(.is-style-logos-only)) .wp-social-link-facebook {
  background-color: #0866ff;
  color: #fff
}

:where(.wp-block-social-links:not(.is-style-logos-only)) .wp-social-link-fivehundredpx {
  background-color: #000;
  color: #fff
}

:where(.wp-block-social-links:not(.is-style-logos-only)) .wp-social-link-flickr {
  background-color: #0461dd;
  color: #fff
}

:where(.wp-block-social-links:not(.is-style-logos-only)) .wp-social-link-foursquare {
  background-color: #e65678;
  color: #fff
}

:where(.wp-block-social-links:not(.is-style-logos-only)) .wp-social-link-github {
  background-color: #24292d;
  color: #fff
}

:where(.wp-block-social-links:not(.is-style-logos-only)) .wp-social-link-goodreads {
  background-color: #eceadd;
  color: #382110
}

:where(.wp-block-social-links:not(.is-style-logos-only)) .wp-social-link-google {
  background-color: #ea4434;
  color: #fff
}

:where(.wp-block-social-links:not(.is-style-logos-only)) .wp-social-link-gravatar {
  background-color: #1d4fc4;
  color: #fff
}

:where(.wp-block-social-links:not(.is-style-logos-only)) .wp-social-link-instagram {
  background-color: #f00075;
  color: #fff
}

:where(.wp-block-social-links:not(.is-style-logos-only)) .wp-social-link-lastfm {
  background-color: #e21b24;
  color: #fff
}

:where(.wp-block-social-links:not(.is-style-logos-only)) .wp-social-link-linkedin {
  background-color: #0d66c2;
  color: #fff
}

:where(.wp-block-social-links:not(.is-style-logos-only)) .wp-social-link-mastodon {
  background-color: #3288d4;
  color: #fff
}

:where(.wp-block-social-links:not(.is-style-logos-only)) .wp-social-link-medium {
  background-color: #000;
  color: #fff
}

:where(.wp-block-social-links:not(.is-style-logos-only)) .wp-social-link-meetup {
  background-color: #f6405f;
  color: #fff
}

:where(.wp-block-social-links:not(.is-style-logos-only)) .wp-social-link-patreon {
  background-color: #000;
  color: #fff
}

:where(.wp-block-social-links:not(.is-style-logos-only)) .wp-social-link-pinterest {
  background-color: #e60122;
  color: #fff
}

:where(.wp-block-social-links:not(.is-style-logos-only)) .wp-social-link-pocket {
  background-color: #ef4155;
  color: #fff
}

:where(.wp-block-social-links:not(.is-style-logos-only)) .wp-social-link-reddit {
  background-color: #ff4500;
  color: #fff
}

:where(.wp-block-social-links:not(.is-style-logos-only)) .wp-social-link-skype {
  background-color: #0478d7;
  color: #fff
}

:where(.wp-block-social-links:not(.is-style-logos-only)) .wp-social-link-snapchat {
  background-color: #fefc00;
  color: #fff;
  stroke: #000
}

:where(.wp-block-social-links:not(.is-style-logos-only)) .wp-social-link-soundcloud {
  background-color: #ff5600;
  color: #fff
}

:where(.wp-block-social-links:not(.is-style-logos-only)) .wp-social-link-spotify {
  background-color: #1bd760;
  color: #fff
}

:where(.wp-block-social-links:not(.is-style-logos-only)) .wp-social-link-telegram {
  background-color: #2aabee;
  color: #fff
}

:where(.wp-block-social-links:not(.is-style-logos-only)) .wp-social-link-threads {
  background-color: #000;
  color: #fff
}

:where(.wp-block-social-links:not(.is-style-logos-only)) .wp-social-link-tiktok {
  background-color: #000;
  color: #fff
}

:where(.wp-block-social-links:not(.is-style-logos-only)) .wp-social-link-tumblr {
  background-color: #011835;
  color: #fff
}

:where(.wp-block-social-links:not(.is-style-logos-only)) .wp-social-link-twitch {
  background-color: #6440a4;
  color: #fff
}

:where(.wp-block-social-links:not(.is-style-logos-only)) .wp-social-link-twitter {
  background-color: #1da1f2;
  color: #fff
}

:where(.wp-block-social-links:not(.is-style-logos-only)) .wp-social-link-vimeo {
  background-color: #1eb7ea;
  color: #fff
}

:where(.wp-block-social-links:not(.is-style-logos-only)) .wp-social-link-vk {
  background-color: #4680c2;
  color: #fff
}

:where(.wp-block-social-links:not(.is-style-logos-only)) .wp-social-link-wordpress {
  background-color: #3499cd;
  color: #fff
}

:where(.wp-block-social-links:not(.is-style-logos-only)) .wp-social-link-whatsapp {
  background-color: #25d366;
  color: #fff
}

:where(.wp-block-social-links:not(.is-style-logos-only)) .wp-social-link-x {
  background-color: #000;
  color: #fff
}

:where(.wp-block-social-links:not(.is-style-logos-only)) .wp-social-link-yelp {
  background-color: #d32422;
  color: #fff
}

:where(.wp-block-social-links:not(.is-style-logos-only)) .wp-social-link-youtube {
  background-color: red;
  color: #fff
}

:where(.wp-block-social-links.is-style-logos-only) .wp-social-link {
  background: none
}

:where(.wp-block-social-links.is-style-logos-only) .wp-social-link svg {
  height: 1.25em;
  width: 1.25em
}

:where(.wp-block-social-links.is-style-logos-only) .wp-social-link-amazon {
  color: #f90
}

:where(.wp-block-social-links.is-style-logos-only) .wp-social-link-bandcamp {
  color: #1ea0c3
}

:where(.wp-block-social-links.is-style-logos-only) .wp-social-link-behance {
  color: #0757fe
}

:where(.wp-block-social-links.is-style-logos-only) .wp-social-link-bluesky {
  color: #0a7aff
}

:where(.wp-block-social-links.is-style-logos-only) .wp-social-link-codepen {
  color: #1e1f26
}

:where(.wp-block-social-links.is-style-logos-only) .wp-social-link-deviantart {
  color: #02e49b
}

:where(.wp-block-social-links.is-style-logos-only) .wp-social-link-dribbble {
  color: #e94c89
}

:where(.wp-block-social-links.is-style-logos-only) .wp-social-link-dropbox {
  color: #4280ff
}

:where(.wp-block-social-links.is-style-logos-only) .wp-social-link-etsy {
  color: #f45800
}

:where(.wp-block-social-links.is-style-logos-only) .wp-social-link-facebook {
  color: #0866ff
}

:where(.wp-block-social-links.is-style-logos-only) .wp-social-link-fivehundredpx {
  color: #000
}

:where(.wp-block-social-links.is-style-logos-only) .wp-social-link-flickr {
  color: #0461dd
}

:where(.wp-block-social-links.is-style-logos-only) .wp-social-link-foursquare {
  color: #e65678
}

:where(.wp-block-social-links.is-style-logos-only) .wp-social-link-github {
  color: #24292d
}

:where(.wp-block-social-links.is-style-logos-only) .wp-social-link-goodreads {
  color: #382110
}

:where(.wp-block-social-links.is-style-logos-only) .wp-social-link-google {
  color: #ea4434
}

:where(.wp-block-social-links.is-style-logos-only) .wp-social-link-gravatar {
  color: #1d4fc4
}

:where(.wp-block-social-links.is-style-logos-only) .wp-social-link-instagram {
  color: #f00075
}

:where(.wp-block-social-links.is-style-logos-only) .wp-social-link-lastfm {
  color: #e21b24
}

:where(.wp-block-social-links.is-style-logos-only) .wp-social-link-linkedin {
  color: #0d66c2
}

:where(.wp-block-social-links.is-style-logos-only) .wp-social-link-mastodon {
  color: #3288d4
}

:where(.wp-block-social-links.is-style-logos-only) .wp-social-link-medium {
  color: #000
}

:where(.wp-block-social-links.is-style-logos-only) .wp-social-link-meetup {
  color: #f6405f
}

:where(.wp-block-social-links.is-style-logos-only) .wp-social-link-patreon {
  color: #000
}

:where(.wp-block-social-links.is-style-logos-only) .wp-social-link-pinterest {
  color: #e60122
}

:where(.wp-block-social-links.is-style-logos-only) .wp-social-link-pocket {
  color: #ef4155
}

:where(.wp-block-social-links.is-style-logos-only) .wp-social-link-reddit {
  color: #ff4500
}

:where(.wp-block-social-links.is-style-logos-only) .wp-social-link-skype {
  color: #0478d7
}

:where(.wp-block-social-links.is-style-logos-only) .wp-social-link-snapchat {
  color: #fff;
  stroke: #000
}

:where(.wp-block-social-links.is-style-logos-only) .wp-social-link-soundcloud {
  color: #ff5600
}

:where(.wp-block-social-links.is-style-logos-only) .wp-social-link-spotify {
  color: #1bd760
}

:where(.wp-block-social-links.is-style-logos-only) .wp-social-link-telegram {
  color: #2aabee
}

:where(.wp-block-social-links.is-style-logos-only) .wp-social-link-threads {
  color: #000
}

:where(.wp-block-social-links.is-style-logos-only) .wp-social-link-tiktok {
  color: #000
}

:where(.wp-block-social-links.is-style-logos-only) .wp-social-link-tumblr {
  color: #011835
}

:where(.wp-block-social-links.is-style-logos-only) .wp-social-link-twitch {
  color: #6440a4
}

:where(.wp-block-social-links.is-style-logos-only) .wp-social-link-twitter {
  color: #1da1f2
}

:where(.wp-block-social-links.is-style-logos-only) .wp-social-link-vimeo {
  color: #1eb7ea
}

:where(.wp-block-social-links.is-style-logos-only) .wp-social-link-vk {
  color: #4680c2
}

:where(.wp-block-social-links.is-style-logos-only) .wp-social-link-whatsapp {
  color: #25d366
}

:where(.wp-block-social-links.is-style-logos-only) .wp-social-link-wordpress {
  color: #3499cd
}

:where(.wp-block-social-links.is-style-logos-only) .wp-social-link-x {
  color: #000
}

:where(.wp-block-social-links.is-style-logos-only) .wp-social-link-yelp {
  color: #d32422
}

:where(.wp-block-social-links.is-style-logos-only) .wp-social-link-youtube {
  color: red
}

.wp-block-social-links.is-style-pill-shape .wp-social-link {
  width: auto
}

:root :where(.wp-block-social-links .wp-social-link a) {
  padding: .25em
}

:root :where(.wp-block-social-links.is-style-logos-only .wp-social-link a) {
  padding: 0
}

:root :where(.wp-block-social-links.is-style-pill-shape .wp-social-link a) {
  padding-left: .66667em;
  padding-right: .66667em
}

.wp-block-social-links:not(.has-icon-color):not(.has-icon-background-color) .wp-social-link-snapchat .wp-block-social-link-label {
  color: #000
}

.wp-block-spacer {
  clear: both
}

.wp-block-tag-cloud {
  box-sizing: border-box
}

.wp-block-tag-cloud.aligncenter {
  justify-content: center;
  text-align: center
}

.wp-block-tag-cloud.alignfull {
  padding-left: 1em;
  padding-right: 1em
}

.wp-block-tag-cloud a {
  display: inline-block;
  margin-right: 5px
}

.wp-block-tag-cloud span {
  display: inline-block;
  margin-left: 5px;
  text-decoration: none
}

:root :where(.wp-block-tag-cloud.is-style-outline) {
  display: flex;
  flex-wrap: wrap;
  gap: 1ch
}

:root :where(.wp-block-tag-cloud.is-style-outline a) {
  border: 1px solid;
  font-size: unset !important;
  margin-right: 0;
  padding: 1ch 2ch;
  text-decoration: none !important
}

.wp-block-table {
  overflow-x: auto
}

.wp-block-table table {
  border-collapse: collapse;
  width: 100%
}

.wp-block-table thead {
  border-bottom: 3px solid
}

.wp-block-table tfoot {
  border-top: 3px solid
}

.wp-block-table td,
.wp-block-table th {
  border: 1px solid;
  padding: .5em
}

.wp-block-table .has-fixed-layout {
  table-layout: fixed;
  width: 100%
}

.wp-block-table .has-fixed-layout td,
.wp-block-table .has-fixed-layout th {
  word-break: break-word
}

.wp-block-table.aligncenter,
.wp-block-table.alignleft,
.wp-block-table.alignright {
  display: table;
  width: auto
}

.wp-block-table.aligncenter td,
.wp-block-table.aligncenter th,
.wp-block-table.alignleft td,
.wp-block-table.alignleft th,
.wp-block-table.alignright td,
.wp-block-table.alignright th {
  word-break: break-word
}

.wp-block-table .has-subtle-light-gray-background-color {
  background-color: #f3f4f5
}

.wp-block-table .has-subtle-pale-green-background-color {
  background-color: #e9fbe5
}

.wp-block-table .has-subtle-pale-blue-background-color {
  background-color: #e7f5fe
}

.wp-block-table .has-subtle-pale-pink-background-color {
  background-color: #fcf0ef
}

.wp-block-table.is-style-stripes {
  background-color: initial;
  border-bottom: 1px solid #f0f0f0;
  border-collapse: inherit;
  border-spacing: 0
}

.wp-block-table.is-style-stripes tbody tr:nth-child(odd) {
  background-color: #f0f0f0
}

.wp-block-table.is-style-stripes.has-subtle-light-gray-background-color tbody tr:nth-child(odd) {
  background-color: #f3f4f5
}

.wp-block-table.is-style-stripes.has-subtle-pale-green-background-color tbody tr:nth-child(odd) {
  background-color: #e9fbe5
}

.wp-block-table.is-style-stripes.has-subtle-pale-blue-background-color tbody tr:nth-child(odd) {
  background-color: #e7f5fe
}

.wp-block-table.is-style-stripes.has-subtle-pale-pink-background-color tbody tr:nth-child(odd) {
  background-color: #fcf0ef
}

.wp-block-table.is-style-stripes td,
.wp-block-table.is-style-stripes th {
  border-color: #0000
}

.wp-block-table .has-border-color td,
.wp-block-table .has-border-color th,
.wp-block-table .has-border-color tr,
.wp-block-table .has-border-color>* {
  border-color: inherit
}

.wp-block-table table[style*=border-top-color] tr:first-child,
.wp-block-table table[style*=border-top-color] tr:first-child td,
.wp-block-table table[style*=border-top-color] tr:first-child th,
.wp-block-table table[style*=border-top-color]>*,
.wp-block-table table[style*=border-top-color]>* td,
.wp-block-table table[style*=border-top-color]>* th {
  border-top-color: inherit
}

.wp-block-table table[style*=border-top-color] tr:not(:first-child) {
  border-top-color: initial
}

.wp-block-table table[style*=border-right-color] td:last-child,
.wp-block-table table[style*=border-right-color] th,
.wp-block-table table[style*=border-right-color] tr,
.wp-block-table table[style*=border-right-color]>* {
  border-right-color: inherit
}

.wp-block-table table[style*=border-bottom-color] tr:last-child,
.wp-block-table table[style*=border-bottom-color] tr:last-child td,
.wp-block-table table[style*=border-bottom-color] tr:last-child th,
.wp-block-table table[style*=border-bottom-color]>*,
.wp-block-table table[style*=border-bottom-color]>* td,
.wp-block-table table[style*=border-bottom-color]>* th {
  border-bottom-color: inherit
}

.wp-block-table table[style*=border-bottom-color] tr:not(:last-child) {
  border-bottom-color: initial
}

.wp-block-table table[style*=border-left-color] td:first-child,
.wp-block-table table[style*=border-left-color] th,
.wp-block-table table[style*=border-left-color] tr,
.wp-block-table table[style*=border-left-color]>* {
  border-left-color: inherit
}

.wp-block-table table[style*=border-style] td,
.wp-block-table table[style*=border-style] th,
.wp-block-table table[style*=border-style] tr,
.wp-block-table table[style*=border-style]>* {
  border-style: inherit
}

.wp-block-table table[style*=border-width] td,
.wp-block-table table[style*=border-width] th,
.wp-block-table table[style*=border-width] tr,
.wp-block-table table[style*=border-width]>* {
  border-style: inherit;
  border-width: inherit
}

:root :where(.wp-block-table-of-contents) {
  box-sizing: border-box
}

:where(.wp-block-term-description) {
  box-sizing: border-box;
  margin-bottom: var(--wp--style--block-gap);
  margin-top: var(--wp--style--block-gap)
}

.wp-block-term-description p {
  margin-bottom: 0;
  margin-top: 0
}

.wp-block-text-columns,
.wp-block-text-columns.aligncenter {
  display: flex
}

.wp-block-text-columns .wp-block-column {
  margin: 0 1em;
  padding: 0
}

.wp-block-text-columns .wp-block-column:first-child {
  margin-left: 0
}

.wp-block-text-columns .wp-block-column:last-child {
  margin-right: 0
}

.wp-block-text-columns.columns-2 .wp-block-column {
  width: 50%
}

.wp-block-text-columns.columns-3 .wp-block-column {
  width: 33.33333%
}

.wp-block-text-columns.columns-4 .wp-block-column {
  width: 25%
}

pre.wp-block-verse {
  overflow: auto;
  white-space: pre-wrap
}

:where(pre.wp-block-verse) {
  font-family: inherit
}

.wp-block-video {
  box-sizing: border-box
}

.wp-block-video video {
  vertical-align: middle;
  width: 100%
}

@supports (position:sticky) {
  .wp-block-video [poster] {
    object-fit: cover
  }
}

.wp-block-video.aligncenter {
  text-align: center
}

.wp-block-video :where(figcaption) {
  margin-bottom: 1em;
  margin-top: .5em
}

.editor-styles-wrapper,
.entry-content {
  counter-reset: footnotes
}

a[data-fn].fn {
  counter-increment: footnotes;
  display: inline-flex;
  font-size: smaller;
  text-decoration: none;
  text-indent: -9999999px;
  vertical-align: super
}

a[data-fn].fn:after {
  content: "[" counter(footnotes) "]";
  float: left;
  text-indent: 0
}

.wp-element-button {
  cursor: pointer
}

:root {
  --wp--preset--font-size--normal: 16px;
  --wp--preset--font-size--huge: 42px
}

:root .has-very-light-gray-background-color {
  background-color: #eee
}

:root .has-very-dark-gray-background-color {
  background-color: #313131
}

:root .has-very-light-gray-color {
  color: #eee
}

:root .has-very-dark-gray-color {
  color: #313131
}

:root .has-vivid-green-cyan-to-vivid-cyan-blue-gradient-background {
  background: linear-gradient(135deg, #00d084, #0693e3)
}

:root .has-purple-crush-gradient-background {
  background: linear-gradient(135deg, #34e2e4, #4721fb 50%, #ab1dfe)
}

:root .has-hazy-dawn-gradient-background {
  background: linear-gradient(135deg, #faaca8, #dad0ec)
}

:root .has-subdued-olive-gradient-background {
  background: linear-gradient(135deg, #fafae1, #67a671)
}

:root .has-atomic-cream-gradient-background {
  background: linear-gradient(135deg, #fdd79a, #004a59)
}

:root .has-nightshade-gradient-background {
  background: linear-gradient(135deg, #330968, #31cdcf)
}

:root .has-midnight-gradient-background {
  background: linear-gradient(135deg, #020381, #2874fc)
}

.has-regular-font-size {
  font-size: 1em
}

.has-larger-font-size {
  font-size: 2.625em
}

.has-normal-font-size {
  font-size: var(--wp--preset--font-size--normal)
}

.has-huge-font-size {
  font-size: var(--wp--preset--font-size--huge)
}

.has-text-align-center {
  text-align: center
}

.has-text-align-left {
  text-align: left
}

.has-text-align-right {
  text-align: right
}

#end-resizable-editor-section {
  display: none
}

.aligncenter {
  clear: both
}

.items-justified-left {
  justify-content: flex-start
}

.items-justified-center {
  justify-content: center
}

.items-justified-right {
  justify-content: flex-end
}

.items-justified-space-between {
  justify-content: space-between
}

.screen-reader-text {
  border: 0;
  clip: rect(1px, 1px, 1px, 1px);
  clip-path: inset(50%);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
  word-wrap: normal !important
}

.screen-reader-text:focus {
  background-color: #ddd;
  clip: auto !important;
  clip-path: none;
  color: #444;
  display: block;
  font-size: 1em;
  height: auto;
  left: 5px;
  line-height: normal;
  padding: 15px 23px 14px;
  text-decoration: none;
  top: 5px;
  width: auto;
  z-index: 100000
}

html :where(.has-border-color) {
  border-style: solid
}

html :where([style*=border-top-color]) {
  border-top-style: solid
}

html :where([style*=border-right-color]) {
  border-right-style: solid
}

html :where([style*=border-bottom-color]) {
  border-bottom-style: solid
}

html :where([style*=border-left-color]) {
  border-left-style: solid
}

html :where([style*=border-width]) {
  border-style: solid
}

html :where([style*=border-top-width]) {
  border-top-style: solid
}

html :where([style*=border-right-width]) {
  border-right-style: solid
}

html :where([style*=border-bottom-width]) {
  border-bottom-style: solid
}

html :where([style*=border-left-width]) {
  border-left-style: solid
}

html :where(img[class*=wp-image-]) {
  height: auto;
  max-width: 100%
}

:where(figure) {
  margin: 0 0 1em
}

html :where(.is-position-sticky) {
  --wp-admin--admin-bar--position-offset: var(--wp-admin--admin-bar--height, 0px)
}

@media screen and (max-width:600px) {
  html :where(.is-position-sticky) {
    --wp-admin--admin-bar--position-offset: 0px
  }
}
`}</style>
	<style id="global-styles-inline-css">{`
		:root {
			--wp--preset--aspect-ratio--square: 1;
			--wp--preset--aspect-ratio--4-3: 4/3;
			--wp--preset--aspect-ratio--3-4: 3/4;
			--wp--preset--aspect-ratio--3-2: 3/2;
			--wp--preset--aspect-ratio--2-3: 2/3;
			--wp--preset--aspect-ratio--16-9: 16/9;
			--wp--preset--aspect-ratio--9-16: 9/16;
			--wp--preset--color--black: #000000;
			--wp--preset--color--cyan-bluish-gray: #abb8c3;
			--wp--preset--color--white: #fff;
			--wp--preset--color--pale-pink: #f78da7;
			--wp--preset--color--vivid-red: #cf2e2e;
			--wp--preset--color--luminous-vivid-orange: #ff6900;
			--wp--preset--color--luminous-vivid-amber: #fcb900;
			--wp--preset--color--light-green-cyan: #7bdcb5;
			--wp--preset--color--vivid-green-cyan: #00d084;
			--wp--preset--color--pale-cyan-blue: #8ed1fc;
			--wp--preset--color--vivid-cyan-blue: #0693e3;
			--wp--preset--color--vivid-purple: #9b51e0;
			--wp--preset--color--gray-600: #e5e6e8;
			--wp--preset--color--gray-500: #4d5a60;
			--wp--preset--color--gray-400: #67777e;
			--wp--preset--color--gray-50: #f0f2f3;
			--wp--preset--color--primary-700: #9e5c00;
			--wp--preset--color--primary-500: #ff5a5f;
			--wp--preset--color--primary-300: #ffce80;
			--wp--preset--color--primary-100: #fff3de;
			--wp--preset--color--secondary-500: #134bc3;
			--wp--preset--color--secondary-400: #5384ee;
			--wp--preset--color--secondary-300: #abd1ff;
			--wp--preset--color--secondary-200: #d4e7ff;
			--wp--preset--color--red: #d63b42;
			--wp--preset--gradient--vivid-cyan-blue-to-vivid-purple: linear-gradient(135deg, rgba(6, 147, 227, 1) 0%, rgb(155, 81, 224) 100%);
			--wp--preset--gradient--light-green-cyan-to-vivid-green-cyan: linear-gradient(135deg, rgb(122, 220, 180) 0%, rgb(0, 208, 130) 100%);
			--wp--preset--gradient--luminous-vivid-amber-to-luminous-vivid-orange: linear-gradient(135deg, rgba(252, 185, 0, 1) 0%, rgba(255, 105, 0, 1) 100%);
			--wp--preset--gradient--luminous-vivid-orange-to-vivid-red: linear-gradient(135deg, rgba(255, 105, 0, 1) 0%, rgb(207, 46, 46) 100%);
			--wp--preset--gradient--very-light-gray-to-cyan-bluish-gray: linear-gradient(135deg, rgb(238, 238, 238) 0%, rgb(169, 184, 195) 100%);
			--wp--preset--gradient--cool-to-warm-spectrum: linear-gradient(135deg, rgb(74, 234, 220) 0%, rgb(151, 120, 209) 20%, rgb(207, 42, 186) 40%, rgb(238, 44, 130) 60%, rgb(251, 105, 98) 80%, rgb(254, 248, 76) 100%);
			--wp--preset--gradient--blush-light-purple: linear-gradient(135deg, rgb(255, 206, 236) 0%, rgb(152, 150, 240) 100%);
			--wp--preset--gradient--blush-bordeaux: linear-gradient(135deg, rgb(254, 205, 165) 0%, rgb(254, 45, 45) 50%, rgb(107, 0, 62) 100%);
			--wp--preset--gradient--luminous-dusk: linear-gradient(135deg, rgb(255, 203, 112) 0%, rgb(199, 81, 192) 50%, rgb(65, 88, 208) 100%);
			--wp--preset--gradient--pale-ocean: linear-gradient(135deg, rgb(255, 245, 203) 0%, rgb(182, 227, 212) 50%, rgb(51, 167, 181) 100%);
			--wp--preset--gradient--electric-grass: linear-gradient(135deg, rgb(202, 248, 128) 0%, rgb(113, 206, 126) 100%);
			--wp--preset--gradient--midnight: linear-gradient(135deg, rgb(2, 3, 129) 0%, rgb(40, 116, 252) 100%);
			--wp--preset--gradient--primary-orange-to-pink: linear-gradient(90deg, rgb(255, 157, 0) 40%, rgb(255, 0, 148) 150%);
			--wp--preset--font-size--small: 0.875rem;
			--wp--preset--font-size--medium: 1.125rem;
			--wp--preset--font-size--large: clamp(1.5rem, 1.1rem + 1.3333vw, 2rem);
			--wp--preset--font-size--x-large: 42px;
			--wp--preset--font-size--regular-16: 1rem;
			--wp--preset--font-size--regular: clamp(1rem, 0.9rem + 0.3333vw, 1.125rem);
			--wp--preset--font-size--medium-2: clamp(1.125rem, 1.025rem + 0.3333vw, 1.25rem);
			--wp--preset--font-size--medium-3: clamp(1.25rem, 1.05rem + 0.6667vw, 1.5rem);
			--wp--preset--font-size--large-2: clamp(2rem, 1.6rem + 1.3333vw, 2.5rem);
			--wp--preset--font-size--large-3: clamp(2rem, 1.2rem + 2.6667vw, 3rem);
			--wp--preset--font-size--xlarge: clamp(3rem, 2.6rem + 1.3333vw, 3.5rem);
			--wp--preset--font-size--xxlarge: clamp(7rem, 2.2rem + 2.6667vw, 4rem);
			--wp--preset--font-size--huge: clamp(3rem, 1rem + 6.6667vw, 5.5rem);
			--wp--preset--font-size--huge-2: clamp(4rem, 2.8rem + 4vw, 5.5rem);
			--wp--preset--spacing--20: 0.44rem;
			--wp--preset--spacing--30: 0.67rem;
			--wp--preset--spacing--40: 1rem;
			--wp--preset--spacing--50: 1.5rem;
			--wp--preset--spacing--60: 2.25rem;
			--wp--preset--spacing--70: 3.38rem;
			--wp--preset--spacing--80: 5.06rem;
			--wp--preset--shadow--natural: 6px 6px 9px rgba(0, 0, 0, 0.2);
			--wp--preset--shadow--deep: 12px 12px 50px rgba(0, 0, 0, 0.4);
			--wp--preset--shadow--sharp: 6px 6px 0px rgba(0, 0, 0, 0.2);
			--wp--preset--shadow--outlined: 6px 6px 0px -3px rgba(255, 255, 255, 1), 6px 6px rgba(0, 0, 0, 1);
			--wp--preset--shadow--crisp: 6px 6px 0px rgba(0, 0, 0, 1);
		}

		:root {
			--wp--style--global--content-size: 1200px;
			--wp--style--global--wide-size: 1200px;
		}

		:where(body) {
			margin: 0;
		}

		.wp-site-blocks>.alignleft {
			float: left;
			margin-right: 2em;
		}

		.wp-site-blocks>.alignright {
			float: right;
			margin-left: 2em;
		}

		.wp-site-blocks>.aligncenter {
			justify-content: center;
			margin-left: auto;
			margin-right: auto;
		}

		:where(.wp-site-blocks)>* {
			margin-block-start: 0;
			margin-block-end: 0;
		}

		:where(.wp-site-blocks)> :first-child {
			margin-block-start: 0;
		}

		:where(.wp-site-blocks)> :last-child {
			margin-block-end: 0;
		}

		:root {
			--wp--style--block-gap: 0;
		}

		:root :where(.is-layout-flow)> :first-child {
			margin-block-start: 0;
		}

		:root :where(.is-layout-flow)> :last-child {
			margin-block-end: 0;
		}

		:root :where(.is-layout-flow)>* {
			margin-block-start: 0;
			margin-block-end: 0;
		}

		:root :where(.is-layout-constrained)> :first-child {
			margin-block-start: 0;
		}

		:root :where(.is-layout-constrained)> :last-child {
			margin-block-end: 0;
		}

		:root :where(.is-layout-constrained)>* {
			margin-block-start: 0;
			margin-block-end: 0;
		}

		:root :where(.is-layout-flex) {
			gap: 0;
		}

		:root :where(.is-layout-grid) {
			gap: 0;
		}

		.is-layout-flow>.alignleft {
			float: left;
			margin-inline-start: 0;
			margin-inline-end: 2em;
		}

		.is-layout-flow>.alignright {
			float: right;
			margin-inline-start: 2em;
			margin-inline-end: 0;
		}

		.is-layout-flow>.aligncenter {
			margin-left: auto !important;
			margin-right: auto !important;
		}

		.is-layout-constrained>.alignleft {
			float: left;
			margin-inline-start: 0;
			margin-inline-end: 2em;
		}

		.is-layout-constrained>.alignright {
			float: right;
			margin-inline-start: 2em;
			margin-inline-end: 0;
		}

		.is-layout-constrained>.aligncenter {
			margin-left: auto !important;
			margin-right: auto !important;
		}

		.is-layout-constrained> :where(:not(.alignleft):not(.alignright):not(.alignfull)) {
			max-width: var(--wp--style--global--content-size);
			margin-left: auto !important;
			margin-right: auto !important;
		}

		.is-layout-constrained>.alignwide {
			max-width: var(--wp--style--global--wide-size);
		}

		body .is-layout-flex {
			display: flex;
		}

		.is-layout-flex {
			flex-wrap: wrap;
			align-items: center;
		}

		.is-layout-flex> :is(*, div) {
			margin: 0;
		}

		body .is-layout-grid {
			display: grid;
		}

		.is-layout-grid> :is(*, div) {
			margin: 0;
		}

		body {
			color: var(--wp--preset--color--gray-600);
			padding-top: 0px;
			padding-right: 0px;
			padding-bottom: 0px;
			padding-left: 0px;
		}

		a:where(:not(.wp-element-button)) {
			text-decoration: underline;
		}

		:root :where(.wp-element-button, .wp-block-button__link) {
			background-color: #32373c;
			border-width: 0;
			color: #fff;
			font-family: inherit;
			font-size: inherit;
			line-height: inherit;
			padding: calc(0.667em + 2px) calc(1.333em + 2px);
			text-decoration: none;
		}

		.has-black-color {
			color: var(--wp--preset--color--black) !important;
		}

		.has-cyan-bluish-gray-color {
			color: var(--wp--preset--color--cyan-bluish-gray) !important;
		}

		.has-white-color {
			color: var(--wp--preset--color--white) !important;
		}

		.has-pale-pink-color {
			color: var(--wp--preset--color--pale-pink) !important;
		}

		.has-vivid-red-color {
			color: var(--wp--preset--color--vivid-red) !important;
		}

		.has-luminous-vivid-orange-color {
			color: var(--wp--preset--color--luminous-vivid-orange) !important;
		}

		.has-luminous-vivid-amber-color {
			color: var(--wp--preset--color--luminous-vivid-amber) !important;
		}

		.has-light-green-cyan-color {
			color: var(--wp--preset--color--light-green-cyan) !important;
		}

		.has-vivid-green-cyan-color {
			color: var(--wp--preset--color--vivid-green-cyan) !important;
		}

		.has-pale-cyan-blue-color {
			color: var(--wp--preset--color--pale-cyan-blue) !important;
		}

		.has-vivid-cyan-blue-color {
			color: var(--wp--preset--color--vivid-cyan-blue) !important;
		}

		.has-vivid-purple-color {
			color: var(--wp--preset--color--vivid-purple) !important;
		}

		.has-gray-600-color {
			color: var(--wp--preset--color--gray-600) !important;
		}

		.has-gray-500-color {
			color: var(--wp--preset--color--gray-500) !important;
		}

		.has-gray-400-color {
			color: var(--wp--preset--color--gray-400) !important;
		}

		.has-gray-50-color {
			color: var(--wp--preset--color--gray-50) !important;
		}

		.has-primary-700-color {
			color: var(--wp--preset--color--primary-700) !important;
		}

		.has-primary-500-color {
			color: var(--wp--preset--color--primary-500) !important;
		}

		.has-primary-300-color {
			color: var(--wp--preset--color--primary-300) !important;
		}

		.has-primary-100-color {
			color: var(--wp--preset--color--primary-100) !important;
		}

		.has-secondary-500-color {
			color: var(--wp--preset--color--secondary-500) !important;
		}

		.has-secondary-400-color {
			color: var(--wp--preset--color--secondary-400) !important;
		}

		.has-secondary-300-color {
			color: var(--wp--preset--color--secondary-300) !important;
		}

		.has-secondary-200-color {
			color: var(--wp--preset--color--secondary-200) !important;
		}

		.has-red-color {
			color: var(--wp--preset--color--red) !important;
		}

		.has-black-background-color {
			background-color: var(--wp--preset--color--black) !important;
		}

		.has-cyan-bluish-gray-background-color {
			background-color: var(--wp--preset--color--cyan-bluish-gray) !important;
		}

		.has-white-background-color {
			background-color: var(--wp--preset--color--white) !important;
		}

		.has-pale-pink-background-color {
			background-color: var(--wp--preset--color--pale-pink) !important;
		}

		.has-vivid-red-background-color {
			background-color: var(--wp--preset--color--vivid-red) !important;
		}

		.has-luminous-vivid-orange-background-color {
			background-color: var(--wp--preset--color--luminous-vivid-orange) !important;
		}

		.has-luminous-vivid-amber-background-color {
			background-color: var(--wp--preset--color--luminous-vivid-amber) !important;
		}

		.has-light-green-cyan-background-color {
			background-color: var(--wp--preset--color--light-green-cyan) !important;
		}

		.has-vivid-green-cyan-background-color {
			background-color: var(--wp--preset--color--vivid-green-cyan) !important;
		}

		.has-pale-cyan-blue-background-color {
			background-color: var(--wp--preset--color--pale-cyan-blue) !important;
		}

		.has-vivid-cyan-blue-background-color {
			background-color: var(--wp--preset--color--vivid-cyan-blue) !important;
		}

		.has-vivid-purple-background-color {
			background-color: var(--wp--preset--color--vivid-purple) !important;
		}

		.has-gray-600-background-color {
			background-color: var(--wp--preset--color--gray-600) !important;
		}

		.has-gray-500-background-color {
			background-color: var(--wp--preset--color--gray-500) !important;
		}

		.has-gray-400-background-color {
			background-color: var(--wp--preset--color--gray-400) !important;
		}

		.has-gray-50-background-color {
			background-color: var(--wp--preset--color--gray-50) !important;
		}

		.has-primary-700-background-color {
			background-color: var(--wp--preset--color--primary-700) !important;
		}

		.has-primary-500-background-color {
			background-color: var(--wp--preset--color--primary-500) !important;
		}

		.has-primary-300-background-color {
			background-color: var(--wp--preset--color--primary-300) !important;
		}

		.has-primary-100-background-color {
			background-color: var(--wp--preset--color--primary-100) !important;
		}

		.has-secondary-500-background-color {
			background-color: var(--wp--preset--color--secondary-500) !important;
		}

		.has-secondary-400-background-color {
			background-color: var(--wp--preset--color--secondary-400) !important;
		}

		.has-secondary-300-background-color {
			background-color: var(--wp--preset--color--secondary-300) !important;
		}

		.has-secondary-200-background-color {
			background-color: var(--wp--preset--color--secondary-200) !important;
		}

		.has-red-background-color {
			background-color: var(--wp--preset--color--red) !important;
		}

		.has-black-border-color {
			border-color: var(--wp--preset--color--black) !important;
		}

		.has-cyan-bluish-gray-border-color {
			border-color: var(--wp--preset--color--cyan-bluish-gray) !important;
		}

		.has-white-border-color {
			border-color: var(--wp--preset--color--white) !important;
		}

		.has-pale-pink-border-color {
			border-color: var(--wp--preset--color--pale-pink) !important;
		}

		.has-vivid-red-border-color {
			border-color: var(--wp--preset--color--vivid-red) !important;
		}

		.has-luminous-vivid-orange-border-color {
			border-color: var(--wp--preset--color--luminous-vivid-orange) !important;
		}

		.has-luminous-vivid-amber-border-color {
			border-color: var(--wp--preset--color--luminous-vivid-amber) !important;
		}

		.has-light-green-cyan-border-color {
			border-color: var(--wp--preset--color--light-green-cyan) !important;
		}

		.has-vivid-green-cyan-border-color {
			border-color: var(--wp--preset--color--vivid-green-cyan) !important;
		}

		.has-pale-cyan-blue-border-color {
			border-color: var(--wp--preset--color--pale-cyan-blue) !important;
		}

		.has-vivid-cyan-blue-border-color {
			border-color: var(--wp--preset--color--vivid-cyan-blue) !important;
		}

		.has-vivid-purple-border-color {
			border-color: var(--wp--preset--color--vivid-purple) !important;
		}

		.has-gray-600-border-color {
			border-color: var(--wp--preset--color--gray-600) !important;
		}

		.has-gray-500-border-color {
			border-color: var(--wp--preset--color--gray-500) !important;
		}

		.has-gray-400-border-color {
			border-color: var(--wp--preset--color--gray-400) !important;
		}

		.has-gray-50-border-color {
			border-color: var(--wp--preset--color--gray-50) !important;
		}

		.has-primary-700-border-color {
			border-color: var(--wp--preset--color--primary-700) !important;
		}

		.has-primary-500-border-color {
			border-color: var(--wp--preset--color--primary-500) !important;
		}

		.has-primary-300-border-color {
			border-color: var(--wp--preset--color--primary-300) !important;
		}

		.has-primary-100-border-color {
			border-color: var(--wp--preset--color--primary-100) !important;
		}

		.has-secondary-500-border-color {
			border-color: var(--wp--preset--color--secondary-500) !important;
		}

		.has-secondary-400-border-color {
			border-color: var(--wp--preset--color--secondary-400) !important;
		}

		.has-secondary-300-border-color {
			border-color: var(--wp--preset--color--secondary-300) !important;
		}

		.has-secondary-200-border-color {
			border-color: var(--wp--preset--color--secondary-200) !important;
		}

		.has-red-border-color {
			border-color: var(--wp--preset--color--red) !important;
		}

		.has-vivid-cyan-blue-to-vivid-purple-gradient-background {
			background: var(--wp--preset--gradient--vivid-cyan-blue-to-vivid-purple) !important;
		}

		.has-light-green-cyan-to-vivid-green-cyan-gradient-background {
			background: var(--wp--preset--gradient--light-green-cyan-to-vivid-green-cyan) !important;
		}

		.has-luminous-vivid-amber-to-luminous-vivid-orange-gradient-background {
			background: var(--wp--preset--gradient--luminous-vivid-amber-to-luminous-vivid-orange) !important;
		}

		.has-luminous-vivid-orange-to-vivid-red-gradient-background {
			background: var(--wp--preset--gradient--luminous-vivid-orange-to-vivid-red) !important;
		}

		.has-very-light-gray-to-cyan-bluish-gray-gradient-background {
			background: var(--wp--preset--gradient--very-light-gray-to-cyan-bluish-gray) !important;
		}

		.has-cool-to-warm-spectrum-gradient-background {
			background: var(--wp--preset--gradient--cool-to-warm-spectrum) !important;
		}

		.has-blush-light-purple-gradient-background {
			background: var(--wp--preset--gradient--blush-light-purple) !important;
		}

		.has-blush-bordeaux-gradient-background {
			background: var(--wp--preset--gradient--blush-bordeaux) !important;
		}

		.has-luminous-dusk-gradient-background {
			background: var(--wp--preset--gradient--luminous-dusk) !important;
		}

		.has-pale-ocean-gradient-background {
			background: var(--wp--preset--gradient--pale-ocean) !important;
		}

		.has-electric-grass-gradient-background {
			background: var(--wp--preset--gradient--electric-grass) !important;
		}

		.has-midnight-gradient-background {
			background: var(--wp--preset--gradient--midnight) !important;
		}

		.has-primary-orange-to-pink-gradient-background {
			background: var(--wp--preset--gradient--primary-orange-to-pink) !important;
		}

		.has-small-font-size {
			font-size: var(--wp--preset--font-size--small) !important;
		}

		.has-medium-font-size {
			font-size: var(--wp--preset--font-size--medium) !important;
		}

		.has-large-font-size {
			font-size: var(--wp--preset--font-size--large) !important;
		}

		.has-x-large-font-size {
			font-size: var(--wp--preset--font-size--x-large) !important;
		}

		.has-regular-16-font-size {
			font-size: var(--wp--preset--font-size--regular-16) !important;
		}

		.has-regular-font-size {
			font-size: var(--wp--preset--font-size--regular) !important;
		}

		.has-medium-2-font-size {
			font-size: var(--wp--preset--font-size--medium-2) !important;
		}

		.has-medium-3-font-size {
			font-size: var(--wp--preset--font-size--medium-3) !important;
		}

		.has-large-2-font-size {
			font-size: var(--wp--preset--font-size--large-2) !important;
		}

		.has-large-3-font-size {
			font-size: var(--wp--preset--font-size--large-3) !important;
		}

		.has-xlarge-font-size {
			font-size: var(--wp--preset--font-size--xlarge) !important;
		}

		.has-xxlarge-font-size {
			font-size: var(--wp--preset--font-size--xxlarge) !important;
		}

		.has-huge-font-size {
			font-size: var(--wp--preset--font-size--huge) !important;
		}

		.has-huge-2-font-size {
			font-size: var(--wp--preset--font-size--huge-2) !important;
		}

		:root :where(.wp-block-pullquote) {
			font-size: 1.5em;
			line-height: 1.6;
		}
	`}</style>
    {showRegistrationModal && (
        <RegistrationModal onClose={() => setShowRegistrationModal(false)} />
      )}
</body>
  );
};

export default IntroTransport;
