import React from "react";
import { useNavigate } from "react-router-dom"; // Import useNavigate

import vd from './step7.mp4';

const Steps8Admin = () => {
  const navigate = useNavigate(); // Initialize the navigate function
  const handleNext = () => {
    navigate("/RegularAdmin"); // Navigate to /steps2Admin
  };

  return (
    <div>
        <style>{`
        .container {
          max-width: 600px;
          margin: 50px auto;
          padding: 20px;
          font-family: Arial, sans-serif;
        }
          
        .container h2 {
          font-size: 29px;
          margin-bottom: 10px;
          color: #242323;
          text-align: left;
        }
        .container h3 {
          font-size: 18px;
          margin-bottom: 10px;
          color: #242323;
          text-align: left;
        }
        .container p {
          font-size: 20px;
          margin-bottom: 20px;
          color: #555;
          text-align: left;
        }
        .options-grid {
          display: grid;
          grid-template-columns: repeat(2, 1fr);
          gap: 20px;
          margin-bottom: 40px;
        }
        @media (min-width: 768px) {
          .options-grid {
            grid-template-columns: repeat(3, 1fr);
          }
        }
        .option {
          border: 1px solid #ff5a5f;
          border-radius: 8px;
          padding: 10px;
          cursor: pointer;
          transition: border-color 0.3s, box-shadow 0.3s;
          text-align: left;
          display: flex;
          flex-direction: column;
          align-items: flex-start;
        }
        .option:hover {
          border-color: #000;
          box-shadow: 0 0 10px rgba(255, 90, 96, 0.17);
        }
        .option.selected {
          border-color: #ff5a5f;
          box-shadow: 0 0 10px #ff5a5f;
          background-color:rgba(255, 90, 96, 0.17);
        }
        .option span {
          font-size: 16px;
          color: #333;
          text-align: left;
          width: 100%;
        }
        .progress-step1cont {
  position: fixed; /* Fix the container at a specific position */
  bottom: 0; /* Place it at the bottom of the viewport */
  left: 0;
  right: 0;
  width: 100%; /* Make sure it spans the full width */
  max-width: 1200px;
  margin: 0 auto; /* Center it horizontally */
  padding: 0 10px;
  box-sizing: border-box;
  background: #fff; /* Add a background to prevent overlap issues */
  z-index: 1000; /* Ensure it stays above other elements */
}
          .progress-bar {
          height: 4px;
          background: #e0e0e0;
          margin-bottom: 5px;
          position: relative;
        }
        .progress {
          height: 100%;
          width: 60%;
          background: #000;
          transition: width 0.3s ease-in-out;
        }
        .buttons-step1cont {
          display: flex;
          justify-content: space-between; /* Space between buttons */
          align-items: center;
          width: 100%;
          margin-top: 10px; /* Add some space above buttons */
        }
         .back-button {
          text-decoration: underline;
          color: #ff5a5f;
          background: none;
          border: none;
          max-width: 50px;
          cursor: pointer;
          font-size: 16px;
          padding: 15px 0px;
        }

        .back-button:hover {
          text-decoration: underline;
          color: #fff;
          background: #ff5a5f;
          border: none;
          max-width: 50px;
          cursor: pointer;
          font-size: 16px;
          padding: 15px 0px;
        }

        .next-button {
          background: #ff5a5f;
          max-width: 70px;
          color: #fff;
          border: none;
          padding: 10px 20px;
          border-radius: 4px;
          font-size: 16px;
          cursor: pointer;
        }     
@media (max-width: 768px) {
  .buttons-step1cont {
    justify-content: flex-start; /* Align all buttons to the left */
  }
  .next-button {
    margin-left: 150px; /* Add some spacing from the left edge */
  }
}
        .option img {
          width: 30px;
          height: 30px;
          flex-shrink: 0;
          align-self: flex-start;
        }
      `}</style>
      <style>{`
        .step1cont {
          display: flex;
          align-items: center;
          max-width: 1200px;
          width: 100%;
          margin: 20px auto;
          padding: 10px;
          box-sizing: border-box;
          flex-wrap: wrap;
        }
        @media (min-width: 768px) {
          .step1cont {
            flex-wrap: nowrap;
          }
          .video-section {
            order: 2;
            width: 60%;
          }
          .text-section {
            order: 1;
            width: 40%;
          }
        
        }
        .text-section {
          order: 2;
          width: 100%;
          margin-bottom: 10px;
        }
        .text-section h2 {
          font-size: 14px;
          margin-bottom: 5px;
          color: #888;
          text-align: left;
        }
        .text-section h3 {
          font-size: 28px;
          margin-bottom: 10px;
          color: rgb(36, 35, 35);
          text-align: left;
        }
        .text-section p {
          font-size: 14px;
          color: #555;
          line-height: 1.4;
          text-align: left;
        }
        .video-section {
          order: 1;
          width: 100%;
          margin-bottom: 10px;
        }
        .video-section video {
          display: block;
          width: 100%;
          max-width: 100%;
          height: 400px;
        }
        .progress-step1cont {
          width: 100%;
          max-width: 1200px;
          margin: 10px auto;
          padding: 0 10px;
          box-sizing: border-box;
        }
        .progress-bar {
          height: 4px;
          background: #e0e0e0;
          margin-bottom: 5px;
          position: relative;
        }
        .progress {
          height: 100%;
          width: 100%;
          background: #000;
          transition: width 0.3s ease-in-out;
        }
        .buttons-step1cont {
          display: flex;
          justify-content: space-between; /* Space between buttons */
          align-items: center;
          width: 100%;
          margin-top: 10px; /* Add some space above buttons */
        }
      
      `}</style>
      <div className="step1cont">
        <div className="text-section">
          <h2>Final  Step</h2>
          <h3>Your Booking Has Been Submitted Successfully</h3>
          <p>
          Your details have been successfully submitted. Please wait for confirmation via email or call. Thank you for your patience! 😊       </p>
        </div>
        <div className="video-section">
          <img
            src="https://media3.giphy.com/media/v1.Y2lkPTc5MGI3NjExM2c1MzZ3bHZyOW1tZjhpcTRrOXQ0YTVhZG5ubGhxNW04MWJjbWk1cCZlcD12MV9pbnRlcm5hbF9naWZfYnlfaWQmY3Q9Zw/W1fFapmqgqEf8RJ9TQ/giphy.gif"
            className="Image-module"
            aria-hidden="true"
            style={{ borderRadius: "8px" }}
            alt="Step 1"
          />
        </div>
      </div>
      <div className="progress-step1cont">
        <div className="progress-bar">
          <div className="progress"></div>
        </div>
        <div className="buttons-step1cont">
        <button className="back-button" onClick={() => navigate(-1)}>
  Back
</button>

          <button className="next-button" onClick={handleNext}>Next</button>
        </div>
      </div>
    </div>
  );
};

export default Steps8Admin;
