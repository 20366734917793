import React, { useState, useEffect } from "react";
import CustomCalendar from "../src/components/CustomCalendar";

import { useLocation, useNavigate } from "react-router-dom";
import { useAuth } from "./AuthProvider";
import Apartments from "./Apartments";
import defaults from "./default_room_room.jpg";
import "./invoice.css";
import "./loaders.css";
import { PDFDocument, rgb } from "pdf-lib";
import RegistrationModal from "./components/RegistrationModal.js"; // Adjust the path based on your folder structure
import logo from "./logoblack.png";
import Swal from "sweetalert2";
import ReactDatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import { format, endOfMonth, differenceInCalendarDays, startOfMonth } from 'date-fns';
import {
  House,
  CalendarClock,
  ScanSearch,
  Star,
  Bus,
  MapPinned,
  PhoneForwarded,
  User,
} from "lucide-react";

const FrontPage = ({ onContinue }) => (
  <div className="full-page-content3">
    <div className="front-page">
      <div className="gif-section">
        <img
          height="100%"
          width="100%"
          alignItems="center"
          src="https://i.pinimg.com/originals/c3/a6/cb/c3a6cbbc1e1d5fed032ea0a49bb9f545.gif"
          class="Image-module"
          aria-hidden="true"
          style={{ borderRadius: "8px" }}
        />
      </div>
      <div className="subsection-module__title register-section">
        <img src={logo} alt="Logo" className="logo" />
        <h2 className="subsection-module__title" style={{ color: "white" }}>
          Welcome to JAES Student Home
        </h2>
        <p className="subsection-module__title" style={{ color: "black" }}>
          Discover a new way of student living with JAES.
        </p>

        <p style={{ color: "white" }}>Press continue and finish booking .</p>
        <button className="btn456" onClick={onContinue}>
          Continue
        </button>
      </div>
    </div>
  </div>
);

const CHECKBOX_OPTIONS = [
  {
    id: "emergency-protocol",
    label: "Emergency Protocol",
    stateKey: "isEmergencyChecked",
  },
  { id: "house-rules", label: "House Rules", stateKey: "isHouseRulesChecked" },
];

function NewInvoice4({ handleClose, handleSubmit }) {
  const location = useLocation();
  const [loading, setLoading] = useState(false);
  const [progress, setProgress] = useState(false);
  const [progress2, setProgress2] = useState(false);
  const [pickedDate, setPickedDate] = useState(null);

  const [showRegistrationModal, setShowRegistrationModal] = useState(false);
  const [selectedPlan1, setSelectedPayment] = useState("");
  const [userData, setUserData] = useState(null);
  const [downloadAfterLogin, setDownloadAfterLogin] = useState(false);
  const [clickedAction, setClickedAction] = useState(null); // Track the action button pressed
  let [totalAmount, setTotalAmount] = useState(null);
  const [chosenPlan, setChosenPlan] = useState("");
  const [thePrice, setThePrice] = useState(null);
  const [selectedFile, setSelectedFile] = useState(null);
  const [showHouseRulesModal, setShowHouseRulesModal] = useState(false);
  const [isEmergencyChecked, setIsEmergencyChecked] = useState(false);
  const [isHouseRulesChecked, setIsHouseRulesChecked] = useState(false);
  const [isIncidentFormChecked, setIsIncidentFormChecked] = useState(false);
  const [isLeaseClauseChecked, setIsLeaseClauseChecked] = useState(false);
  const [step, setStep] = useState(0);
  const [transport, setTransport] = useState("");
  const [serviceFee, setServiceFee] = useState("");
  const serviceFeeCost = serviceFee === "yes" ? 1850 : 0;
  const [fundingOption, setFundingOption] = useState("");
  const [totalCost, setTotalCost] = useState(0);
  const [selectedItems, setSelectedItems] = useState([]);
  const navigate = useNavigate();
  const [selectedPlan, setSelectedPlan] = useState("");
  const [selectedPlan2, setSelectedPlan2] = useState(null);

  let chosenDefault = "not used";
  const [showLoginPrompt, setShowLoginPrompt] = useState(false);
  const [hasBookedRoom, setHasBookedRoom] = useState(false);
  const [hasCashedBooked, setHasCashedBooked] = useState(false);
  let cBooking = null;
  let nsfasBooking = null;
  const [bookings, setBookings] = useState([]);
  const [isPlaced, setIsPlaced] = useState(false);
  const [isPlaced2, setIsPlaced2] = useState(false);
  const [adminData, setAdminData] = useState(null);
  const [error, setError] = useState(null);
  let [outsideDaysUsed, setOutsideDaysUsed] = useState(null);
  let [daysInBetween, setDaysInBetween] = useState(null);
  let [moveInDate, setMoveInDate] = useState(null);
  const [payIn, setPayIn] = useState("");

  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(endOfMonth(new Date()));

  const toggleCheckbox = (stateKey) => {
    setCheckboxStates((prevState) => ({
      ...prevState,
      [stateKey]: !prevState[stateKey],
    }));
  };

  const [calculatedRent, setCalculatedRent] = useState(0);


  // useEffect(() => {
  //   // Calculate the total whenever selectedPlan, transport, or serviceFee changes
  //   const planCost = selectedPlan ? parseInt(selectedPlan) : 0;
  //   if (fundingOption === "nsfas") {
  //     setTransport("yes"); // Automatically set transport to "yes"
  //     setTotalCost(planCost + serviceFeeCost); // Exclude transport cost from total for NSFAS users
  //   } else {
  //     // For non-NSFAS users, include transport cost if transport is "yes"
  //     const transportCost = transport === "yes" ? 250 : 0;
  //     setTotalCost(planCost + transportCost + serviceFeeCost);
  //   }

  //   //setTotalCost(planCost + transportCost + serviceFeeCost);

  //   const items = [];
  //   if (transport == "yes") items.push("Transport (R250)");
  //   if (serviceFee == "yes") items.push("Service Fee (R1850)");
  //   if (selectedPlan) {
  //     const planName =
  //       selectedPlan == "0"
  //         ? "Pay Zero Rent Promo"
  //         : selectedPlan == `${calculatedRent}` ? 'pro rata'
  //         : selectedPlan == `${apartment.ApartmentPrice / 2}`
  //         ? "Pay 50% of Rent Promo"
  //         : "Pay in Full";
  //     items.push(`Plan (${planName})`);
  //   }

  //   setSelectedItems(items);
  // }, [selectedPlan, transport, serviceFee]);

  useEffect(() => {
    // Calculate the total whenever selectedPlan, transport, or serviceFee changes
    const planCost =
      Number(selectedPlan) == 0 ? Number(selectedPlan) : Number(selectedPlan);  // Default is 0 for invalid plans
    const transportCost = transport === "yes" ? 250 : 0;

    if (fundingOption === "nsfas") {
      setTransport("yes");
      setTotalCost(serviceFeeCost); // NSFAS excludes transport and plan costs
    } else {
      setTotalCost(planCost + transportCost + serviceFeeCost); // Include transport for non-NSFAS users
    }

    const items = [];
    if (transport === "yes") items.push("Transport (R250)");
    if (serviceFee === "yes") items.push(`Service Fee (R${serviceFeeCost})`);

    if (selectedPlan) {
      const planName =
        selectedPlan == "0"
          ? "Pay Zero Rent Promo"
          : selectedPlan == `${calculatedRent}`
            ? `PRO RATA`
            : null;

      if (planName) items.push(`Plan (${planName})`);
    }

    setSelectedItems(items);
    console.log("#All items updated:", items);
    console.log("#selectedPlan", selectedPlan);
    console.log("#calculatedRent", calculatedRent);


  }, [selectedPlan, transport, serviceFee, calculatedRent, fundingOption]);


  const [checkboxStates, setCheckboxStates] = useState({
    isEmergencyChecked: false,
    isHouseRulesChecked: false,
  });
  const transportCost = transport === "yes" ? 250 : 0;

  const { isLoggedIn, role } = useAuth();
  const { date, apartment, apartmentandbuilding } = location.state || {};

  const { userId } = useAuth(); // Get userId from AuthContext
  let amount = null;
  useEffect(() => {
    const items = [];
    if (checkboxStates.transport) items.push("Transport (R250)");
    setSelectedItems(items);
  }, [checkboxStates]);

  useEffect(() => {
    if (userId) {
      checkPriorBooking();
    }
  }, [userId]);
  useEffect(() => {
    // Ensure "PayNow" is checked on mount
    const today = new Date();
    setStartDate(today); // Auto-select today's date on mount
    setPayIn("PayNow");
  }, []);
  const choosingHowtoPay = (value) => {
    console.log("Choosing payment: ", value); // Debug: log the value being set
    setPayIn(value);

  };


  const handleDateChange = (datevalue) => {
    setStartDate(datevalue);
    // setShowButton(true); // Show button when date is picked
    setPickedDate(format(datevalue, 'dd MMM yyyy')); // Format as "day Month year"


    console.log('Picked Move-In Date:', datevalue);
    setMoveInDate(datevalue.toLocaleDateString('en-US', { day: 'numeric', month: 'long', year: 'numeric' }));

    const endOfTheMonth = endOfMonth(new Date());
    console.log('endOfTheMonth', endOfTheMonth);

    // Total days in the current month
    const daysInMonth = differenceInCalendarDays(endOfTheMonth, startOfMonth(new Date())) + 1;
    console.log('Total days in month:', daysInMonth);

    // Monthly rent
    const monthlyRent = totalRent;

    // Daily rent calculation
    const dailyRent = monthlyRent / daysInMonth;
    console.log('Daily Rent:', dailyRent.toFixed(2));

    const today = new Date();
    const daysBetweenTodayAndMoveIn = differenceInCalendarDays(datevalue, today);
    console.log('Days Between Today and Move-in Date:', daysBetweenTodayAndMoveIn);
    setDaysInBetween(daysBetweenTodayAndMoveIn);

    // Calculate days used (from move-in date to the end of the month)
    const daysUsed = differenceInCalendarDays(endOfTheMonth, datevalue) + 1; // Include move-in day
    console.log('Days Used:', daysUsed);
    setOutsideDaysUsed(daysUsed);
    console.log('passed value', outsideDaysUsed);

    // Pro rata rent calculation
    const proratedRent = dailyRent * daysUsed;
    console.log('Pro Rata Rent:', proratedRent.toFixed(2));

    // Set and format the calculated rent
    setCalculatedRent(Math.ceil(proratedRent)); // Round up to the nearest integer
    console.log('Calculated Rent:', Math.ceil(proratedRent));

    setSelectedPlan(Math.ceil(proratedRent));
    setSelectedPlan2('Pro rata -plan');
    console.log('current plan is', selectedPlan2);

    handleSelection('pro-rata', Math.ceil(proratedRent));
    ///  calculateRent(date);
  };

  const handleDateChange2 = (dateValue2) => {
    setStartDate(dateValue2);
    setPickedDate(format(dateValue2, 'dd MMM yyyy')); // Format as "day Month year"

    console.log('Picked Move-In Date:', dateValue2);
    console.log('Picked Move-In Date:', dateValue2.toLocaleDateString('en-US', { day: 'numeric', month: 'long', year: 'numeric' }));
    setMoveInDate(dateValue2.toLocaleDateString('en-US', { day: 'numeric', month: 'long', year: 'numeric' }));
    const endOfTheMonth = endOfMonth(new Date());
    console.log('endOfTheMonth', endOfTheMonth);

    // Total days in the current month
    const daysInMonth = differenceInCalendarDays(endOfTheMonth, startOfMonth(new Date())) + 1;
    console.log('❌Total days in month:', daysInMonth);

    // Monthly rent
    const monthlyRent = totalRent;

    // Daily rent calculation
    const dailyRent = monthlyRent / daysInMonth;
    console.log('❌Daily Rent:', dailyRent.toFixed(2));


    const today = new Date();
    const daysBetweenTodayAndMoveIn = differenceInCalendarDays(dateValue2, today);
    console.log('❌Days Between Today and Move-in Date:', daysBetweenTodayAndMoveIn);
    setDaysInBetween(daysBetweenTodayAndMoveIn);

    // Calculate days used (from move-in date to the end of the month)
    const daysUsed = differenceInCalendarDays(endOfTheMonth, dateValue2) + 1; // Include move-in day
    console.log('❌Days Used:', daysUsed);
    setOutsideDaysUsed(daysUsed);
    console.log('❌passed value', outsideDaysUsed);

    // Pro rata rent calculation
    const proratedRent = dailyRent * daysUsed;
    console.log('❌Pro Rata Rent:', proratedRent.toFixed(2));


    setSelectedPlan2('zero - plan');

    console.log('current plan is', selectedPlan2);

    handleSelection('zero-rent', 0);
    // setShowButton(true); // Show button when date is picked
    // calculateRent2(date);
  };





  const calculateRent = (moveInDate) => {
    console.log('Picked Move-In Date:', moveInDate);
    setMoveInDate(moveInDate.toLocaleDateString('en-US', { day: 'numeric', month: 'long', year: 'numeric' }));

    const endOfTheMonth = endOfMonth(new Date());
    console.log('endOfTheMonth', endOfTheMonth);

    // Total days in the current month
    const daysInMonth = differenceInCalendarDays(endOfTheMonth, startOfMonth(new Date())) + 1;
    console.log('Total days in month:', daysInMonth);

    // Monthly rent
    const monthlyRent = totalRent;

    // Daily rent calculation
    const dailyRent = monthlyRent / daysInMonth;
    console.log('Daily Rent:', dailyRent.toFixed(2));

    const today = new Date();
    const daysBetweenTodayAndMoveIn = differenceInCalendarDays(moveInDate, today);
    console.log('Days Between Today and Move-in Date:', daysBetweenTodayAndMoveIn);
    setDaysInBetween(daysBetweenTodayAndMoveIn);

    // Calculate days used (from move-in date to the end of the month)
    const daysUsed = differenceInCalendarDays(endOfTheMonth, moveInDate) + 1; // Include move-in day
    console.log('Days Used:', daysUsed);
    setOutsideDaysUsed(daysUsed);
    console.log('passed value', outsideDaysUsed);

    // Pro rata rent calculation
    const proratedRent = dailyRent * daysUsed;
    console.log('Pro Rata Rent:', proratedRent.toFixed(2));

    // Set and format the calculated rent
    setCalculatedRent(Math.ceil(proratedRent)); // Round up to the nearest integer
    console.log('Calculated Rent:', Math.ceil(proratedRent));
  };

  const calculateRent2 = (moveInDate) => {
    console.log('Picked Move-In Date:', moveInDate);
    console.log('Picked Move-In Date:', moveInDate.toLocaleDateString('en-US', { day: 'numeric', month: 'long', year: 'numeric' }));
    setMoveInDate(moveInDate.toLocaleDateString('en-US', { day: 'numeric', month: 'long', year: 'numeric' }));
    const endOfTheMonth = endOfMonth(new Date());
    console.log('endOfTheMonth', endOfTheMonth);

    // Total days in the current month
    const daysInMonth = differenceInCalendarDays(endOfTheMonth, startOfMonth(new Date())) + 1;
    console.log('❌Total days in month:', daysInMonth);

    // Monthly rent
    const monthlyRent = totalRent;

    // Daily rent calculation
    const dailyRent = monthlyRent / daysInMonth;
    console.log('❌Daily Rent:', dailyRent.toFixed(2));


    const today = new Date();
    const daysBetweenTodayAndMoveIn = differenceInCalendarDays(moveInDate, today);
    console.log('❌Days Between Today and Move-in Date:', daysBetweenTodayAndMoveIn);
    setDaysInBetween(daysBetweenTodayAndMoveIn);

    // Calculate days used (from move-in date to the end of the month)
    const daysUsed = differenceInCalendarDays(endOfTheMonth, moveInDate) + 1; // Include move-in day
    console.log('❌Days Used:', daysUsed);
    setOutsideDaysUsed(daysUsed);
    console.log('❌passed value', outsideDaysUsed);

    // Pro rata rent calculation
    const proratedRent = dailyRent * daysUsed;
    console.log('❌Pro Rata Rent:', proratedRent.toFixed(2));

    // Set and format the calculated rent
    //  setCalculatedRent(Math.ceil(proratedRent)); // Round up to the nearest integer
    //console.log('Calculated Rent:', Math.ceil(proratedRent));
  };
  useEffect(() => {
    // Check if all required data is present before trying to access it
    if (!date || !apartment || !apartmentandbuilding) {
      console.error(
        "Missing necessary data from location.state",
        date,
        apartment,
        apartmentandbuilding
      );
      setLoading(false); // Set loading false to show error or missing data message
      return; // Prevent further execution
    }
    setLoading(false);
    window.scrollTo(0, 0); // Data is present, so you can turn off loading
  }, [date, apartment, apartmentandbuilding]); // Ensure useEffect runs when any of these dependencies update

  useEffect(() => {
    if (userId && fundingOption) {
      checkCashBooking(userId, fundingOption);
      console.log("the values", userId, fundingOption);
    }
  }, [userId, fundingOption]);

  useEffect(() => {
    if (userId) {
      nsfasBooking_check();
    }
  }, [userId]);

  useEffect(() => {
    if (userId) {
      cashBooking_check();
    }
  }, [userId]);

  function isNullOrUndefined(value) {
    return value == null || value == undefined || value == "";
  }

  const totalRent = apartment?.ApartmentPrice;

  const handleSelection = (value, amount) => {
    setSelectedPlan(amount);
    handleCardClick(value, amount);
  };

  const checkCashBooking = async (userId, fundingType) => {
    try {
      const response = await fetch(
        `https://backendjaes.passiontrials.co.za/api/cash_booking?userId=${userId}&fundingType=${fundingType}`
      );

      if (!response.ok) {
        throw new Error("Failed to fetch booking status");
      }

      const data = await response.json(); // Parse the JSON response

      setHasCashedBooked(data.hasBooking);
      console.log("cash booking", data.hasBooking);
      return data.hasBooking; // Returns true or false
    } catch (err) {
      console.error("Error fetching booking status:", err);
      throw new Error("Failed to fetch booking status");
    }
  };

  useEffect(() => {
    console.log("normal isPlaced value", isPlaced);
    console.log("nsfas isPlaced2 value", isPlaced2);
  }, []);

  const checkPriorBooking = () => {
    fetch(`https://backendjaes.passiontrials.co.za/user_has_booking/${userId}`)
      .then((response) => response.json())
      .then((data) => {
        if (data.hasBooking) {
          console.log("User has already booked a room", data.hasBooking);
          setHasBookedRoom(true);
        } else {
          console.log("User has not booked a room", data.hasBooking);
          setHasBookedRoom(false);
        }
      })
      .catch((error) => {
        console.error("Error checking booking status:", error);
      });
  };

  const cashBooking_check = () => {
    fetch(`https://backendjaes.passiontrials.co.za/other_cash_booking_check/${userId}`)
      .then((response) => response.json())
      .then((data) => {
        if (data.hasBooking) {
          console.log(
            "Self or Bursary User has already been included in the list",
            data.hasBooking
          );
          setIsPlaced(true);
        } else {
          console.log(
            "Self or Bursary User has not been included yet",
            data.hasBooking
          );
          setIsPlaced(false);
        }
      })
      .catch((error) => {
        console.error("Error checking booking status:", error);
      });
  };

  const nsfasBooking_check = () => {
    fetch(`https://backendjaes.passiontrials.co.za/nsfas_booking_check/${userId}`)
      .then((response) => response.json())
      .then((data) => {
        if (data.hasBooking) {
          console.log(
            "NSFAS User: has already been included in the list",
            data.hasBooking
          );
          setIsPlaced2(true);
        } else {
          console.log("NSFAS User: has not been included yet", data.hasBooking);
          setIsPlaced2(false);
        }
      })
      .catch((error) => {
        console.error("Error checking booking status:", error);
      });
  };
  useEffect(() => {
    const fetchAdminData = async () => {
      try {
        console.log("BuildingId:", apartment?.BuildingId); // Log BuildingId here

        if (!apartment?.BuildingId) {
          console.error("Missing BuildingId in apartment data");
          return;
        }

        const response = await fetch(
          `https://backendjaes.passiontrials.co.za/api/admin-data?buildingId=${apartment.BuildingId}`
        );

        if (!response.ok) {
          throw new Error(`Error: ${response.statusText}`);
        }

        const data = await response.json();
        setAdminData(data); // Update state with admin data

        console.log("Admin Data:", data); // Display fetched admin data in console
      } catch (error) {
        console.error("Error fetching admin data:", error);
      }
    };

    fetchAdminData();
  }, [apartment]);

  useEffect(() => {
    if (isLoggedIn && userId) {
      setShowRegistrationModal(false);
      const fetchUserData = async () => {
        try {
          const response = await fetch(
            `https://backendjaes.passiontrials.co.za/user_reserve/${userId}`,
            {
              method: "GET",
              headers: {
                "Content-Type": "application/json",
              },
            }
          );

          if (!response.ok) {
            throw new Error("Failed to fetch user data");
          }

          const data = await response.json();
          setUserData(data.length ? data[0] : data);

          console.log("dsdasdasadasd", data);

          setFundingOption(
            data[0].TypeOfFunding.toLowerCase().replace(" ", "-")
          );

          const funding = data[0].TypeOfFunding.toLowerCase().replace(" ", "-");
          const Designation =
            apartment?.RoomDesignation?.toLowerCase()?.replace(" ", "-");

          console.log(
            "the funding type",
            funding,
            "the actual from the database",
            data[0].TypeOfFunding
          );
          console.log(
            `this is the funding --${funding}  type and this is the Room Designation-- `,
            Designation
          );

          if (funding == "nsfas") {
            setTransport("no");
            console.log(
              `this is the funding ${funding}  type and this is the `,
              Designation
            );

            if (isNullOrUndefined(Designation) || Designation !== "nsfas") {
              Swal.fire({
                title: "Non-supported Apartment",
                text: "This apartment is not for NSFAS users. Please choose an NSFAS-supported apartment.",
                icon: "warning",
              });
              console.log(
                `this is the user funding $ ${funding}  type and this is the room $ `,
                Designation
              );
              navigate(-1);
            }
          }

          setShowRegistrationModal(false);
        } catch (error) {
          console.error("Error fetching user data:", error);
        }
      };
      fetchUserData();
    } else {
      setShowRegistrationModal(true);
    }
  }, [isLoggedIn, userId, apartment, navigate]);

  // const nextStep = () => setStep((prevStep) => Math.min(prevStep + 1, 5));
  const nextStep = () => {
    setStep((prevStep) => {
      // Skip Step 2 if fundingOption is "nsfas" or Package is "Basic"
      if (fundingOption === "nsfas" && prevStep === 1) {
        return 3; // Skip directly to Step 3
      }
      return Math.min(prevStep + 1, 5); // Ensure step stays within the range of 1 to 5
    });
  };

  const prevStep = () => {
    setStep((prevStep) => {
      // Skip Step 2 if fundingOption is "nsfas" or Package is "Basic"
      if (fundingOption === "nsfas" && prevStep === 3) {
        return 1; // Go directly back to Step 1
      }
      return Math.max(prevStep - 1, 1); // Ensure minimum step is 1
    });
  };
  // const prevStep = () => setStep((prevStep) => Math.max(prevStep - 1, 1));

  const handleFundingChange = (event) => {
    setFundingOption(event.target.value);

    // if (selectedFunding === "nsfas") {
    //     setTransport("yes");
    // } else {
    //     setTransport(""); // Reset transport for other funding types
    // }
  };

  //   const handleCardClick = (planCost) => {
  //     const planLabel =
  //       planCost === 0
  //         ? "Pay R0"
  //         : planCost === apartment.ApartmentPrice / 2
  //         ? "Pay 50% Rent"
  //         : "Pay in Full";

  //     setChosenPlan(planLabel);
  //     setSelectedPlan(planCost);

  //     nextStep();
  //   };

  const handleCardClick = (planIdentifier, amount) => {
    const planLabel =
      planIdentifier === "zero-rent"
        ? "Pay R0 Rent Promo"
        : planIdentifier === "pro-rata"
          ? "Pro Rata"
          : "Unknown Plan";

    setChosenPlan(planLabel);

    setSelectedPlan(amount);

    console.log("The chosen plan $$$:", planLabel, typeof planLabel);
    console.log("The plan identifier $$$:", planIdentifier, typeof planIdentifier);

    nextStep();
  };

  // Define state for each checkbox item

  if (step === 0) {
    // Show the front page first
    return <FrontPage onContinue={() => setStep(1)} />;
  }

  const allCheckboxesChecked =
    isEmergencyChecked &&
    isHouseRulesChecked &&
    isIncidentFormChecked &&
    isLeaseClauseChecked;

  const handlePayment2 = async () => {
    try {
      const amountInCents = Math.round(parseFloat(totalCost) * 100);
      const response = await fetch("https://backendjaes.passiontrials.co.za/api/pay", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          amount: amountInCents,
          currency: "ZAR",
          successUrl: `https://jaesstudenthomes.co.za/Payment?status=success&user=50`,
          cancelUrl: "https://jaesstudenthomes.co.za/cancel?status=cancel",
        }),
      });

      if (!response.ok) {
        const errorText = await response.text();
        throw new Error(`Error: ${response.status} - ${errorText}`);
      }

      const data = await response.json();
      console.log("Payment response:", data);

      if (data.redirectUrl) {
        window.location.href = data.redirectUrl;
      }
    } catch (error) {
      console.error("Payment error:", error);
    }
  };

  const generatePDF = async () => {
    setClickedAction("downloadPDF");
    if (userData) {
      try {
        console.log("it got in", userData);
        // Fetch the PDF file as an array buffer
        const response = await fetch(
          "https://jaesstudenthomes.co.za/2STUDENT%20HOMES%20BOOKING%20FORM%202025.pdf"
        );
        const pdfBytes = await response.arrayBuffer();
        console.log("PDF Bytes:", pdfBytes);

        // Load PDF using pdf-lib
        const pdfDoc = await PDFDocument.load(pdfBytes);
        const firstPage = pdfDoc.getPages()[0];

        // Add user data to the PDF

        firstPage.drawText(`${userData.Name} ${userData.Surname}`, {
          x: 230,
          y: 535,
          size: 12,
          color: rgb(0, 0, 0),
        });
        firstPage.drawText(userData.IdNumber || userData.PassportNumber, {
          x: 230,
          y: 510,
          size: 12,
          color: rgb(0, 0, 0),
        });
        firstPage.drawText(apartmentandbuilding.RoomIdentifier, {
          x: 180,
          y: 790,
          size: 12,
          color: rgb(0, 0, 0),
        });
        if (transport === "yes") {
          firstPage.drawText("X", {
            x: 385,
            y: 490,
            size: 12,
            color: rgb(0, 0, 0),
          });
        }

        firstPage.drawText("X", {
          x: 230,
          y: 490,
          size: 12,
          color: rgb(0, 0, 0),
        });
        firstPage.drawText(apartmentandbuilding.RoomIdentifier, {
          x: 405,
          y: 50,
          size: 10,
          color: rgb(0, 0, 0),
        });
        const formattedTotal = `R${totalCost.toFixed(2)}`;
        firstPage.drawText(formattedTotal, {
          x: 405,
          y: 35,
          size: 10,
          color: rgb(0, 0, 0),
        });

        firstPage.drawText(apartmentandbuilding.BuildingLocation || "N/A", {
          x: 230,
          y: 465,
          size: 12,
          color: rgb(0, 0, 0),
        });
        firstPage.drawText(userData.Email, {
          x: 265,
          y: 440,
          size: 12,
          color: rgb(0, 0, 0),
        });
        firstPage.drawText(userData.PhoneNumber, {
          x: 255,
          y: 415,
          size: 12,
          color: rgb(0, 0, 0),
        });
        firstPage.drawText(new Date().toLocaleDateString("en-GB"), {
          x: 170,
          y: 85,
          size: 12,
          color: rgb(0, 0, 0),
        });

        const formatName = (fullName) => {
          const nameParts = fullName.split(" ");
          const initials = nameParts
            .slice(0, -1)
            .map((name) => name[0].toUpperCase() + ".")
            .join(""); // Get initials for all but the last part
          const surname = nameParts[nameParts.length - 1]; // The last part is the surname
          return `${initials} ${surname}`;
        };

        // Apply the function
        const formattedName = formatName(
          `${userData.Name} ${userData.Surname}`
        );
        firstPage.drawText(formattedName, {
          x: 170,
          y: 65,
          size: 12,
          color: rgb(0, 0, 0),
        });

        // Save the modified PDF to a blob and trigger download
        const pdfDataUri = await pdfDoc.saveAsBase64({ dataUri: true });
        const link = document.createElement("a");
        link.href = pdfDataUri;
        link.download = `Booking_Form_${Date.now()}.pdf`;
        document.body.appendChild(link); // Append link to the DOM for Firefox support
        link.click();
        document.body.removeChild(link); // Remove link after download

        console.log("PDF successfully created and downloaded.");
        await sendEmailToSelfAndBursary();
      } catch (error) {
        console.error("Error creating PDF:", error.message);
        console.error("Error details:", error);
      }
    } else {
      console.error("User data is not available for generating PDF");
    }
  };

  const Toast = Swal.mixin({
    toast: true,
    position: "top",
    showConfirmButton: false,
    timer: 5000,
    timerProgressBar: true,
    didOpen: (toast) => {
      toast.onmouseenter = Swal.stopTimer;
      toast.onmouseleave = Swal.resumeTimer;
    }
  });


  const handleEftPay = async () => {
    setProgress2(true);
    try {
      if (isPlaced == false) {
        await handleEFT();

        await generatePDF();

        checkCashBooking(userId, fundingOption);
        setProgress2(false);
        Toast.fire({
          icon: "info",
          title: "You will be Redirected in a few seconds and await your approval..."
        });
        setTimeout(() => {
          navigate('/userDashboard', { replace: true })
        }, 5000);
      } else {
        Swal.fire({
          title: "Booking Twice error",
          text: "You have already booked, and waiting for approval, which you will be notified through your email",
          icon: "warning",
        });
      }
    } catch (error) {
      console.error("Error handling Pay via EFT and PDF generation:", error);
    }
  };
  // Function to handle payment
  const handlePayment = () => {
    setClickedAction("pay"); // Set action as 'pay'

    if (role === "Admin" || role === "Super Admin") {
      alert("Admins are not permitted to book an apartment");
      navigate(-1);
      return;
    }

    if (selectedPlan && allCheckboxesChecked) {
      if (!isLoggedIn) {
        // Show the registration modal if user is not logged in
        setShowRegistrationModal(true);
        return;
      }

      setLoading(true);
      setTimeout(() => {
        setLoading(false);
        navigate("/YocoPayment", {
          state: {
            date,
            apartment,
            apartmentandbuilding: apartmentandbuilding.BuildingId,
            totalCost,
            serviceFee,
            chosenPlan,
            nextPaymentDate,
            Email: userData.Email,
          },
        });
      }, 5000);
    } else if (!allCheckboxesChecked) {
      alert("Please agree to all the terms by checking the boxes.");
    } else {
      alert("Please select a payment plan");
    }
  };

  const handleGender = (binary) => {
    if (binary) {
      if (binary == 0) {
        return "Female";
      }

      if (binary == 1) {
        return "Male";
      }

      if (binary == 2) {
        return "Mixed";
      }
    }
  };

  const handleSetup = (binary) => {
    if (binary) {
      if (binary == 0) {
        return "Single";
      }

      if (binary == 1) {
        return "Single with Ensuite";
      }

      if (binary == 2) {
        return "Sharing Double";
      }

      if (binary == 3) {
        return "Sharing Double with Ensuite";
      }

      if (binary == 4) {
        return "Sharing Triple";
      }

      if (binary == 5) {
        return "Bachelor Pods";
      }
    }
  };

  const handleSelectedPlan = (e) => {
    const { name, value } = e.target;
    let plan = e?.target.options[e?.target.selectedIndex]?.text;
    setSelectedPayment(Number(value));
    console.log("this is the plan amount chosen", Number(value) + amount);
    // setTotalAmount(Number(value) + amount);
    setServiceFee(Number(value));

    setChosenPlan(plan);

    let splited = plan.split("-")[0].trim();

    console.log("its split :", splited);
    if (splited == "Plan A") {
      amount = apartment?.ApartmentPrice;
      //represents plan a
      console.log("plan a:,", amount);
      setThePrice(amount);
      setTotalAmount(amount + Number(value));
    }

    if (splited == "Plan B") {
      amount = apartment?.PriceB;
      console.log("plan b:,", amount);
      setThePrice(amount);
      setTotalAmount(amount + Number(value));
    }
  };

  if (loading) {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh", // This ensures it takes the full viewport height
        }}
      >
        <div class="loader">
          <div class="circle">
            <div class="dot"></div>
            <div class="outline"></div>
          </div>
          <div class="circle">
            <div class="dot"></div>
            <div class="outline"></div>
          </div>
          <div class="circle">
            <div class="dot"></div>
            <div class="outline"></div>
          </div>
          <div class="circle">
            <div class="dot"></div>
            <div class="outline"></div>
          </div>
        </div>
        ;{" "}
      </div>
    ); // Show loading while checking for data
  }

  if (!date || !apartment || !apartmentandbuilding) {
    return (
      <div>
        Error: Missing invoice data. Please ensure all data is provided.
      </div>
    ); // Show error if data is missing
  }
  // Callback function after successful registration
  const handleRegistrationSuccess = () => {
    setShowRegistrationModal(false); // Close the modal

    // Debug log to check which action is triggered
    console.log("Registration Success:", clickedAction);

    if (downloadAfterLogin || clickedAction === "downloadPDF") {
      console.log("Downloading PDF after login..."); // Check if this line is reached
      generatePDF(); // Call `generatePDF()` to download the PDF
      setDownloadAfterLogin(false); // Reset the flag
    } else if (clickedAction === "pay") {
      console.log("Redirecting to YocoPayment...");
      navigate("/ReserveYocoPayment", {
        state: {
          /* your existing parameters */
        },
      });
    }
  };
  // Format dates
  // const pickedDate = new Date(date).toLocaleDateString("en-US", {
  //   day: "numeric",
  //   month: "short",
  //   year: "numeric",
  // }); //current date no picking
  const nextPaymentDate = new Date(date);
  nextPaymentDate.setMonth(nextPaymentDate.getMonth() + 3);

  nextPaymentDate.setDate(
    new Date(
      nextPaymentDate.getFullYear(),
      nextPaymentDate.getMonth() + 1,
      0
    ).getDate()
  );

  ///= apartment.ApartmentPrice;
  let currentDay = date.toLocaleDateString("en-US", { day: "numeric" });
  let earlyDate = 0;
  let lateDate = 0;
  let AmountAfterDeduction = 0;
  let AmountAfterIncrease = 0;

  const handleFileChange = (event) => {
    setSelectedFile(event.target.files[0]);
  };

  const handleUpload = async () => {
    const formData = new FormData();
    formData.append("image", selectedFile);

    try {
      const response = await fetch("https://backendjaes.passiontrials.co.za/upload", {
        headers: { "Content-Type": "multipart/form-data" },
        method: "POST",
        body: formData,
      });

      if (response.ok) {
        const data = await response.json();
        console.log("Image uploaded successfully: ", data);
      } else {
        console.error("Error uploading image:");
      }
    } catch (error) {
      console.error("error uploading image:", error);
    }
  };
  const sendEmailToStudent = async () => {
    try {
      if (isPlaced2 == false) {
        Swal.fire({
          title: "Booking Approval?",
          text: "An email will be sent to you shortly",
          icon: "success",
        });

        const response = await fetch(
          "https://backendjaes.passiontrials.co.za/sendBookingEmailForNSFAS",
          {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify({
              apartmentId: "NSFAS Booking",
              userId,
              name: `${userData?.Name} ${userData?.Surname}`,
              IdNumber: `${userData?.IdNumber}`,
              BookingNo: `${apartment?.RoomIdentifier}`,
              // Date: new Date().toLocaleDateString('en-GB'),
              Date: new Date().toLocaleString("en-US", {
                day: "numeric",
                month: "short",
                year: "numeric",
              }),
              Cell: `${userData?.PhoneNumber}`,
              Address: `${apartmentandbuilding?.BuildingLocation}`,
              Transport: `${transport}`,
              Homes: "Accommodation",
              userEmail: `${userData?.Email}`,
              totalCost: `${totalCost}`, // Pass totalCost

            }),
          }
        );

        if (!response.ok) {
          const errorText = await response.text();
          throw new Error(`Failed to send booking email: ${errorText}`);
        } else {
          console.log("Booking email sent successfully!");
          console.log("dta", {
            UserId: userId,
            ApartmentId: apartment?.ApartmentId,
            BookNumber: apartment?.RoomIdentifier,
            Date: new Date().toLocaleString("en-US", {
              day: "numeric",
              month: "short",
              year: "numeric",
              hour: "numeric",
              minute: "numeric",
              second: "numeric",
            }),
            Name: userData?.Name,
            Surname: userData?.Surname,
            Email: userData?.Email,
            ApprovalStatus: "Pending",
            Approval: false,
          });
          const makeARecord = await fetch(
            "https://backendjaes.passiontrials.co.za/nsfas-bookings",
            {
              method: "POST",
              headers: { "Content-type": "application/json" },
              body: JSON.stringify({
                UserId: userId,
                ApartmentId: apartment?.ApartmentId,
                BookNumber: apartment?.RoomIdentifier,
                Date: new Date().toLocaleString("en-US", {
                  day: "numeric",
                  month: "short",
                  year: "numeric",
                  hour: "numeric",
                  minute: "numeric",
                  second: "numeric",
                }),
                Name: userData?.Name,
                Surname: userData?.Surname,
                Email: userData?.Email,
                ApprovalStatus: "Pending",
                Approval: false,
              }),
            }
          );

          if (!makeARecord.ok) {
            const errorText = await response.text();
            throw new Error(
              `Failed to record booking information : ${errorText}`
            );
          } else {
            nsfasBooking_check();
            console.log("Booking record saved successfully!");
          }
        }

        console.log("the NSFAS students");
      } else {
        Swal.fire({
          title: "Booking Twice Error",
          text: "You have already booked, and waiting for approval, which you will be notified through your email",
          icon: "warning",
        });
      }
    } catch (error) {
      console.log("Error sending booking email for NSFAS student", error);
    }
  };
  const sendEmailToSelfAndBursary = async () => {
    try {
      if (isPlaced == false) {
        Swal.fire({
          title: "EFT Booking Approval",
          text: "An email will be sent to you shortly",
          icon: "success",
        });

        const response = await fetch(
          "https://backendjaes.passiontrials.co.za/sendBookingEmailForNSFAS",
          {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify({
              apartmentId: "Normal Booking",
              userId,
              name: `${userData?.Name} ${userData?.Surname}`,
              IdNumber: `${userData?.IdNumber}`,
              BookingNo: `${apartment?.RoomIdentifier}`,
              // Date: new Date().toLocaleDateString('en-GB'),
              Date: new Date().toLocaleString("en-US", {
                day: "numeric",
                month: "short",
                year: "numeric",
              }),
              Cell: `${userData?.PhoneNumber}`,
              Address: `${apartmentandbuilding?.BuildingLocation}`,
              Transport: `${transport}`,
              Homes: "Accommodation",
              userEmail: `${userData?.Email}`,
              totalCost: `${totalCost}`, // Pass totalCost

            }),
          }
        );

        if (!response.ok) {
          const errorText = await response.text();
          throw new Error(`Failed to send booking email: ${errorText}`);
        } else {
          console.log("cash Booking email sent successfully!");
          console.log("dta", {
            UserId: userId,
            ApartmentId: apartment?.ApartmentId,
            BookNumber: apartment?.RoomIdentifier,
            Date: new Date().toLocaleString("en-US", {
              day: "numeric",
              month: "short",
              year: "numeric",
              hour: "numeric",
              minute: "numeric",
              second: "numeric",
            }),
            Name: userData?.Name,
            Surname: userData?.Surname,
            Email: userData?.Email,
            ApprovalStatus: "Pending",
            Approval: false,
          });
          // const makeARecord = await fetch("https://backendjaes.passiontrials.co.za/nsfas-bookings", {
          //     method: 'POST',
          //     headers: { 'Content-type': 'application/json' },
          //     body: JSON.stringify({
          //         UserId: userId,
          //         ApartmentId: apartment?.ApartmentId,
          //         BookNumber: apartment?.RoomIdentifier,
          //         Date: new Date().toLocaleString('en-US', { day: 'numeric', month: 'short', year: 'numeric', hour: 'numeric', minute: 'numeric', second: 'numeric' }),
          //         Name: userData?.Name,
          //         Surname: userData?.Surname,
          //         Email: userData?.Email,
          //         ApprovalStatus: 'Pending',
          //         Approval: false
          //     })
          // });

          // if (!makeARecord.ok) {
          //     const errorText = await response.text();
          //     throw new Error(`Failed to record booking information : ${errorText}`);
          // } else {
          //     nsfasBooking_check();
          //     console.log('Booking record saved successfully!');
          // }
        }

        console.log("the self & bursary students");
      } else {
        Swal.fire({
          title: "Booking Twice Error",
          text: "You have already booked, and waiting for approval, which you will be notified through your email",
          icon: "warning",
        });
      }
    } catch (error) {
      console.log(
        "Error sending cash booking email for self & bursary  student",
        error
      );
    }
  };

  const handleEFT = async () => {
    const makeARecord = await fetch(
      "https://backendjaes.passiontrials.co.za/api/eft-bookings",
      {
        method: "POST",
        headers: { "Content-type": "application/json" },
        body: JSON.stringify({
          UserId: userId,
          ApartmentId: apartment?.ApartmentId,
          BookingNumber: apartment?.RoomIdentifier,
          Date: new Date().toLocaleString("en-US", {
            day: "numeric",
            month: "short",
            year: "numeric",
            hour: "numeric",
            minute: "numeric",
            second: "numeric",
          }),
          Name: userData?.Name,
          Surname: userData?.Surname,
          Email: userData?.Email,
          ApprovalStatus: "Pending",
          Approval: false,
          PaidTransport: transportCost == 0 ? false : true,
          Amount: totalCost,
          ChosenMoveInDate: moveInDate,
          DaysPaidFor: payIn == 'PayNow' ? outsideDaysUsed + daysInBetween : daysInBetween,

        }),
      }
    );

    if (!makeARecord.ok) {
      const errorText = await makeARecord.text();
      throw new Error(
        `Failed to record eft booking information : ${errorText}`
      );
    } else {
      cashBooking_check();
      console.log(" eft Booking record saved successfully!");
    }
  };

  //   const handleBookingPayment = () => {
  //     setClickedAction("pay"); // Set action as 'pay'

  //     if (role === "Admin" || role === "Super Admin") {
  //       alert("Admins are not permitted to book an apartment");
  //       navigate(-1);
  //       return;
  //     }

  //     if (isLoggedIn) {
  //       // if (!isLoggedIn) {
  //       // Show the registration modal if user is not logged in
  //       // setShowRegistrationModal(true);
  //       // return;
  //       // }
  //       setProgress(true);
  //       const room = apartment.RoomDesignation;
  //       if (room == "Self Funded" || isNullOrUndefined(room)) {
  //         setTimeout(() => {
  //           setProgress(false);
  //           if (isPlaced === false) {
  //             navigate("/YocoPayment", {
  //               state: {
  //                 date,
  //                 apartment,
  //                 apartmentandbuilding: apartmentandbuilding.BuildingId,
  //                 totalCost,
  //                 serviceFee,
  //                 chosenDefault: chosenPlan,
  //                 nextPaymentDate,
  //                 Email: userData.Email,
  //               },
  //             });
  //           } else {
  //             Swal.fire({
  //               title: "Booking Twice error",
  //               text: "You have already booked, and waiting for approval, which you will be notified through your email",
  //               icon: "warning",
  //             });
  //           }
  //         }, 5000);
  //       } else {
  //         setTimeout(() => {
  //           setProgress(false);

  //           if (isPlaced2 === false) {
  //             sendEmailToStudent();
  //             checkCashBooking(userId, fundingOption); // does not reach thus point
  //             nsfasBooking_check(); //and this
  //           } else {
  //             Swal.fire({
  //               title: "Booking Twice error",
  //               text: "You have already booked, and waiting for approval, which you will be notified through your email",
  //               icon: "warning",
  //             });
  //           }
  //         }, 5000);
  //       }
  //     } else {
  //       console.log("User not logged in, opening registration modal");
  //       setShowRegistrationModal(true);
  //       return;
  //     }
  //   };

  // if (currentDay > 1 && currentDay < 25) {

  //     currentDay = currentDay - 1;
  //     earlyDate = currentDay / 100 * amount;

  //     AmountAfterDeduction = amount - earlyDate;

  //     amount = AmountAfterDeduction;

  // }
  // else if (currentDay > 24 && currentDay <= 31) {
  //     currentDay = currentDay - 1;
  //     lateDate = currentDay / 100 * amount;

  //     AmountAfterIncrease = lateDate + amount;

  //     amount = AmountAfterIncrease;

  //     nextPaymentDate.setMonth(nextPaymentDate.getMonth() + 1, 1);

  // }

  const handleBookingPayment = () => {
    setClickedAction('pay'); // Set action as 'pay'

    if (role === 'Admin' || role === 'Super Admin') {
      alert('Admins are not permitted to book an apartment');
      navigate(-1);
      return;
    }

    if (isLoggedIn) {
      // if (!isLoggedIn) {
      // Show the registration modal if user is not logged in
      // setShowRegistrationModal(true);
      // return;
      // }
      setProgress(true);
      const room = apartment.RoomDesignation;
      if (room?.trim().toLowerCase().replace(/\s+/g, ' ') === 'self funded' || isNullOrUndefined(room)) {
        console.log('normal booking');

        setTimeout(() => {
          setProgress(false);
          if (isPlaced === false) {

            navigate('/YocoPayment', { state: { date, userPickedDate: moveInDate, apartment, apartmentandbuilding: apartmentandbuilding.BuildingId, totalCost, serviceFee, chosenDefault: chosenPlan, nextPaymentDate, Email: userData.Email, paidDays: payIn == 'PayNow' ? outsideDaysUsed + daysInBetween : daysInBetween, paidTransport: transport == 'yes' ? true : false } });

          }
          else {
            Swal.fire({
              title: "Booking Twice error",
              text: "You have already booked, and waiting for approval, which you will be notified through your email",
              icon: "warning"
            });

          }

        }, 5000);
      }
      else {
        setTimeout(() => {
          setProgress(false);


          if (isPlaced2 === false) {
            sendEmailToStudent();
            checkCashBooking(userId, fundingOption); // does not reach thus point
            nsfasBooking_check(); //and this 
            
            Toast.fire({
              icon: "info",
              title: "You will be Redirected in a few seconds and await your approval..."
            });
            setTimeout(() => {
              navigate('/userDashboard', { replace: true })
            }, 5000);
          }
          else {
            Swal.fire({
              title: "Booking Twice error",
              text: "You have already booked, and waiting for approval, which you will be notified through your email",
              icon: "warning"
            });

          }



        }, 5000);


      }


    }
    else {
      console.log("User not logged in, opening registration modal");
      setShowRegistrationModal(true);
      return;
    }
  };
  const fetchBookings = () => {
    setLoading(true);
    fetch("https://backendjaes.passiontrials.co.za/api/nsfas-bookings")
      .then((res) => res.json())
      .then((data) => {
        setBookings(data);
        setLoading(false);
      })
      .catch((err) => {
        console.error("Error fetching bookings:", err);
        // setError("Failed to fetch bookings.");
        setLoading(false);
      });
  };

  const formatName = (fullName) => {
    const nameParts = fullName.split(" ");
    const initials = nameParts
      .slice(0, -1)
      .map((name) => name[0].toUpperCase() + ".")
      .join(""); // Get initials for all but the last part
    const surname = nameParts[nameParts.length - 1]; // The last part is the surname
    return `${initials} ${surname}`;
  };

  // Apply the function
  const formattedName = formatName(`${userData?.Name} ${userData?.Surname}`);

  return (
    <>
      <div class="containerinvoice">
        <div className="rounded-lg lg:shadow-lg lg:p-3 lg:border border-gray-100 booking-summary">
          <div>
            <div className="flex">
              <p className="content-font uppercase font-semibold text-gray-500">
                Booking Summary
              </p>
            </div>
            <img
              alt="Classic Ensuite"
              src={
                apartment.ApartmentRoomImage
                  ? `https://backendjaes.passiontrials.co.za/src${apartment.ApartmentRoomImage}`
                  : defaults
              }
              width="301"
              height="200"
              className="w-full h-40 object-cover rounded-md my-2"
            />
          </div>
          <div className="text-gray-700">
            <h3 className="subsection-module__title">
              {apartmentandbuilding.BuildingName}
            </h3>
            <ul className="content-font">
              <li className="flex justify-between pb-0.5 flex-col lg:flex-row mb-2 lg:mb-1">
                <span className="flex-shrink-0 lg:mr-2 font-semibold">
                  Room Type
                </span>
                <span>{apartment.LivingArrangement}</span>
              </li>
              <li className="flex justify-between mb-2 lg:mb-1">
                <span className="flex-shrink-0 lg:mr-2 font-semibold">
                  Suburb
                </span>
                <span>{apartmentandbuilding.Suburb}</span>
              </li>
            </ul>
            <div className="divider my-3"></div>
            <ul className="content-font">
              <li className="flex items-center gap-x-6 justify-between md:justify-start">
                <div>
                  <p>Move-in</p>
                  <p className="text-base font-semibold">
                    {pickedDate || new Date().toLocaleDateString('en-US', { day: 'numeric', month: 'short', year: 'numeric' })}
                  </p>
                </div>
                <svg
                  className="svg"
                  fill="none"
                  stroke="currentColor"
                  strokeWidth="2.5"
                  width="40"
                  height="40"
                  viewBox="0 0 24 24"
                >
                  <path
                    d="M8.25 4.5l7.5 7.5-7.5 7.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  ></path>
                </svg>
                <div className="text-right md:text-left">
                  <p>Move-out</p>
                  <p className="text-base font-semibold">30 Nov 2025</p>
                </div>
              </li>
            </ul>
            <div className="divider my-3"></div>
            <ul className="mb-2">
              <li className="flex justify-between">
                <span className="font-semibold">Rent</span>
                {fundingOption === "nsfas" ? (
                  <span className="text-theme-orange font-semibold">
                    NSFAS Funded
                  </span>
                ) : (
                  <span className="text-theme-orange font-semibold">
                    R{apartment.ApartmentPrice}
                  </span>
                )}
              </li>

              <li className="flex justify-between pb-0.5">
                <span className="content-font">Service Fee</span>
                <span>R{serviceFeeCost}</span>
              </li>
              {fundingOption !== "nsfas" ? (
                <li className="flex items-center gap-x-6 justify-between md:justify-start">
                  <span className="content-font" style={{ padding: "2px" }}>
                    Transport
                  </span>
                  <span>R{transportCost}</span>
                </li>
              ) : (
                <li className="flex items-center gap-x-6 justify-between md:justify-start">
                  <span className="content-font" style={{ padding: "2px" }}>
                    Transport
                  </span>
                  <span className="strikethrough">R{250}</span>
                </li>
              )}
            </ul>
          </div>
        </div>

        {/* Render the registration modal */}
        <div className="full-page-content">
          {/* <span className="close-btn" onClick={handleClose}>×</span> */}
          <div
            className="move-to-top-desktop"
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <h1 className="subsection-module__title dsdsfsfdsfdsfdsdsfds">
              Hi, let's complete your booking form
            </h1>
            <img
              src={logo}
              alt="Logo"
              className="logo"
              style={{
                width: "100px", // Adjust width as needed
                height: "auto", // Maintain aspect ratio
              }}
            />
          </div>

          <style>{`
  @media screen and (min-width: 768px) {
   .dsdsfsfdsfdsfdsdsfds {
      position: relative;
      top: -20px; /* Move this value to adjust the distance */
    }
    .move-to-top-desktop {
      position: relative;
      top: -20px; /* Move this value to adjust the distance */
    }
  }
`}</style>

          <div
            className="step-progress-container"
          >
            <h2 className="subsection-module__title">Step {step}/5: </h2>
            <div className="progress-container subsection-module__title">
              {(fundingOption === "nsfas" ? [1, 3, 4, 5] : [1, 2, 3, 4, 5]).map(
                (bar) => (
                  <div
                    key={bar}
                    className={`progress-bar ${step >= bar ? "filled" : ""}`}
                  />
                )
              )}
            </div>
          </div>

          <style>{` @media screen and (min-width: 768px) {
    .step-progress-container {
      position: relative;
      top: -50px; /* Move upward only on desktop */
      margin-bottom: -60px
    }
  }`}

          </style>




          {/* Step Content */}
          {step === 1 && (
            <div>
              {/* Funding Source Title */}
              <label
                style={{
                  textAlign: "left",
                  display: "block",
                  fontSize: "20px",
                }}
              >
                Personal Details <span style={{ color: "red" }}>*</span>
              </label>

              {/* Funding Options (Dynamically Highlighted) */}
              <div className="amber-form-group2">
                <div>
                  <label
                    className={
                      fundingOption === "self-funded" ? "selected" : ""
                    }
                  >
                    <input
                      type="radio"
                      name="fundingOption"
                      value="self-funded"
                      checked={fundingOption === "self-funded"}
                      readOnly
                    />
                    self-funded
                  </label>

                  <label
                    className={fundingOption === "nsfas" ? "selected" : ""}
                  >
                    <input
                      type="radio"
                      name="fundingOption"
                      value="nsfas"
                      checked={fundingOption === "nsfas"}
                      readOnly
                    />
                    NSFAS
                  </label>

                  <label
                    className={fundingOption === "bursary" ? "selected" : ""}
                  >
                    <input
                      type="radio"
                      name="fundingOption"
                      value="bursary"
                      checked={fundingOption === "bursary"}
                      readOnly
                    />
                    Other Bursaries
                  </label>
                </div>
              </div>

              {/* Dynamic Input Fields Based on Funding Option */}

              {(fundingOption === "nsfas" &&
                (isNullOrUndefined(apartment?.RoomDesignation) ||
                  apartment?.RoomDesignation?.toLowerCase() !== "nsfas")) ||
                ((fundingOption === "self-funded" ||
                  fundingOption === "bursary") &&
                  apartment?.RoomDesignation?.toLowerCase() === "nsfas") ? (
                <div>
                  <p
                    style={{
                      color: "red",
                      fontSize: "16px",
                      margin: "20px 0",
                      textAlign: "center",
                    }}
                  >
                    {fundingOption === "nsfas"
                      ? "This room does not support NSFAS-funded students. Please choose another room."
                      : "This room is designated for NSFAS-funded students only. Please choose another room."}
                  </p>
                  <button className="btn456" onClick={() => navigate(-1)}>
                    Go Back
                  </button>
                </div>
              ) : (
                <div className="form-grid">
                  {showLoginPrompt && (
                    <p
                      style={{
                        color: "red",
                        fontSize: "16px",
                        margin: "auto",
                        textAlign: "center",
                      }}
                    >
                      You need to log in to proceed. Please log in to continue
                      with your booking.
                    </p>
                  )}

                  {hasBookedRoom ? (
                    <div
                      style={{
                        color: "red",
                        fontSize: "18px",
                        marginBottom: "20px",
                        margin: "auto",
                        marginTop: "50px",
                      }}
                    >
                      <p
                        style={{
                          color: "red",
                          fontSize: "16px",
                          margin: "auto",
                          textAlign: "center",
                        }}
                      >
                        You have already booked a room. Please go back to review
                        your existing booking.
                      </p>
                      <button className="btn456" onClick={() => navigate(-1)}>
                        Go Back
                      </button>
                    </div>
                  ) : (
                    <>
                      {fundingOption === "self-funded" && (
                        <>
                          <div className="amber-form-group">
                            <div className="bookYourPerfectAccommodation-module__title amber-form-group">
                              <label>
                                Name <span style={{ color: "red" }}>*</span>
                              </label>
                              <input
                                type="text"
                                placeholder="Eg. Single , Married , Etc..."
                                value={userData.Name}
                                required
                              />
                            </div>
                            <div className="bookYourPerfectAccommodation-module__title amber-form-group">
                              <label>
                                Email Address{" "}
                                <span style={{ color: "red" }}>*</span>
                              </label>
                              <input
                                type="text"
                                placeholder="Enter funders address"
                                value={userData.Email}
                                required
                              />
                            </div>

                            <div className="bookYourPerfectAccommodation-module__title amber-form-group">
                              <label>
                                Institution{" "}
                                <span style={{ color: "red" }}>*</span>
                              </label>
                              <input
                                type="text"
                                placeholder="Eg. Single , Married , Etc..."
                                value={userData.InstituteName}
                                required
                              />
                            </div>
                          </div>
                          <div className="amber-form-group">
                            <div className="bookYourPerfectAccommodation-module__title amber-form-group">
                              <label>
                                Surname <span style={{ color: "red" }}>*</span>
                              </label>
                              <input
                                type="text"
                                placeholder="Enter funders address"
                                value={userData.Surname}
                                required
                              />
                            </div>

                            <div className="bookYourPerfectAccommodation-module__title amber-form-group">
                              <label>
                                Contact <span style={{ color: "red" }}>*</span>
                              </label>
                              <input
                                type="text"
                                placeholder="Eg. Single , Married , Etc..."
                                value={userData.PhoneNumber}
                                required
                              />
                            </div>
                            <div className="bookYourPerfectAccommodation-module__title amber-form-group">
                              <label>
                                Home Address{" "}
                                <span style={{ color: "red" }}>*</span>
                              </label>
                              <input
                                type="text"
                                placeholder="Enter Home address"
                                value={userData.HomeAddress}
                                required
                              />
                            </div>
                          </div>
                        </>
                      )}

                      {fundingOption === "nsfas" && (
                        <>
                          <div className="amber-form-group">
                            <div className="bookYourPerfectAccommodation-module__title amber-form-group">
                              <label>
                                NSFAS Reference Number{" "}
                                <span style={{ color: "red" }}>*</span>
                              </label>
                              <input
                                type="text"
                                placeholder="Enter NSFAS reference number"
                                value={userData.NsfasReferenceNumber}
                                required
                              />
                            </div>
                            <div className="bookYourPerfectAccommodation-module__title amber-form-group">
                              <label>
                                Name <span style={{ color: "red" }}>*</span>
                              </label>
                              <input
                                type="text"
                                placeholder="Enter your email adress here."
                                value={userData.Name}
                                required
                              />
                            </div>
                            <div className="bookYourPerfectAccommodation-module__title amber-form-group">
                              <label>
                                Institution{" "}
                                <span style={{ color: "red" }}>*</span>
                              </label>
                              <input
                                type="text"
                                placeholder="Enter your home address here."
                                value={userData.InstituteName}
                                required
                              />
                            </div>
                          </div>
                          <div className="amber-form-group">
                            <div className="bookYourPerfectAccommodation-module__title amber-form-group">
                              <label>
                                Email Address{" "}
                                <span style={{ color: "red" }}>*</span>
                              </label>
                              <input
                                type="text"
                                placeholder="Enter NSFAS reference number"
                                value={userData.Email}
                                required
                              />
                            </div>
                            <div className="bookYourPerfectAccommodation-module__title amber-form-group">
                              <label>
                                Surname <span style={{ color: "red" }}>*</span>
                              </label>
                              <input
                                type="text"
                                placeholder="Enter your email adress here."
                                value={userData.Surname}
                                required
                              />
                            </div>
                            <div className="bookYourPerfectAccommodation-module__title amber-form-group">
                              <label>
                                Home Address{" "}
                                <span style={{ color: "red" }}>*</span>
                              </label>
                              <input
                                type="text"
                                placeholder="Enter your home address here."
                                value={userData.HomeAddress}
                                required
                              />
                            </div>
                          </div>
                        </>
                      )}

                      {fundingOption === "bursary" && (
                        <>
                          <div className="amber-form-group">
                            <div className="bookYourPerfectAccommodation-module__title amber-form-group">
                              <label>
                                Bursary Name{" "}
                                <span style={{ color: "red" }}>*</span>
                              </label>
                              <input
                                type="text"
                                placeholder="Enter bursary organization name"
                                value={userData.BurseryOrganizationName}
                                required
                              />
                            </div>
                            <div className="bookYourPerfectAccommodation-module__title amber-form-group">
                              <label>
                                Reference Number{" "}
                                <span style={{ color: "red" }}>*</span>
                              </label>
                              <input
                                type="text"
                                placeholder="Enter bursary reference number"
                                value={userData.BurseryReferenceNumber}
                                required
                              />
                            </div>
                            <div className="bookYourPerfectAccommodation-module__title amber-form-group">
                              <label>
                                Bursary Type{" "}
                                <span style={{ color: "red" }}>*</span>
                              </label>
                              <input
                                type="text"
                                placeholder="Full bursary , partial bursary , Etc..."
                                value={userData.BurseryType}
                                required
                              />
                            </div>
                          </div>
                          <div className="amber-form-group">
                            <div className="bookYourPerfectAccommodation-module__title amber-form-group">
                              <label>
                                Bursary Start Date{" "}
                                <span style={{ color: "red" }}>*</span>
                              </label>
                              <input
                                type="text"
                                placeholder="Enter the date funding starts "
                                value={userData.BurseryStartDate}
                                required
                              />
                            </div>
                            <div className="bookYourPerfectAccommodation-module__title amber-form-group">
                              <label>
                                Bursary End Date{" "}
                                <span style={{ color: "red" }}>*</span>
                              </label>
                              <input
                                type="text"
                                placeholder="Enter the end of your funding for the year. "
                                value={userData.BurseryEndDate}
                                required
                              />
                            </div>
                            <div className="bookYourPerfectAccommodation-module__title amber-form-group">
                              <label>
                                Coverage Details{" "}
                                <span style={{ color: "red" }}>*</span>
                              </label>
                              <input
                                type="text"
                                placeholder="Accomodation, tution , food , transport Etc ... "
                                value={userData.CoverageDetails}
                                required
                              />
                            </div>
                          </div>
                          <div className="amber-form-group">
                            <div className="bookYourPerfectAccommodation-module__title amber-form-group">
                              <label>
                                Bursary Benefactor Email{" "}
                                <span style={{ color: "red" }}>*</span>
                              </label>
                              <input
                                type="text"
                                placeholder="Enter phone number or email. "
                                value={userData.Email}
                                required
                              />
                            </div>
                            <div className="bookYourPerfectAccommodation-module__title amber-form-group">
                              <label>
                                Institution{" "}
                                <span style={{ color: "red" }}>*</span>
                              </label>
                              <input
                                type="text"
                                placeholder="Enter your institution. e.g DUT, MUT "
                                value={userData.InstituteName}
                                required
                              />
                            </div>
                          </div>
                        </>
                      )}
                      {!isLoggedIn ? (
                        <button
                          className="btn456"
                          onClick={() => setShowRegistrationModal(true)}
                        >
                          Login to proceed
                        </button>
                      ) : (
                        <button
                          className="btn456"
                          onClick={nextStep}
                          disabled={!fundingOption}
                        >
                          Next
                        </button>
                      )}
                    </>
                  )}
                </div>
              )}
              {/* Next Button */}
            </div>
          )}

          {step === 2 && (
            <div>
              <label
                style={{
                  textAlign: "left",
                  display: "block",
                  fontSize: "20px",
                }}
              >
                Transport <span style={{ color: "red" }}>*</span>
              </label>

              {adminData?.Package === "Basic" ? (
                <div
                  style={{ marginTop: "20px", color: "red", fontSize: "16px" }}
                >
                  <p
                    style={{
                      backgroundColor: "#f9f9f9",
                      border: "2px solid #d9534f",
                      padding: "10px 15px",
                      fontSize: "16px",
                      lineHeight: "1.5",
                      borderRadius: "8px",
                      color: "#333",
                    }}
                  >
                    <strong>Important:</strong> The building you have selected falls under a{" "}
                    <strong>Basic Package</strong>, which requires transport to be booked
                    separately. Once you have completed this process, you may proceed to book
                    transport through our system. Thank you for understanding.
                  </p>

                </div>
              ) : (
                <div
                  className="transport-options"
                  style={{ marginTop: "20px" }}
                >
                  <div
                    className={`transport-option-wrapper ${transport === "yes" ? "selected" : ""
                      }`}
                    onClick={() =>
                      fundingOption !== "nsfas" && setTransport("yes")
                    }
                  >
                    <input
                      type="radio"
                      name="transport"
                      id="transportYes"
                      value="yes"
                      checked={transport === "yes"}
                      onChange={(e) => setTransport(e.target.value)}
                      disabled={fundingOption === "nsfas"}
                      className="radio-button"
                    />
                    <label htmlFor="transportYes" className="transport-label">
                      <div className="transport-option">
                        <p className="subsection-module__title">
                          Yes, I need transport
                        </p>
                        <p className="subsection-module__subtitle">
                          <span className="discount-highlight">SAVE 45%</span>{" "}
                          R250 <span className="strikethrough">R450</span>
                          <br />
                          Enjoy safe, reliable transport to and from your
                          institution.
                          <br />
                          <span className="discount-highlight">
                            Transport Deal ends 01 Feb 2025.
                          </span>
                        </p>
                      </div>
                    </label>
                  </div>
                  <div
                    className={`transport-option-wrapper ${transport === "no" ? "selected" : ""
                      }`}
                    onClick={() =>
                      fundingOption !== "nsfas" && setTransport("no")
                    }
                  >
                    <input
                      type="radio"
                      name="transport"
                      id="transportNo"
                      value="no"
                      checked={transport === "no"}
                      onChange={(e) => setTransport(e.target.value)}
                      disabled={fundingOption === "nsfas"}
                      className="radio-button"
                    />
                    <label htmlFor="transportNo" className="transport-label">
                      <div className="transport-option">
                        <p className="subsection-module__title">
                          No, I do NOT need transport
                        </p>
                        <p className="subsection-module__subtitle">
                          Choose this option if you have your own transport
                          arrangements.
                        </p>
                      </div>
                    </label>
                  </div>
                </div>
              )}

              <div className="form-grid2">
                <button className="btn456" onClick={prevStep}>
                  Back
                </button>
                <button
                  className="btn456"
                  onClick={nextStep}
                  disabled={adminData?.Package === "Basic" ? false : !transport}
                >
                  Accept & Continue
                </button>
              </div>
            </div>
          )}

          {step === 3 && (
            <div className="step3-container">
              <label
                style={{
                  textAlign: "left",
                  display: "block",
                  fontSize: "15px"
                  , marginLeft: '10px'
                }}
              >
                Terms and Conditions <span style={{ color: "red" }}>*</span>
              </label>
              <div className="booking-form scrollable">
                <div className="step">Terms and conditions</div>
                <p className="step">JAES STUDENT HOMES - GENERAL HOUSE RULES</p>
                <p className="step">
                  The following is the general house rules for all properties.
                  Each property has specific added terms that are to be adhered
                  to in addition to the following:
                </p>

                <ul className="bullet-list">
                  <li>
                    &#8226; Communal areas should be kept clean at all times.
                  </li>
                  <li>
                    &#8226; Gates and house entrances should be locked AT ALL
                    TIMES for tenants’ safety.
                  </li>
                  <li>
                    &#8226; Rooms should be kept locked when tenant is not on
                    the premises...
                  </li>
                  <li>&#8226; Every tenant’s privacy should be respected...</li>
                  <li>&#8226; No smoking permitted inside the premises...</li>
                  <li>&#8226; No washing is to be hanged in the bathroom.</li>
                  <li>&#8226; No more than 2 visitors per lessee allowed...</li>
                  <li>
                    &#8226; No visitors allowed before 10am and after 21:00 pm.
                    NO SLEEPOVERS.
                  </li>
                  <li>&#8226; Down time after 21:30 pm.</li>
                </ul>

                <div
                  className="step3"
                  style={{ textAlign: "left", display: "block" }}
                >
                  Fines (of up to R950) will be charged to tenant account for
                  each rule break.
                </div>
                <hr />

                <label style={{ textAlign: "left", display: "block" }}>
                  Check all boxes to accept terms and conditions{" "}
                  <span style={{ color: "red" }}>*</span>
                </label>
                <div style={{ textAlign: "left", display: "block" }}>
                  By ticking the boxes below, you confirm that you have read,
                  understood, and agreed to the Terms and Conditions, General
                  House Rules, and Emergency Protocols outlined above. Please
                  note, non-compliance with these rules may result in penalties
                  and may prevent you from successfully booking or reserving a
                  space.{" "}
                </div>
                <div className="form-grid2">
                  {CHECKBOX_OPTIONS.map(({ id, label, stateKey }) => (
                    <div
                      key={id}
                      className="checkbox-wrapper"
                      onClick={() => toggleCheckbox(stateKey)}
                    >
                      <input
                        id={id}
                        name={id}
                        type="checkbox"
                        checked={checkboxStates[stateKey]}
                        onChange={() => toggleCheckbox(stateKey)}
                      />
                      <label className="terms-label" htmlFor={id}>
                        <span className="label-text">{label}</span>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 200 200"
                          className="checkbox-svg"
                        >
                          <mask fill="white" id={`path-1-inside-1_476_5-${id}`}>
                            <rect height="200" width="200"></rect>
                          </mask>
                          <rect
                            mask={`url(#path-1-inside-1_476_5-${id})`}
                            strokeWidth="40"
                            className="checkbox-box"
                            height="200"
                            width="200"
                          ></rect>
                          <path
                            strokeWidth="15"
                            d="M52 111.018L76.9867 136L149 64"
                            className="checkbox-tick"
                          ></path>
                        </svg>
                      </label>
                    </div>
                  ))}
                </div>

                <style>{`
.checkbox-container {
display: inline, /* Align checkboxes side by side */
  flex-wrap: wrap; /* Allow wrapping to the next line if necessary */
  gap: 15px; /* Add spacing between checkboxes */
  justify-content: flex-start; /* Align items to the left */
  margin: 20px 0; /* Add spacing around the container */
}

.checkbox-wrapper {
  display: inline, /* Display each checkbox and its label inline */
  align-items: center; /* Align items vertically */
  gap: 10px; /* Add spacing between the checkbox and the label */
}

.checkbox-wrapper input[type="checkbox"] {
  display: none; /* Hide the default checkbox */
}

.checkbox-wrapper .terms-label {
  cursor: pointer;
  display: in-line;
  align-items: center;
}

.checkbox-wrapper .terms-label .label-text {
  margin-right: 10px; /* Add spacing after the text */
}

.checkbox-wrapper .checkbox-svg {
  width: 30px;
  height: 30px;
}

.checkbox-wrapper .checkbox-box {
  fill: rgba(207, 205, 205, 0.425);
  stroke: #ff5a5f;
  stroke-dasharray: 800;
  stroke-dashoffset: 800;
  transition: stroke-dashoffset 0.6s ease-in;
}

.checkbox-wrapper .checkbox-tick {
  stroke: #ff5a5f;
  stroke-dasharray: 172;
  stroke-dashoffset: 172;
  transition: stroke-dashoffset 0.6s ease-in;
}

.checkbox-wrapper input[type="checkbox"]:checked + .terms-label .checkbox-box,
.checkbox-wrapper input[type="checkbox"]:checked + .terms-label .checkbox-tick {
  stroke-dashoffset: 0;
}


`}</style>
              </div>
              <div className="form-grid2">
                <button className="btn456" onClick={prevStep}>
                  Back
                </button>
                <button
                  className="btn456"
                  onClick={nextStep}
                  disabled={
                    !Object.values(checkboxStates).every((checked) => checked)
                  }
                >
                  Accept & Continue
                </button>
              </div>
            </div>
          )}
          {step === 4 && (
            <div className="step4-container">
              <label
                style={{
                  textAlign: "left",
                  display: "block",
                  fontSize: "20px",
                }}
              >
                Booking Form <span style={{ color: "red" }}>*</span>
              </label>

              {/* <div className="transport-options" style={{ marginTop: '20px' }}>
                                <div
                                    className={`transport-option-wrapper ${serviceFee === 'yes' ? 'selected' : ''}`}
                                >
                                    <input
                                        type="radio"
                                        name="serviceFee"
                                        id="serviceYes"
                                        value="yes"
                                        checked={serviceFee === 'yes'}
                                        onChange={(e) => setServiceFee(e.target.value)}
                                        className="radio-button"
                                    />
                                    <label htmlFor="transportYes" className="transport-label">
                                        <div className="transport-option">
                                            <p className="subsection-module__title">Service Fee</p>
                                            <p className="subsection-module__subtitle">
                                                <span className="discount-highlight">R1 850</span><br />
                                                Service Fee includes , cleaning services upon arrival and proccessing of documentaion.<br />


                                            </p>
                                        </div>
                                    </label>
                                </div>

                            </div> */}
              <div className="booking-form scrollable">
                {/* <div className="step">SERVICE FEE Acknowledgement</div> */}

                {/* <div>
                                    Service fee is a non-refundable charge that covers administrative costs associated with processing your booking or reservation. This fee ensures that all necessary arrangements, documentation, and support are in place to provide a seamless and efficient service. By paying the service fee of R1850.00, you contribute to maintaining the quality and reliability of our operations, ensuring your booking process is handled with care and professionalism.
                                </div> 
                                <div className="divider my-3"></div>


                                {/* <label style={{ textAlign: 'left', display: 'block' }}>
                                    Check all boxes to accept terms and conditions <span style={{ color: 'red' }}>*</span>
                                </label> 
                                <div>
                                    By ticking the box below, you confirm that you have read, understood, and agreed to the Service Fee policy outlined above. Please note, failure to acknowledge the service fee may prevent you from successfully booking or reserving a space.  </div>
                                {/* <div className="form-grid2">
                                    {/* {CHECKBOX_OPTIONS.map(({ id, label, stateKey }) => (
                                        <div key={id} className="checkbox-wrapper" onClick={() => toggleCheckbox(stateKey)}>
                                            <input
                                                id={id}
                                                name={id}
                                                type="checkbox"
                                                checked={checkboxStates[stateKey]}
                                                onChange={() => toggleCheckbox(stateKey)}
                                            />
                                            <label className="terms-label" htmlFor={id}>
                                                <span className="label-text">{label}</span>
                                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 200 200" className="checkbox-svg">
                                                    <mask fill="white" id={`path-1-inside-1_476_5-${id}`}>
                                                        <rect height="200" width="200"></rect>
                                                    </mask>
                                                    <rect mask={`url(#path-1-inside-1_476_5-${id})`} strokeWidth="40" className="checkbox-box" height="200" width="200"></rect>
                                                    <path strokeWidth="15" d="M52 111.018L76.9867 136L149 64" className="checkbox-tick"></path>
                                                </svg>
                                            </label>
                                        </div>
                                    ))} 
   
                                    <div className="checkbox-wrapper" onClick={() => setServiceFee(serviceFee === 'yes' ? '' : 'yes')}>
                                        <input
                                            id="serviceYes"
                                            name="serviceFee"
                                            type="checkbox"
                                            checked={serviceFee === 'yes'}
                                            onChange={(e) => setServiceFee(e.target.checked ? 'yes' : '')}
                                        />
                                        <label className="terms-label" htmlFor="serviceFeeAcknowledgment">
                                            <span className="label-text">I acknowledge and agree to the Service Fee.</span>
                                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 200 200" className="checkbox-svg">
                                                <mask fill="white" id="path-1-inside-1_476_5-serviceFeeAcknowledgment">
                                                    <rect height="200" width="200"></rect>
                                                </mask>
                                                <rect mask="url(#path-1-inside-1_476_5-serviceFeeAcknowledgment)" strokeWidth="40" className="checkbox-box" height="200" width="200"></rect>
                                                <path strokeWidth="15" d="M52 111.018L76.9867 136L149 64" className="checkbox-tick"></path>
                                            </svg>
                                        </label>
                                    </div>
                                </div> */}

                <div className="container2">
                  <div className="header">
                    <img src={logo} alt="Logo" className="logobooing" />
                    <style>{`
.logobooing {
    width: 150px; /* Default size for larger screens */
    height: auto; /* Maintain aspect ratio */
}

@media screen and (max-width: 768px) {
    .logobooing {
        width: 100px; /* Reduce size for mobile screens */
        height: auto; /* Maintain aspect ratio */
                margin-left: 20px; /* Move the logo slightly to the right */

    }
}
`}</style>

                    <div className="booking-box">
                      <h1>BOOKING NUMBER :{apartment?.RoomIdentifier} </h1>
                    </div>
                  </div>
                  <h1>STUDENT HOMES 2025 - BOOKING FORM</h1>

                  <div className="content">
                    <p>
                      The booking form herein, the undersigned, later on
                      referred to as the “Client”, agrees to the detailed
                      clauses below.
                    </p>
                    <p>
                      The Client agrees to pay the required booking/deductible
                      service fee to JAES, for the purposes of removal of the
                      rental premises from the market for the timeline stated
                      below and/or administrative costs for the transport
                      service.
                    </p>
                  </div>

                  <div className="section-title">Service Fees</div>

                  <table className="table">
                    <thead>
                      <tr>
                        <th>Service</th>
                        <th>Description</th>
                        <th>Fee</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>Service booking fee</td>
                        <td>
                          The service fee is an annual booking and
                          administrative charge. It encompasses essential
                          booking costs and contributes to offsetting initial
                          move-in expenses, as well as subsidising additional
                          amenities included in the student homes.
                        </td>
                        <td>R1,850.00</td>
                      </tr>
                      <tr>
                        <td>Transport administrative fee</td>
                        <td>
                          The annual transport administrative fee covers the
                          registration and transport card (for access into the
                          system). It covers the administration and the booking
                          platform as well. Note it’s a once-off fee.
                        </td>
                        <td>R450.00</td>
                      </tr>
                    </tbody>
                  </table>

                  <div className="section-title">Client Details</div>

                  <table className="table">
                    <thead>
                      <tr>
                        <th>Field</th>
                        <th>Information</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>Full Name</td>
                        <td>
                          {userData.Surname} {userData.Name}
                        </td>
                      </tr>
                      <tr>
                        <td>ID/Passport Number</td>
                        <td>{userData.IdNumber}</td>
                      </tr>
                      <tr>
                        <td>Services</td>
                        <td>
                          <div className="checkbox-item">
                            <input
                              type="checkbox"
                              id="emergency-protocol"
                              name="emergency-protocol"
                              checked
                              onChange={() => {
                                {
                                }
                              }}
                            />
                            <label htmlFor="emergency-protocol">
                              Student Accommodation
                            </label>
                          </div>
                          <div className="checkbox-item">
                            <input
                              type="checkbox"
                              id="house-rules"
                              name="house-rules"
                              value={transport}
                              checked={transport === "yes"}
                            />
                            <label htmlFor="house-rules">Transport</label>
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  </table>

                  <div className="section-title">Contact Information</div>

                  <table className="table">
                    <thead>
                      <tr>
                        <th>Field</th>
                        <th>Details</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>Tenant Address/Student Home</td>
                        <td>{userData.HomeAddress}</td>
                      </tr>
                      <tr>
                        <td>Email</td>
                        <td>{userData.Email} </td>
                      </tr>
                      <tr>
                        <td>Cell</td>
                        <td>{userData.PhoneNumber}</td>
                      </tr>
                      <tr>
                        <td>WhatsApp</td>
                        <td></td>
                      </tr>
                    </tbody>
                  </table>

                  <div className="section-title">Terms & Conditions</div>

                  <div className="terms">
                    <p style={{ textAlign: "left" }}>
                      1. The service booking fee and Transport administrative
                      fee is <span className="underline">NOT REFUNDABLE</span>{" "}
                      under any circumstances.
                    </p>
                    <p style={{ textAlign: "left" }}>
                      2. The Client confirms the information given above is true
                      and accurate and is responsible for informing JAES should
                      any details given above alter.
                    </p>
                    <p style={{ textAlign: "left" }}>
                      3. If the Client fails to sign the Terms of Residence or
                      Terms of Service (by the 1st February 2025, for the period
                      February to 31st November 2025) or cancels, JAES reserves
                      the right to cancel the service.
                    </p>
                    <p style={{ textAlign: "left" }}>
                      4. If applicant signs the Terms of Residence and fails to
                      move in, applicant will be responsible for the initial
                      term or until property rents again.
                    </p>
                    <p style={{ textAlign: "left" }}>
                      5. The annual service booking fee for the duration, 15th
                      February to 15th November, does not include the food
                      package or transport service.
                    </p>
                    <p style={{ textAlign: "left" }}>
                      6. The annual transport administrative fee is solely for
                      the purposes of the transport service for the duration,
                      15th February to 15th November 2025.
                    </p>
                  </div>

                  <div className="signature-line" style={{ textAlign: "left" }}>
                    <p>
                      SIGNATURE OF CLIENT:{" "}
                      <span>
                        <span style={{ borderBottom: "1px solid #000" }}>
                          <span style={{ opacity: "0" }}>---------</span>
                        </span>
                        <span style={{ borderBottom: "1px solid #000" }}>
                          {formattedName}
                        </span>
                        <span style={{ borderBottom: "1px solid #000" }}>
                          <span style={{ opacity: "0" }}>---------</span>
                        </span>
                      </span>
                      <br />{" "}
                    </p>
                  </div>
                  <div className="signature-line" style={{ textAlign: "left" }}>
                    <p>
                      DATE:{" "}
                      <span style={{ borderBottom: "1px solid #000" }}>
                        <span style={{ opacity: "0" }}>---------</span>
                        <span style={{ borderBottom: "1px solid #000" }}>
                          {new Date().toLocaleString("en-US", {
                            day: "numeric",
                            month: "short",
                            year: "numeric",
                          })}
                        </span>
                        <span style={{ opacity: "0" }}>---------</span>
                      </span>
                    </p>
                  </div>
                  <div
                    className="checkbox-wrapper"
                    onClick={() =>
                      setServiceFee(serviceFee === "yes" ? "" : "yes")
                    }
                  >
                    <input
                      id="serviceYes"
                      name="serviceFee"
                      type="checkbox"
                      checked={serviceFee === "yes"}
                      onChange={(e) =>
                        setServiceFee(e.target.checked ? "yes" : "")
                      }
                    />
                    <label
                      className="terms-label"
                      htmlFor="serviceFeeAcknowledgment"
                    >
                      <span className="label-text">
                        I acknowledge and agree to the booking form.
                      </span>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 200 200"
                        className="checkbox-svg"
                      >
                        <mask
                          fill="white"
                          id="path-1-inside-1_476_5-serviceFeeAcknowledgment"
                        >
                          <rect height="200" width="200"></rect>
                        </mask>
                        <rect
                          mask="url(#path-1-inside-1_476_5-serviceFeeAcknowledgment)"
                          strokeWidth="40"
                          className="checkbox-box"
                          height="200"
                          width="200"
                        ></rect>
                        <path
                          strokeWidth="15"
                          d="M52 111.018L76.9867 136L149 64"
                          className="checkbox-tick"
                        ></path>
                      </svg>
                    </label>
                  </div>
                  <style>{`
.container2 {
    max-width: 800px;
    margin: 0 auto;
    background-color: #fff;
    padding: 20px;
    border: 2px solid #000;
    box-shadow: 0 4px 10px rgba(0,0,0,0.2);
    gap: 3px;
}
h1 {
    text-align: center;
    font-size: 22px;
    font-weight: bold;
    margin-bottom: 5px;
    text-transform: uppercase;
}
.section-title {
    font-size: 16px;
    font-weight: bold;
    margin-top: 5px;
    margin-bottom: 5px;
    color: #000;
    text-decoration: underline;
}
.content {
    font-size: 14px;
    line-height: 1.5;
    color: #000;
    margin-bottom: 5px;
}
.table {
    width: 100%;
    border-collapse: collapse;
    margin: 20px 0;
}
.table th, .table td {
    border: 1px solid #000;
    padding: 10px;
    text-align: left;
    font-size: 14px;
}
.checkbox-section {
    margin: 15px 0;
    font-size: 14px;
    display: flex;
    gap: 20px;
    align-items:flex-start;
}
.terms {
    font-size: 14px;
    line-height: 1.5;
    margin-top: 5px;
    border: 1px solid #000;
    padding: 10px;
    background-color: #f9f9f9;
}
p {
    margin: 5px 0;
}
.signature-line {
    margin: 20px 0;
    font-size: 14px;
    padding-top: 15px;
}
.underline {
    text-decoration: underline;
}
.section-title {
    font-size: 16px;
    font-weight: bold;
    margin-top: 5px;
    margin-bottom: 5px;
    color: #000;
    text-decoration: underline;
}
.content {
    font-size: 14px;
    line-height: 1.5;
    color: #000;
    margin-bottom: 5px;
}
.table {
    width: 100%;
    border-collapse: collapse;
    margin: 20px 0;
}
.table th, .table td {
    border: 1px solid #000;
    padding: 10px;
    text-align: left;
    font-size: 14px;
}
.checkbox-section {
    margin: 15px 0;
    font-size: 14px;
    display: flex;
    gap: 20px;
    align-items:flex-start;
}
.terms {
    font-size: 14px;
    line-height: 1.5;
    margin-top: 5px;
    border: 1px solid #000;
    padding: 10px;
    background-color: #f9f9f9;
}
p {
    margin: 5px 0;
}
.signature-line {
    margin: 20px 0;
    font-size: 14px;
    padding-top: 15px;
}
.underline {
    text-decoration: underline;
}
.header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 5px;
}
.booking-box {
    border: 2px solid #000;
    padding: 25px;
    font-weight: bold;
    font-size: 1.2em;
    font-family: Arial, sans-serif;
    margin-bottom: 15px;
}/* Additional Styles for Mobile */
@media screen and (max-width: 768px) {
    .container2 {
        width: 155%; /* Expand the width beyond the screen size */
        max-width: 700px; /* Set a limit for larger mobile devices */
        margin-left: -27.5%; /* Center the container by offsetting the extra width */
        padding: 25px; /* Increase padding for better spacing */
        box-sizing: border-box; /* Include padding and border in width */
    }

    h1 {
        font-size: 16px; /* Adjust font size for better readability on smaller screens */
    }

    .section-title {
        font-size: 14px; /* Slightly reduce font size */
    }

    .content {
        font-size: 12px; /* Reduce font size to fit more content */
    }

    .table th, .table td {
        font-size: 12px; /* Adjust table font size */
        padding: 8px; /* Reduce padding to fit smaller screens */
    }

    .booking-box {
        padding: 0px; /* Adjust padding for smaller screens */
        font-size: 12px; /* Scale font size */
    }

    .terms {
        padding: 8px; /* Reduce padding in terms */
        font-size: 12px; /* Adjust font size */
    }
}`}</style>
                </div>

                <style>{`
  /* Container for checkboxes */
.checkbox-container {
display: inline, /* Align checkboxes side by side */
  flex-wrap: wrap; /* Allow wrapping to the next line if necessary */
  gap: 15px; /* Add spacing between checkboxes */
  justify-content: flex-start; /* Align items to the left */
  margin: 20px 0; /* Add spacing around the container */
}

.checkbox-wrapper {
  display: inline, /* Display each checkbox and its label inline */
  align-items: center; /* Align items vertically */
  gap: 10px; /* Add spacing between the checkbox and the label */
}

.checkbox-wrapper input[type="checkbox"] {
  display: none; /* Hide the default checkbox */
}

.checkbox-wrapper .terms-label {
  cursor: pointer;
  display: in-line;
  align-items: center;
}

.checkbox-wrapper .terms-label .label-text {
  margin-right: 10px; /* Add spacing after the text */
}

.checkbox-wrapper .checkbox-svg {
  width: 30px;
  height: 30px;
}

.checkbox-wrapper .checkbox-box {
  fill: rgba(207, 205, 205, 0.425);
  stroke: #ff5a5f;
  stroke-dasharray: 800;
  stroke-dashoffset: 800;
  transition: stroke-dashoffset 0.6s ease-in;
}

.checkbox-wrapper .checkbox-tick {
  stroke: #ff5a5f;
  stroke-dasharray: 172;
  stroke-dashoffset: 172;
  transition: stroke-dashoffset 0.6s ease-in;
}

.checkbox-wrapper input[type="checkbox"]:checked + .terms-label .checkbox-box,
.checkbox-wrapper input[type="checkbox"]:checked + .terms-label .checkbox-tick {
  stroke-dashoffset: 0;
}


`}</style>
              </div>
              <div className="form-grid2">
                <button className="btn456" onClick={prevStep}>
                  Back
                </button>
                <button
                  className="btn456"
                  onClick={nextStep}
                  disabled={!serviceFee}
                >
                  Accept & Continue
                </button>
              </div>
            </div>
          )}
          {step === 5 && (
            <div>
              <label
                style={{
                  textAlign: "left",
                  display: "block",
                  fontSize: "20px",
                }}
              >
                Booking your home <span style={{ color: "red" }}>*</span>
              </label>

              <div className="contentinvoice">
                <div className="amber-form-grid">
                  {/* <div className="form-grid">
                                        <Bus size={20} />
                                        <p className="subsection-module__title">
                                            <strong>Effortless Commuting:</strong>
                                        </p>
                                        <p className="subsection-module__subtitle">
                                            Reliable daily transport to get you where you need to be, on time, every time.
                                        </p>
                                    </div>

                                    <div className="form-grid">
                                        <Star size={20} />
                                        <p className="subsection-module__title">
                                            <strong>Prime Campus Location:</strong>
                                        </p>
                                        <p className="subsection-module__subtitle">
                                            Immerse yourself in a vibrant neighborhood, just steps away from your academic pursuits.
                                        </p>
                                    </div>

                                    <div className="form-grid">
                                        <PhoneForwarded size={20} />
                                        <p className="subsection-module__title">
                                            <strong>Round-the-Clock Support: </strong>
                                        </p>
                                        <p className="subsection-module__subtitle">
                                            Our dedicated team is always ready to assist, ensuring a smooth and stress-free stay.
                                        </p>
                                    </div>

                                    <div className="form-grid">
                                        <MapPinned size={20} />
                                        <p className="subsection-module__title">
                                            <strong>Privacy and Peace: </strong>
                                        </p>
                                        <p className="subsection-module__subtitle">
                                            Your own personal space, designed for relaxation and focus.
                                        </p>
                                    </div> */}

                  {fundingOption === "nsfas" ? (
                    <div className="amber-form-grid">
                      <div className="form-grid">
                        <Bus size={20} />
                        <p className="subsection-module__title">
                          <strong>Effortless Commuting:</strong>
                        </p>
                        <p className="subsection-module__subtitle">
                          Reliable daily transport to get you where you need to
                          be, on time, every time.
                        </p>
                      </div>

                      <div className="form-grid">
                        <Star size={20} />
                        <p className="subsection-module__title">
                          <strong>Prime Campus Location:</strong>
                        </p>
                        <p className="subsection-module__subtitle">
                          Immerse yourself in a vibrant neighborhood, just steps
                          away from your academic pursuits.
                        </p>
                      </div>

                      <div className="form-grid">
                        <PhoneForwarded size={20} />
                        <p className="subsection-module__title">
                          <strong>Round-the-Clock Support: </strong>
                        </p>
                        <p className="subsection-module__subtitle">
                          Our dedicated team is always ready to assist, ensuring
                          a smooth and stress-free stay.
                        </p>
                      </div>

                      <div className="form-grid">
                        <MapPinned size={20} />
                        <p className="subsection-module__title">
                          <strong>Privacy and Peace: </strong>
                        </p>
                        <p className="subsection-module__subtitle">
                          Your own personal space, designed for relaxation and
                          focus.
                        </p>
                      </div>
                    </div>
                  ) : (
                    <div
                      style={{
                        marginTop: "2px",
                        display: "flex",
                        flexDirection: "column",
                        gap: "12px",
                        alignItems: "center",
                      }}
                    >
                      {/* <div
                                            // className={`transport-option-wrapper ${selectedPlan === `${apartment.ApartmentPrice}` ? 'selected' : ''}`}
                                            style={{
                                                width: '100%',
                                                maxWidth: '500px',
                                                border: '1px solid #d9534f',
                                                borderRadius: '8px',
                                                padding: '15px',
                                                cursor: 'pointer',
                                                textAlign: 'center',
                                                backgroundColor: selectedPlan === `${apartment.ApartmentPrice}` ? '#fbe9e7' : '#fff',
                                                boxShadow: selectedPlan === `${apartment.ApartmentPrice}` ? '0px 4px 6px rgba(0, 0, 0, 0.1)' : 'none',
                                            }}
                                            onClick={() => {
                                                // setSelectedPlan(`${apartment.ApartmentPrice}`);
                                                // handleCardClick(apartment.ApartmentPrice);
                                                handleSelection(`${apartment.ApartmentPrice}`)
                                            }}
                                        >
                                            <input
                                                type="radio"
                                                name="promo"
                                                id="payInFull"
                                                value={`${apartment.ApartmentPrice}`}
                                                checked={selectedPlan === `${apartment.ApartmentPrice}`}
                                                onChange={(e) =>
                                                    // setSelectedPlan(e.target.value)
                                                    handleSelection(e.target.value)
                                                }
                                                // className="radio-button"
                                                style={{ display: 'none' }}
                                            />
                                            <label htmlFor="payInFull" style={{ display: 'block', cursor: 'pointer' }}>
                                                <div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
                                                    <p className='subsection-module__title' style={{ fontWeight: 'bold', margin: 0 }}>Pay in Full</p>

                                                    <Star size={20} fill="gold" color='gold' />
                                                    <Star size={20} fill="gold" color='gold' />
                                                    <Star size={20} fill="gold" color='gold' />

                                                </div>
                                                <label className='bookYourPerfectAccommodation-module__title amber-form-group' style={{ fontSize: '14px', color: '#555', marginTop: '8px' }}>
                                                    Settle your full rent upfront and guarantee your spot without any delays. Quick, simple, and hassle-free                                                 </label>

                                            </label>
                                        </div>
                                        <div
                                            // className={`transport-option-wrapper ${selectedPlan === `${apartment.ApartmentPrice / 2}` ? 'selected' : ''}`}
                                            style={{
                                                width: '100%',
                                                maxWidth: '500px',
                                                border: '1px solid #d9534f',
                                                borderRadius: '8px',
                                                padding: '15px',
                                                cursor: 'pointer',
                                                textAlign: 'center',
                                                backgroundColor: selectedPlan === `${apartment.ApartmentPrice / 2}` ? '#fbe9e7' : '#fff',
                                                boxShadow: selectedPlan === `${apartment.ApartmentPrice / 2}` ? '0px 4px 6px rgba(0, 0, 0, 0.1)' : 'none',
                                            }}
                                            onClick={() => {
                                                const halfRent = apartment.ApartmentPrice / 2;
                                                // setSelectedPlan(`${halfRent}`);
                                                // handleCardClick(halfRent);
                                                handleSelection(`${apartment.ApartmentPrice / 2}`)
                                            }}>
                                            <input
                                                type="radio"
                                                name="promo"
                                                id="halfRent"
                                                value={`${apartment.ApartmentPrice / 2}`}
                                                checked={selectedPlan === `${apartment.ApartmentPrice / 2}`}
                                                onChange={(e) =>
                                                    // setSelectedPlan(e.target.value)
                                                    handleSelection(e.target.value)
                                                }
                                                // className="radio-button"
                                                style={{ display: 'none' }}
                                            />
                                            <label htmlFor="halfRent" style={{ display: 'block', cursor: 'pointer' }}>
                                                <div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
                                                    <p className='subsection-module__title' style={{ fontWeight: 'bold', margin: 0 }}>PAY 50% RENT NOW!</p>

                                                    <Star size={20} color='grey' fill="grey" />
                                                    <Star size={20} color='grey' fill="grey" />

                                                </div>
                                                <label className='bookYourPerfectAccommodation-module__title amber-form-group' style={{ fontSize: '14px', color: '#555', marginTop: '8px' }}>
                                                    Get a 50% discount on your first month’s rent as part of this promotional offer.
                                                </label>

                                            </label>
                                        </div>

                                        <div
                                            // className={`transport-option-wrapper ${selectedPlan === '0' ? 'selected' : ''}`}
                                            onClick={() => {
                                                // setSelectedPlan('0'); // Rent is zero for this promo
                                                // handleCardClick(0);
                                                handleSelection('0')
                                            }}
                                            style={{
                                                width: '100%',
                                                maxWidth: '500px',
                                                border: '1px solid #d9534f',
                                                borderRadius: '8px',
                                                padding: '15px',
                                                cursor: 'pointer',
                                                textAlign: 'center',
                                                backgroundColor: selectedPlan === '0' ? '#fbe9e7' : '#fff',
                                                boxShadow: selectedPlan === '0' ? '0px 4px 6px rgba(0, 0, 0, 0.1)' : 'none',
                                            }}

                                        >
                                            <input
                                                type="radio"
                                                name="promo"
                                                id="zeroRent"
                                                value="0"
                                                checked={selectedPlan === '0'}
                                                onChange={(e) =>
                                                    // setSelectedPlan(e.target.value)
                                                    handleSelection(e.target.value)
                                                }
                                                // className="radio-button"
                                                style={{ display: 'none' }}

                                            />
                                            <label htmlFor="zeroRent" style={{ display: 'block', cursor: 'pointer' }}
                                            >
                                                <div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
                                                    <p className='subsection-module__title' style={{ fontWeight: 'bold', margin: 0 }}>PAY R0 RENT UPFRONT!!</p>

                                                    <Star size={20} color='#CD7F32' fill="#CD7F32" />


                                                </div>
                                                <label className='bookYourPerfectAccommodation-module__title amber-form-group' style={{ fontSize: '14px', color: '#555', marginTop: '8px' }}>
                                                    Secure your spot today without paying rent upfront. Pay your rent only before you move-in-no stress, no hassle!                                                </label>

                                            </label>
                                        </div> */}



                      {payIn == 'PayNow' && (
                        <div

                        >
                          <li className="flex justify-between">

                            <ReactDatePicker
                              selected={startDate}
                              onChange={handleDateChange}
                              selectsStart
                              startDate={startDate}
                              endDate={endDate}
                              minDate={new Date()} // This disables all dates before today
                              maxDate={endOfMonth(new Date())} // Optional: Limit the range to the end of the month
                              inline
                              calendarClassName="custom-datepicker" /* Add custom class for calendar styling */
                            />


                          </li>

                          <style>{`
                                              .custom-datepicker {
    font-size: 14px; /* Make text larger */
    background-color: white; /* Set the calendar background to white */
    border: 1px solid #ddd; /* Add a subtle border */
    border-radius: 10px; /* Round the corners */
    padding: 10px; /* Add padding for a spacious feel */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Add a light shadow */
  }

  .custom-datepicker .react-datepicker__day,
  .custom-datepicker .react-datepicker__day-name {
    width: 40px; /* Increase day cell width */
    height: 40px; /* Increase day cell height */
    line-height: 40px; /* Center align text */
  }

  .custom-datepicker .react-datepicker__day--disabled {
    background-color: #f5f5f5; /* Light gray background for disabled dates */
    color: #b0b0b0; /* Gray text for disabled dates */
    pointer-events: none; /* Prevent interaction */
    cursor: not-allowed; /* Show not-allowed cursor */
  }

  .custom-datepicker .react-datepicker__day--selected {
    background-color: #d9534f; /* Highlight selected day */
    color: white;
    border-radius: 50%; /* Make the selected day circular */
  }

  .custom-datepicker .react-datepicker__day:hover {
    background-color: #e6f0ff; /* Add hover effect */
  }

  .custom-datepicker .react-datepicker__header {
    background-color: white; /* Header background matches the calendar */
    border-bottom: 1px solid #ddd; /* Subtle border for header */
  }

  .custom-datepicker .react-datepicker__current-month {
    font-size: 20px; /* Larger font for the current month */
    font-weight: bold;
    color: red; /* Make January 2025 text red */
  }

  .custom-datepicker .react-datepicker__navigation {
    top: 15px; /* Adjust navigation button position */
  }
`}</style>
                        </div>
                      )}

                      {payIn == 'PayLater' && (
                        <>
                          <div
                          >
                            <li className="flex justify-between">

                              <ReactDatePicker
                                selected={startDate}
                                onChange={handleDateChange2}
                                selectsStart
                                startDate={startDate}
                                endDate={endDate}
                                minDate={new Date()}
                                maxDate={endOfMonth(new Date())}
                                inline
                                calendarClassName="custom-datepicker"
                              // dayClassName={(date) =>
                              //   date < new Date().setHours(0, 0, 0, 0) ? "disabled-day" : undefined
                              // } // Add disabled class for past dates
                              />

                              <style>{`
.custom-datepicker {
  font-size: 14px; /* Bigger text */
  background-color: white; /* White background */
  border: 1px solid #ddd; /* Subtle border */
  border-radius: 10px; /* Rounded corners */
  padding: 10px; /* Spacious padding */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Light shadow */
}

.custom-datepicker .react-datepicker__day,
.custom-datepicker .react-datepicker__day-name {
  width: 40px; /* Larger day cell width */
  height: 40px; /* Larger day cell height */
  line-height: 40px; /* Center-align text */
}

.custom-datepicker .react-datepicker__day--selected {
  background-color: #d9534f; /* Highlight selected day */
  color: white;
  border-radius: 50%; /* Circular selection */
}

.custom-datepicker .react-datepicker__day:hover {
  background-color: #e6f0ff; /* Hover effect */
  color: #000
}

.custom-datepicker .react-datepicker__header {
  background-color: white; /* White header */
  border-bottom: 1px solid #ddd; /* Subtle header border */
}

.custom-datepicker .react-datepicker__current-month {
  font-size: 20px; /* Larger font for month */
  font-weight: bold;
  color: #d9534f; /* Make January 2025 text red */
}

.custom-datepicker .react-datepicker__navigation {
  top: 15px; /* Adjust navigation button position */
}
  .custom-datepicker .react-datepicker__day--disabled {
    background-color: #f5f5f5; /* Light gray background for disabled dates */
    color: #000; /* Gray text for disabled dates */
    pointer-events: none; /* Prevent interaction */
    cursor: not-allowed; /* Show not-allowed cursor */
  }

/* Add disabled-day styling */
// .custom-datepicker .disabled-day {
//   color: #ccc !important; /* Gray out disabled dates */
//   pointer-events: none; /* Prevent clicking */
//   background-color: #f5f5f5 !important; /* Subtle background for disabled dates */
// }

`}</style>
                            </li>
                          </div>
                          <div
                            // className={`transport-option-wrapper ${selectedPlan === '0' ? 'selected' : ''}`}

                            style={{
                              display: 'none',
                              width: '100%',
                              maxWidth: '500px',
                              border: '1px solid #d9534f',
                              borderRadius: '8px',
                              padding: '15px',
                              cursor: 'pointer',
                              textAlign: 'center',
                              backgroundColor: selectedPlan == '0' ? '#fbe9e7' : '#fff',
                              boxShadow: selectedPlan == '0' ? '0px 4px 6px rgba(0, 0, 0, 0.1)' : 'none',
                            }}

                          >
                            <input
                              type="radio"
                              name="promo"
                              id="zeroRent"
                              value="0"
                              checked={selectedPlan == '0'}

                              // className="radio-button"
                              style={{ display: 'none' }}

                            />
                            <label htmlFor="zeroRent" style={{ display: 'block', cursor: 'pointer' }}
                            >
                              <div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
                                <p className='subsection-module__title' style={{ fontWeight: 'bold', margin: 0 }}>PAY R0 RENT UPFRONT!!</p>

                                <Star size={20} color='#CD7F32' fill="#CD7F32" />


                              </div>
                              <label className='bookYourPerfectAccommodation-module__title amber-form-group' style={{ fontSize: '14px', color: '#555', marginTop: '8px' }}>
                                Secure your spot today without paying rent upfront. Pay your rent only before you move-in-no stress, no hassle!                                                </label>

                            </label>
                          </div>
                        </>
                      )}


                    </div>
                  )}
                </div>
                <style>{`
.contentinvoice {
    display: flex;
    flex-direction: row; /* Default: Side by side layout for larger screens */
    justify-content: space-between;
    gap: 20px;
}

.booking-summary {
    flex: 1;
    max-width: 400px; /* Limit width for desktop */
    margin: 0;
}

@media screen and (max-width: 768px) {
    .contentinvoice {
        flex-direction: column; /* Stack elements vertically on mobile */
        align-items: center; /* Center align content */
    }

    .booking-summary {
        min-width: 335px; /* Allow full width on mobile */
        margin-top: 20px; /* Add space above for visual separation */
    }
}
`}</style>
                <style>{`
.booking-sadasdaad {
    position: relative; /* Ensure relative positioning for adjustments */
    bottom: 100px; /* Default position */
    left: 20px
}

@media screen and (max-width: 768px) {
    .booking-sadasdaad {
    
        min-width: 335px;
          margin-top: 120px;
          margin-right: 30px
    }
}
`}</style>

                <div className="rounded-lg lg:shadow-lg lg:p-3 lg:border border-gray-100 booking-summary booking-sadasdaad">
                  <div>
                    <div className="flex">
                      <p className="content-font uppercase font-semibold text-gray-500">
                        Booking Summary{" "}
                      </p>
                    </div>
                  </div>
                  <div className="text-gray-700">
                    <h3 className="subsection-module__title">
                      {apartmentandbuilding.BuildingName} -{" "}
                      {apartmentandbuilding.ApartmentNumber}
                    </h3>
                    <div className="divider my-3"></div>

                    <ul className="mb-2">
                      {fundingOption !== "nsfas" && (
                        <div className="amber-form-group2">
                          <div>
                            <label
                              className={payIn == "PayNow" ? "selected" : ""}
                            >
                              <input
                                type="radio"
                                name="PayNow"
                                value="Pay Now"
                                // onChange={() => choosingHowtoPay('PayNow')}
                                onClick={() => {
                                  choosingHowtoPay("PayNow");
                                }}
                              />
                              PAY NOW
                            </label>

                            <label
                              className={payIn == "PayLater" ? "selected" : ""}
                            >
                              <input
                                type="radio"
                                name="PayLater"
                                value="Pay Rent later"
                                onClick={() => {
                                  choosingHowtoPay("PayLater");
                                  setSelectedPlan(0);
                                }}
                              />
                              PAY RENT LATER
                            </label>

                          </div>
                        </div>
                      )}
                      <li className="flex justify-between">
                        <span className="font-semibold">Room (Pro rata)</span>
                        {fundingOption === "nsfas" ? (
                          <span className="text-theme-orange font-semibold">
                            NSFAS Funded
                          </span>
                        ) : (
                          <span className="text-theme-orange font-semibold">
                            R{selectedPlan}
                          </span>
                        )}
                      </li>
                      <li className="flex justify-between pb-0.5">
                        <span className="content-font">
                          Once-off Service fee :
                        </span>
                        <span>R{serviceFeeCost}</span>
                      </li>
                      {fundingOption !== "nsfas" && (
                        <li className="flex justify-between pb-0.5">
                          <span className="content-font">
                            Transport (Fee) :
                          </span>
                          <span>R{transportCost}</span>
                        </li>
                      )}
                      {fundingOption !== "nsfas" && (
                        <li className="flex justify-between pb-0.5">
                          <span className="content-font">
                            Move in Date:
                          </span>
                          <span>{moveInDate}</span>
                        </li>
                      )}
                      <div className="divider my-3"></div>
                      <li className="flex justify-between">
                        {fundingOption === "nsfas" ? (
                          <span className="font-semibold">
                            Inclusive Fee's Due:
                          </span>
                        ) : (
                          <span className="font-semibold">Total Due:</span>
                        )}
                        <span className="text-theme-orange font-semibold">
                          R{totalCost}
                        </span>
                      </li>
                      <div className="form-grid2">
                        <button className="btn456" onClick={prevStep}>
                          Back
                        </button>
                        <button
                          className="btn456"
                          onClick={(e) => {
                            e.preventDefault();
                            if (fundingOption !== "nsfas" && (!selectedPlan || selectedPlan === 0) && payIn !== "PayLater") {
                              Swal.fire({
                                title: "Choose a Date",
                                text: "Please select a date before proceeding!",
                                icon: "warning",
                                confirmButtonText: "OK",
                              });
                            } else if (!selectedPlan2 && fundingOption !== 'nsfas') {
                              Swal.fire({
                                title: "Payment not Selected",
                                text: "Please select an option before proceeding!",
                                icon: "warning",
                                confirmButtonText: "OK",
                              });
                            } else {
                              handleBookingPayment();
                            }
                          }}
                          disabled={
                            fundingOption === "nsfas"
                              ? isPlaced2 == true
                              : isPlaced == true
                          }
                        >
                          {progress
                            ? fundingOption === "nsfas"
                              ? "Processing..."
                              : (!moveInDate
                                ? "Pick Date to Pay"
                                : ((selectedPlan && selectedPlan !== 0) || payIn === "PayLater"
                                  ? "Processing..."
                                  : "Pick Date to Pay"))
                            : fundingOption === "nsfas"
                              ? "Submit"
                              : (!moveInDate
                                ? "Pick Date to Pay"
                                : ((selectedPlan && selectedPlan !== 0) || payIn === "PayLater"
                                  ? "Submit"
                                  : "Pick Date to Pay"))}
                        </button>



                      </div>
                      {fundingOption !== "nsfas" && (
                        <div className="form-grid2">
                          <button
                            className="btn456"
                            onClick={() => {

                              if ((!selectedPlan || selectedPlan === 0) && payIn !== "PayLater") {
                                // setWarning('Please select an option before proceeding.');
                                Swal.fire({
                                  title: "Choose Date",
                                  text: "Please pick a move in date to proceeding!",
                                  icon: "warning",
                                  confirmButtonText: "OK",
                                });
                              } else if (!moveInDate) {
                                Swal.fire({
                                  title: "Choose Date",
                                  text: "Please pick a move in date to proceed!",
                                  icon: "warning",
                                  confirmButtonText: "OK",
                                });
                              } else {
                                // setWarning(''); // Clear warning if valid
                                handleEftPay();
                              }
                            }}
                            disabled={isPlaced == true}

                          >
                            {progress2 ? "Processing..."
                              : (!moveInDate
                                ? "Pick Date to Make EFT"
                                : ((selectedPlan && selectedPlan !== 0) || payIn === "PayLater"
                                  ? "Make EFT Instead"
                                  : "Pick Date to Make EFT"))}
                          </button>
                        </div>
                      )}
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
        {showRegistrationModal && (
          <RegistrationModal
            // Pass down clickedAction
            clickedAction={clickedAction}
            onSuccess={handleRegistrationSuccess}
            onClose={() => {
              setShowRegistrationModal(false);
              if (!isLoggedIn) {
                setShowLoginPrompt(true); // Show login prompt if user cancels without logging in
              }
            }}
            date={date}
            apartment={apartment}
            apartmentandbuilding={apartmentandbuilding}
            totalCost={totalCost}
            serviceFee={serviceFee}
            chosenDefault={chosenPlan}
            nextPaymentDate={nextPaymentDate}
          />
        )}
      </div>
      <style>{`
  /* Container for checkboxes */
.checkbox-container {
display: inline, /* Align checkboxes side by side */
  flex-wrap: wrap; /* Allow wrapping to the next line if necessary */
  gap: 15px; /* Add spacing between checkboxes */
  justify-content: flex-start; /* Align items to the left */
  margin: 20px 0; /* Add spacing around the container */
}

.checkbox-wrapper {
  display: inline, /* Display each checkbox and its label inline */
  align-items: center; /* Align items vertically */
  gap: 10px; /* Add spacing between the checkbox and the label */
}

.checkbox-wrapper input[type="checkbox"] {
  display: none; /* Hide the default checkbox */
}

.checkbox-wrapper .terms-label {
  cursor: pointer;
  display: in-line;
  align-items: center;
}

.checkbox-wrapper .terms-label .label-text {
  margin-right: 10px; /* Add spacing after the text */
}

.checkbox-wrapper .checkbox-svg {
  width: 20px;
  height: 20px;
}

.checkbox-wrapper .checkbox-box {
  fill: rgba(207, 205, 205, 0.425);
  stroke: #ff5a5f;
  stroke-dasharray: 800;
  stroke-dashoffset: 800;
  transition: stroke-dashoffset 0.6s ease-in;
}

.checkbox-wrapper .checkbox-tick {
  stroke: #ff5a5f;
  stroke-dasharray: 172;
  stroke-dashoffset: 172;
  transition: stroke-dashoffset 0.6s ease-in;
}

.checkbox-wrapper input[type="checkbox"]:checked + .terms-label .checkbox-box,
.checkbox-wrapper input[type="checkbox"]:checked + .terms-label .checkbox-tick {
  stroke-dashoffset: 0;
}


`}</style>
    </>
  );
}

export default NewInvoice4;
