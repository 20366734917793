
import React, { useState, useEffect } from "react";
import { Link, useNavigate  } from "react-router-dom";
import { useAuth } from './AuthProvider'; // Ensure to import the useAuth hook

import './slides.css'
import './HeroSection.css';
import './tiles.css';

import './card.css';
import logo from './logowhitefooter.png';
import remove from './remove-location_12557391.png';
import wifi from './wifi-signal_3178314.png';
import chat from './chat-bubble_1048123.png';
import About from './E7E6162E-1C97-466B-B6E4-9C4334401A03_1_201_a.jpeg';

function chunkArray(array, chunkSize) {
  const result = [];
  for (let i = 0; i < array.length; i += chunkSize) {
    result.push(array.slice(i, i + chunkSize));
  }
  return result;
}

function Home_page() {
  const [buildings, setBuildings] = useState([]);
  const { isLoggedIn, role } = useAuth(); // Use the isLoggedIn and role from useAuth


  const navigate = useNavigate();
  useEffect(() => {
    fetch(`https://backendjaes.passiontrials.co.za/displayapartment`)  // Endpoint to fetch all apartments
      .then(response => response.json())
      .then(data => {
        //  setBuildings(data); // Assume the data is an array of apartments
      })
      .catch(error => console.error("Error loading all buildings:", error));

    buildingInfo();
  }, []);

  const buildingInfo = () => {
    fetch(`https://backendjaes.passiontrials.co.za/new_building_tb`)
      .then(res => res.json())
      .then(info => {
        setBuildings(info);
        console.log('this is the data that must be revealed', info);
      })
      .catch(error => {
        console.log('An error occurred', error);
      })
  };


  const [servicesShown, setServicesShown] = useState(3);
   // Changes made
   const services = [
    {
      title: 'Uncapped Wi-Fi',
      description: 'Fast Fibre Wi-Fi of up to 50 mbp/s.',
      img: 'fas fa-wifi' // Wi-Fi icon
    },
    {
      title: 'Transport',
      description: 'To and from campus at designated times.',
      img: 'fas fa-bus' // Bus icon
    },
    {
      title: 'Loadshedding Solar System',
      description: 'Backup power units with charging ports.',
      img: 'fas fa-bolt' // Bolt icon for solar power
    },
    {
      title: 'Fully Kitted Houses',
      description: 'Fully furnished with laundry and kitchen appliances.',
      img: 'fas fa-toolbox' // Toolbox icon for fully kitted houses
    },
    {
      title: 'Full-Time Administration',
      description: 'Full-time administrative team to help with queries and documents.',
      img: 'fas fa-server' // Server icon for administration
    },
    {
      title: 'Sky Glass TV',
      description: 'The smarter TV. Stream all your favorite movies and shows with ease.',
      img: 'fas fa-tv' // TV icon for Sky Glass TV
    },
    {
      title: 'Capacity Ratios',
      description: 'Our houses are set-up to ensure students share an equal amount of space.',
      img: 'fas fa-chart-pie' // Pie chart icon for capacity ratios
    },
    {
      title: 'JAES Alumni',
      description: 'Career opportunities for students to expand knowledge and secure jobs.',
      img: 'fas fa-suitcase' // Suitcase icon for JAES Alumni
    },
    {
      title: 'Youth Development',
      description: 'Programs that promote self-growth for students.',
      img: 'fas fa-bar-chart' // Bar chart icon for youth development
    }
  ];  
  const showMoreServices = () => {
    if (servicesShown < services.length) {
      setServicesShown(services.length); 
    } else {
      setServicesShown(3); 
    }
  };
  const allapartments = () => {
    navigate('/allapartments');
  };
  const userDashboard = () => {
    if (isLoggedIn) {
      // Redirect to the appropriate dashboard based on the role
      if (role === 'User') {
        navigate('/userDashboard');
      } else if (role === 'Admin') {
        navigate('/RegularAdmin');
      }  else if (role === 'reserve') {
        navigate('/ReserveUserDashboard');
      } else if (role === 'TransportOnly') {
        navigate('/TransportuserDashboard');
      } else if (role === 'Super Admin') {
        navigate('/NewAdminDashboard');
      } else if (role === 'SalesAndServiceProvider') {
        navigate('/NewAdminDashboard');
      } else if (role === 'Transport') {
        navigate('/transport-admin');
      } else if (role === 'Media') {
        navigate('/event-details');
      } else {
        console.log("Unknown role");
      }
    } else {
      // If not logged in, navigate to login page
      navigate('/login');
    }
  };
  const ContactUs = () => {
    navigate('/ContactUs');
  };
  const listyourhome = () => {
    navigate('/ListHomes');
  };
  const Event_Ticket = () => {
    navigate('/Event_Ticket');
  };
  const Transport = () => {
    if (role === 'Transport') {
      navigate('/transport-admin');
    } else if (role === 'Super Admin') {
      navigate('/transport-admin');
    }else if (role === 'SalesAndServiceProvider') {
      navigate('/transport-admin');
    } else if (role === 'TransportOnly') {
      navigate('/TransportuserDashboard');
    } else{
       navigate('/IntroTransport');     
    }
    
  };
  return (
    <body>
   <div class="astral-viewport">
    <div class="nebula-construct">
        <div class="quasar-zone" onClick={allapartments}>
            <Link to="/allapartments">
            <h2 class="starlight-title">Student Accommodations</h2>
            </Link>
        </div>
        <div class="lunar-chamber">
        <div className="lunar-rise" onClick={userDashboard}>
        <h2 className="orbit-title">
                {role === 'User' && 'User Dashboard'} 
                {role === 'Admin' && 'Admin Dashboard'}
                {role === 'reserve' && 'Reserve Dashboard'}
                {role === 'TransportOnly' && 'Transport Dashboard'}
                {role === 'Super Admin' && 'Super Admin Dashboard'}
                {role === 'Transport' && 'Transport Admin Dashboard'}
                {role === 'Media' && 'Media Dashboard'}
                {isLoggedIn && !['User', 'Admin', 'reserve', 'TransportOnly', 'Super Admin', 'Transport', 'Media'].includes(role) && 'Dashboard'}
                {!isLoggedIn && 'Login'}
              </h2>
            </div>
            <div class="solar-dive"  onClick={listyourhome}>
               
                <h2 class="orbit-title">List your Home</h2>
            
            </div>
        </div>
    </div>

    <div class="comet-ribbon">
        <div class="starfal-spark">
            <Link to="/">
            <h2 class="galactic-header">Food</h2>
            </Link>
        </div>
        <div class="cosmic-surge"  onClick={Transport}>
            <Link >
            <h2 class="galactic-header">Transport</h2>
        </Link>
        </div>
        <div class="galactic-glow"  onClick={Event_Ticket}>
        <Link to="/Event_Ticket" >
            <h2 class="galactic-header">Rewards & Benefits</h2>
        </Link>
        </div>

        <div class="starfall-spark"  onClick={ContactUs}>
            <Link to="/ContactUs">
            <h2 class="galactic-header">Contact Us</h2>
            </Link>
        </div>
    </div>
</div>
      <section class="w3l-index5 pb-5 pt-4">
      </section>
    </body>
  );
}
export default Home_page;
