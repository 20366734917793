

import Swal from 'sweetalert2';
import React, { useState, useEffect } from 'react';

const EftBookings = () => {
    const [bookings, setBookings] = useState([]);
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(true); // Loading state
    const [loadingStates, setLoadingStates] = useState({});


    useEffect(() => {
        fetchEFTBookings();
    }, []);

    const fetchEFTBookings = () => {
        fetch('https://backendjaes.passiontrials.co.za/api/eft-bookings')
            .then((response) => {
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                return response.json();
            })
            .then((data) => setBookings(data))
            .catch((err) => setError('Failed to fetch bookings'))
            .finally(() => setLoading(false)); // Stop loading
    };


    const fetchSpecifcApartment = async (id) => {
        try {
            const response = await fetch(`https://backendjaes.passiontrials.co.za/apartment_tb/single/${id}`);
            if (!response.ok) {
                throw new Error('Failed to fetch apartment data');
            }
            const data = await response.json();
            console.log("fetched data:", data.data[0]);
            return data.data[0]; // Properly return the first apartment object
        } catch (err) {
            console.error('Error fetching apartment data:', err.message);
            return null; // Return null if there is an error
        } finally {
            setLoading(false); // Ensure loading state is updated
        }
    };

    const fetchBuilding = async (id) => {
        try {
            const response = await fetch(`https://backendjaes.passiontrials.co.za/apartment_tb/singlewithjoint/${id}`);
            if (!response.ok) {
                throw new Error('Failed to fetch apartment data');
            }
            const data = await response.json();
            console.log("fetched data:", data.data[0]);
            return data.data[0]; // Properly return the first apartment object
        } catch (err) {
            console.error('Error fetching building & apartment data:', err.message);
            return null; // Return null if there is an error
        } finally {
            setLoading(false); // Ensure loading state is updated
        }
    };


    const getLatestInfo = async (user) => {
        try {
            const d = await fetch(`https://backendjaes.passiontrials.co.za/api/eft-bookings/${user}`, {
                headers: { 'Content-Type': 'application/json' },

            });
            const data = await d.json();
            if (data && data.length > 0) {

                console.log("User fetched data:", data[0]);
                return data[0]; // Return fetched data
            } else {
                throw new Error("No user data found for the given user ID.");
            }
        } catch (error) {
            console.log('Failed to retrieve eft info ');
        }

    };




    const isAssociated = async (user) => {
        try {
            const response = await fetch(`https://backendjaes.passiontrials.co.za/user_apartment_exists/${user}`, {
                headers: { 'Content-Type': 'application/json' },
            });

            if (!response.ok) {
                console.error("Failed to fetch association status:", response.statusText);
                throw new Error("Failed to fetch association status"); // Throw an error instead of returning false
            }

            const data = await response.json();
            return data.exists; // Return the "exists" boolean value only if the response is valid
        } catch (error) {
            console.error("Error occurred while checking if associated:", error.message);
            throw error; // Re-throw the error for handling elsewhere
        }
    };



    // const handleApproval = async (id, status, user, room) => {
    //     fetch(`https://backendjaes.passiontrials.co.za/api/eft-bookings/${id}`, {
    //         method: 'PUT',
    //         headers: {
    //             'Content-Type': 'application/json',
    //         },
    //         body: JSON.stringify({ ApprovalStatus: status, Approval: status === 'Approved' ? 1 : 0 }),
    //     })
    //         .then((response) => {
    //             if (!response.ok) {
    //                 throw new Error('Failed to update approval status');
    //             }
    //             return response.json();
    //         })
    //         .then(() => {
    //             if (status === 'Approved') {
    //                 associateUserWithApartment(room, user);
    //                 markApartmentAsOccupied(room);

    //                 const apartment = fetchSpecifcApartment(room);

    //                 saveToWallet(apartment, user, 0, 0);
    //             }

    //             if (status === 'Dismissed') {
    //                 dismissUser(room, user);
    //             }
    //             fetchEFTBookings();
    //         })
    //         .catch((err) => {
    //             console.error(`Error updating booking with ID ${id}:`, err);
    //         });
    // };

    const handleApproval = async (id, status, user, room, datePicked, buttonType) => {
        try {
            setLoadingStates((prev) => ({ ...prev, [`${id}-${buttonType}`]: true }));

            const response = await fetch(`https://backendjaes.passiontrials.co.za/api/eft-bookings/${id}`, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ ApprovalStatus: status, Approval: status === 'Approved' ? 1 : 0 }),
            });

            if (!response.ok) {
                throw new Error('Failed to update approval status');
            }

            if (status === 'Approved') {
                const singleBooker = await getLatestInfo(user);

                if (singleBooker) {
                    await associateUserWithApartment(room, user, singleBooker);
                    console.log('waited for latest Eft record then associated user');
                }
                await markApartmentAsOccupied(room);

                const apartment = await fetchSpecifcApartment(room); // Wait for apartment data

                const buildingName = await fetchBuilding(room);



                const paymentDate = new Date(datePicked);
                const year = paymentDate.getFullYear();
                const month = paymentDate.getMonth(); // 0-based month
                const daysInMonth = new Date(year, month + 1, 0).getDate();
                const dailyRate = apartment.ApartmentPrice / daysInMonth;


                if (singleBooker.PaidTransport == true) {

                    await transport(user, buildingName);
                    console.log('yes paid for transport');

                }

                await saveToWallet(apartment, user, dailyRate, singleBooker.DaysPaidFor, room); // Ensure the data is correctly passed



            }

            if (status === 'Dismissed') {
                await dismissUser(room, user);
            }

            fetchEFTBookings(); // Refresh bookings
        } catch (err) {
            console.error(`Error updating booking with ID ${id}:`, err.message);
        }
        finally {
            setLoadingStates((prev) => ({ ...prev, [`${id}-${buttonType}`]: false })); // Reset loader for specific button
        }
    };


    const dismissUser = async (roomid, userid) => {
        try {


            // const checkstatus = await getLatestInfo(userid);
            const isAss = await isAssociated(userid);

            if (isAss) {
                console.log("Deleted the user that is  associated.");

            } else {
                console.log("Deleted user is NOT associated with an apartment.");
            }


            const removeApartmentFromUser = await fetch(`https://backendjaes.passiontrials.co.za/api/eft-bookings/${userid}`, {
                method: 'DELETE',
                headers: { 'Content-Type': 'application/json' },
            });

            if (!removeApartmentFromUser.ok && removeApartmentFromUser.status !== 404) {
                const errorText = await removeApartmentFromUser.text();
                throw new Error(`Failed to delete user apartment record: ${errorText}`);
            }

            if (removeApartmentFromUser.ok) {
                fetchEFTBookings();
                console.log('deleted and refreshed');
            }
            // if (checkstatus.ApprovalStatus.toLowerCase() == 'approved') {
            //     const incrementCapacity = await fetch(`https://backendjaes.passiontrials.co.za/increment-capacity/${roomid}`, {
            //         method: 'PUT',
            //         headers: { 'Content-Type': 'application/json' },
            //     });

            //     if (!incrementCapacity.ok && incrementCapacity.status !== 404) {
            //         const errorText = await incrementCapacity.text();
            //         throw new Error(`Failed to increment apartment capacity: ${errorText}`);
            //     }

            //     console.log('decrement apartment successfully');

            // }



            console.log('Dismiss process completed successfully');
        } catch (error) {
            console.error('Error dismissing user:', error.message);
        }
    };

    const markApartmentAsOccupied = async (roomid) => {
        try {
            // Ensure apartmentId exists before proceeding
            if (roomid) {
                // Decrement the Capacity2 in the apartment_tb table
                const decrementCapacity = await fetch(`https://backendjaes.passiontrials.co.za/decrement-capacity/${roomid}`, {
                    method: 'PUT',
                    headers: { 'Content-Type': 'application/json' }
                });

                if (!decrementCapacity.ok) {
                    const errorText = await decrementCapacity.text();
                    throw new Error(`Failed to decrement apartment capacity: ${errorText}`);
                }

                console.log('Apartment capacity has been decremented successfully');
            } else {
                console.log('No apartment or room found');
            }
        } catch (error) {
            throw new Error(`Error marking apartment as occupied: ${error.message}`);
        }
    };

    const associateUserWithApartment = async (room, user, eftDetails) => {
        try {
            const response = await fetch('https://backendjaes.passiontrials.co.za/user_apartment_tb', {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({
                    UserId: user,
                    ApartmentId: room,
                    // MoveInDate: moveInDate.toLocaleDateString('en-US', { day: 'numeric', month: 'long', year: 'numeric' }),
                    // MoveOutDate: formattedMoveOutDate, // Use the formatted date
                    MoveInDate: eftDetails.ChosenMoveInDate,
                    MoveOutDate: 'test',
                    ChosenMoveInDate: eftDetails.ChosenMoveInDate,
                    RentAmount: eftDetails.Amount,
                    LeaseTerm: '1 Month',
                    IsActive: true,
                    DepositPaid: true,
                    ApartmentRating: 5,
                    Guest: 1, // Default guest number
                    Notes: 'EFT Booker',
                    TenantAgreementType: 'Month-to-Month',
                    ChosenPlan: "Self & Bursary Funded",
                    ServiceFee: 0,
                    RentCost: eftDetails.Amount,
                    PayableRent: eftDetails.Amount,
                    Transport: eftDetails.PaidTransport == 0 ? "did not pay" : "Paid Transport",
                    PaidTransport: eftDetails.PaidTransport
                })
            });

            if (!response.ok) {
                const errorText = await response.text();
                throw new Error(`Failed to associate user with apartment: ${errorText}`);
            }

            console.log('User associated with apartment successfully!');
        } catch (error) {
            throw new Error(`Error associating user with apartment: ${error.message}`);
        }
    };

    const saveToWallet = async (apartmentData, user, dailyRate, daysPaidFor, room) => {
        try {

            console.log('aparmtent info', apartmentData.ApartmentId, apartmentData.ApartmentPrice);
            const response = await fetch('https://backendjaes.passiontrials.co.za/wallet', {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({
                    UserId: user,
                    ApartmentId: room,
                    PaymentDate: new Date().toISOString().split('T')[0], // Format as 'YYYY-MM-DD'
                    AmountPaid: apartmentData.ApartmentPrice,
                    DailyRate: dailyRate.toFixed(2) || 0,
                    DaysPaidFor: daysPaidFor || 0,
                    MonthYear: new Date().toLocaleDateString('en-US', { month: 'long', year: 'numeric' }),
                    PaymentMethod: "EFT"
                })
            });
            if (!response.ok) {
                const errorText = await response.text();
                throw new Error(`Failed to save to wallet: ${errorText}`);
            }
            console.log('Wallet record saved successfully!');



            const removeFromList = await fetch(`https://backendjaes.passiontrials.co.za/api/eft-bookings/${user}`, {
                method: 'DELETE',
                headers: { 'Content-Type': 'application/json' },
            });

            if (!removeFromList.ok && removeFromList.status !== 404) {
                const errorText = await removeFromList.text();
                throw new Error(`Failed to delete user from the list : ${errorText}`);
            }

            if (removeFromList.ok) {
                fetchEFTBookings();
                console.log('deleted and refreshed');
            }
        } catch (error) {
            throw new Error(`Error saving to wallet: ${error.message}`);
        }
    };



    const transport = async (user, buildingDetails) => {

        const date = new Date();
        const currentMonth = date.toLocaleDateString('en-US', { month: 'long' });
        const currentYear = date.getFullYear();

        const paymentData = {
            UserId: user,
            Month: `${currentMonth} ${date.getFullYear()}`,
            Price: 250,
            Building: buildingDetails.BuildingName,
        };

        // Initialize all months to 0
        const months = {
            january: 0,
            february: 0,
            march: 0,
            april: 0,
            may: 0,
            june: 0,
            july: 0,
            august: 0,
            september: 0,
            october: 0,
            november: 0,
            december: 0,
        };

        // Set the current month to 'Paid'
        months[currentMonth] = 'Paid';

        // Add months data to paymentData
        Object.assign(paymentData, months);
        try {
            const response = await fetch('https://backendjaes.passiontrials.co.za/transport_monthly_payment', {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify(paymentData),
            });

            const data = await response.json();
            if (response.ok) {
                console.log('Transport payment recorded successfully:', data);
            }


        }
        catch (error) {
            console.log('Failed record transport payment', error);

        }

    };



    const handleDismissConfirmation = (id, user, room, btn) => {
        Swal.fire({
            title: 'Are you sure?',
            text: 'You are about to dismiss this user from the booking list. This action cannot be undone.User remains associated with room ,if approved.',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#d33',
            cancelButtonColor: '#3085d6',
            confirmButtonText: 'Yes, dismiss it!',
        }).then((result) => {
            if (result.isConfirmed) {
                handleApproval(id, 'Dismissed', user, room, 'none', btn);
                Swal.fire('Dismissed!', 'The booking has been dismissed.', 'success');
            }
        });
    };

    if (loading) {
        return <div>Loading bookings...</div>;
    }

    if (error) {
        return <div>Error: {error}</div>;
    }

    return (
        <div className="eft-bookings-container">
            <h1>EFT Bookings</h1>
            {bookings.length === 0 ? (
                <div>No bookings found.</div>
            ) : (
                <table className="eft-bookings-table">
                    <thead>
                        <tr>
                            <th>Booking Number</th>
                            <th>Name</th>
                            <th>Surname</th>
                            <th>Email</th>
                            <th>Approval Status</th>
                            <th>Actions</th>
                        </tr>
                    </thead>
                    <tbody>
                        {bookings.map((booking) => (
                            <tr key={booking.Id}>
                                <td>{booking.BookingNumber}</td>
                                <td>{booking.Name}</td>
                                <td>{booking.Surname}</td>
                                <td>{booking.Email}</td>
                                <td>{booking.ApprovalStatus}</td>
                                <td>
                                    <button
                                        onClick={() =>
                                            handleApproval(booking.Id, 'Approved', booking.UserId, booking.ApartmentId, booking.ChosenMoveInDate, 'approve')
                                        }
                                        // disabled={booking.ApprovalStatus === 'Approved'}
                                        disabled={booking.ApprovalStatus === 'Approved' || loadingStates[`${booking.Id}-approve`]}

                                        style={{ backgroundColor: 'green' }}
                                    >
                                        {loadingStates[`${booking.Id}-approve`] ? (
                                            <div className="loader"></div>
                                        ) : (
                                            'Approve'
                                        )}
                                    </button>
                                    <button
                                        onClick={() =>
                                            handleDismissConfirmation(booking.Id, booking.UserId, booking.ApartmentId,
                                                'dismiss')
                                        }
                                        // disabled={booking.ApprovalStatus === 'Dismissed'}
                                        disabled={booking.ApprovalStatus === 'Dismissed' || loadingStates[`${booking.Id}-dismiss`]}

                                    >
                                        {loadingStates[`${booking.Id}-dismiss`] ? (
                                            <div className="loader"></div>
                                        ) : (
                                            'Dismiss'
                                        )}
                                    </button>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            )}
            <style>{`
                .eft-bookings-container {
                    padding: 20px;
                }
                .eft-bookings-table {
                    width: 100%;
                    border-collapse: collapse;
                    margin-top: 20px;
                }
                .eft-bookings-table th,
                .eft-bookings-table td {
                    border: 1px solid #ddd;
                    padding: 8px;
                    text-align: left;
                }
                .eft-bookings-table th {
                    background-color: #f4f4f4;
                    font-weight: bold;
                }
                .eft-bookings-table tr:hover {
                    background-color: #f1f1f1;
                }
                .eft-bookings-table button {
                    margin-right: 10px;
                    padding: 5px 10px;
                    cursor: pointer;
                }
                      @keyframes spin {
                    0% { transform: rotate(0deg); }
                    100% { transform: rotate(360deg); }
                }
                .loader {
                    border: 3px solid #f3f3f3; /* Light grey */
                    border-top: 3px solid #3498db; /* Blue */
                    border-radius: 50%;
                    width: 16px;
                    height: 16px;
                    animation: spin 1s linear infinite;
                    display: inline-block;
                    margin-right: 8px;
                }
            `}</style>
        </div>
    );
};

export default EftBookings;
