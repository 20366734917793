import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import ApartmentTable from "./ApartmentTable.js";
import { useAuth } from "./AuthProvider";

const Steps3_2Admin = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { userId } = useAuth();
  const { typeOfHouse, typeOfPlace } = location.state || {};
  const [selectedSetups, setSelectedSetups] = useState([]);
  const [expandedRoomType, setExpandedRoomType] = useState(null);
  const [adminEmail, setAdminEmail] = useState(""); // State to store admin email
  const [buildingsByEmail, setBuildingsByEmail] = useState([]);

  //   const { BuildingId } = location.state; // Get BuildingId from the previous page state
  useEffect(() => {
    // Scroll to the top of the page on component mount
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    const fetchAdminEmail = async () => {
      try {
        const response = await fetch(
          `https://backendjaes.passiontrials.co.za/admin_email/${userId}`
        );
        if (!response.ok) {
          throw new Error("Failed to fetch admin email");
        }
        const data = await response.json();
        if (data && data.AdminEmail) {
          setAdminEmail(data.AdminEmail); // Set admin email in state

          console.log("Fetched admin email:", data.AdminEmail); // Log the email
        }
      } catch (error) {
        console.error("Error fetching admin email:", error);
      }
    };

    if (userId) {
      fetchAdminEmail(); // Fetch admin email if userId exists
    }
  }, [userId]);
  useEffect(() => {
    const fetchBuildingsByEmail = async () => {
      try {
        const response = await fetch(
          `https://backendjaes.passiontrials.co.za/buildings_by_email/${adminEmail}`
        );
        if (!response.ok) {
          throw new Error("Failed to fetch buildings");
        }
        const data = await response.json();
        setBuildingsByEmail(data); // Store the fetched buildings
        // Auto-select the first building
        if (data.length > 0) {
          setFormData2((prev) => ({ ...prev, BuildingId: data[0].BuildingId }));
        }
      } catch (error) {
        console.error("Error fetching buildings by email:", error);
      }
    };

    if (adminEmail) {
      fetchBuildingsByEmail(); // Fetch buildings when adminEmail is available
    }
  }, [adminEmail]);

  const handleLivingArrangementSelection = (roomType, variation) => {
    const newLivingArrangement = `${roomType} - ${variation}`;
    setFormData2((prevData) => ({
      ...prevData,
      LivingArrangement: newLivingArrangement,
      ApartmentType: variation, // Dynamically set ApartmentType
      Capacity2: roomType === "Single" || roomType === "Bachelor" ? "1" : "2", // Set Capacity2 dynamically
      TotalNumOfBeds:
        roomType === "Single" || roomType === "Bachelor" ? "1" : "2", // Set TotalNumOfBeds dynamically
    }));
  };

  const [formData2, setFormData2] = useState({
    ApartmentNumber: "",
    RoomIdentifier: "",
    ApartmentPrice: "",
    ApartmentPreferedGender: "",
    LivingArrangement: "",
    RoomDesignation: "",
    Capacity2: "",
    ApartmentType: "",
    TotalNumOfBeds: "",
    Suburb: "Central Park",
    University: "Pretoria University",
    ApartmentDeposit: "1500",
    ApartmentRating: "4.5",
    BedRoomStatus: "Available",
    BedRoomCount: "2",
    Date: new Date().toISOString().split("T")[0],
    BuildingManager: "John Doe",
    Guest: "2",
    ApartmentSize: "80 sqm",
    LivingSpace: "Shared Lounge",
    PriceB: "4700",
    CreatedDate: new Date().toISOString().split("T")[0], // Sets CreatedDate to today's date in YYYY-MM-DD format
    CreatedBy: adminEmail,
    Latitude: "-25.746111",
    Longitude: "28.188056",
  });

  const [imageFiles, setImageFiles] = useState({});
  const [imagePreviewsOnUpload, setImagePreviewsOnUpload] = useState({});
  useEffect(() => {
    if (formData2.BuildingId) {
      const selectedBuilding = buildingsByEmail.find(
        (building) => building.BuildingId === formData2.BuildingId
      );
      if (selectedBuilding) {
        setFormData2((prev) => ({
          ...prev,
          ApartmentPreferedGender:
            selectedBuilding.PreferedGender === "Male and Female"
              ? ""
              : selectedBuilding.PreferedGender,
        }));
      }
    }
  }, [formData2.BuildingId, buildingsByEmail]);

  const handleChange2 = (e) => {
    const { name, value, type, checked } = e.target;

    if (name === "BuildingId") {
      // Fetch the selected building's details
      const selectedBuilding = buildingsByEmail.find(
        (building) => building.BuildingId === value
      );
      if (selectedBuilding) {
        // Update ApartmentPreferedGender based on the building's PreferedGender
        setFormData2((prev) => ({
          ...prev,
          BuildingId: value,
          ApartmentPreferedGender:
            selectedBuilding.PreferedGender === "Male and Female"
              ? ""
              : selectedBuilding.PreferedGender,
        }));
      }
    } else if (type === "radio") {
      setFormData2((prev) => ({
        ...prev,
        [name]: checked ? value : "",
      }));
    } else {
      setFormData2((prev) => ({
        ...prev,
        [name]: value,
      }));
    }
  };

  const handleImageChange2 = (e) => {
    const { name, files } = e.target;
    const file = files[0];
    if (file) {
      setImageFiles((prev) => ({ ...prev, [name]: file }));
      setImagePreviewsOnUpload((prev) => ({
        ...prev,
        [name]: URL.createObjectURL(file),
      }));
    }
  };

  const validateForm = () => {
    const requiredFields = [
      "BuildingId",
      "ApartmentNumber",
      "RoomIdentifier",
      "ApartmentPrice",
      "ApartmentPreferedGender",
      "LivingArrangement",
    ];

    // Validate all required fields except RoomDesignation
    for (const field of requiredFields) {
      console.log(`Validating field: ${field}, value: ${formData2[field]}`); // Debugging log
      if (!formData2[field]) {
        alert(
          `Please fill out the required field: ${field.replace(
            /([A-Z])/g,
            " $1"
          )}`
        );
        return false; // Stop submission if any field is empty
      }
    }

    // Custom validation for RoomDesignation
    if (!formData2.RoomDesignation) {
      alert("Please select a Room Designation (NSFAS or Self Funded).");
      return false; // Stop submission if RoomDesignation is not selected
    }

    return true; // All fields are valid
  };

  const handleSubmit2 = async (e) => {
    e.preventDefault();

    if (!validateForm()) {
      return; // Stop form submission if validation fails
    }

    const formData = new FormData();
    // Add other fields
    Object.keys(formData2).forEach((key) => {
      if (key !== "BuildingId") {
        formData.append(key, formData2[key]);
      }
    });

    // Add BuildingId manually if it's hardcoded or coming from another source
    if (formData2.BuildingId) {
      formData.append("BuildingId", formData2.BuildingId); // Use the selected BuildingId
    } else {
      alert("Please select a building");
      return; // Stop form submission if no building is selected
    }

    // Add image files to the FormData
    Object.keys(imageFiles).forEach((key) => {
      formData.append(key, imageFiles[key]);
    });

    try {
      const response = await fetch(
        "https://backendjaes.passiontrials.co.za/new_apartment_tb",
        {
          method: "POST",
          body: formData,
        }
      );

      if (response.ok) {
        const result = await response.json();
        alert("Apartment added successfully!");
        window.location.reload();

        // navigate('/apartments'); Redirect to the apartments list or another page
      } else {
        const errorData = await response.json();
        console.error("Error creating apartment:", errorData);
        //alert('Failed to add apartment. Please try again.');
        window.location.reload();
      }
    } catch (error) {
      console.error("Error creating apartment:", error.message);
      alert("An unexpected error occurred. Please try again.");
    }
  };

  const handleBack = () => {
    navigate(-1); // Navigate back to the previous page
  };
  const handleNext = () => {
    if (typeOfPlace) {
      navigate("/Steps3_5Admin", { state: { typeOfHouse, typeOfPlace } }); // Pass typeOfPlace to /Steps3Admin
    } else {
      alert("Please select an option before proceeding.");
    }
  };

  const goBack = () => navigate(-1); // Navigate to the previous page
  const roomTypesWithVariations = {
    Single: ["Standard", "Ensuite", "Kitchenette", "Flatlet", "Deluxe"],
    Double: ["Standard", "Ensuite", "Kitchenette", "Flatlet", "Deluxe"],
    Twin: ["Standard", "Ensuite", "Kitchenette", "Flatlet", "Deluxe"],
    Bachelor: ["Flatlet", "Deluxe"],
  };
  const toggleRoomType = (roomType) => {
    setExpandedRoomType(roomType); // Always keep the room type expanded
  };

  return (
    <div
      className="ducati-container"
      style={{ backgroundColor: "white", marginBottom: "520px" }}
    >
      <h1 className="ducati-title">Add a new apartment</h1>
      <form onSubmit={handleSubmit2} className="ducati-form">
        <div className="kawasaki-field">
          <label htmlFor="BuildingId" className="kawasaki-label">
            Select Building
          </label>
          <select
            id="BuildingId"
            name="BuildingId"
            value={formData2.BuildingId}
            onChange={handleChange2}
            className="kawasaki-input"
            required
          >
            <option value="">Select a Building</option>
            {buildingsByEmail.map((building) => (
              <option key={building.BuildingId} value={building.BuildingId}>
                {building.BuildingName}
              </option>
            ))}
          </select>
        </div>

        <div className="input-row-kawasaki">
          <div className="kawasaki-field">
            <label htmlFor="ApartmentNumber" className="kawasaki-label">
              Room Name
            </label>
            <input
              type="text"
              id="ApartmentNumber"
              name="ApartmentNumber"
              value={formData2.ApartmentNumber}
              required
              onChange={handleChange2}
              placeholder="Room Name (e.g., Room A1)"
              className="kawasaki-input"
            />
          </div>
          <div className="kawasaki-field">
            <label htmlFor="RoomIdentifier" className="kawasaki-label">
              Room Identifier
            </label>
            <input
              type="text"
              id="RoomIdentifier"
              name="RoomIdentifier"
              value={formData2.RoomIdentifier}
              required
              onChange={handleChange2}
              placeholder="Room Identifier (e.g., 081_BWN_A02)"
              className="kawasaki-input"
            />
          </div>
        </div>
        <style>{`
.input-row-kawasaki {
  display: flex;
  justify-content: space-between;
  gap: 20px; /* Space between the inputs */
}

.kawasaki-field {
  flex: 1; /* Ensure fields take equal space */
  display: flex;
  flex-direction: column;
}

.kawasaki-label {
  font-size: 14px;
  font-weight: bold;
  margin-bottom: 5px; /* Space between label and input */
  color: #333;
    text-align: left; /* Align label text to the left */

}

.kawasaki-input {
  padding: 10px;
  font-size: 14px;
  border: 1px solid #ccc;
  border-radius: 5px;
}
`}</style>

        <div className="input-row-group">
          <div className="kawasaki-field">
            <label htmlFor="ApartmentPrice" className="kawasaki-label">
              Price
            </label>
            <input
              type="number"
              id="ApartmentPrice"
              name="ApartmentPrice"
              value={formData2.ApartmentPrice}
              onChange={handleChange2}
              required
              placeholder="Price"
              className="kawasaki-input"
            />
          </div>
          <div className="harley-field">
            <label htmlFor="ApartmentPreferedGender" className="harley-label">
              Preferred Gender
            </label>
            <select
              id="ApartmentPreferedGender"
              name="ApartmentPreferedGender"
              value={formData2.ApartmentPreferedGender}
              onChange={handleChange2}
              required
              className="harley-select"
              disabled={formData2.ApartmentPreferedGender !== ""} // Disable if gender is pre-set
            >
              <option value="">Select Gender</option>
              <option value="Male">Male</option>
              <option value="Female">Female</option>
              <option value="Male and Female">Male & Female</option>
            </select>
          </div>
        </div>
        <style>{`
.input-row-group {
  display: flex;
  justify-content: space-between;
  gap: 20px; /* Space between the fields */
}

.kawasaki-field,
.harley-field {
  flex: 1; /* Ensure fields take equal space */
  display: flex;
  flex-direction: column;
}

.kawasaki-label,
.harley-label {
  font-size: 14px;
  font-weight: bold;
  margin-bottom: 5px; /* Space between label and input/select */
  color: #333;
  text-align: left;
}

.kawasaki-input,
.harley-select {
  padding: 10px;
  font-size: 14px;
  border: 1px solid #ccc;
  border-radius: 5px;
}
`}</style>
        <div>
          <ul className="radio-switch4">
            <li className="radio-switch__item4">
              <input
                className="radio-switch__input4 ri5-sr-only4"
                type="radio"
                name="RoomDesignation"
                id="radio-1"
                value="NSFAS"
                checked={formData2.RoomDesignation === "NSFAS"}
                onChange={handleChange2}
              />
              <label className="radio-switch__label4" htmlFor="radio-1">
                NSFAS
              </label>
            </li>

            <li className="radio-switch__item4">
              <input
                className="radio-switch__input4 ri5-sr-only4"
                type="radio"
                name="RoomDesignation"
                id="radio-2"
                value="Self Funded"
                checked={formData2.RoomDesignation === "Self Funded"}
                onChange={handleChange2}
              />
              <label className="radio-switch__label4" htmlFor="radio-2">
                Self Funded
              </label>
              <div aria-hidden="true" className="radio-switch__marker4"></div>
            </li>
          </ul>
        </div>

        <div className="room-selection-container">
          <label className="room-selection-label">
            Select a Type for the Apartment (Room Types)
          </label>

          {/* Room Type Buttons Always on Top */}
          <div className="room-type-buttons">
            {Object.keys(roomTypesWithVariations).map((roomType) => (
              <button
                key={roomType}
                type="button" // Prevents form submission
                onClick={() => toggleRoomType(roomType)}
                className={`room-type-button ${
                  expandedRoomType === roomType ? "active" : ""
                }`}
              >
                {roomType}
              </button>
            ))}
          </div>

          {/* Variations Display Below */}
          <div className="room-variations">
            {Object.keys(roomTypesWithVariations).map((roomType) =>
              expandedRoomType === roomType ? (
                <div
                  key={roomType}
                  className={`room-variation-group room-variation-${roomType.toLowerCase()}`}
                >
                  {roomTypesWithVariations[roomType].map((variation) => (
                    <button
                      key={`${roomType}-${variation}`}
                      type="button" // Prevents form submission
                      onClick={() =>
                        handleLivingArrangementSelection(roomType, variation)
                      }
                      className={`room-variation-button ${
                        formData2.LivingArrangement ===
                        `${roomType} - ${variation}`
                          ? "selected"
                          : ""
                      }`}
                    >
                      {variation}
                    </button>
                  ))}
                </div>
              ) : null
            )}
          </div>
        </div>

        <div className="suzuki-field">
          <label htmlFor="ApartmentRoomImage" className="suzuki-label">
            Apartment Room Image:{" "}
            {imagePreviewsOnUpload.ApartmentRoomImage && (
              <div className="bmw-preview">
                <label className="bmw-label">Room Image Preview:</label>
                <img
                  src={imagePreviewsOnUpload.ApartmentRoomImage}
                  alt="Room Preview"
                  className="bmw-image"
                />
              </div>
            )}
          </label>
          <input
            type="file"
            name="ApartmentRoomImage"
            onChange={handleImageChange2}
            className="suzuki-input"
          />
        </div>

        <button type="submit" className="ducati-button">
          Save Apartment
        </button>
        <button type="button" onClick={goBack}>
          Back
        </button>
      </form>
      <ApartmentTable buildingId={formData2.BuildingId} />
      <style>{`.room-selection-container {
  margin: 10px;
  text-align: center;
}

.room-type-buttons {
  display: flex;
  justify-content: center;
  gap: 5px;
}

.room-type-button {
  padding: 10px 10px;
  background-color: #f0f0f0;
  color: #000;
  border: 1px solid #ccc;
  border-radius: 5px;
  cursor: pointer;
}

.room-type-button.active {
  background-color: #ff5a5f; /* Blue background for active type */
  color: #fff;
  border: 1px solid #ff5a5f;
}


.room-variations {
  margin-top: 10px;
}

.room-variation-group {
  display: flex;
  justify-content: center;
  gap: 10px;
}

.room-variation-button {
  padding: 8px 15px;
  background-color: #e0e0e0;
  border: 1px solid #ccc;
  border-radius: 5px;
  cursor: pointer;
   color: #000;
}

.room-variation-button.selected {
  background-color: #ff5a5f;
  color: #fff;
  border: 1px solid #ff5a5f;
}
`}</style>
      <style>{`
    .container {
      max-width: 600px;
      margin: 10px auto;
      padding: 20px;
      text-align: center;
    }
    .container h3 {
      font-size: 24px;
      margin-bottom: 20px;
      color: #242323;
      text-align: left;
    }
    .options-container {
      display: flex;
      flex-direction: column;
      gap: 15px;
    }
    .option {
      border: 1px solid #ddd;
      border-radius: 8px;
      padding: 15px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      cursor: pointer;
      transition: border-color 0.3s;
    }
    .option:hover {
      border: 2px solid #000;
    }
    .option.selected {
      border: 2px solid #000; /* Highlight color for selected option */
    }
    .option img {
      max-width: 30px;
      margin-left: 15px;
    }
    .option .text {
      text-align: left;
      flex-grow: 1;
    }
    .option .text h4 {
      font-size: 18px;
      margin: 0;
      color: #333;
    }
    .option .text p {
      font-size: 12px;
      color: #555;
      margin: 5px 0 0;
    }
         /* Responsive Styles for Mobile */
    @media (max-width: 600px) {
      .option {
        width: 100%; /* Decrease width on small screens */
      }
      .container h3 {
        font-size: 20px;
      }
      .option img {
        max-width: 25px;
      }
    }

     .progress-step1cont {
position: fixed; /* Fix the container at a specific position */
bottom: 0; /* Place it at the bottom of the viewport */
left: 0;
right: 0;
width: 100%; /* Make sure it spans the full width */
max-width: 1200px;
margin: 0 auto; /* Center it horizontally */
padding: 0 10px;
box-sizing: border-box;
background: #fff; /* Add a background to prevent overlap issues */
z-index: 1000; /* Ensure it stays above other elements */
}

    .progress-bar {
      height: 4px;
      background: #e0e0e0;
      margin-bottom: 5px;
      position: relative;
    }
    .progress {
      height: 100%;
      width: 50%;
      background: #000;
      transition: width 0.3s ease-in-out;
    }
    .buttons-step1cont {
      display: flex;
      justify-content: space-between; /* Space between buttons */
      align-items: center;
      width: 100%;
      margin-top: 10px; /* Add some space above buttons */
    }
     .back-button {
          text-decoration: underline;
          color: #ff5a5f;
          background: none;
          border: none;
          max-width: 50px;
          cursor: pointer;
          font-size: 16px;
          padding: 15px 0px;
        }

        .back-button:hover {
          text-decoration: underline;
          color: #fff;
          background: #ff5a5f;
          border: none;
          max-width: 50px;
          cursor: pointer;
          font-size: 16px;
          padding: 15px 0px;
        }

        .next-button {
          background: #ff5a5f;
          max-width: 70px;
          color: #fff;
          border: none;
          padding: 10px 20px;
          border-radius: 4px;
          font-size: 16px;
          cursor: pointer;
        }    
@media (max-width: 768px) {
.buttons-step1cont {
justify-content: flex-start; /* Align all buttons to the left */
}
.next-button {
margin-left: 150px; /* Add some spacing from the left edge */
}
}
  `}</style>
      <div className="progress-step1cont">
        <div className="progress-bar">
          <div className="progress"></div>
        </div>
        <div className="buttons-step1cont">
          <button className="back-button" onClick={handleBack}>
            Back
          </button>
          <button className="next-button" onClick={handleNext}>
            Next
          </button>
        </div>
      </div>
    </div>
  );
};

export default Steps3_2Admin;
