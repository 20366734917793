import React, { useEffect, useRef, useState } from 'react';
import { useNavigate, useLocation } from "react-router-dom"; // Import useLocation
import { useAuth } from "./AuthProvider"; // Import useAuth

import 'leaflet/dist/leaflet.css';
import L from 'leaflet';

const Steps4Admin = () => {
  const navigate = useNavigate();

  const { userId } = useAuth(); // Get the user object from the auth context
  const location = useLocation();

  const { typeOfHouse, typeOfPlace } = location.state || {};
  const mapRef = useRef(null); // Reference to the map container
  const apiKey = "45437ab5db4c4411b8a9d0a4921076b3"; // Your Geoapify API Key

   // Form state for address inputs
   const [formData, setFormData] = useState({
    country: "South Africa",
    streetAddress: "",
    suburb: "",
    city: "",
    province: "",
    postalCode: "",
  });

  useEffect(() => {
    // Initialize the map
    const initMap = () => {
        const shanghaiCoords = [-29.8687, 31.0018]; // Coordinates for Durban
        const map = L.map(mapRef.current, {
          center: shanghaiCoords,
          zoom: 13,
          zoomControl: false,
        });
      
        // Replace OpenStreetMap tile layer with Geoapify's tile layer
        L.tileLayer('https://maps.geoapify.com/v1/tile/osm-bright/{z}/{x}/{y}.png?apiKey=45437ab5db4c4411b8a9d0a4921076b3', {
          attribution: '&copy; OpenStreetMap contributors, © Geoapify',
        }).addTo(map);
      
        return map;
      };
      

    const map = initMap();
    return () => {
      map.remove(); // Cleanup the map instance on component unmount
    };
  }, []);

  // Autocomplete functionality
  const handleAutocomplete = async (event) => {
    const query = event.target.value;
    const autocompleteResults = document.getElementById("beijing-autocomplete-results");

    if (query.length < 3) {
      autocompleteResults.innerHTML = "";
      return;
    }

    const response = await fetch(
      `https://api.geoapify.com/v1/geocode/autocomplete?text=${encodeURIComponent(query)}&apiKey=${apiKey}`
    );
    const data = await response.json();
    autocompleteResults.innerHTML = "";

    if (data.features) {
      data.features.forEach((feature) => {
        const item = document.createElement("div");
        item.classList.add("beijing-autocomplete-item");
        item.textContent = feature.properties.formatted;

        item.addEventListener("click", () => {
          document.getElementById("beijing-address-input").value = feature.properties.formatted;
          populateAddressFields(feature.properties);
          autocompleteResults.innerHTML = "";
          showForm();
        });

        autocompleteResults.appendChild(item);
      });
    }
  };

  // Populate address fields
  const populateAddressFields = (properties) => {
    const street = properties.street || "";
    const housenumber = properties.housenumber || "";
    const streetAddress = housenumber ? `${housenumber} ${street}` : street;

    setFormData((prevData) => ({
      ...prevData,
      streetAddress,
      suburb: properties.suburb || properties.district || "",
      city: properties.city || "",
      province: properties.state || "",
      postalCode: properties.postcode || "",
    }));
    document.getElementById("shenzhen-street-address").value = streetAddress;
    document.getElementById("shenzhen-suburb").value = properties.suburb || properties.district || "";
    document.getElementById("shenzhen-city").value = properties.city || "";
    document.getElementById("shenzhen-province").value = properties.state || "";
    document.getElementById("shenzhen-postal-code").value = properties.postcode || "";
  };
 // Handle form input changes
 const handleInputChange = (e) => {
  const { id, value } = e.target;
  setFormData((prevData) => ({ ...prevData, [id]: value }));
};
  // Show form and hide map
  const showForm = () => {
    document.querySelector('.beijing-map-container').style.display = "none";
    document.querySelector('.shenzhen-form-container').style.display = "block";
  };
  // Handle navigation to Steps5Admin
  const handleNext = async () => {
    // Perform the update before navigating to the next step
    const AdminId = userId; // Replace with the correct property from your user object
  
    if (!AdminId) {
      alert("Unable to fetch Admin ID. Please log in.");
      return;
    }
  
    const payload = {
      AdminId,
      PropertyType: typeOfHouse,
      Guest: typeOfPlace,
      FullAddress: `${formData.streetAddress}, ${formData.suburb}, ${formData.city}, ${formData.province}, ${formData.postalCode}`,
      CountryOrRegion: formData.country,
      StreetAddress: formData.streetAddress,
      Suburb: formData.suburb,
      CityOrVillage: formData.city,
      Province: formData.province,
      PostalCode: formData.postalCode,
    };
  
    try {
      const response = await fetch("https://backendjaes.passiontrials.co.za/api/update-admin-data", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(payload),
      });
  
      if (response.ok) {
        const result = await response.text();
        console.log("Data updated successfully: " + result);
  
        // Navigate to the next step after successful update
        navigate("/Steps8Admin", {
          state: {
            typeOfHouse,
            typeOfPlace,
            ...formData, // Include the updated data from this page
          },
        });
      } else {
        const error = await response.text();
        console.error("Error updating data:", error);
        alert("Failed to update data.");
      }
    } catch (err) {
      console.error("Error:", err);
      alert("An unexpected error occurred.");
    }
  };
  

  const handleBack = () => {
    navigate(-1); // Navigate back to the previous page
  };
  return (
    <div>
      <div className="beijing-container">
        <h3 className="beijing-title">Confirm your address</h3>
        <p className="beijing-description">Your address is only shared with guests after they've made a reservation.</p>
        <div className="beijing-map-container">
          <div id="map" ref={mapRef}></div>
          <div className="beijing-address-bar">
            <input
              type="text"
              id="beijing-address-input"
              placeholder="Enter your address"
              onInput={handleAutocomplete}
            />
          </div>
          <div id="beijing-autocomplete-results" className="beijing-autocomplete-results"></div>
        </div>
        <div className="shenzhen-form-container">
          <div className="shenzhen-form-group">
            <label htmlFor="country">Country / Region</label>
            <select id="country"  value={formData.country}
              onChange={handleInputChange}>
              <option value="South Africa" selected>
                South Africa - ZA
              </option>
            </select>
          </div>
          <div className="shenzhen-form-group"> 
            <label htmlFor="shenzhen-street-address">Street address</label>
            <input type="text" id="shenzhen-street-address" placeholder=" " value={formData.streetAddress}
              onChange={handleInputChange} />
               </div>
          <div className="shenzhen-form-group">
            <label htmlFor="shenzhen-suburb">Suburb</label>
            <input type="text" id="shenzhen-suburb" placeholder=" " value={formData.suburb}
              onChange={handleInputChange} />
          </div>
          <div className="shenzhen-form-group">
            <label htmlFor="shenzhen-city">City / Village</label>
            <input type="text" id="shenzhen-city" placeholder=" " value={formData.city}
              onChange={handleInputChange} />
          </div>
          <div className="shenzhen-form-group">
            <label htmlFor="shenzhen-province">Province</label>
            <input type="text" id="shenzhen-province" placeholder=" " value={formData.province}
              onChange={handleInputChange}/>
          </div>
          <div className="shenzhen-form-group">
            <label htmlFor="shenzhen-postal-code">Postal code</label>
            <input type="text" id="shenzhen-postal-code" placeholder=" " value={formData.postalCode}
              onChange={handleInputChange} />
          </div>
        </div>
      </div>
      <div className="progress-step1cont">
        <div className="progress-bar">
          <div className="progress"></div>
        </div>
        <div className="buttons-step1cont">
          <button className="back-button" onClick={handleBack}>
            Back
          </button>
          <button className="next-button" onClick={handleNext}>
            Next
          </button>
        </div>
      </div>
      <style>{`
        .beijing-container {
          max-width: 600px;
          width: 100%;
          background: #fff;
          border-radius: 8px;
          box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
          padding: 20px;
          box-sizing: border-box;
          margin: 0 auto;
        }
        .beijing-title {
          font-size: 20px;
          font-weight: 600;
          color: #242323;
          text-align: left;
        }
        .beijing-description {
          font-size: 14px;
          color: #555;
          text-align: left;
  margin-bottom: 15px;
        }
        .shenzhen-form-container {
          display: none;
        }
    .shenzhen-form-group {
  position: relative; /* Make the label position relative to the group */
}

.shenzhen-form-group label {
  position: absolute;
  top: 2px;
  left: 12px;
  font-size: 12px;
  color: #aaa;
  transition: all 0.2s ease; /* Smooth animation for label movement */
  pointer-events: none; /* Prevent clicking on the label */
}

.shenzhen-form-group input,
.shenzhen-form-group select {
  border: 1px solid #ddd;
  padding: 14px 12px 12px; /* Add padding to make space for the label */
  font-size: 14px;
  color: #333;
  outline: none;
  background: #f9f9f9;
  width: 100%;
  box-sizing: border-box;
}

.shenzhen-form-group input:focus,
.shenzhen-form-group select:focus {
  border-color: #000;
  background: #fff;
}

.shenzhen-form-group input:focus ~ label,
.shenzhen-form-group select:focus ~ label,
.shenzhen-form-group input:not(:placeholder-shown) ~ label,
.shenzhen-form-group select:not(:placeholder-shown) ~ label {
  top: -8px;
  font-size: 10px;
  color: #f52929;
  background: #fff;
  padding: 0 14px; /* Add padding around the floating label */
}

        .beijing-map-container {
          margin-bottom: 20px;
          border-radius: 12px;
          overflow: hidden;
          box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
          height: 400px;
          position: relative; /* Ensure child elements are positioned relative to this container */
          display: flex;
          justify-content: center;
          align-items: center;
        }
        #map {
          width: 90%;
          height: 90%;
          border-radius: 14px;
        }
        .beijing-address-bar {
          position: absolute;
          top: 30px; /* Adjusted to be inside the map */
          left: 50%;
          transform: translateX(-50%);
          z-index: 1000;
          display: flex;
          align-items: center;
          background: #fff;
          border-radius: 24px;
          box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
          padding: 10px 20px;
          width: 80%;
        }
        .beijing-address-bar input {
          border: none;
          outline: none;
          font-size: 16px;
          flex: 1;
          padding: 5px;
        }
        .beijing-autocomplete-results {
          position: absolute;
          top: 80px;
          left: 50%;
          transform: translateX(-50%);
          width: 80%;
          background: white;
          box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
          z-index: 1000;
          border-radius: 8px;
        }
        .beijing-autocomplete-item {
          padding: 10px;
          cursor: pointer;
        }
        .beijing-autocomplete-item:hover {
          background-color: #f0f0f0;
        }
        .progress-step1cont {
  position: fixed; /* Fix the container at a specific position */
  bottom: 0; /* Place it at the bottom of the viewport */
  left: 0;
  right: 0;
  width: 100%; /* Make sure it spans the full width */
  max-width: 1200px;
  margin: 0 auto; /* Center it horizontally */
  padding: 0 10px;
  box-sizing: border-box;
  background: #fff; /* Add a background to prevent overlap issues */
  z-index: 1000; /* Ensure it stays above other elements */
}
        .progress-bar {
          height: 4px;
          background: #e0e0e0;
          margin-bottom: 5px;
          position: relative;
        }
        .progress {
          height: 100%;
          width: 66.64%;
          background: #000;
          transition: width 0.3s ease-in-out;
        }
        .buttons-step1cont {
          display: flex;
          justify-content: space-between; /* Space between buttons */
          align-items: center;
          width: 100%;
          margin-top: 10px; /* Add some space above buttons */
        }
 .back-button {
          text-decoration: underline;
          color: #ff5a5f;
          background: none;
          border: none;
          max-width: 50px;
          cursor: pointer;
          font-size: 16px;
          padding: 15px 0px;
        }

        .back-button:hover {
          text-decoration: underline;
          color: #fff;
          background: #ff5a5f;
          border: none;
          max-width: 50px;
          cursor: pointer;
          font-size: 16px;
          padding: 15px 0px;
        }

        .next-button {
          background: #ff5a5f;
          max-width: 70px;
          color: #fff;
          border: none;
          padding: 10px 20px;
          border-radius: 4px;
          font-size: 16px;
          cursor: pointer;
        }
@media (max-width: 768px) {
  .buttons-step1cont {
    justify-content: flex-start; /* Align all buttons to the left */
  }
  .next-button {
    margin-left: 150px; /* Add some spacing from the left edge */
  }
}
      `}</style>
    </div>
  );
};

export default Steps4Admin;
