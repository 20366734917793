import React, { useState } from 'react';
import { useAuth } from './AuthProvider';
import { useNavigate } from 'react-router-dom';
import './profile.css'
import lock from './lockjaes.svg';
import locks from './lockwithnoshildjaes.svg';
import lockss from './eyes.svg';
import Swal from 'sweetalert2';

function Profile() {
    const [activeTab, setActiveTab] = useState(0);
    const [expandedBio, setExpandedBio] = useState(false);
    const { userId, role } = useAuth();
    const [loading, setLoading] = useState(false);
    const [info, setInfo] = useState(null);
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);
    const [userInfo, setUserInfo] = useState({
        UserId: '',
        ApartmentId: '',
        Name: '',
        Surname: '',
        IdNumber: '',
        Gender: '',
        Email: '',
        PhoneNumber: '',
        FaceImage: '',
        ConfirmedUser: '',
        Role: '',
        ID_Image: '',
        DateOfBirth: '',
    });

    // useNavigate hook to go back in history
    const navigate = useNavigate();
    const [error, setError] = useState('');
    let faceImage = null;
    const [successMessage, setSuccessMessage] = useState('');
    const [idImagePreview, setIdImagePreview] = useState(null);
    const [faceImagePreview, setFaceImagePreview] = useState(null);

    const handleTabChange = (index) => {
        setActiveTab(index);
    };

    const toggleBio = () => {
        setExpandedBio(!expandedBio);
    };

    const handleUpdate = async (e) => {
        e.preventDefault();

        if (password !== confirmPassword) {
            setError("Passwords do not match.");
            return;
        }
        setLoading(true); // Show loader
        try {
            const formData = new FormData();
            formData.append('Name', userInfo.Name);
            formData.append('Surname', userInfo.Surname);
            formData.append('Email', userInfo.Email);
            formData.append('PhoneNumber', userInfo.PhoneNumber);
            formData.append('IdNumber', userInfo.IdNumber);

            if (userInfo.FaceImage) {
                formData.append('FaceImage', userInfo.FaceImage);
            }
            if (userInfo.ID_Image) {
                formData.append('ID_Image', userInfo.ID_Image);
            }
            if (password) {
                formData.append('Password', password);
            }
            const response = await fetch(`https://backendjaes.passiontrials.co.za/updateUser/${userInfo.UserId}`, {
                method: 'PUT',
                body: formData, // Send the form data, including files
            });
            const data = await response.json();
            if (response.ok) {
                Swal.fire({
                    icon: 'success',
                    title: 'Updated!',
                    text: 'Information updated successfully.',
                }).then(() => {
                    window.location.reload(); // Refresh the page after SweetAlert
                });
                setLoading(false);
            } else {
                setError(data.message || 'Failed to update information.');
                setSuccessMessage('');
                clearMessagesAfterTimeout();
            }
        }
        catch (error) {
            console.error('Error updating user details', error);
            setError('An error occured while updating information!');
            setSuccessMessage('');
            clearMessagesAfterTimeout();
        }
    }
    const clearMessagesAfterTimeout = () => {
        setTimeout(() => {
            setError('');
            setSuccessMessage('');
        }, 3000); // Clears the messages after 3 seconds (3000 milliseconds)
    };

    React.useEffect(() => {
        if (userId) {

            fetch(`https://backendjaes.passiontrials.co.za/profile/${userId}/${role}`, {
                headers: { 'Content-Type': 'application/json' }
            }).then(res => res.json())
                .then(data => {
                    if (data) {

                        setInfo(data[0]);
                        setUserInfo(data[0]);
                        setFaceImagePreview(data[0]?.FaceImage);
                        setIdImagePreview(data[0]?.ID_Image);
                        console.log(data);

                    }
                    else {
                        console.log('No profile was found');
                    }
                })
                .catch(error => {
                    console.error('Error', error);
                    setError(error.message);
                })



        }
    }, [userId, role]);
    faceImage = info?.FaceImage || "https://picsum.photos/200/300?random=30";

    const handleChange = (e) => {
        const { name, value } = e.target;

        // If the field is password, handle it separately
        if (name === 'Password') {
            setPassword(value);
            setShowConfirmPassword(value.length > 0);
        } else if (name === 'ConfirmPassword') {
            setConfirmPassword(value);
        } else {
            setUserInfo({
                ...userInfo,
                [name]: value,
            });
        }
    };

    const handleImageChange = (e) => {
        const { name, files } = e.target;
        const file = files[0];
        if (name === 'FaceImage') {
            setFaceImagePreview(URL.createObjectURL(file));
        } else if (name === 'ID_Image') {
            setIdImagePreview(URL.createObjectURL(file));
        }
        setUserInfo({
            ...userInfo,
            [name]: file, // Store the selected file in state
        });
    };
    // Function to mask the email
    const maskEmail = (email) => {
        if (!email) return ''; // If the email is not provided, return an empty string

        const [localPart, domain] = email.split('@');

        if (localPart.length <= 2) {
            return `${localPart}***@${domain}`;
        }

        return `${localPart[0]}***${localPart[localPart.length - 1]}@${domain}`;
    };
    return (
        <>
            <div class="containerssss">
                <div class="info-section">  <form onSubmit={handleUpdate}>   <button
                    onClick={() => navigate(-1)}
                    style={{ marginBottom: '20px', backgroundColor: '#ff5a5f', color: 'white', padding: '10px 20px', border: 'none', borderRadius: '4px', cursor: 'pointer', width: '30%' }}>
                    Back
                </button>
                    <h2>Personal infffo</h2>
                    <div class="info-item">
                        <label style={{ color: 'red' }}>Legal name</label>
                        <span>{info?.AdminName || info?.Name} {info?.AdminSurname || info?.Surname}</span>
                        <div class="input-group">
                            <input
                                type="text"
                                name="Name"
                                value={userInfo.Name}
                                onChange={handleChange}
                                required
                                placeholder="Name"
                                class="input-field"
                            />
                            <input
                                type="text"
                                name="Surname"
                                value={userInfo.Surname}
                                onChange={handleChange}
                                required
                                placeholder="Last Name" class="input-field"
                            />
                        </div>
                        <a style={{ opacity: '0' }}>Add</a>
                    </div>
                    <div class="info-item">
                    <div class="input-group"></div>
                        <label style={{ color: 'red' }}>Phone number</label>
                        <input
                            type="text"
                            name="PhoneNumber"
                            value={userInfo.PhoneNumber}
                            onChange={handleChange}
                            required
                            class="input-field"
                        />
                        <a style={{ opacity: '0' }}>Add</a>
                    </div> <div class="info-item">
                        <label style={{ color: 'red' }}>Email address</label>
                        <span>{maskEmail(info?.AdminEmail || info?.Email)}</span> 

                        <a style={{ opacity: '0' }}>Add</a>
                    </div>
                    <div class="info-item">
                        <label style={{ color: 'red' }}>Gender</label>
                        <span>{userInfo.Gender}</span>
                        <a style={{ opacity: '0' }}>Add</a>
                    </div>
                    <div class="info-item">
                        <label style={{ color: 'red' }}>Government ID number</label>
                        <span>{userInfo.IdNumber}</span>
                        <a style={{ opacity: '0' }}>Add</a>
                    </div>
                    <div class="info-item">
    <label style={{ color: 'red' }}>Selfie</label>
    <div class="file-input-group">
        {/* If image exists, display preview */}
        {faceImagePreview ? (
            <img
                src={`https://backendjaes.passiontrials.co.za/src${faceImagePreview}`}
                alt="Face Preview"
                style={{
                    width: '100px',
                    height: '100px',
                    objectFit: 'cover',
                    filter: 'blur(2px)', // Apply blur effect
                    transition: 'filter 0.3s ease-in-out', // Smooth transition for unblur
                    pointerEvents: 'none', // Disable dragging
                    userSelect: 'none', // Disable selection
                }}
                onContextMenu={(e) => e.preventDefault()} // Disable right-click
            />
        ) : (
            <>
              <form class="file-upload-form">
  <label for="file" class="file-upload-label">
    <div class="file-upload-design">
      <svg viewBox="0 0 640 512" height="1em">
        <path
          d="M144 480C64.5 480 0 415.5 0 336c0-62.8 40.2-116.2 96.2-135.9c-.1-2.7-.2-5.4-.2-8.1c0-88.4 71.6-160 160-160c59.3 0 111 32.2 138.7 80.2C409.9 102 428.3 96 448 96c53 0 96 43 96 96c0 12.2-2.3 23.8-6.4 34.6C596 238.4 640 290.1 640 352c0 70.7-57.3 128-128 128H144zm79-217c-9.4 9.4-9.4 24.6 0 33.9s24.6 9.4 33.9 0l39-39V392c0 13.3 10.7 24 24 24s24-10.7 24-24V257.9l39 39c9.4 9.4 24.6 9.4 33.9 0s9.4-24.6 0-33.9l-80-80c-9.4-9.4-24.6-9.4-33.9 0l-80 80z"
        ></path>
      </svg>
      <p>Upload Selfie</p>
      <span class="browse-button">Browse file</span>
    </div>
    <input
                    type="file"
                    name="FaceImage"
                    accept="image/*"
                    onChange={handleImageChange}
                    class="file-input"
                    id="file"
                />
  </label>
  <style>{` 
.file-upload-form {
  width: fit-content;
  height: fit-content;
  display: flex;
  align-items: center;
  justify-content: center;
}
.file-upload-label input {
  display: none;
}
.file-upload-label svg {
  height: 50px;
  fill: rgb(82, 82, 82);
  margin-bottom: 20px;
}
.file-upload-label {
  cursor: pointer;
  background-color: #ddd;
  padding: 30px 70px;
  border-radius: 40px;
  border: 2px dashed rgb(82, 82, 82);
  box-shadow: 0px 0px 200px -50px rgba(0, 0, 0, 0.719);
}
.file-upload-design {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 5px;
}
.browse-button {
  background-color: rgb(82, 82, 82);
  padding: 5px 15px;
  border-radius: 10px;
  color: white;
  transition: all 0.3s;
}
.browse-button:hover {
  background-color: rgb(14, 14, 14);
}
`}</style>
</form>
               
            </>
        )}
    </div>
</div>
                    <div class="info-item">
   
    <div>
        {idImagePreview ? (
            <>
             <label>ID Photo</label>
               <div>
               <img
                    src={`https://backendjaes.passiontrials.co.za/src${idImagePreview}`}
                    alt="ID Preview"
                    loading="lazy"
                    style={{
                        width: '100px',
                        height: '100px',
                        filter: 'blur(2px)',
                        objectFit: 'cover',
                        transition: 'filter 0.3s ease-in-out',
                        pointerEvents: 'none', // Disable dragging
                        userSelect: 'none', // Disable selection
                    }}
                    onContextMenu={(e) => e.preventDefault()} // Disable right-click menu
                />
               </div>
            </>
        ) : (
<form class="file-upload-form">
  <label for="file" class="file-upload-label">
    <div class="file-upload-design">
      <svg viewBox="0 0 640 512" height="1em">
        <path
          d="M144 480C64.5 480 0 415.5 0 336c0-62.8 40.2-116.2 96.2-135.9c-.1-2.7-.2-5.4-.2-8.1c0-88.4 71.6-160 160-160c59.3 0 111 32.2 138.7 80.2C409.9 102 428.3 96 448 96c53 0 96 43 96 96c0 12.2-2.3 23.8-6.4 34.6C596 238.4 640 290.1 640 352c0 70.7-57.3 128-128 128H144zm79-217c-9.4 9.4-9.4 24.6 0 33.9s24.6 9.4 33.9 0l39-39V392c0 13.3 10.7 24 24 24s24-10.7 24-24V257.9l39 39c9.4 9.4 24.6 9.4 33.9 0s9.4-24.6 0-33.9l-80-80c-9.4-9.4-24.6-9.4-33.9 0l-80 80z"
        ></path>
      </svg>
      <p>Upload ID Photo</p>
      <span class="browse-button">Browse file</span>
    </div>
    <input id="file"
                type="file"
                name="ID_Image"
                accept="image/*"
                onChange={handleImageChange}
            />
  </label>
  <style>{` 
.file-upload-form {
  width: fit-content;
  height: fit-content;
  display: flex;
  align-items: center;
  justify-content: center;
}
.file-upload-label input {
  display: none;
}
.file-upload-label svg {
  height: 50px;
  fill: rgb(82, 82, 82);
  margin-bottom: 20px;
}
.file-upload-label {
  cursor: pointer;
  background-color: #ddd;
  padding: 30px 70px;
  border-radius: 40px;
  border: 2px dashed rgb(82, 82, 82);
  box-shadow: 0px 0px 200px -50px rgba(0, 0, 0, 0.719);
}
.file-upload-design {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 5px;
}
.browse-button {
  background-color: rgb(82, 82, 82);
  padding: 5px 15px;
  border-radius: 10px;
  color: white;
  transition: all 0.3s;
}
.browse-button:hover {
  background-color: rgb(14, 14, 14);
}
`}</style>
</form>

        )}
    </div>
</div>
                    <div class="info-item">
                        <label style={{ color: 'red' }}>New password:</label>
                        <input
                            type="password"
                            name="Password"
                            value={password}
                            onChange={handleChange}
                            placeholder="New password"
                            class="input-field"
                            autoComplete='off'

                        />

                        <label style={{ color: 'red' }}>Confirm Password:</label>
                        <input
                            type="password"
                            name="ConfirmPassword"
                            value={confirmPassword}
                            onChange={handleChange}
                            placeholder="Confirm new password"
                            class="input-field"
                        />
                    </div>
                    <button
    type="submit"
    style={{
        marginBottom: '20px',
        backgroundColor: loading ? '#ddd' : '#ff5a5f',
        color: 'white',
        padding: '10px 20px',
        border: 'none',
        borderRadius: '4px',
        cursor: loading ? 'not-allowed' : 'pointer',
        width: '30%',
    }}
    disabled={loading} // Disable button during loading
>
    {loading ? 'Updating...' : 'Update Information'}
</button>
                </form>
                </div>
                <div class="help-section">
                    <div class="help-box">

                        <div class="help-text"> <img src={lock} alt="Help Image 1" class="help-image" />
                            <h3>Why isn’t my info shown here?</h3>
                            <p>We’re hiding some account details to protect your identity.</p>
                        </div>
                    </div>
                    <hr />
                    <div class="help-box">

                        <div class="help-text">
                            <img src={locks} alt="Help Image 2" class="help-image" />
                            <h3>Which details can be edited?</h3>
                            <p>Contact info and personal details can be edited...</p>
                        </div>
                    </div>
                    <hr />
                    <div class="help-box">

                        <div class="help-text">
                            <img src={lockss} alt="Help Image 3" class="help-image" />
                            <h3>What info is shared with others?</h3>
                            <p>Jaes only releases contact information for Hosts, Transport, Meals and Service ...</p>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}
export default Profile; 
