
// import React, { useState, useEffect } from 'react';
// import { useNavigate, Link } from 'react-router-dom';
// import './Register.css';
// import Swal from 'sweetalert2'
// import { EyeClosed, Eye } from 'lucide-react';

// function Register() {
//     const navigation = useNavigate();
//     const [formData, setFormData] = useState({
//         Name: '',
//         Surname: '',
//         IdNumber: '',
//         PassportNumber: '',
//         Country: '',
//         Gender: '',
//         DateOfBirth: '',
//         Email: '',
//         PhoneNumber: '',
//         Password: '',
//         ConfirmPassword: '',
//         TypeOfFunding: '', // Add TypeOfFunding to the form state,
//         InstituteName: '',
//         NsfasReferenceNumber: '',
//         HomeAddress: '',
//         BurseryOrganizationName: '',
//         BurseryReferenceNumber: '',
//         BurseryType: '',
//         BurseryStartDate: '',
//         BurseryEndDate: '',
//         CoverageDetails: '',
//         BursaryProofImage: ''
//     });
//     const [useID, setUseID] = useState(true); // Toggle between ID or Passport
//     const [step, setStep] = useState(1); // Step 1: Basic info, Step 2: Additional info
//     const [loading, setLoading] = useState(false); // Loading state for form submission
//     const [faceImage, setFaceImage] = useState(null);
//     const [idImage, setIdImage] = useState(null);
//     const [proofImage, setProofImage] = useState(null);
//     const [errors, setErrors] = useState('');
//     const [loading2, setLoading2] = useState(false);


//     //verification process
//     const [verificationCode, setVerificationCode] = useState('');
//     const [codeInput, setCodeInput] = useState('');
//     const [showVerificationPopup, setShowVerificationPopup] = useState(false);
//     const [message, setMessage] = useState('');

//     const [inputs, setInputs] = useState(["", "", "", ""]);
//     const [timeLeft, setTimeLeft] = useState(null);
//     const [resendDisabled, setResendDisabled] = useState(true);

//      const [showPassword, setShowPassword] = useState(false); // Password visibility toggle
//     const [showConfirmPassword, setShowConfirmPassword] = useState(false);

//     const [resendEnabled, setResendEnabled] = useState(false);


//     //11 jan 2025
//     const [coverageDetails, setCoverageDetails] = useState([]);

//     const handleCheckboxChange = (e) => {
//         const { value, checked } = e.target;

//         setCoverageDetails((prev) =>
//             checked ? [...prev, value] : prev.filter((item) => item !== value)
//         );
//     };

//     const checkIdNumberExists = async (idNumber) => {
//         try {
//             const response = await fetch('https://backendjaes.passiontrials.co.za/check_idnumber', {
//                 method: 'POST',
//                 headers: { 'Content-Type': 'application/json' },
//                 body: JSON.stringify({ idNumber }),
//             });

//             const data = await response.json();
//             return data.exists; // Return true if the ID exists, false otherwise
//         } catch (error) {
//             console.error('Error checking ID number:', error);
//             return false; // Default to false in case of an error
//         }
//     };



//     const checkEmailExists = async (email) => {
//         try {
//             const response = await fetch('https://backendjaes.passiontrials.co.za/check_email', {
//                 method: 'POST',
//                 headers: { 'Content-Type': 'application/json' },
//                 body: JSON.stringify({ email }),
//             });

//             const data = await response.json();
//             console.log('does this exist', data.exists);
//             return data.exists; // Return true if the email exists, false otherwise
//         } catch (error) {
//             console.error('Error checking email existence:', error);
//             return false; // Default to false in case of an error
//         }
//     };


//     const handleResendCode = async () => {
//         setLoading2(true);
//         try {
//             const response = await fetch("https://backendjaes.passiontrials.co.za/resend_code", {
//                 method: "POST",
//                 headers: { "Content-Type": "application/json" },
//                 body: JSON.stringify({ email: formData.Email }),
//             });

//             const data = await response.json();
//             if (response.ok) {
//                 Swal.fire({
//                     title: "Code Resent!",
//                     text: data.message,
//                     icon: "info",
//                     confirmButtonText: "OK",
//                 });
//                 //  console.log("Code resent successfully:", data.message);
//                 setTimeLeft(120);
//             } else {
//                 Swal.fire({
//                     title: "Error!",
//                     text: 'Failed to resend code' || data.error,
//                     icon: "error",
//                     confirmButtonText: "Try Again",
//                 });
//             }
//         } catch (error) {
//             Swal.fire({
//                 title: "Error!",
//                 text: "Failed to resend verification code.",
//                 icon: "error",
//                 confirmButtonText: "Try Again",
//             });
//         } finally {
//             setLoading2(false);
//         }
//     };


//     useEffect(() => {// 10 jan 2025
//         if (timeLeft !== null) {
//             if (timeLeft > 0) {
//                 const timer = setTimeout(() => setTimeLeft((prev) => prev - 1), 1000);
//                 return () => clearTimeout(timer);
//             } else {
//                 // Trigger auto-resend and reset the timer
//                 handleResendCode();
//             }
//         }
//     }, [timeLeft]);

//     useEffect(() => {
//         if (showVerificationPopup) {
//             // Initialize the timer to 30 seconds when the popup is shown
//             setTimeLeft(120);
//         } else {
//             // Clear the timer when the popup is closed
//             setTimeLeft(null);
//         }
//     }, [showVerificationPopup]);

//     const handleInputChange = (index, value) => {// 10 jan 2025
//         if (!isNaN(value) && value.length <= 1) {
//             const newInputs = [...inputs];
//             newInputs[index] = value;
//             setInputs(newInputs);

//             // Automatically focus the next box
//             if (value && index < 3) {
//                 document.getElementById(`input-${index + 1}`).focus();
//             }
//         }
//     };


//     const handleChange = (e) => {
//         const { name, value } = e.target;

//         if (name === 'IdNumber' && value.length >= 10) {
//             const gender = handleGender(value);
//             const dateOfBirth = handleDateOfBirth(value);

//             setFormData({
//                 ...formData,
//                 [name]: value,
//                 Gender: gender,
//                 DateOfBirth: dateOfBirth
//             });
//             setErrors('');
//         } else {
//             setFormData({ ...formData, [name]: value });
//             setErrors('');
//         }
//     };

//     const handleImageChange = (e) => {
//         if (e.target.name === 'FaceImage') {
//             setFaceImage(e.target.files[0]);
//         } else if (e.target.name === 'ID_Image') {
//             setIdImage(e.target.files[0]);
//         }
//     };

//     function isNullOrUndefined(value) {
//         return value == null || value == undefined || value == '';
//     }



//     const validateStep = () => {
//         let newErrors = '';

//         if (step === 1) {
//             if (!formData.Name || !formData.Surname || !formData.Email || !formData.PhoneNumber) {
//                 newErrors = 'All fields are required in this step.';
//             } else if (!/\S+@\S+\.\S+/.test(formData.Email)) {
//                 newErrors = 'Invalid email format.';
//             }
//         } else if (step === 2) {
//             if (!formData.IdNumber && !formData.PassportNumber) {
//                 newErrors = 'You must provide either an ID Number or Passport Number.';
//             } else if (formData.IdNumber && formData.IdNumber.length !== 13) {
//                 newErrors = 'ID Number must be exactly 13 digits.';
//             }else if (!/^\d*$/.test(formData.IdNumber)) {
//                 newErrors = 'ID Number must contain only numbers.';
//             }
            
//             else if (!useID) {
//                 // Passport validation
//                 if (!formData.PassportNumber) {
//                     newErrors = 'Passport number is required.';
//                 }
//                 if (!formData.Country) {
//                     newErrors = 'Country is required when using a passport.';
//                 }
//                 if (!formData.Gender) {
//                     newErrors = 'Gender is required when using a passport.';
//                 }
//                 if (!formData.DateOfBirth) {
//                     newErrors = 'Date of Birth is required when using a passport.';
//                 }
//             }
//         } else if (step === 3) {
//             if (!formData.TypeOfFunding || !formData.InstituteName) {
//                 newErrors = 'Please select a funding type and institution.';
//             }
//             if (!formData.HomeAddress) {
//                 newErrors = 'Please fill in your home address.';
//             }
//             if (
//                 formData.TypeOfFunding.toLowerCase() === 'nsfas' &&
//                 !formData.NsfasReferenceNumber
//             ) {
//                 newErrors = 'NSFAS Reference Number is required.';
//             }

//             if (formData.TypeOfFunding.toLowerCase() === 'bursary') {
//                 if (
//                     !formData.BurseryOrganizationName ||
//                     !formData.BurseryReferenceNumber ||
//                     !formData.BurseryType ||
//                     !formData.BurseryStartDate ||
//                     !formData.BurseryEndDate ||
//                     !proofImage ||
//                     coverageDetails.length === 0

//                 ) {
//                     newErrors = 'All bursary details are required.';
//                 }
//             }
//         } else if (step === 4) {
//             if (!formData.Password || !formData.ConfirmPassword) {
//                 newErrors = 'Password and Confirm Password are required.';
//             } else if (formData.Password !== formData.ConfirmPassword) {
//                 newErrors = 'Passwords do not match.';
//             } else if (formData.Password.length < 8) {
//                 newErrors = 'Password must be at least 8 characters.';
//             }
//             else if (!/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&#])[A-Za-z\d@$!%*?&#]{8,}$/.test(formData.Password)) {
//                 newErrors = 'Password must include uppercase, lowercase, number, and special character.';
//             }


//         }

//         setErrors(newErrors);
//         return !newErrors; // Return true if no errors
//     };

//     const handleGender = (id) => {
//         const genderPart = id.slice(6, 10); // Extract part of the ID number
//         const gender = parseInt(genderPart, 10);

//         if (gender >= 0 && gender <= 4999) {
//             console.log('Gender :', gender, 'this is a Female');
//             return 'Female';
//         } else if (gender >= 5000 && gender <= 9999) {
//             console.log('Gender :', gender, 'this is a Male');
//             return 'Male';
//         } else {
//             return '';
//         }
//     };

//     const handleDateOfBirth = (id) => {
//         const datePart = id.slice(0, 6); // Extract part of the ID number
//         const year = datePart.slice(0, 2);
//         const month = datePart.slice(2, 4);
//         const day = datePart.slice(4, 6);

//         const fullYear = year.startsWith('0') || year.startsWith('1') ? `20${year}` : `19${year}`;
//         const dateOfBirth = `${fullYear}-${month}-${day}`;
//         let me = new Date(dateOfBirth).toLocaleDateString('en-US', { day: "numeric", month: 'long', year: 'numeric' });

//         console.log('Date of Birth :', me);
//         return me;
//     };

//     const handleProofImageChange = (e) => {
//         if (e.target.name === 'BursaryProofImage') {
//             setProofImage(e.target.files[0]);
//             setErrors('');
//         }
//     };

//     const handleNextStep = async () => {
//         if (step === 1) {
//             // Check if email exists before running the rest of the validation
//             const emailExists = await checkEmailExists(formData.Email);
//             if (emailExists) {
//                 setErrors('This email is already registered. Please use another email or log in.');
//                 return; // Prevent moving to the next step
//             }
//         }

//         if (step === 2) {
//             // Check if email exists before running the rest of the validation

//             if (useID && formData.IdNumber.length === 13) {
//                 const idExists = await checkIdNumberExists(formData.IdNumber);
//                 if (idExists) {
//                     setErrors('This ID number is already registered.');
//                     return; // Prevent moving to the next step
//                 }
//             }

//         }



//         if (validateStep()) {
//             setStep((prev) => prev + 1);
//         }
//     };

//     const handleSubmit = async (e) => {
//         e.preventDefault();
//         setLoading(true); // Set loading to true while processing the request
//         if (!validateStep()) {
//             setLoading(false);
//             return; // Stop submission if validation fails
//         }
//         const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&#])[A-Za-z\d@$!%*?&#]{8,}$/;
//         //const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&#])[A-Za-z\d@$!%*?&#\-\s]{8,}$/;
//         //const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[A-Za-z\d]{8,}$/;

//         // Check if the passwords match
//         if (formData.Password !== formData.ConfirmPassword) {
//             //alert("Passwords do not match!");
//             Swal.fire({
//                 position: "top-end",
//                 icon: "error",
//                 title: "Passwords do not match!",
//                 showConfirmButton: false,
//                 timer: 5500
//             });
//             setLoading(false); // Stop loading
//             return;
//         }

//         // Check if the password meets the strength requirements
//         if (!passwordRegex.test(formData.Password)) {
//             //alert("Passwor    d must be at least 8 characters long, include at least one uppercase letter, one lowercase letter, one number, and one special character.");
//             Swal.fire({
//                 position: "top-end",
//                 icon: "error",
//                 title: "Password must be at least 8 characters long, include at least one uppercase letter, one lowercase letter, one number, and one special character.",
//                 showConfirmButton: false,
//                 timer: 5500
//             });
//             setLoading(false); // Stop loading
//             return;
//         }

//         const formDataToSend = new FormData();
//         formDataToSend.append('Name', formData.Name);
//         formDataToSend.append('Surname', formData.Surname);
//         formDataToSend.append('Email', formData.Email);
//         formDataToSend.append('PhoneNumber', formData.PhoneNumber);
//         formDataToSend.append('Password', formData.Password);
//         formDataToSend.append('TypeOfFunding', formData.TypeOfFunding);
//         formDataToSend.append('InstituteName', formData.InstituteName); // Add TypeOfFunding to the form data
//         formDataToSend.append('HomeAddress', formData.HomeAddress); // Add TypeOfFunding to the form data

//         if (useID) {
//             formDataToSend.append('IdNumber', formData.IdNumber);
//             formDataToSend.append('Gender', formData.Gender);
//             formDataToSend.append('DateOfBirth', formData.DateOfBirth);
//         } else {
//             formDataToSend.append('PassportNumber', formData.PassportNumber);
//             formDataToSend.append('Country', formData.Country);
//             formDataToSend.append('Gender', formData.Gender);
//             formDataToSend.append('DateOfBirth', formData.DateOfBirth);
//         }

//         if (faceImage) {
//             formDataToSend.append('FaceImage', faceImage);
//         }

//         if (idImage) {
//             formDataToSend.append('ID_Image', idImage);
//         }
//         if (proofImage) {
//             formDataToSend.append('BursaryProofImage', proofImage);
//         }
//         if (formData.TypeOfFunding.toLowerCase() === 'bursary') {
//             formDataToSend.append('BurseryOrganizationName', formData.BurseryOrganizationName);
//             formDataToSend.append('BurseryReferenceNumber', formData.BurseryReferenceNumber);
//             formDataToSend.append('BurseryType', formData.BurseryType);
//             formDataToSend.append('BurseryStartDate', formData.BurseryStartDate);
//             formDataToSend.append('BurseryEndDate', formData.BurseryEndDate);
//             formDataToSend.append('CoverageDetails', coverageDetails.join(', '));
//         }

//         if (formData.TypeOfFunding.toLowerCase() === 'nsfas') {
//             formDataToSend.append('NsfasReferenceNumber', formData.NsfasReferenceNumber);

//         }


//         for (let pair of formDataToSend.entries()) {
//             console.log(pair[0] + ': ' + pair[1]);
//         }

//         if (validateStep()) {
//             try {
//                 const response = await fetch('https://backendjaes.passiontrials.co.za/new_user_register', {
//                     method: 'POST',
//                     body: formDataToSend
//                 });
//                 const data = await response.json();
//                 //alert(data.message);
//                 console.log(data.message);
//                 if (response.ok) {
//                     Swal.fire({
//                         position: "top-end",
//                         icon: "success",
//                         title: "Registration successful. Please verify your account.",
//                         showConfirmButton: false,
//                         timer: 5500
//                     });
//                     setVerificationCode(data.verificationCode); // Retrieve from the server
//                     console.log('the code ', data.verificationCode);
//                     setShowVerificationPopup(true);

//                     // navigation("/login");
//                 }
//                 else {
//                     Swal.fire({
//                         position: "top-end",
//                         icon: "error",
//                         title: data.message || "Registration failed.",
//                         showConfirmButton: false,
//                         timer: 5500
//                     });
//                 }


//             } catch (error) {
//                 console.error('Error during registration:', error);
//                 Swal.fire({
//                     position: "top-end",
//                     icon: "error",
//                     title: "Error during registration",
//                     showConfirmButton: false,
//                     timer: 5500
//                 });
//             } finally {
//                 setLoading(false); // Stop loading
//             }
//         }
//     };

//     const handleVerificationCodeSubmit = async (code) => {
//         const response = await fetch('https://backendjaes.passiontrials.co.za/verify_code', {
//             method: 'POST',
//             headers: { 'Content-Type': 'application/json' },
//             body: JSON.stringify({
//                 email: formData.Email,
//                 codeInput: code,
//             }),
//         });

//         const data = await response.json();
//         if (response.ok) {
//             Swal.fire({
//                 position: "top-end",
//                 icon: "success",
//                 title: "Verification successful!",
//                 showConfirmButton: false,
//                 timer: 5500
//             });
//             setShowVerificationPopup(false);
//             navigation('/login'); // Redirect user to the dashboard
//         } else {
//             Swal.fire({
//                 position: "top-end",
//                 icon: "error",
//                 title: "Invalid verification code. Please try again.",
//                 showConfirmButton: false,
//                 timer: 5500
//             });
//         }
//     };




//     const handleVerify = () => {
//         const code = inputs.join(""); // Combine inputs into a single code
//         handleVerificationCodeSubmit(code);
//     };

//     const togglePasswordVisibility = () => {
//         setShowPassword((prevShowPassword) => !prevShowPassword);
//     };
//     const togglePasswordVisibility2 = () => {
//         setShowConfirmPassword((prevShowPassword) => !prevShowPassword);
//     };

//     return (
//         <div className="register-page">
//             <div className="register-container">
//                 <form onSubmit={handleSubmit} className="register-form">
//                     <h2>Create Account</h2>

//                     {step === 1 && (
//                         <>
//                             <div className="form-group">
//                                 <input name="Name" value={formData.Name} onChange={handleChange} placeholder="Name" required />
//                                 <input name="Surname" value={formData.Surname} onChange={handleChange} placeholder="Surname" required />
//                             </div>
//                             <div className="form-group">
//                                 <input name="Email" value={formData.Email} onChange={handleChange} placeholder="Email" required type="email" />
//                                 <input name="PhoneNumber" value={formData.PhoneNumber} onChange={handleChange} placeholder="Phone Number" required />
//                             </div>
//                             {/* 
//                             <div className="form-group">
//                                 <select name="InstituteName" value={formData.InstituteName} onChange={handleChange} required>
//                                     <option value="">Select Institution</option>
//                                     <option value="Ukzn Howard">UKZN Howard</option>
//                                     <option value="DUT Steve Biko">DUT</option>
//                                 </select>
//                             </div>
//                              */}

//                             <button type="button" className="continue-button" onClick={handleNextStep}>Continue</button>
//                             {errors && <p style={{
//                                 color: "red",
//                                 fontSize: "12px",
//                                 marginTop: '10px',
//                                 textAlign: "center"
//                             }}>{errors}</p>}
//                         </>
//                     )}

//                     {step === 2 && (
//                         <>
//                             <div className="form-group">
//                                 {/* <label>Use ID Number or Passport?</label> */}
//                                 <select onChange={(e) => setUseID(e.target.value === 'ID')} value={useID ? 'ID' : 'Passport'}>
//                                     <option value="ID">ID Number</option>
//                                     <option value="Passport">Passport</option>
//                                 </select>
//                             </div>

//                             {useID ? (
//                                 <>
//                                     <div className="form-group">
//                                         <input name="IdNumber" type='text' maxLength="13" value={formData.IdNumber} onChange={handleChange} placeholder="ID Number" required />
//                                     </div>
//                                     <div className="form-group">
//                                         <input name="Gender" value={formData.Gender} onChange={handleChange} placeholder="Gender" readOnly />
//                                         <input name="DateOfBirth" value={formData.DateOfBirth} onChange={handleChange} placeholder="Date of Birth" readOnly />
//                                     </div>
//                                 </>
//                             ) : (
//                                 <>
//                                     <div className="form-group">
//                                         <input name="PassportNumber" type="text" value={formData.PassportNumber} onChange={handleChange} placeholder="Passport Number" required />
//                                         <input name="Country" type="text" value={formData.Country} onChange={handleChange} placeholder="Country" required />
//                                     </div>
//                                     <div className="gender-group-wrapper">
//                                         <label className="gender-label">Gender:</label>
//                                         <div className="gender-button-group">
//                                             <label
//                                                 className={`gender-button ${formData.Gender === 'Male' ? 'selected' : ''}`}
//                                             >
//                                                 <input
//                                                     type="radio"
//                                                     name="Gender"
//                                                     value="Male"
//                                                     checked={formData.Gender === 'Male'}
//                                                     onChange={handleChange}
//                                                 />
//                                                 Male
//                                             </label>
//                                             <label
//                                                 className={`gender-button ${formData.Gender === 'Female' ? 'selected' : ''}`}
//                                             >
//                                                 <input
//                                                     type="radio"
//                                                     name="Gender"
//                                                     value="Female"
//                                                     checked={formData.Gender === 'Female'}
//                                                     onChange={handleChange}
//                                                 />
//                                                 Female
//                                             </label>
//                                             <div className="form-group">
//                                                 <input name="DateOfBirth" type="date" value={formData.DateOfBirth} onChange={handleChange} placeholder="Date of Birth" required />
//                                             </div>
//                                         </div>

//                                         <style>{`
//         .gender-group-wrapper {
//             display: flex;
//             flex-direction: column;
//             margin-bottom: 1em;
//         }

//         .gender-label {
//             margin-bottom: 0.5em;
//             color: #888;
//             font-weight: bold;
//         }

//         .gender-button-group {
//             display: flex;
//             gap: 1em;
//         }

//         .gender-button {
//             padding: 0.5em 1em;
//             border: 2px solid #ccc;
//             border-radius: 5px;
//             cursor: pointer;
//             font-weight: bold;
//             text-align: center;
//             transition: border-color 0.3s;
//         }

//         .gender-button input {
//             display: none; /* Hide the original radio input */
//         }

//         .gender-button.selected {
//             border-color: #ff5a5f; /* Change border color when selected */
//             background-color: #f0f8ff;
//         }
//     `}</style>
//                                     </div>

//                                 </>
//                             )}




//                             {/* <div className="form-group">
//                                 <label htmlFor="FaceImage">Upload selfie</label>
//                                 <input name="FaceImage" type="file" onChange={handleImageChange} />
//                             </div>
//                             <div className="form-group">
//                                 <label htmlFor="ID_Image">Upload ID or Passport Image</label>
//                                 <input name="ID_Image" type="file" onChange={handleImageChange} />
//                             </div> */}

//                             {/*                             
//                             <div className="form-group password-group">
//                                 <input name="Password" value={formData.Password} onChange={handleChange} placeholder="Password" required type="password" />
//                                 <input name="ConfirmPassword" value={formData.ConfirmPassword} onChange={handleChange} placeholder="Confirm Password" required type="password" />
//                             </div> */}
//                             <div className="form-navigation">

//                                 <button type="button" className="continue-button" style={{ color: 'white' }} onClick={() => setStep(1)}>Back</button>
//                                 <button type="button" className="continue-button" onClick={handleNextStep}>Continue</button>
//                             </div>
//                             {errors && <p style={{
//                                 color: "red",
//                                 fontSize: "12px",
//                                 marginTop: '10px',
//                                 textAlign: "center"
//                             }}>{errors}</p>}
//                         </>
//                     )}

//                     {step === 3 && (
//                         <>
//                             <div className="form-group">
//                                 {/* <label>Type of Funding:</label> */}
//                                 <select name="TypeOfFunding" value={formData.TypeOfFunding} onChange={handleChange} required>
//                                     <option value="">Select Funding Type</option>
//                                     <option value="self funded">Self Funded</option>
//                                     <option value="Bursary">Bursary</option>
//                                     <option value="NSFAS">NSFAS</option>
//                                 </select>
//                             </div>
//                             <div className="form-group">
//                                 <select
//                                     name="InstituteName"
//                                     value={formData.InstituteName}
//                                     onChange={handleChange}
//                                     required
//                                 >
//                                       <option value="">Select institute</option>
//                                 <option value="Ukzn Howard">UKZN Howard</option>
//                                 <option value="UKZN Medical School">UKZN Medical School</option>
//                                 <option value="Umbilo College">Umbilo College</option>
//                                 <option value="Coastal College">Coastal College</option>
//                                 <option value="Ukzn Westville">Ukzn Westville</option>
//                                 </select>
//                             </div>
//                             <div className="amber-form-group">
//                                 <div className="bookYourPerfectAccommodation-module__title amber-form-group">
//                                     <label>Home Address <span style={{ color: 'red' }}>*</span></label>
//                                     <input name='HomeAddress' type="text" placeholder="Enter home address here." value={formData.HomeAddress} onChange={handleChange} required />
//                                 </div>
//                             </div>
//                             <br></br>

//                             {formData.TypeOfFunding.toLowerCase() === "nsfas" && (
//                                 <>
//                                     <div className="amber-form-group">
//                                         <div className="bookYourPerfectAccommodation-module__title amber-form-group">
//                                             <label>NSFAS Reference Number <span style={{ color: 'red' }}>*</span></label>
//                                             <input name='NsfasReferenceNumber' type="text" placeholder="Enter NSFAS reference number here." value={formData.NsfasReferenceNumber} onChange={handleChange} required />
//                                         </div>
//                                     </div>



//                                 </>
//                             )}

//                             {formData.TypeOfFunding.toLowerCase() === "bursary" && (
//                                 <>
//                                     <div className="bursary-section">
//                                         <div className="scrollable-container">
//                                             <div className="amber-form-group">
//                                                 <div className="bookYourPerfectAccommodation-module__title amber-form-group">
//                                                     <label>Bursary Name <span style={{ color: 'red' }}>*</span></label>
//                                                     <input name='BurseryOrganizationName' type="text" placeholder="Enter bursary organization name" value={formData.BurseryOrganizationName} onChange={handleChange} required />
//                                                 </div>
//                                                 <div className="bookYourPerfectAccommodation-module__title amber-form-group">
//                                                     <label>Reference Number <span style={{ color: 'red' }}>*</span></label>
//                                                     <input name='BurseryReferenceNumber' type="text" placeholder="Enter bursary reference number" value={formData.BurseryReferenceNumber} onChange={handleChange} required />
//                                                 </div>
//                                                 <div className="bookYourPerfectAccommodation-module__title amber-form-group">
//                                                     <label>Bursary Type <span style={{ color: 'red' }}>*</span></label>
//                                                     {/* <input name='BurseryType' type="text" placeholder="Full bursary , partial bursary , Etc..." value={formData.BurseryType} onChange={handleChange} required /> */}
//                                                     <select
//                                                         name="BurseryType"
//                                                         value={formData.BurseryType}
//                                                         onChange={handleChange}
//                                                         required
//                                                         className="dropdown-select"
//                                                     >
//                                                         <option value="">Select Bursary Type</option>
//                                                         <option value="Full Bursary">Full Bursary</option>
//                                                         <option value="Partial Bursary">Partial Bursary</option>
//                                                         <option value="Top-up Bursary">Top-up Bursary</option>
//                                                         <option value="Fixed Bursary">Fixed Bursary</option>
//                                                     </select>
//                                                 </div>
//                                             </div>
//                                             <div className="amber-form-group">
//                                                 <div className="bookYourPerfectAccommodation-module__title amber-form-group">
//                                                     <label>Bursary Start Date <span style={{ color: 'red' }}>*</span></label>
//                                                     {/* <input name='BurseryStartDate' type="text" placeholder="Enter the date funding starts " value={formData.BurseryStartDate} onChange={handleChange} required /> */}
//                                                     <input
//                                                         name="BurseryStartDate"
//                                                         type="date"
//                                                         value={formData.BurseryStartDate}

//                                                         onChange={handleChange}
//                                                         required
//                                                     />
//                                                 </div>
//                                                 <div className="bookYourPerfectAccommodation-module__title amber-form-group">
//                                                     <label>Bursary End Date <span style={{ color: 'red' }}>*</span></label>
//                                                     {/* <input name='BurseryEndDate' type="text" placeholder="Enter the end of your funding for the year. " value={formData.BurseryEndDate} onChange={handleChange} required /> */}
//                                                     <input
//                                                         name="BurseryEndDate"
//                                                         type="date"
//                                                         value={formData.BurseryEndDate}
//                                                         onChange={handleChange}
//                                                         required
//                                                     />
//                                                 </div>
//                                                 <div className="bookYourPerfectAccommodation-module__title amber-form-group">
//                                                     <label>Coverage Details <span style={{ color: 'red' }}>*</span></label>
//                                                     {/* <input name='CoverageDetails' type="text" placeholder="Accomodation, tution , food , transport Etc ... " value={formData.CoverageDetails} onChange={handleChange} required /> */}
//                                                     <div class="checkbox-grid">

//                                                         <div class="checkbox-item">
//                                                             <input type="checkbox" id="Tuition" value="Tuition" onChange={handleCheckboxChange} />
//                                                             <label for="Tuition">Tuition</label>
//                                                         </div>
//                                                         <div class="checkbox-item">
//                                                             <input type="checkbox" id="Accommodation" value="Accommodation" onChange={handleCheckboxChange} />
//                                                             <label for="Accommodation">Accommodation</label>
//                                                         </div>
//                                                         <div class="checkbox-item">
//                                                             <input type="checkbox" id="Living Allowance" value="Living Allowance" onChange={handleCheckboxChange} />
//                                                             <label for="Living Allowance">Living Allowance</label>
//                                                         </div>
//                                                         <div class="checkbox-item">
//                                                             <input type="checkbox" id="Transportation" value="Transportation" onChange={handleCheckboxChange} />
//                                                             <label for="Transportation">Transportation</label>
//                                                         </div>
//                                                         <div class="checkbox-item">
//                                                             <input type="checkbox" id="Study material" value="Study material" onChange={handleCheckboxChange} />
//                                                             <label for="Study material">Study material</label>
//                                                         </div>
//                                                         <div class="checkbox-item">
//                                                             <input type="checkbox" id="Special equipment" value="Special equipment" onChange={handleCheckboxChange} />
//                                                             <label for="Special equipment">Special equipment</label>
//                                                         </div>


//                                                     </div>


//                                                     <style>{`:root {
//   --background: #fff;
//   --text-color: #414856;
//   --highlight-color: #4f29f0;
//   --border-color: #c3c8de;
//   --border-radius: 10px;
//   --gap: 15px; /* Gap between items */
// }

// .checkbox-grid {
//   display: flex;
//   flex-wrap: wrap;
//   gap: var(--gap);
//   justify-content: center;
//   padding: 20px;
// }

// .checkbox-item {
//   display: inline-table;
//   align-items: center;
//   justify-content: center;
//   flex: 1 1 calc(20% - var(--gap)); /* Responsive width based on available space */
//   max-width: calc(20% - var(--gap)); /* Ensures consistency across all items */
//   padding: 10px;
//   background: var(--background);
//   color: var(--text-color);
//   border: 2px solid var(--border-color);
//   border-radius: var(--border-radius);
//   cursor: pointer;
//   text-align: center;
//   transition: background 0.3s ease, color 0.3s ease, box-shadow 0.3s ease;
// }

// .checkbox-item input {
//   display: none; /* Hide the checkbox itself */
// }

// .checkbox-item label {
//   cursor: pointer;
//   width: 100%;
//   height: 100%;
//   display: flex;
//   align-items: center;
//   justify-content: center;
//   font-weight: bold;
// }

// .checkbox-item input:checked + label {
//   background: var(--highlight-color);
//   color: var(--background);
//   box-shadow: 0 5px 15px rgba(79, 41, 240, 0.3);
// }
// `}</style>
//                                                 </div>
//                                             </div>
//                                             <div className="amber-form-group">
//                                                 <div className="bookYourPerfectAccommodation-module__title amber-form-group">
//                                                     <label>Upload Proof of Bursary <span style={{ color: 'red' }}>*</span></label>
//                                                     <input
//                                                         type="file"
//                                                         name="BursaryProofImage"
//                                                         accept="image/*"
//                                                         onChange={handleProofImageChange}
//                                                         required
//                                                     />
//                                                 </div>
//                                             </div>
//                                             <style>{`.scrollable-container {
//   max-height: 223px; /* Set a fixed height for the scrollable area */
//   overflow-y: auto; /* Enable vertical scrolling */
//   padding: 10px;
//   border: 1px solid #ddd;
//   border-radius: 5px;
//   background-color: #f9f9f9; /* Optional: Add a light background for contrast */
// }

// .scrollable-container::-webkit-scrollbar {
//   width: 8px; /* Width of the scrollbar */
// }

// .scrollable-container::-webkit-scrollbar-thumb {
//   background-color: #888; /* Color of the scrollbar thumb */
//   border-radius: 4px; /* Rounded edges for the scrollbar thumb */
// }

// .scrollable-container::-webkit-scrollbar-thumb:hover {
//   background-color: #555; /* Darken the scrollbar thumb on hover */
// }`}</style>

//                                         </div>
//                                     </div>
//                                 </>

//                             )}

//                             {/* < div className="form-grid2">
//                                 <button type="button" onClick={handlePreviousStep}>
//                                     Back
//                                 </button>
//                                 <button type="button" onClick={handleNextStep}>
//                                     Next
//                                 </button>
//                             </div> */}

//                             {errors && <p style={{
//                                 color: "red",
//                                 fontSize: "12px",
//                                 marginTop: '10px',
//                                 textAlign: "center"
//                             }}>{errors}</p>}

//                             <div className="form-navigation">

//                                 <button type="button" className="continue-button" style={{ color: 'white' }} onClick={() => setStep(2)}>Back</button>
//                                 <button type="button" className="continue-button" onClick={handleNextStep}>Continue</button>
//                             </div>

//                         </>
//                     )}

//                     {step === 4 && (
//                         <>

//                             <div className="form-group password-group">
//                                 <input name="Password" value={formData.Password} onChange={handleChange} placeholder="Password" required type={showPassword ? 'text' : 'password'} />
//                                 <span onClick={togglePasswordVisibility} style={{ marginTop: '95px', right: '516px' }} className="eye-icon">
//                                     {showPassword ? <Eye size={20} /> : <EyeClosed size={20} />}
//                                 </span>
//                             </div>
//                             <div className="form-group password-group">

//                                 <input name="ConfirmPassword" value={formData.ConfirmPassword} onChange={handleChange} placeholder="Confirm Password" required  type={showConfirmPassword ? 'text' : 'password'} />
//                                 <span onClick={togglePasswordVisibility2} style={{ marginTop: '152px', right: '516px' }} className="eye-icon">
//                                     {showConfirmPassword ? <Eye size={20} /> : <EyeClosed size={20} />}
//                                 </span>
//                             </div>
//                             <div className="form-navigation">
//                                 <button type="button" className="back-button" style={{ color: 'white' }} onClick={() => setStep(3)}>Back</button>
//                                 <button type="submit" className="register-button" disabled={loading}>
//                                     {loading ? "Processing..." : "Register"}
//                                 </button>
//                             </div>
//                             {errors && <p style={{
//                                 color: "red",
//                                 fontSize: "12px",
//                                 marginTop: '10px',
//                                 textAlign: "center"
//                             }}>{errors}</p>}
//                         </>
//                     )}

//                     {step === 1 && (
//                         <p>Already have an account? <Link to="/login" className="login-link">Login</Link></p>
//                     )}
//                     {step === 4 && (
//                         <p>Already have an account? <Link to="/login" className="login-link">Login</Link></p>
//                     )}
//                 </form>
//             </div>
//             {showVerificationPopup && (
//                 <div className="verification-popup">
//                     <div className="popup-content">
//                         <h2>Enter Verification Code</h2>
//                         <p>Please enter the 4-digit code sent to your email.</p>
//                         {/* <input
//                             type="text"
//                             placeholder="Enter code"
//                             value={codeInput}
//                             onChange={(e) => setCodeInput(e.target.value)}
//                             maxLength={4}
//                         /> */}
//                         <div className="input-boxes">
//                             {inputs.map((value, index) => (
//                                 <input
//                                     key={index}
//                                     id={`input-${index}`}
//                                     type="text"
//                                     maxLength={1}
//                                     value={value}
//                                     onChange={(e) => handleInputChange(index, e.target.value)}
//                                     onKeyDown={(e) => {
//                                         if (e.key === "Backspace" && index > 0 && !value) {
//                                             document.getElementById(`input-${index - 1}`).focus();
//                                         }
//                                     }}
//                                     className="verification-input"
//                                 />
//                             ))}
//                         </div>

//                         {/* <button
//                                 className="verify-button"
//                                 onClick={handleVerificationCodeSubmit}
//                                 disabled={loading2}
//                             >
//                                 {loading2 ? "Verifying..." : "Verify"}
//                             </button> */}
//                         <button
//                             className="verify-button"
//                             onClick={handleVerify}
//                             disabled={inputs.some((input) => input === "")} // Disable if inputs are incomplete
//                         >
//                             Verify
//                         </button>

//                         {/* <button
//                                 className="resend-button"
//                                 onClick={handleResendCode}
//                                 disabled={loading2}
//                             >
//                                 {loading2 ? "Resending..." : "Resend Code"}
//                             </button> */}
//                         <p className="resend-timer">
//                             {/* {resendDisabled
//                                 ? `Wait ${String(timeLeft).padStart(2, "0")} seconds to resend the code.`
//                                 : (
//                                     <button className="resend-button" onClick={handleResendCode}>
//                                         Resend Code
//                                     </button>
//                                 )} */}
//                             Resending a new code in <strong>{String(timeLeft).padStart(2, "0")}</strong> seconds...

//                         </p>
//                     </div>
//                     <style>{`
//                  .verification-popup {
//           position: fixed;
//           top: 0;
//           left: 0;
//           width: 100%;
//           height: 100%;
//           background: rgba(0, 0, 0, 0.5);
//           display: flex;
//           justify-content: center;
//           align-items: center;
//           z-index: 1000;
//         }
//         .popup-content {
//           background: white;
//           padding: 20px;
//           border-radius: 10px;
//           text-align: center;
//           box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
//         }
//         .input-boxes {
//           display: flex;
//           justify-content: center;
//           gap: 10px;
//           margin: 20px 0;
//         }
//         .verification-input {
//           width: 50px;
//           height: 50px;
//           text-align: center;
//           font-size: 20px;
//           border: 1px solid #ccc;
//           border-radius: 5px;
//         }
//         .verify-button {
//           padding: 10px 20px;
//           background-color: #ff5a5f;
//           color: white;
//           border: none;
//           border-radius: 5px;
//           cursor: pointer;
//           font-weight: bold;
//         }
//         .resend-timer {
//           margin-top: 15px;
//           font-size: 14px;
//           color: #555;
//         }
//         .resend-button {
//           background: none;
//           border: none;
//           color: #007bff;
//           cursor: pointer;
//           text-decoration: underline;
//         }
//                `}</style>
//                 </div>
//             )}

//         </div>
//     );
// }

// export default Register;







import React, { useState, useEffect } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import './Register.css';
import Swal from 'sweetalert2'
import { EyeClosed, Eye } from 'lucide-react';

function Register() {
    const navigation = useNavigate();
    const [formData, setFormData] = useState({
        Name: '',
        Surname: '',
        IdNumber: '',
        PassportNumber: '',
        Country: '',
        Gender: '',
        DateOfBirth: '',
        Email: '',
        PhoneNumber: '',
        Password: '',
        ConfirmPassword: '',
        TypeOfFunding: '', // Add TypeOfFunding to the form state,
        InstituteName: '',
        NsfasReferenceNumber: '',
        HomeAddress: '',
        BurseryOrganizationName: '',
        BurseryReferenceNumber: '',
        BurseryType: '',
        BurseryStartDate: '',
        BurseryEndDate: '',
        CoverageDetails: '',
        BursaryProofImage: ''
    });
    const [useID, setUseID] = useState(true); // Toggle between ID or Passport
    const [step, setStep] = useState(1); // Step 1: Basic info, Step 2: Additional info
    const [loading, setLoading] = useState(false); // Loading state for form submission
    const [faceImage, setFaceImage] = useState(null);
    const [idImage, setIdImage] = useState(null);
    const [proofImage, setProofImage] = useState(null);
    const [errors, setErrors] = useState('');
    const [loading2, setLoading2] = useState(false);


    //verification process
    const [verificationCode, setVerificationCode] = useState('');
    const [codeInput, setCodeInput] = useState('');
    const [showVerificationPopup, setShowVerificationPopup] = useState(false);
    const [message, setMessage] = useState('');

    const [inputs, setInputs] = useState(["", "", "", ""]);
    const [timeLeft, setTimeLeft] = useState(null);
    const [resendDisabled, setResendDisabled] = useState(true);

    const [resendEnabled, setResendEnabled] = useState(false);


    const [showPassword, setShowPassword] = useState(false); // Password visibility toggle
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);



    //11 jan 2025
    const [coverageDetails, setCoverageDetails] = useState([]);

    const handleCheckboxChange = (e) => {
        const { value, checked } = e.target;

        setCoverageDetails((prev) =>
            checked ? [...prev, value] : prev.filter((item) => item !== value)
        );
    };

    const checkIdNumberExists = async (idNumber) => {
        try {
            const response = await fetch('https://backendjaes.passiontrials.co.za/check_idnumber', {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ idNumber }),
            });

            const data = await response.json();
            return data.exists; // Return true if the ID exists, false otherwise
        } catch (error) {
            console.error('Error checking ID number:', error);
            return false; // Default to false in case of an error
        }
    };



    const checkEmailExists = async (email) => {
        try {
            const response = await fetch('https://backendjaes.passiontrials.co.za/check_email', {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ email }),
            });

            const data = await response.json();
            console.log('does this exist', data.exists);
            return data.exists; // Return true if the email exists, false otherwise
        } catch (error) {
            console.error('Error checking email existence:', error);
            return false; // Default to false in case of an error
        }
    };


    const handleResendCode = async () => {
        setLoading2(true);
        try {
            const response = await fetch("https://backendjaes.passiontrials.co.za/resend_code", {
                method: "POST",
                headers: { "Content-Type": "application/json" },
                body: JSON.stringify({ email: formData.Email }),
            });

            const data = await response.json();
            if (response.ok) {
                Swal.fire({
                    title: "Code Resent!",
                    text: data.message,
                    icon: "info",
                    confirmButtonText: "OK",
                });
                //  console.log("Code resent successfully:", data.message);
                setTimeLeft(120);
                setResendEnabled(false);
            } else {
                Swal.fire({
                    title: "Error!",
                    text: 'Failed to resend code' || data.error,
                    icon: "error",
                    confirmButtonText: "Try Again",
                });
            }
        } catch (error) {
            Swal.fire({
                title: "Error!",
                text: "Failed to resend verification code.",
                icon: "error",
                confirmButtonText: "Try Again",
            });
        } finally {
            setLoading2(false);
        }
    };


    useEffect(() => {// 10 jan 2025
        if (timeLeft !== null) {
            if (timeLeft > 0) {
                const timer = setTimeout(() => setTimeLeft((prev) => prev - 1), 1000);
                return () => clearTimeout(timer);
            } else if (timeLeft === 0) {
                // Trigger auto-resend and reset the timer
                // handleResendCode();
                setResendEnabled(true);
            }
        }
    }, [timeLeft]);

    useEffect(() => {
        if (showVerificationPopup) {
            // Initialize the timer to 30 seconds when the popup is shown
            setTimeLeft(120);
            setResendEnabled(false);
        } else {
            // Clear the timer when the popup is closed
            setTimeLeft(null);
        }
    }, [showVerificationPopup]);

    const handleInputChange = (index, value) => {// 10 jan 2025
        if (!isNaN(value) && value.length <= 1) {
            const newInputs = [...inputs];
            newInputs[index] = value;
            setInputs(newInputs);

            // Automatically focus the next box
            if (value && index < 3) {
                document.getElementById(`input-${index + 1}`).focus();
            }
        }
    };


    const handleChange = (e) => {
        const { name, value } = e.target;

        if (name === 'IdNumber' && value.length >= 10) {
            const gender = handleGender(value);
            const dateOfBirth = handleDateOfBirth(value);

            setFormData({
                ...formData,
                [name]: value,
                Gender: gender,
                DateOfBirth: dateOfBirth
            });
            setErrors('');
        } else {
            setFormData({ ...formData, [name]: value });
            setErrors('');
        }
    };

    const handleImageChange = (e) => {
        if (e.target.name === 'FaceImage') {
            setFaceImage(e.target.files[0]);
        } else if (e.target.name === 'ID_Image') {
            setIdImage(e.target.files[0]);
        }
    };

    function isNullOrUndefined(value) {
        return value == null || value == undefined || value == '';
    }



    const validateStep = () => {
        let newErrors = '';

        if (step === 1) {
            if (!formData.Name || !formData.Surname || !formData.Email || !formData.PhoneNumber) {
                newErrors = 'All fields are required in this step.';
            } else if (!/\S+@\S+\.\S+/.test(formData.Email)) {
                newErrors = 'Invalid email format.';
            }
        } else if (step === 2) {
            if (!formData.IdNumber && !formData.PassportNumber) {
                newErrors = 'You must provide either an ID Number or Passport Number.';
            } else if (formData.IdNumber && formData.IdNumber.length !== 13) {
                newErrors = 'ID Number must be exactly 13 digits.';
            } else if (!/^\d*$/.test(formData.IdNumber)) {
                newErrors = 'ID Number must contain only numbers.';
            }

            else if (!useID) {
                // Passport validation
                if (!formData.PassportNumber) {
                    newErrors = 'Passport number is required.';
                }
                if (!formData.Country) {
                    newErrors = 'Country is required when using a passport.';
                }
                if (!formData.Gender) {
                    newErrors = 'Gender is required when using a passport.';
                }
                if (!formData.DateOfBirth) {
                    newErrors = 'Date of Birth is required when using a passport.';
                }
            }
        } else if (step === 3) {
            if (!formData.TypeOfFunding || !formData.InstituteName) {
                newErrors = 'Please select a funding type and institution.';
            }
            if (!formData.HomeAddress) {
                newErrors = 'Please fill in your home address.';
            }
            if (
                formData.TypeOfFunding.toLowerCase() === 'nsfas' &&
                !formData.NsfasReferenceNumber
            ) {
                newErrors = 'NSFAS Reference Number is required.';
            }

            if (formData.TypeOfFunding.toLowerCase() === 'bursary') {
                if (
                    !formData.BurseryOrganizationName ||
                    !formData.BurseryReferenceNumber ||
                    !formData.BurseryType ||
                    !formData.BurseryStartDate ||
                    !formData.BurseryEndDate ||
                    !proofImage ||
                    coverageDetails.length === 0

                ) {
                    newErrors = 'All bursary details are required.';
                }
            }
        } else if (step === 4) {
            if (!formData.Password || !formData.ConfirmPassword) {
                newErrors = 'Password and Confirm Password are required.';
            } else if (formData.Password !== formData.ConfirmPassword) {
                newErrors = 'Passwords do not match.';
            } else if (formData.Password.length < 8) {
                newErrors = 'Password must be at least 8 characters.';
            }
            else if (!/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&#])[A-Za-z\d@$!%*?&#]{8,}$/.test(formData.Password)) {
                newErrors = 'Password must include uppercase, lowercase, number, and special character.';
            }


        }

        setErrors(newErrors);
        return !newErrors; // Return true if no errors
    };

    const handleGender = (id) => {
        const genderPart = id.slice(6, 10); // Extract part of the ID number
        const gender = parseInt(genderPart, 10);

        if (gender >= 0 && gender <= 4999) {
            console.log('Gender :', gender, 'this is a Female');
            return 'Female';
        } else if (gender >= 5000 && gender <= 9999) {
            console.log('Gender :', gender, 'this is a Male');
            return 'Male';
        } else {
            return '';
        }
    };

    const handleDateOfBirth = (id) => {
        const datePart = id.slice(0, 6); // Extract part of the ID number
        const year = datePart.slice(0, 2);
        const month = datePart.slice(2, 4);
        const day = datePart.slice(4, 6);

        const fullYear = year.startsWith('0') || year.startsWith('1') ? `20${year}` : `19${year}`;
        const dateOfBirth = `${fullYear}-${month}-${day}`;
        let me = new Date(dateOfBirth).toLocaleDateString('en-US', { day: "numeric", month: 'long', year: 'numeric' });

        console.log('Date of Birth :', me);
        return me;
    };

    const handleProofImageChange = (e) => {
        if (e.target.name === 'BursaryProofImage') {
            setProofImage(e.target.files[0]);
            setErrors('');
        }
    };

    const handleNextStep = async () => {
        if (step === 1) {
            // Check if email exists before running the rest of the validation
            const emailExists = await checkEmailExists(formData.Email);
            if (emailExists) {
                setErrors('This email is already registered. Please use another email or log in.');
                return; // Prevent moving to the next step
            }
        }

        if (step === 2) {
            // Check if email exists before running the rest of the validation

            if (useID && formData.IdNumber.length === 13) {
                const idExists = await checkIdNumberExists(formData.IdNumber);
                if (idExists) {
                    setErrors('This ID number is already registered.');
                    return; // Prevent moving to the next step
                }
            }

        }



        if (validateStep()) {
            setStep((prev) => prev + 1);
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true); // Set loading to true while processing the request
        if (!validateStep()) {
            setLoading(false);
            return; // Stop submission if validation fails
        }
        const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&#])[A-Za-z\d@$!%*?&#]{8,}$/;
        //const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&#])[A-Za-z\d@$!%*?&#\-\s]{8,}$/;
        //const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[A-Za-z\d]{8,}$/;

        // Check if the passwords match
        if (formData.Password !== formData.ConfirmPassword) {
            //alert("Passwords do not match!");
            Swal.fire({
                position: "top-end",
                icon: "error",
                title: "Passwords do not match!",
                showConfirmButton: false,
                timer: 5500
            });
            setLoading(false); // Stop loading
            return;
        }

        // Check if the password meets the strength requirements
        if (!passwordRegex.test(formData.Password)) {
            //alert("Passwor    d must be at least 8 characters long, include at least one uppercase letter, one lowercase letter, one number, and one special character.");
            Swal.fire({
                position: "top-end",
                icon: "error",
                title: "Password must be at least 8 characters long, include at least one uppercase letter, one lowercase letter, one number, and one special character.",
                showConfirmButton: false,
                timer: 5500
            });
            setLoading(false); // Stop loading
            return;
        }

        const formDataToSend = new FormData();
        formDataToSend.append('Name', formData.Name);
        formDataToSend.append('Surname', formData.Surname);
        formDataToSend.append('Email', formData.Email);
        formDataToSend.append('PhoneNumber', formData.PhoneNumber);
        formDataToSend.append('Password', formData.Password);
        formDataToSend.append('TypeOfFunding', formData.TypeOfFunding);
        formDataToSend.append('InstituteName', formData.InstituteName); // Add TypeOfFunding to the form data
        formDataToSend.append('HomeAddress', formData.HomeAddress); // Add TypeOfFunding to the form data

        if (useID) {
            formDataToSend.append('IdNumber', formData.IdNumber);
            formDataToSend.append('Gender', formData.Gender);
            formDataToSend.append('DateOfBirth', formData.DateOfBirth);
        } else {
            formDataToSend.append('PassportNumber', formData.PassportNumber);
            formDataToSend.append('Country', formData.Country);
            formDataToSend.append('Gender', formData.Gender);
            formDataToSend.append('DateOfBirth', formData.DateOfBirth);
        }

        if (faceImage) {
            formDataToSend.append('FaceImage', faceImage);
        }

        if (idImage) {
            formDataToSend.append('ID_Image', idImage);
        }
        if (proofImage) {
            formDataToSend.append('BursaryProofImage', proofImage);
        }
        if (formData.TypeOfFunding.toLowerCase() === 'bursary') {
            formDataToSend.append('BurseryOrganizationName', formData.BurseryOrganizationName);
            formDataToSend.append('BurseryReferenceNumber', formData.BurseryReferenceNumber);
            formDataToSend.append('BurseryType', formData.BurseryType);
            formDataToSend.append('BurseryStartDate', formData.BurseryStartDate);
            formDataToSend.append('BurseryEndDate', formData.BurseryEndDate);
            formDataToSend.append('CoverageDetails', coverageDetails.join(', '));
        }

        if (formData.TypeOfFunding.toLowerCase() === 'nsfas') {
            formDataToSend.append('NsfasReferenceNumber', formData.NsfasReferenceNumber);

        }


        for (let pair of formDataToSend.entries()) {
            console.log(pair[0] + ': ' + pair[1]);
        }

        if (validateStep()) {
            try {
                const response = await fetch('https://backendjaes.passiontrials.co.za/new_user_register', {
                    method: 'POST',
                    body: formDataToSend
                });
                const data = await response.json();
                //alert(data.message);
                console.log(data.message);
                if (response.ok) {
                    Swal.fire({
                        position: "top-end",
                        icon: "success",
                        title: "Registration successful. Please verify your account.",
                        showConfirmButton: false,
                        timer: 5500
                    });
                    setVerificationCode(data.verificationCode); // Retrieve from the server
                    console.log('the code ', data.verificationCode);
                    setShowVerificationPopup(true);

                    // navigation("/login");
                }
                else {
                    Swal.fire({
                        position: "top-end",
                        icon: "error",
                        title: data.message || "Registration failed.",
                        showConfirmButton: false,
                        timer: 5500
                    });
                }


            } catch (error) {
                console.error('Error during registration:', error);
                Swal.fire({
                    position: "top-end",
                    icon: "error",
                    title: "Error during registration",
                    showConfirmButton: false,
                    timer: 5500
                });
            } finally {
                setLoading(false); // Stop loading
            }
        }
    };

    const handleVerificationCodeSubmit = async (code) => {
        const response = await fetch('https://backendjaes.passiontrials.co.za/verify_code', {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
                email: formData.Email,
                codeInput: code,
            }),
        });

        const data = await response.json();
        if (response.ok) {
            Swal.fire({
                position: "top-end",
                icon: "success",
                title: "Verification successful!",
                showConfirmButton: false,
                timer: 5500
            });
            setShowVerificationPopup(false);
            navigation('/login'); // Redirect user to the dashboard
        } else {
            Swal.fire({
                position: "top-end",
                icon: "error",
                title: "Invalid verification code. Please try again.",
                showConfirmButton: false,
                timer: 5500
            });
        }
    };




    const handleVerify = () => {
        const code = inputs.join(""); // Combine inputs into a single code
        handleVerificationCodeSubmit(code);
    };

    const togglePasswordVisibility = () => {
        setShowPassword((prevShowPassword) => !prevShowPassword);
    };
    const togglePasswordVisibility2 = () => {
        setShowConfirmPassword((prevShowPassword) => !prevShowPassword);
    };

    return (
        <div className="register-page">
            <div className="register-container">
                <form onSubmit={handleSubmit} className="register-form">
                    <h2>Create Account</h2>

                    {step === 1 && (
                        <>
                            <div className="form-group">
                                <input name="Name" value={formData.Name} onChange={handleChange} placeholder="Name" required />
                                <input name="Surname" value={formData.Surname} onChange={handleChange} placeholder="Surname" required />
                            </div>
                            <div className="form-group">
                                <input name="Email" value={formData.Email} onChange={handleChange} placeholder="Email" required type="email" />
                                <input name="PhoneNumber" value={formData.PhoneNumber} onChange={handleChange} placeholder="Phone Number" required />
                            </div>
                            {/* 
                            <div className="form-group">
                                <select name="InstituteName" value={formData.InstituteName} onChange={handleChange} required>
                                    <option value="">Select Institution</option>
                                    <option value="Ukzn Howard">UKZN Howard</option>
                                    <option value="DUT Steve Biko">DUT</option>
                                </select>
                            </div>
                             */}

                            <button type="button" className="continue-button" onClick={handleNextStep}>Continue</button>
                            {errors && <p style={{
                                color: "red",
                                fontSize: "12px",
                                marginTop: '10px',
                                textAlign: "center"
                            }}>{errors}</p>}
                        </>
                    )}

                    {step === 2 && (
                        <>
                            <div className="form-group">
                                {/* <label>Use ID Number or Passport?</label> */}
                                <select onChange={(e) => setUseID(e.target.value === 'ID')} value={useID ? 'ID' : 'Passport'}>
                                    <option value="ID">ID Number</option>
                                    <option value="Passport">Passport</option>
                                </select>
                            </div>

                            {useID ? (
                                <>
                                    <div className="form-group">
                                        <input name="IdNumber" type='text' maxLength="13" value={formData.IdNumber} onChange={handleChange} placeholder="ID Number" required />
                                    </div>
                                    <div className="form-group">
                                        <input name="Gender" value={formData.Gender} onChange={handleChange} placeholder="Gender" readOnly />
                                        <input name="DateOfBirth" value={formData.DateOfBirth} onChange={handleChange} placeholder="Date of Birth" readOnly />
                                    </div>
                                </>
                            ) : (
                                <>
                                    <div className="form-group">
                                        <input name="PassportNumber" type="text" value={formData.PassportNumber} onChange={handleChange} placeholder="Passport Number" required />
                                        <input name="Country" type="text" value={formData.Country} onChange={handleChange} placeholder="Country" required />
                                    </div>
                                    <div className="gender-group-wrapper">
                                        <label className="gender-label">Gender:</label>
                                        <div className="gender-button-group">
                                            <label
                                                className={`gender-button ${formData.Gender === 'Male' ? 'selected' : ''}`}
                                            >
                                                <input
                                                    type="radio"
                                                    name="Gender"
                                                    value="Male"
                                                    checked={formData.Gender === 'Male'}
                                                    onChange={handleChange}
                                                />
                                                Male
                                            </label>
                                            <label
                                                className={`gender-button ${formData.Gender === 'Female' ? 'selected' : ''}`}
                                            >
                                                <input
                                                    type="radio"
                                                    name="Gender"
                                                    value="Female"
                                                    checked={formData.Gender === 'Female'}
                                                    onChange={handleChange}
                                                />
                                                Female
                                            </label>
                                            <div className="form-group">
                                                <input name="DateOfBirth" type="date" value={formData.DateOfBirth} onChange={handleChange} placeholder="Date of Birth" required />
                                            </div>
                                        </div>

                                        <style>{`
        .gender-group-wrapper {
            display: flex;
            flex-direction: column;
            margin-bottom: 1em;
        }

        .gender-label {
            margin-bottom: 0.5em;
            color: #888;
            font-weight: bold;
        }

        .gender-button-group {
            display: flex;
            gap: 1em;
        }

        .gender-button {
            padding: 0.5em 1em;
            border: 2px solid #ccc;
            border-radius: 5px;
            cursor: pointer;
            font-weight: bold;
            text-align: center;
            transition: border-color 0.3s;
        }

        .gender-button input {
            display: none; /* Hide the original radio input */
        }

        .gender-button.selected {
            border-color: #ff5a5f; /* Change border color when selected */
            background-color: #f0f8ff;
        }
    `}</style>
                                    </div>

                                </>
                            )}




                            {/* <div className="form-group">
                                <label htmlFor="FaceImage">Upload selfie</label>
                                <input name="FaceImage" type="file" onChange={handleImageChange} />
                            </div>
                            <div className="form-group">
                                <label htmlFor="ID_Image">Upload ID or Passport Image</label>
                                <input name="ID_Image" type="file" onChange={handleImageChange} />
                            </div> */}

                            {/*                             
                            <div className="form-group password-group">
                                <input name="Password" value={formData.Password} onChange={handleChange} placeholder="Password" required type="password" />
                                <input name="ConfirmPassword" value={formData.ConfirmPassword} onChange={handleChange} placeholder="Confirm Password" required type="password" />
                            </div> */}
                            <div className="form-navigation">

                                <button type="button" className="continue-button" style={{ color: 'white' }} onClick={() => setStep(1)}>Back</button>
                                <button type="button" className="continue-button" onClick={handleNextStep}>Continue</button>
                            </div>
                            {errors && <p style={{
                                color: "red",
                                fontSize: "12px",
                                marginTop: '10px',
                                textAlign: "center"
                            }}>{errors}</p>}
                        </>
                    )}

                    {step === 3 && (
                        <>
                            <div className="form-group">
                                {/* <label>Type of Funding:</label> */}
                                <select name="TypeOfFunding" value={formData.TypeOfFunding} onChange={handleChange} required>
                                    <option value="">Select Funding Type</option>
                                    <option value="self funded">Self Funded</option>
                                    <option value="Bursary">Bursary</option>
                                    <option value="NSFAS">NSFAS</option>
                                </select>
                            </div>
                            <div className="form-group">
                                <select
                                    name="InstituteName"
                                    value={formData.InstituteName}
                                    onChange={handleChange}
                                    required
                                >
                                    <option value="">Select institute</option>
                                    <option value="Ukzn Howard">UKZN Howard</option>
                                    <option value="UKZN Medical School">UKZN Medical School</option>
                                    <option value="Umbilo College">Umbilo College</option>
                                    <option value="Coastal College">Coastal College</option>
                                    <option value="Ukzn Westville">Ukzn Westville</option>
                                </select>
                            </div>
                            <div className="amber-form-group">
                                <div className="bookYourPerfectAccommodation-module__title amber-form-group">
                                    <label>Home Address <span style={{ color: 'red' }}>*</span></label>
                                    <input name='HomeAddress' type="text" placeholder="Enter home address here." value={formData.HomeAddress} onChange={handleChange} required />
                                </div>
                            </div>
                            <br></br>

                            {formData.TypeOfFunding.toLowerCase() === "nsfas" && (
                                <>
                                    <div className="amber-form-group">
                                        <div className="bookYourPerfectAccommodation-module__title amber-form-group">
                                            <label>NSFAS Reference Number <span style={{ color: 'red' }}>*</span></label>
                                            <input name='NsfasReferenceNumber' type="text" placeholder="Enter NSFAS reference number here." value={formData.NsfasReferenceNumber} onChange={handleChange} required />
                                        </div>
                                    </div>



                                </>
                            )}

                            {formData.TypeOfFunding.toLowerCase() === "bursary" && (
                                <>
                                    <div className="bursary-section">
                                        <div className="scrollable-container">
                                            <div className="amber-form-group">
                                                <div className="bookYourPerfectAccommodation-module__title amber-form-group">
                                                    <label>Bursary Name <span style={{ color: 'red' }}>*</span></label>
                                                    <input name='BurseryOrganizationName' type="text" placeholder="Enter bursary organization name" value={formData.BurseryOrganizationName} onChange={handleChange} required />
                                                </div>
                                                <div className="bookYourPerfectAccommodation-module__title amber-form-group">
                                                    <label>Reference Number <span style={{ color: 'red' }}>*</span></label>
                                                    <input name='BurseryReferenceNumber' type="text" placeholder="Enter bursary reference number" value={formData.BurseryReferenceNumber} onChange={handleChange} required />
                                                </div>
                                                <div className="bookYourPerfectAccommodation-module__title amber-form-group">
                                                    <label>Bursary Type <span style={{ color: 'red' }}>*</span></label>
                                                    {/* <input name='BurseryType' type="text" placeholder="Full bursary , partial bursary , Etc..." value={formData.BurseryType} onChange={handleChange} required /> */}
                                                    <select
                                                        name="BurseryType"
                                                        value={formData.BurseryType}
                                                        onChange={handleChange}
                                                        required
                                                        className="dropdown-select"
                                                    >
                                                        <option value="">Select Bursary Type</option>
                                                        <option value="Full Bursary">Full Bursary</option>
                                                        <option value="Partial Bursary">Partial Bursary</option>
                                                        <option value="Top-up Bursary">Top-up Bursary</option>
                                                        <option value="Fixed Bursary">Fixed Bursary</option>
                                                    </select>
                                                </div>
                                            </div>
                                            <div className="amber-form-group">
                                                <div className="bookYourPerfectAccommodation-module__title amber-form-group">
                                                    <label>Bursary Start Date <span style={{ color: 'red' }}>*</span></label>
                                                    {/* <input name='BurseryStartDate' type="text" placeholder="Enter the date funding starts " value={formData.BurseryStartDate} onChange={handleChange} required /> */}
                                                    <input
                                                        name="BurseryStartDate"
                                                        type="date"
                                                        value={formData.BurseryStartDate}

                                                        onChange={handleChange}
                                                        required
                                                    />
                                                </div>
                                                <div className="bookYourPerfectAccommodation-module__title amber-form-group">
                                                    <label>Bursary End Date <span style={{ color: 'red' }}>*</span></label>
                                                    {/* <input name='BurseryEndDate' type="text" placeholder="Enter the end of your funding for the year. " value={formData.BurseryEndDate} onChange={handleChange} required /> */}
                                                    <input
                                                        name="BurseryEndDate"
                                                        type="date"
                                                        value={formData.BurseryEndDate}
                                                        onChange={handleChange}
                                                        required
                                                    />
                                                </div>
                                                <div className="bookYourPerfectAccommodation-module__title amber-form-group">
                                                    <label>Coverage Details <span style={{ color: 'red' }}>*</span></label>
                                                    {/* <input name='CoverageDetails' type="text" placeholder="Accomodation, tution , food , transport Etc ... " value={formData.CoverageDetails} onChange={handleChange} required /> */}
                                                    <div class="checkbox-grid">

                                                        <div class="checkbox-item">
                                                            <input type="checkbox" id="Tuition" value="Tuition" onChange={handleCheckboxChange} />
                                                            <label for="Tuition">Tuition</label>
                                                        </div>
                                                        <div class="checkbox-item">
                                                            <input type="checkbox" id="Accommodation" value="Accommodation" onChange={handleCheckboxChange} />
                                                            <label for="Accommodation">Accommodation</label>
                                                        </div>
                                                        <div class="checkbox-item">
                                                            <input type="checkbox" id="Living Allowance" value="Living Allowance" onChange={handleCheckboxChange} />
                                                            <label for="Living Allowance">Living Allowance</label>
                                                        </div>
                                                        <div class="checkbox-item">
                                                            <input type="checkbox" id="Transportation" value="Transportation" onChange={handleCheckboxChange} />
                                                            <label for="Transportation">Transportation</label>
                                                        </div>
                                                        <div class="checkbox-item">
                                                            <input type="checkbox" id="Study material" value="Study material" onChange={handleCheckboxChange} />
                                                            <label for="Study material">Study material</label>
                                                        </div>
                                                        <div class="checkbox-item">
                                                            <input type="checkbox" id="Special equipment" value="Special equipment" onChange={handleCheckboxChange} />
                                                            <label for="Special equipment">Special equipment</label>
                                                        </div>


                                                    </div>


                                                    <style>{`:root {
  --background: #fff;
  --text-color: #414856;
  --highlight-color: #4f29f0;
  --border-color: #c3c8de;
  --border-radius: 10px;
  --gap: 15px; /* Gap between items */
}

.checkbox-grid {
  display: flex;
  flex-wrap: wrap;
  gap: var(--gap);
  justify-content: center;
  padding: 20px;
}

.checkbox-item {
  display: inline-table;
  align-items: center;
  justify-content: center;
  flex: 1 1 calc(20% - var(--gap)); /* Responsive width based on available space */
  max-width: calc(20% - var(--gap)); /* Ensures consistency across all items */
  padding: 10px;
  background: var(--background);
  color: var(--text-color);
  border: 2px solid var(--border-color);
  border-radius: var(--border-radius);
  cursor: pointer;
  text-align: center;
  transition: background 0.3s ease, color 0.3s ease, box-shadow 0.3s ease;
}

.checkbox-item input {
  display: none; /* Hide the checkbox itself */
}

.checkbox-item label {
  cursor: pointer;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
}

.checkbox-item input:checked + label {
  background: var(--highlight-color);
  color: var(--background);
  box-shadow: 0 5px 15px rgba(79, 41, 240, 0.3);
}
`}</style>
                                                </div>
                                            </div>
                                            <div className="amber-form-group">
                                                <div className="bookYourPerfectAccommodation-module__title amber-form-group">
                                                    <label>Upload Proof of Bursary <span style={{ color: 'red' }}>*</span></label>
                                                    <input
                                                        type="file"
                                                        name="BursaryProofImage"
                                                        accept="image/*"
                                                        onChange={handleProofImageChange}
                                                        required
                                                    />
                                                </div>
                                            </div>
                                            <style>{`.scrollable-container {
  max-height: 223px; /* Set a fixed height for the scrollable area */
  overflow-y: auto; /* Enable vertical scrolling */
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 5px;
  background-color: #f9f9f9; /* Optional: Add a light background for contrast */
}

.scrollable-container::-webkit-scrollbar {
  width: 8px; /* Width of the scrollbar */
}

.scrollable-container::-webkit-scrollbar-thumb {
  background-color: #888; /* Color of the scrollbar thumb */
  border-radius: 4px; /* Rounded edges for the scrollbar thumb */
}

.scrollable-container::-webkit-scrollbar-thumb:hover {
  background-color: #555; /* Darken the scrollbar thumb on hover */
}`}</style>

                                        </div>
                                    </div>
                                </>

                            )}

                            {/* < div className="form-grid2">
                                <button type="button" onClick={handlePreviousStep}>
                                    Back
                                </button>
                                <button type="button" onClick={handleNextStep}>
                                    Next
                                </button>
                            </div> */}

                            {errors && <p style={{
                                color: "red",
                                fontSize: "12px",
                                marginTop: '10px',
                                textAlign: "center"
                            }}>{errors}</p>}

                            <div className="form-navigation">

                                <button type="button" className="continue-button" style={{ color: 'white' }} onClick={() => setStep(2)}>Back</button>
                                <button type="button" className="continue-button" onClick={handleNextStep}>Continue</button>
                            </div>

                        </>
                    )}

                    {step === 4 && (
                        <>

                            <div className="form-group password-group">
                                <input name="Password" value={formData.Password} onChange={handleChange} placeholder="Password" required type={showPassword ? 'text' : 'password'} />
                                <span onClick={togglePasswordVisibility} style={{ marginTop: '95px', right: '516px' }} className="eye-icon">
                                    {showPassword ? <Eye size={20} /> : <EyeClosed size={20} />}
                                </span>
                            </div>
                            <div className="form-group password-group">

                                <input name="ConfirmPassword" value={formData.ConfirmPassword} onChange={handleChange} placeholder="Confirm Password" required type={showConfirmPassword ? 'text' : 'password'} />
                                <span onClick={togglePasswordVisibility2} style={{ marginTop: '152px', right: '516px' }} className="eye-icon">
                                    {showConfirmPassword ? <Eye size={20} /> : <EyeClosed size={20} />}
                                </span>
                            </div>
                            <div className="form-navigation">
                                <button type="button" className="back-button" style={{ color: 'white' }} onClick={() => setStep(3)}>Back</button>
                                <button type="submit" className="register-button" disabled={loading}>
                                    {loading ? "Processing..." : "Register"}
                                </button>
                            </div>
                            {errors && <p style={{
                                color: "red",
                                fontSize: "12px",
                                marginTop: '10px',
                                textAlign: "center"
                            }}>{errors}</p>}
                        </>
                    )}

                    {step === 1 && (
                        <p>Already have an account? <Link to="/login" className="login-link">Login</Link></p>
                    )}
                    {step === 4 && (
                        <p>Already have an account? <Link to="/login" className="login-link">Login</Link></p>
                    )}
                </form>
            </div>
            {showVerificationPopup && (
                <div className="verification-popup">
                    <div className="popup-content">
                        <h2>Enter Verification Code</h2>
                        <p>Please enter the 4-digit code sent to your email.</p>
                        {/* <input
                            type="text"
                            placeholder="Enter code"
                            value={codeInput}
                            onChange={(e) => setCodeInput(e.target.value)}
                            maxLength={4}
                        /> */}
                        <div className="input-boxes">
                            {inputs.map((value, index) => (
                                <input
                                    key={index}
                                    id={`input-${index}`}
                                    type="text"
                                    maxLength={1}
                                    value={value}
                                    onChange={(e) => handleInputChange(index, e.target.value)}
                                    onKeyDown={(e) => {
                                        if (e.key === "Backspace" && index > 0 && !value) {
                                            document.getElementById(`input-${index - 1}`).focus();
                                        }
                                    }}
                                    className="verification-input"
                                />
                            ))}
                        </div>

                        {/* <button
                                className="verify-button"
                                onClick={handleVerificationCodeSubmit}
                                disabled={loading2}
                            >
                                {loading2 ? "Verifying..." : "Verify"}
                            </button> */}

                        {!resendEnabled && (
                            <button
                                className="verify-button"
                                onClick={handleVerify}
                                disabled={inputs.some((input) => input === "")} // Disable if inputs are incomplete
                            >
                                Verify
                            </button>
                        )}

                        <br></br>

                        {resendEnabled && (
                            <button
                                className="resend-button"
                                onClick={handleResendCode}
                                disabled={loading2}
                            >
                                {loading2 ? "Resending..." : "Resend Code"}
                            </button>
                        )}

                        <p className="resend-timer">
                            {/* {resendDisabled
                                ? `Wait ${String(timeLeft).padStart(2, "0")} seconds to resend the code.`
                                : (
                                    <button className="resend-button" onClick={handleResendCode}>
                                        Resend Code
                                    </button>
                                )} */}
                            {resendEnabled
                                ? "Didn't receive a code? Click Resend."
                                : `Resending a new code in ${String(timeLeft).padStart(2, "0")} seconds...`}
                        </p>
                    </div>
                    <style>{`
                 .verification-popup {
          position: fixed;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          background: rgba(0, 0, 0, 0.5);
          display: flex;
          justify-content: center;
          align-items: center;
          z-index: 1000;
        }
        .popup-content {
          background: white;
          padding: 20px;
          border-radius: 10px;
          text-align: center;
          box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
        }
        .input-boxes {
          display: flex;
          justify-content: center;
          gap: 10px;
          margin: 20px 0;
        }
        .verification-input {
          width: 50px;
          height: 50px;
          text-align: center;
          font-size: 20px;
          border: 1px solid #ccc;
          border-radius: 5px;
        }
        .verify-button {
          padding: 10px 20px;
          background-color: #28a745;
          color: white;
          border: none;
          border-radius: 5px;
          cursor: pointer;
          font-weight: bold;
        }
        .resend-timer {
          margin-top: 15px;
          font-size: 14px;
          color: #555;
        }
        .resend-button {
          background: none;
          border: none;
          color: #007bff;
          cursor: pointer;
          text-decoration: underline;
        }

        .resend-button: hover{
         color: #ffffff;
        }
               `}</style>
                </div>
            )}

        </div>
    );
}

export default Register;
