
import React, { useState,useEffect } from 'react';
import './RegistrationModal.css'; // Make sure this CSS file is properly imported to style the modal
import { useNavigate, Link } from 'react-router-dom';
import { EyeClosed, Eye } from 'lucide-react';
import ReserveLoginModal from './TransportLoginModal.js'; // Import the Login Modal
import Swal from 'sweetalert2';


function ReserveRegistrationModal({ onClose }) {
    const navigate = useNavigate();
    const [showPassword, setShowPassword] = useState(false); // Password visibility toggle
    const [formData, setFormData] = useState({
        Name: '',
        Surname: '',
        Email: '',
        PhoneNumber: '',
        Password: '',
        ConfirmPassword: '',
        IdNumber: '',
        PassportNumber: '',
        Country: '',
        Gender: '',
        DateOfBirth: '',
        InstituteName: '',
        TypeOfFunding: "Transport" // Set initial value from selectedFunding prop

    });
    const [faceImage, setFaceImage] = useState(null); // State to hold face image
    const [idImage, setIdImage] = useState(null); // State to hold ID image
    const [loading, setLoading] = useState(false);
    const [isLoginModalOpen, setIsLoginModalOpen] = useState(false); // State to manage login modal
    const [useID, setUseID] = useState(true);
    const [loading2, setLoading2] = useState(false);


    const [verificationCode, setVerificationCode] = useState('');
    const [showVerificationPopup, setShowVerificationPopup] = useState(false);
    const [inputs, setInputs] = useState(["", "", "", ""]);
    const [timeLeft, setTimeLeft] = useState(null);
    const [resendEnabled, setResendEnabled] = useState(false);

    const togglePasswordVisibility = () => {
        setShowPassword((prevShowPassword) => !prevShowPassword);
    };
    // Handle input changes
    const handleChange = (e) => {
        const { name, value } = e.target;

        if (useID && name === 'IdNumber' && value.length === 13) {
            const gender = handleGender(value);
            const dateOfBirth = handleDateOfBirth(value);

            setFormData({
                ...formData,
                [name]: value,
                Gender: gender,
                DateOfBirth: dateOfBirth
            });
        } else if (!useID && name === 'PassportNumber') {
            setFormData({
                ...formData,
                PassportNumber: value
            });
        } else {
            setFormData({ ...formData, [name]: value });
        }
    };


    // Function to extract gender from ID number
    const handleGender = (idNumber) => {
        const genderCode = idNumber.slice(6, 10);
        return parseInt(genderCode, 10) >= 5000 ? 'Male' : 'Female';
    };

    // Function to extract date of birth from ID number
    const handleDateOfBirth = (idNumber) => {
        const year = idNumber.slice(0, 2);
        const month = idNumber.slice(2, 4);
        const day = idNumber.slice(4, 6);
        const fullYear = parseInt(year, 10) >= 0 && parseInt(year, 10) <= 22 ? `20${year}` : `19${year}`;
        const final = `${fullYear}-${month}-${day}`;

        let formated = new Date(final).toLocaleDateString(`en-Us`, { day: "numeric", month: 'long', year: 'numeric' });
        return formated;
    };

    // Handle image file selection
    const handleImageChange = (e) => {
        if (e.target.name === 'FaceImage') {
            setFaceImage(e.target.files[0]); // Set face image
        } else if (e.target.name === 'ID_Image') {
            setIdImage(e.target.files[0]); // Set ID image
        }
    };

    const Toast = Swal.mixin({
        toast: true,
        position: "top",
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.onmouseenter = Swal.stopTimer;
          toast.onmouseleave = Swal.resumeTimer;
        }
      });
     

      const checkIdNumberExists = async (idNumber) => {
        try {
            const response = await fetch('https://backendjaes.passiontrials.co.za/check_idnumber', {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ idNumber }),
            });

            const data = await response.json();
            return data.exists; // Return true if the ID exists, false otherwise
        } catch (error) {
            console.error('Error checking ID number:', error);
            return false; // Default to false in case of an error
        }
    };

    // Handle form submission
    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true); // Start loading



        const emailExists = await checkEmailExists(formData.Email);
        if (emailExists) {
            // setErrors('This email is already registered. Please use another email or log in.');
            Toast.fire({
                icon: "error",
                title: "This email is already registered. Please use another email or log in."
              });
            setLoading(false);
            return; // Prevent moving to the next step
        }

        const idExists = await checkIdNumberExists(formData.IdNumber);
        if (idExists) {
            // setErrors('This email is already registered. Please use another email or log in.');
            Toast.fire({
                icon: "error",
                title: "This ID number is already registered."
              });
            setLoading(false);
            return; // Prevent moving to the next step
        }


        if(formData.IdNumber && useID){


            if (formData.IdNumber && formData.IdNumber.length !== 13) {
                Toast.fire({
                    icon: "error",
                    title: "ID Number must be exactly 13 digits."
                  });
                setLoading(false);
                return;          } 
                
            if(!/^\d*$/.test(formData.IdNumber)){
                Toast.fire({
                    icon: "error",
                    title: "ID Number must contain only numbers."
                  });
                setLoading(false);
                return; // Prevent 
            }
        }




        const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&#])[A-Za-z\d@$!%*?&#]{8,}$/;

        // Check if the passwords match
        if (formData.Password !== formData.ConfirmPassword) {
            // alert("Passwords do not match!");
            Toast.fire({
                icon: "error",
                title: "Passwords do not match!"
              });
            setLoading(false); // Stop loading
            return;
        }

        // Check if the password meets the strength requirements
        if (!passwordRegex.test(formData.Password)) {
            // alert("Password must be at least 8 characters long, include at least one uppercase letter, one lowercase letter, one number, and one special character.");
            Toast.fire({
                icon: "error",
                title: "Password must be at least 8 characters long, include at least one uppercase letter, one lowercase letter, one number, and one special character."
              });
            setLoading(false); // Stop loading
            return;
        }

        const formDataToSend = new FormData();
        Object.keys(formData).forEach((key) => {
            if (key === 'IdNumber' && !useID) {
                return; // Do not append IdNumber if Passport is selected
            }
            if (key === 'PassportNumber' && useID) {
                return; // Do not append PassportNumber if ID is selected
            }
            formDataToSend.append(key, formData[key]);
        });

        if (faceImage) {
            formDataToSend.append('FaceImage', faceImage);
        }

        if (idImage) {
            formDataToSend.append('ID_Image', idImage);
        }

        try {
            const response = await fetch('https://backendjaes.passiontrials.co.za/new_user_register', {
                method: 'POST',
                body: formDataToSend
            });

            const data = await response.json();
            if (response.ok) {
                //alert(data.message);
                // Show login modal after successful registration
               // setIsLoginModalOpen(true);
               setShowVerificationPopup(true);
            } else {
                // alert('Registration failed: ' + data.message);
                Swal.fire({
                    position: "top-end",
                    icon: "error",
                    title: data.message || "Registration failed.",
                    showConfirmButton: false,
                    timer: 5500
                });
            }
        } catch (error) {
            console.error('Error during registration:', error);
            // alert('An error occurred during registration.');
            Swal.fire({
                position: "top-end",
                icon: "error",
                title: "Error during registration",
                showConfirmButton: false,
                timer: 5500
            });
        } finally {
            setLoading(false); // Stop loading after process is complete
        }
    };

    const handleLoginSuccess = () => {
       navigate('/SignupTransport', { 
                
            });
        
    };

    
    const handleResendCode = async () => {
        setLoading2(true);
        try {
            const response = await fetch("https://backendjaes.passiontrials.co.za/resend_code", {
                method: "POST",
                headers: { "Content-Type": "application/json" },
                body: JSON.stringify({ email: formData.Email }),
            });

            const data = await response.json();
            if (response.ok) {
                Swal.fire({
                    title: "Code Resent!",
                    text: data.message,
                    icon: "info",
                    confirmButtonText: "OK",
                });
                //  console.log("Code resent successfully:", data.message);
                setTimeLeft(120);
                setResendEnabled(true);

            } else {
                Swal.fire({
                    title: "Error!",
                    text: 'Failed to resend code' || data.error,
                    icon: "error",
                    confirmButtonText: "Try Again",
                });
            }
        } catch (error) {
            Swal.fire({
                title: "Error!",
                text: "Failed to resend verification code.",
                icon: "error",
                confirmButtonText: "Try Again",
            });
        } finally {
            setLoading2(false);
        }
    };

    useEffect(() => {// 10 jan 2025
        if (timeLeft !== null) {
            if (timeLeft > 0) {
                const timer = setTimeout(() => setTimeLeft((prev) => prev - 1), 1000);
                return () => clearTimeout(timer);
            } else if(timeLeft===0) {
                // Trigger auto-resend and reset the timer
                // handleResendCode();
                setResendEnabled(false);

            }
        }
    }, [timeLeft]);

    useEffect(() => {
        if (showVerificationPopup) {
            // Initialize the timer to 30 seconds when the popup is shown
            setTimeLeft(120);
            setResendEnabled(false);

        } else {
            // Clear the timer when the popup is closed
            setTimeLeft(null);
        }
    }, [showVerificationPopup]);


    const handleInputChange = (index, value) => {// 10 jan 2025
        if (!isNaN(value) && value.length <= 1) {
            const newInputs = [...inputs];
            newInputs[index] = value;
            setInputs(newInputs);

            // Automatically focus the next box
            if (value && index < 3) {
                document.getElementById(`input-${index + 1}`).focus();
            }
        }
    };

  //15 jan 2025
  const handleVerificationCodeSubmit = async (code) => {
    const response = await fetch('https://backendjaes.passiontrials.co.za/verify_code', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ email: formData.Email, codeInput: code, })
    });


    const data = await response.json();

    if (response.ok) {
        Swal.fire({
            position: "top-end",
            icon: "success",
            title: "Verification successful!",
            showConfirmationButton: false,
            timer: 5500
        });

        setShowVerificationPopup(false);
        // navigate('/login')
        setIsLoginModalOpen(true);

    }
    else {

        Swal.fire({
            position: "top-end",
            icon: "error",
            title: "Invalid verification code. Please try again.",
            showConfirmationButton: false,
            timer: 5500
        });
    }
};

const checkEmailExists = async (email) => {
    try {
        const response = await fetch('https://backendjaes.passiontrials.co.za/check_email', {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ email }),
        });

        const data = await response.json();
        console.log('does this exist', data.exists);
        return data.exists; // Return true if the email exists, false otherwise
    } catch (error) {
        console.error('Error checking email existence:', error);
        return false; // Default to false in case of an error
    }
};

const handleVerify = () => {
    const code = inputs.join("");
    handleVerificationCodeSubmit(code);
};

    return (
        <div className="modal-overlay">
            <style>{`/* Set all text color to black */
.modal-content {
    color: #ff5a5f !important; /* Ensures all text is black */
}


/* Ensure input text is black */
.form-group input,
.grape-input-wrapper input,
.verification-input {
    color: #ff5a5f !important; /* Ensures input text is black */
}
`}</style>
              {showVerificationPopup && (
                <div className="verification-popup">
                    <div className="popup-content">
                        <h2>Enter Verification Code</h2>
                        <p>Please enter the 4-digit code sent to your email.</p>
                        {/* <input
                            type="text"
                            placeholder="Enter code"
                            value={codeInput}
                            onChange={(e) => setCodeInput(e.target.value)}
                            maxLength={4}
                        /> */}
                        <div className="input-boxes">
                            {inputs.map((value, index) => (
                                <input
                                    key={index}
                                    id={`input-${index}`}
                                    type="text"
                                    maxLength={1}
                                    value={value}
                                    onChange={(e) => handleInputChange(index, e.target.value)}
                                    onKeyDown={(e) => {
                                        if (e.key === "Backspace" && index > 0 && !value) {
                                            document.getElementById(`input-${index - 1}`).focus();
                                        }
                                    }}
                                    className="verification-input"
                                />
                            ))}
                        </div>

                        {/* <button
                                className="verify-button"
                                onClick={handleVerificationCodeSubmit}
                                disabled={loading2}
                            >
                                {loading2 ? "Verifying..." : "Verify"}
                            </button> */}
                             {!resendEnabled && (
                        <button
                            className="verify-button"
                            onClick={handleVerify}
                            disabled={inputs.some((input) => input === "")} // Disable if inputs are incomplete
                        >
                            Verify
                        </button>
                             )}
                          <br></br>

{resendEnabled && (
                            <button
                                className="resend-button"
                                onClick={handleResendCode}
                                disabled={loading2}
                            >
                                {loading2 ? "Resending..." : "Resend Code"}
                            </button>
                        )}
                        <p className="resend-timer">
                        {resendEnabled
                                ? "Didn't receive a code? Click Resend."
                                : `Resending a new code in ${String(timeLeft).padStart(2, "0")} seconds...`}

                        </p>
                    </div>
                    <style>{`
                 .verification-popup {
          position: fixed;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          background: rgba(0, 0, 0, 0.5);
          display: flex;
          justify-content: center;
          align-items: center;
          z-index: 1000;
        }
        .popup-content {
          background: white;
          padding: 20px;
          border-radius: 10px;
          text-align: center;
          box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
        }
        .input-boxes {
          display: flex;
          justify-content: center;
          gap: 10px;
          margin: 20px 0;
        }
        .verification-input {
          width: 50px;
          height: 50px;
          text-align: center;
          font-size: 20px;
          border: 1px solid #ccc;
          border-radius: 5px;
        }
        .verify-button {
          padding: 10px 20px;
          background-color: #ff5a5f;
          color: white;
          border: none;
          border-radius: 5px;
          cursor: pointer;
          font-weight: bold;
        }
        .resend-timer {
          margin-top: 15px;
          font-size: 14px;
          color: #555;
        }
        .resend-button {
          background: none;
          border: none;
          color: #007bff;
          cursor: pointer;
          text-decoration: underline;
        }
           .resend-button: hover{
          color: #ffffff;

        }
               `}</style>
                </div>
            )}
            <div className="modal-content">
                <h4 style={{marginBottom:'10px'}}>Personal Details</h4>
                <form onSubmit={handleSubmit} encType="multipart/form-data">
                    {/* Group First and Last Name */}
                    <div className="form-group">
                        <input
                            type="text"
                            name="Name"
                            value={formData.Name}
                            onChange={handleChange}
                            placeholder="First Name"
                            required
                        />
                        <input
                            type="text"
                            name="Surname"
                            value={formData.Surname}
                            onChange={handleChange}
                            placeholder="Last Name"
                            required
                        />
                    </div>

                    {/* Group Email and Phone Number */}
                    <div className="form-group">
                        <input
                            type="email"
                            name="Email"
                            value={formData.Email}
                            onChange={handleChange}
                            placeholder="Email"
                            required
                        />
                        <input
                            type="text"
                            name="PhoneNumber"
                            value={formData.PhoneNumber}
                            onChange={handleChange}
                            placeholder="Phone Number"
                            required
                        />
                    </div>

                    <div className="id-passport-form-group">
    <label className="id-passport-label" style={{textAlign:'left'}}>Use ID Number or Passport?</label>
    <select
        onChange={(e) => setUseID(e.target.value === 'ID')}
        value={useID ? 'ID' : 'Passport'}
        className="id-passport-select"
    >
        <option value="ID">ID Number</option>
        <option value="Passport">Passport</option>
    </select>

    <style>{`
        .id-passport-form-group {
            display: flex;
            flex-direction: column;
            margin-bottom: 1em;
        }

        .id-passport-label {
            margin-bottom: 0.5em;
            font-weight: bold;
        }

        .id-passport-select {
            padding: 0.5em;
            font-size: 1em;
        }
    `}</style>
</div>


                    {useID ? (
                        <div className="form-group">
                            <input
                                type="text"
                                name="IdNumber"
                                value={formData.IdNumber}
                                onChange={handleChange}
                                placeholder="e.g 2010145742086"
                                maxLength="13"
                                required
                            />
                        </div>
                    ) : (
                        <div className="form-group">
                            <input
                                type="text"
                                name="PassportNumber"
                                value={formData.PassportNumber}
                                onChange={handleChange}
                                placeholder="Passport Number"
                                required
                            />
                            <input
                                type="text"
                                name="Country"
                                value={formData.Country}
                                onChange={handleChange}
                                placeholder="Country"
                                required
                            />
                        </div>
                    )}
                    {/* Gender and Date of Birth */}
                    <div className="form-group">
                        {useID ? (
                            <>
                                <input
                                    type="text"
                                    name="Gender"
                                    value={formData.Gender}
                                    placeholder="Gender"
                                    readOnly
                                />
                                <input
                                    type="text"
                                    name="DateOfBirth"
                                    value={formData.DateOfBirth}
                                    placeholder="Date of Birth"
                                    readOnly
                                />
                            </>
                        ) : (
                            <>
                               <div className="gender-group-wrapper">
    <label className="gender-label">Gender:</label>
    <div className="gender-button-group">
        <label 
            className={`gender-button ${formData.Gender === 'Male' ? 'selected' : ''}`}
            onClick={() => handleChange({ target: { name: 'Gender', value: 'Male' } })}
        >
            <input 
                type="radio" 
                name="Gender" 
                value="Male" 
                checked={formData.Gender === 'Male'} 
                onChange={handleChange} 
            />
            Male
        </label>
        <label 
            className={`gender-button ${formData.Gender === 'Female' ? 'selected' : ''}`}
            onClick={() => handleChange({ target: { name: 'Gender', value: 'Female' } })}
        >
            <input 
                type="radio" 
                name="Gender" 
                value="Female" 
                checked={formData.Gender === 'Female'} 
                onChange={handleChange} 
            />
            Female
        </label>
    </div>

    <style>{`
        .gender-group-wrapper {
            display: flex;
            flex-direction: column;
            margin-bottom: 1em;
        }

        .gender-label {
            margin-bottom: 0.5em;
            color: #888;
            font-weight: bold;
        }

        .gender-button-group {
            display: flex;
            gap: 1em;
        }

        .gender-button {
            padding: 0.5em 1em;
            border: 2px solid #ccc;
            border-radius: 5px;
            cursor: pointer;
            font-weight: bold;
            text-align: center;
            transition: border-color 0.3s;
        }

        .gender-button input {
            display: none; /* Hide the original radio input */
        }

        .gender-button.selected {
            border-color: #ff5a5f; /* Change border color when selected */
            background-color: #f0f8ff;
        }
    `}</style>
</div>


<div className="dob-group-wrapper">
    <label className="dob-label">Date of Birth:</label>
    <input
        type="date"
        name="DateOfBirth"
        value={formData.DateOfBirth}
        onChange={handleChange}
        className="dob-input"
        required
    />

    <style>{`
        .dob-group-wrapper {
            display: flex;
            flex-direction: column;
            margin-bottom: 1em;
            width: 100%;
        }

        .dob-label {
            margin-bottom: 0.5em;
            font-weight: bold;
            color: #888;
        }

        .dob-input {
            padding: 0.5em 1em;
            border: 2px solid #ccc;
            border-radius: 5px;
            font-size: 1em;
            font-weight: bold;
            text-align: center;
            transition: border-color 0.3s;
            width: 100%;
            box-sizing: border-box;
        }

        .dob-input:focus {
            border-color: #007bff;
            outline: none;
        }
    `}</style>
</div>

                            </>
                        )}
                    </div>
                    <div className="form-group" style={{display:'none'}}>
                        <label>Type of Funding:</label>
                        <input
                            type="text"
                            name="TypeOfFunding"
                            value={formData.TypeOfFunding}
                            readOnly
                        />
                    </div>
                    <div className="form-group">
                        <select
                            name="InstituteName"
                            value={formData.InstituteName}
                            onChange={handleChange}
                            required
                        >
                              <option value="">Select Institution</option>
                            
                                <option value="Ukzn Howard">UKZN Howard</option>
                                <option value="UKZN Medical School">UKZN Medical School</option>
                                <option value="Umbilo College">Umbilo College</option>
                                <option value="Coastal College">Coastal College</option>
                                <option value="Ukzn Westville">Ukzn Westville</option>
                        </select>
                    </div>

                    {/* Group Password and Confirm Password */}
                    <div className="form-group">
    <div className="grape-input-wrapper">
        <input
            type={showPassword ? 'text' : 'password'}
            name="Password"
            value={formData.Password}
            onChange={handleChange}
            placeholder="Password"
            required
        />
        <span onClick={togglePasswordVisibility} className="grape-eye-icon">
            {showPassword ? <EyeClosed size={20} /> : <Eye size={20} />}
        </span>
    </div>

    <div className="grape-input-wrapper">
        <input
            type={showPassword ? 'text' : 'password'}
            name="ConfirmPassword"
            value={formData.ConfirmPassword}
            onChange={handleChange}
            placeholder="Confirm Password"
            required
        />
        <span onClick={togglePasswordVisibility} className="grape-eye-icon">
            {showPassword ? <EyeClosed size={20} /> : <Eye size={20} />}
        </span>
    </div>

    <style>{`
        .grape-input-wrapper {
            position: relative;
            width: 100%;
            margin-bottom: 1em;
        }

        .grape-input-wrapper input {
            width: 100%;
            padding-right: 2em; /* Add padding to avoid overlap with icon */
        }

        .grape-eye-icon {
            position: absolute;
            top: 50%;
            right: 10px;
            transform: translateY(-50%);
            cursor: pointer;
            color: #888;
        }
    `}</style>
</div>


                    {/* Add file input for uploading face image 
                    <div className="form-group">
                        <label>Upload Face Photo:</label>
                        <input
                            type="file"
                            name="FaceImage"
                            accept="image/*"
                            onChange={handleImageChange}
                            required
                        />
                    </div>*/}

                    {/* Add file input for uploading ID image
                    <div className="form-group">
                        <label>Upload ID Image:</label>
                        <input
                            type="file"
                            name="ID_Image"
                            accept="image/*"
                            onChange={handleImageChange}
                            required
                        />
                    </div> */}

                    <div className="form-group">
                        <button type="button" style={{backgroundColor:"#ff5a5f", height:'45px'}} onClick={onClose}>Cancel</button>
                        <button type="submit"  style={{backgroundColor:"#ff5a5f", height:'45px'}} disabled={loading}>
                            {loading ? 'Registering...' : 'Register'}
                        </button>
                    </div>

                    
                    <p style={{
                                fontSize: "14px",
                                marginTop: '10px',
                                textAlign: "center"
                            }}>Already have an account? <a  onClick={() => setIsLoginModalOpen(true)} className="login-link">Login</a></p>

                </form>

                {/* Show the login modal after clicking the Login button */}
                {isLoginModalOpen && (
                    <ReserveLoginModal
                    onClose={() => setIsLoginModalOpen(false)}

                    />
                )}
            </div>
        </div>
    );
}

export default ReserveRegistrationModal;
