import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import './transport.css'
import { useAuth } from './AuthProvider';

const Transport = () => {
    const [selectedBuilding, setSelectedBuilding] = useState("");
    const [institutions, setInstitutions] = useState(["Ukzn Howard", "UKZN Medical School", "Umbilo College", "Coastal College", "Ukzn Westville"]);
    const [selectedInstitution, setSelectedInstitution] = useState(""); // For selected institution
    const [userInstitute, setUserInstitute] = useState(""); // To store the fetched institute name

    const [selectedDirection, setSelectedDirection] = useState("");
    const [availableTimes, setAvailableTimes] = useState([]);
    const [selectedTime, setSelectedTime] = useState(""); // Track selected time
    const [bookedTransports, setBookedTransports] = useState([]); // To store the user's booked transports
    const [reload, setReload] = useState(false);
    const { isLoggedIn, logout, role, userId } = useAuth();
    const [currentWeekDates, setCurrentWeekDates] = useState([]); // To store the week dates
    const [selectedDate, setSelectedDate] = useState(null); // Initialize with null
    const [loading, setLoading] = useState(false); // Loader state for the button
    const [isToday, setIsToday] = useState(false); // New state to track if selected date is today
    const [userFirstName, setUserFirstName] = useState(""); // State for first name
    const [userLastName, setUserLastName] = useState(""); // State for last name
    
    const location = useLocation();
    const { buildingName } = location.state || {}; // Assuming userId is passed through location.state

    useEffect(() => {
        if (buildingName) {
            setSelectedBuilding(buildingName);
        }
    }, [buildingName]);

    // Fetch the user's booked transport data
    useEffect(() => {
        if (userId) {
            fetch(`https://backendjaes.passiontrials.co.za/api/transport/${userId}`)
                .then(response => response.json())
                .then(data => setBookedTransports(data))
                .catch(error => console.error('Error fetching transport data:', error));
        }
    }, [userId, reload]); 
    
    // Function to handle transport cancellation
 // Function to handle transport cancellation with confirmation
const handleCancelTransport = (bookingId) => {
    // Show confirmation dialog
    const isConfirmed = window.confirm("Are you sure you want to cancel this trip?");
    
    // If the user confirmed, proceed with cancellation
    if (isConfirmed) {
        fetch(`https://backendjaes.passiontrials.co.za/api/transport/cancel/${bookingId}`, {
            method: 'DELETE', // or 'PUT' if you are marking it as canceled
            headers: { 'Content-Type': 'application/json' },
        })
            .then(response => response.json())
            .then(data => {
                alert('Transport canceled successfully!');
                setReload(prev => !prev); // Toggle reload state to force a rerender
            })
            .catch(error => console.error('There was an error canceling the transport!', error));
    }
};

    // Fetch data when either userId or reload changes
   
        useEffect(() => {
            if (isLoggedIn) {
                fetch(`https://backendjaes.passiontrials.co.za/user_reserve/${userId}`, {
                    headers: { "Content-Type": "application/json" }
                })
                .then(response => {
                    if (!response.ok) {
                        throw new Error('Failed to fetch user details');
                    }
                    return response.json();
                })
                .then(data => {
                    if (data && data[0]) {
                        setUserInstitute(data[0].InstituteName); // Set fetched InstituteName
                        setUserFirstName(data[0].Name); // Set first name
                        setUserLastName(data[0].Surname); // Set last name
                        setSelectedInstitution(data[0].InstituteName); // Pre-select institution
                    } else {
                        console.log('No user full details found');
                    }
                })
                .catch(err => {
                    console.error('Error fetching user details:', err);
                });
            } else {
                setUserInstitute(""); // Reset institute name when logged out
                setSelectedInstitution(""); // Reset selected institution
                setUserFirstName(""); // Reset first name
                setUserLastName(""); // Reset last name
            }
        }, [userId, isLoggedIn]);
    

    


    // Function to update available times based on institution and direction
    useEffect(() => {
        let times = [];
        if (selectedInstitution === "Ukzn Howard" && selectedDirection === "To Home") {
            times = ["07:30", "10:00", "12:00"];
        } else if (selectedInstitution === "Ukzn Howard" && selectedDirection === "To Institution") {
            times = ["12:30", "14:30", "17:30", "22:00"];
        } else if (selectedInstitution === "UKZN Medical School" && selectedDirection === "To Home") {
            times = ["07:30", "10:00", "12:00"];
        } else if (selectedInstitution === "UKZN Medical School" && selectedDirection === "To Institution") {
            times = ["12:30", "14:30", "17:30", "22:00"];
        } else if (selectedInstitution === "Umbilo College" && selectedDirection === "To Home") {
            times = ["07:30"];
        } else if (selectedInstitution === "Umbilo College" && selectedDirection === "To Institution") {
            times = ["14:00"];
        } else if (selectedInstitution === "Coastal College" && selectedDirection === "To Home") {
            times = ["07:30"];
        } else if (selectedInstitution === "Coastal College" && selectedDirection === "To Institution") {
            times = ["14:00"];
        } else if (selectedInstitution === "Ukzn Westville" && selectedDirection === "To Home") {
            times = ["07:30", "10:00", "12:00"];
        } else if (selectedInstitution === "Ukzn Westville" && selectedDirection === "To Institution") {
            times = ["12:30", "14:30", "17:30", "22:00"];
        }
    
    
        setAvailableTimes(times);
        console.log("Available Times:", times); // Log times to check
        setSelectedTime(""); // Reset selected time whenever options change
    }, [selectedInstitution, selectedDirection]);
    
    

    const handleBuildingChange = (e) => {
        setSelectedBuilding(e.target.value);
        console.log('Selected Building:', e.target.value);
    };

    const handleInstitutionChange = (e) => {
        setSelectedInstitution(e.target.value);
        console.log('Selected Institution:', e.target.value);
    };
    
    const handleDirectionChange = (e) => {
        setSelectedDirection(e.target.value);
        console.log('Selected Direction:', e.target.value);
    };
    

    const handleDateChange = (date) => {
        setSelectedDate(date);
        console.log('Selected Date:', date);
    };

    const handleTimeSelect = (time) => {
        setSelectedTime(time); // Set the selected time
    };
// Function to check if selected date is today's date
const checkIfToday = (date) => {
    const today = new Date();
    return date.getDate() === today.getDate() &&
        date.getMonth() === today.getMonth() &&
        date.getFullYear() === today.getFullYear();
};
const handleSaveTransport = () => {
    if (isToday) {
        alert("You cannot book transport for today's date.");
        return;
    }
    const transportData = {
        userId,
        firstName: userFirstName, // Pass the first name
        lastName: userLastName, // Pass the last name
        buildingName: selectedBuilding,
        institution: selectedInstitution,
        direction: selectedDirection,
        transportDate: selectedDate.toISOString().split('T')[0],
        transportTime: selectedTime,
    };

    setLoading(true); // Set loading to true when saving transport

    fetch('https://backendjaes.passiontrials.co.za/api/transport', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(transportData),
    })
    .then(response => response.json())
    .then(data => {
        alert('Transport data saved successfully!');
        setReload(prev => !prev); // Toggle reload state to force a rerender
        setLoading(false); // Set loading to false after success
    })
    .catch(error => {
        console.error('There was an error saving the transport data!', error);
        setLoading(false); // Set loading to false after error
    });
};

    

    useEffect(() => {
        const today = new Date();
        const dayOfWeek = today.getDay(); // 0 = Sunday, 6 = Saturday
    
        // Calculate the start of the week (Monday)
        const startOfWeek = new Date(today);
        startOfWeek.setDate(today.getDate() - dayOfWeek + 1); // Adjust to start from Monday (day 1)
    
        // Create an array of dates from Monday to Friday
        const weekDates = [];
        for (let i = 0; i < 5; i++) { // Monday to Friday
            const date = new Date(startOfWeek);
            date.setDate(startOfWeek.getDate() + i);
            weekDates.push(date);
        }
    
        setCurrentWeekDates(weekDates); // Set the week dates
    }, []);
    
   
    

     // Function to filter out dates that are not in the current week and exclude weekends
     const isWeekdayInCurrentWeek = (date) => {
        const today = new Date();
        const startOfWeek = new Date(today.setDate(today.getDate() - today.getDay() + 1)); // Start from Monday
        const endOfWeek = new Date(today.setDate(startOfWeek.getDate() + 4)); // End on Friday (Monday + 4)

        const day = date.getDay();
        const isWeekday = day !== 0 && day !== 6; // Exclude Sunday (0) and Saturday (6)

        return isWeekday && date >= startOfWeek && date <= endOfWeek;
    };

    
    // Format date in display as "Tues, 2 July"
    const formatDate = (date) => {
        const validDate = new Date(date); // Ensure the date is a Date object
        if (isNaN(validDate)) return "Invalid Date"; // Handle invalid dates
        const options = { weekday: 'short', month: 'short', day: 'numeric' };
        return validDate.toLocaleDateString('en-US', options);
    };
    
   // Handle date selection and ensure it's within the allowed range
   const handleDateSelect = (date) => {
    setSelectedDate(date); // Set the selected date
    setIsToday(checkIfToday(date)); // Check if the selected date is today
    console.log('Selected Date:', date);
};

    

    // Compare transport date with today's date + 2 days to determine status
    const getStatus = (dateString) => {
        const today = new Date();
        const datePlusTwo = new Date();
        datePlusTwo.setDate(today.getDate() + 2);

        const transportDate = new Date(dateString);

        // Mark as "Inactive" if the transport date is equal to today's date + 2 days
        if (transportDate.getDate() === datePlusTwo.getDate() &&
            transportDate.getMonth() === datePlusTwo.getMonth() &&
            transportDate.getFullYear() === datePlusTwo.getFullYear()) {
            return "Inactive";
        }

        // Otherwise, it is "Active"
        return "Active";
    };

    return (
        <div className="transport-container">
            {/* Transport Booking Form */}
            <div style={{paddingRight:'10px'}}>
                <h2>Transport Booking</h2>
                <div className='mainslect'>
    {/* Building Dropdown */}
    <div>
        <select
            id="building"
            value={selectedBuilding}
            onChange={handleBuildingChange}
            disabled
              className='selectpro'
            style={{ backgroundColor: "#ff5a5f", color: 'white', border: "1px solid #ccc", textAlign:'center', alignContent:'center', borderRadius:'30px' }}
        >
            <option value="">Select Home</option>
            {selectedBuilding && <option value={selectedBuilding}>{selectedBuilding}</option>}
        </select>
    </div>

    {/* Institution Dropdown */}
    <div >
        <select
            id="institution"
            value={userInstitute}
            disabled
            onChange={(e) => setUserInstitute(e.target.value)}
            className='selectpro'
            style={{ backgroundColor: "#ff5a5f", color: 'white', border: "1px solid #ccc", textAlign:'center', borderRadius:'30px'}}
        >
            <option value="">Select Institution</option>
                                <option value="Ukzn Howard">UKZN Howard</option>
                                <option value="UKZN Medical School">UKZN Medical School</option>
                                <option value="Umbilo College">Umbilo College</option>
                                <option value="Coastal College">Coastal College</option>
                                <option value="Ukzn Westville">Ukzn Westville</option>
        </select>
    </div>
</div>


<label style={{display:'flex'}} htmlFor="direction" >Direction: </label>
<div style={{display:'flex'}}>
    
    <div>
    <label style={{ alignItems: 'center', cursor: 'pointer', marginRight: '20px' }}>
        <input
            type="radio"
            name="direction"
            value="To Home"
            checked={selectedDirection === "To Home"}
            onChange={handleDirectionChange}
            style={{ marginRight: '10px' }} 
            
        />
        <span  style={{
            display: 'inline-block',
            padding: '10px 20px',
            backgroundColor: selectedDirection === "To Home" ? '#ff5a5f' : '#f0f0f0',
            color: selectedDirection === "To Home" ? '#fff' : '#000',
            border: '1px solid #ccc',
            borderRadius: '20px',
            cursor: 'pointer',
            transition: 'background-color 0.3s'
        }}>
            To institution
        </span>
    </label>
    
    <label style={{ alignItems: 'center', cursor: 'pointer' }}>
        <input
            type="radio"
            name="direction"
            value="To Institution"
            checked={selectedDirection === "To Institution"}
            onChange={handleDirectionChange}
            style={{ marginRight: '10px' }} 
        />
        <span style={{
            display: 'inline-block',
            padding: '10px 20px',
            backgroundColor: selectedDirection === "To Institution" ? '#ff5a5f' : '#f0f0f0',
            color: selectedDirection === "To Institution" ? '#fff' : '#000',
            border: '1px solid #ccc',
            borderRadius: '20px',
            cursor: 'pointer',
            transition: 'background-color 0.3s'
        }}>
            From Institution
        </span>
    </label>
</div>

</div>



<div className="date-selection-container">
    <label  style={{display:'flex'}}>Select Date:</label>
    <div className="date-selection-buttons">
        {currentWeekDates.map((date, index) => (
            <button
                key={index}
                onClick={() => handleDateSelect(date)}
                className={`date-button ${selectedDate && selectedDate.getTime() === date.getTime() ? 'selected' : ''}`}
            >
                {formatDate(date)}
            </button>
        ))}
    </div>
</div>

              {/* Time Buttons */}
{/* Time Buttons */}
{availableTimes.length > 0 && (
    <div  className="date-selection-container">
        <label  style={{display:'flex'}}>Select Time:</label>
        <div className="date-selection-buttons">
            {availableTimes.map((time, index) => (
                <button
                    key={index}
                    onClick={() => handleTimeSelect(time)}
                    style={{
                        padding: '10px 20px',
                        backgroundColor: selectedTime === time ? '#ff5a5f' : '#f0f0f0', // Highlight selected time
                        color: selectedTime === time ? '#fff' : '#000',
                        border: '1px solid #ccc',
                        borderRadius: '30px',
                        cursor: 'pointer'
                    }}
                >
                    {time}
                </button>
            ))}
        </div>
    </div>
)}


                {/* Save Button */}
                {selectedTime && (
                    <div >
                        <button
    onClick={handleSaveTransport}
    disabled={loading} // Disable the button when loading
    style={{
        padding: '10px 20px',
        marginTop:'10px',
        backgroundColor: loading ? '#ccc' : '#ff5a5f', // Change background color when loading
        color: '#fff',
        cursor: loading ? 'not-allowed' : 'pointer', // Change cursor when loading
        opacity: loading ? 0.7 : 1, // Add opacity effect when loading
    }}
>
    {loading ? 'Booking...' : 'Book trip'} {/* Change button text when loading */}
</button>

                    </div>
                )}

                {/* Display selected time */}
                {selectedTime && (
                    <div >
                        <p>Selected Time: <strong>{selectedTime}</strong></p>
                    </div>
                )}

                {/* Display booked transports */}
                <div style={{ padding: '20px', marginTop: '109px' }}>
                    <h2>Your Trips</h2>
                    <p>to cancel your trip click on the trip you want to cancel</p>

                    {Array.isArray(bookedTransports) && bookedTransports.length > 0 ? (
                        <div >
                            <table style={{ width: '100%', borderCollapse: 'collapse', borderRadius: '5px', overflow: 'hidden' }}>
                                <thead>
                                    <tr style={{ backgroundColor: '#f8f8f8', textAlign: 'left' }}>
                                        <th style={{ padding: '10px' }}>Date</th>
                                        <th style={{ padding: '10px' }}>Time</th>
                                        <th style={{ padding: '10px' }}>Direction</th>
                                        <th style={{ padding: '10px' }}>Institution</th>
                                        <th style={{ padding: '10px' }}>Building</th>
                                        {/* <th style={{ padding: '10px' }}>Status</th> */}
                                     
                                    </tr>
                                </thead>
                                <tbody>
                                    {bookedTransports.map((booking, index) => (
                                        <tr     onClick={() => handleCancelTransport(booking.id)} key={index} style={{ borderBottom: '1px solid #ccc', textAlign: 'left' }}>
                                            <td style={{ padding: '10px' }}>{formatDate(booking.transport_date)}</td>
                                            <td style={{ padding: '10px' }}>{booking.transport_time}</td>
                                            <td style={{ padding: '10px' }}>{booking.direction}</td>
                                            <td style={{ padding: '10px' }}>{booking.institution}</td>
                                            <td style={{ padding: '10px' }}>{booking.building_name}</td>
                                            {/* <td style={{ padding: '10px' }}>{getStatus(booking.transport_date)}</td> */}
                                            
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    ) : (
                        <p>No transports booked yet.</p>
                    )}
                </div>
            </div>

            {/* Transport T&Cs */}
            <div className="transport-tc" style={{  paddingLeft: '20px', borderLeft: '1px solid #ccc', alignItems:'left', textAlign:'left' }}>
                <h3>Transport T&Cs</h3>
                <ul>
                    <li>- Please arrive at the pick-up location on campus before the time.</li>
                    <li>- Transport will wait at the gate on campus for 10 minutes. If you fail to arrive before the transport leaves, you’ll have to wait for the next pick-up time or find your own way home.</li>
                    <li>- No eating in the car.</li>
                    <li>- Radio will stay switched off. No music requests are accepted.</li>
                    <li>- No speakers are to be played in the transport.</li>
                    <li>- Please limit your interaction with the drivers.</li>
                    <li>- If you have any issues/complaints, please log them on the service box on the website.</li>
                </ul>
            </div>
        </div>
    );
}

export default Transport;
