import React, { useRef, useState, useEffect } from "react";
import { Route, Routes, Link, Outlet, useNavigate } from "react-router-dom";
import MyApartment from "./myApartment";
import { useAuth } from "./AuthProvider";
import Countdown from "react-countdown";
import "./modal.css";
import "./userdashboard.css";
import "./roundloader.css";
import L from "leaflet";
import "leaflet/dist/leaflet.css";
import Swal from "sweetalert2";
import { PDFDocument, rgb } from "pdf-lib"; // Ensure this is imported

function useOutsideAlerter(ref, onOutsideClick) {
  useEffect(() => {
    function handleClickOutside(event) {
      if (ref.current && !ref.current.contains(event.target)) {
        onOutsideClick();
      }
    }
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [ref, onOutsideClick]);
}
const PaymentModal = ({ isVisible, onClose, onSubmit }) => {
  const [customAmount, setCustomAmount] = useState("");
  const [selectedAmount, setSelectedAmount] = useState(null);
  const [isCustom, setIsCustom] = useState(false);

  const handleAmountChange = (e) => {
    setCustomAmount(e.target.value);
    // setSelectedAmount('');  // Clear predefined amounts if custom is entered
  };

  const handleSubmit = () => {
    const amount = isCustom ? customAmount : selectedAmount;
    if (!amount) {
      alert("Please select or enter an amount.");
      return;
    }
    onSubmit(amount); // Pass the amount to the parent component
  };

  const handleAmountSelect = (amount) => {
    setSelectedAmount(amount);
    setIsCustom(false); // Deselect custom option if a preset amount is chosen
  };

  const handleCustomSelect = () => {
    setIsCustom(true); // Show custom input when "Custom" is selected
    setSelectedAmount(null); // Clear preset selection
  };

  if (!isVisible) return null;

  return (
    <div className="modal-overlay">
      <div className="modal-content">
        <span className="close" onClick={onClose}>
          &times;
        </span>
        <h2>Select or Enter Payment Amount</h2>
        <div className="amount-options">
          <button onClick={() => handleAmountSelect(500)}>R500</button>
          <button onClick={() => handleAmountSelect(1000)}>R1000</button>
          <button onClick={() => handleAmountSelect(1500)}>R1500</button>
          <button onClick={() => handleAmountSelect(2000)}>R2000</button>
          <button onClick={handleCustomSelect}>Custom</button>
        </div>
        {isCustom && (
          <div className="custom-amount">
            <input
              type="number"
              placeholder="Enter amount"
              value={customAmount}
              onChange={handleAmountChange}
            />
          </div>
        )}
        <button onClick={handleSubmit} className="submit-btn">
          Submit
        </button>
      </div>
    </div>
  );
};
const Loader = () => {
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100vh", // This ensures it takes the full viewport height
      }}
    >
      <div class="loader">
        <div class="circle">
          <div class="dot"></div>
          <div class="outline"></div>
        </div>
        <div class="circle">
          <div class="dot"></div>
          <div class="outline"></div>
        </div>
        <div class="circle">
          <div class="dot"></div>
          <div class="outline"></div>
        </div>
        <div class="circle">
          <div class="dot"></div>
          <div class="outline"></div>
        </div>
      </div>
      <svg xmlns="http://www.w3.org/2000/svg" version="1.1">
        <defs>
          <filter id="goo">
            <feGaussianBlur
              in="SourceGraphic"
              stdDeviation="10"
              result="blur"
            ></feGaussianBlur>
            <feColorMatrix
              in="blur"
              mode="matrix"
              values="1 0 0 0 0  0 1 0 0 0  0 0 1 0 0  0 0 0 18 -7"
              result="goo"
            ></feColorMatrix>
            <feBlend in="SourceGraphic" in2="goo"></feBlend>
          </filter>
        </defs>
      </svg>
    </div>
  );
};
const CheckoutModal = ({ isVisible, onClose, onConfirm }) => {
  if (!isVisible) return null;

  return (
    <div className="modal-overlay">
      <div className="modal-content">
        <h2>Confirm Checkout</h2>
        <p>Are you sure you want to checkout?</p>
        <div className="modal-buttons">
          <button onClick={onConfirm} className="confirm-btn">
            Confirm
          </button>
          <button onClick={onClose} className="cancel-btn">
            Cancel
          </button>
        </div>
      </div>
    </div>
  );
};
// Modal for uploading missing images
const ImageUploadModal = ({ isVisible, onClose, onUpload }) => {
  const [faceImage, setFaceImage] = useState(null);
  const [idImage, setIdImage] = useState(null);

  const handleImageChange = (e) => {
    if (e.target.name === "FaceImage") {
      setFaceImage(e.target.files[0]);
    } else if (e.target.name === "ID_Image") {
      setIdImage(e.target.files[0]);
    }
  };

  const handleSubmit = () => {
    if (!faceImage || !idImage) {
      alert("Please upload both Face and ID Images.");
      return;
    }

    onUpload(faceImage, idImage); // Pass images to the parent component
    onClose(); // Close the modal after submission
  };

  if (!isVisible) return null;

  return (
    <div className="unique-modal-overlay-12345">
      <div className="unique-modal-content-12345">
        <button onClick={onClose} className="unique-close-btn-12345">
          ✖
        </button>
        <h2 className="unique-modal-title-12345" style={{ textAlign: "left" }}>
          Upload Missing Images
        </h2>
        <div className="unique-form-group-12345">
          <label htmlFor="FaceImage" className="unique-label-12345">
            Upload selfie
          </label>
          <input
            name="FaceImage"
            type="file"
            onChange={handleImageChange}
            className="unique-input-12345"
          />
        </div>
        <div className="unique-form-group-12345">
          <label htmlFor="ID_Image" className="unique-label-12345">
            Upload ID Image
          </label>
          <input
            name="ID_Image"
            type="file"
            onChange={handleImageChange}
            className="unique-input-12345"
          />
        </div>
        <button onClick={handleSubmit} className="unique-submit-btn-12345">
          Upload
        </button>
      </div>
      <style>
        {`
      .unique-modal-overlay-12345 {
        position: fixed;
        top: 0;
        left: 0;
        width: 100vw;
        height: 100vh;
        background: rgba(0, 0, 0, 0.5);
        display: flex;
        justify-content: center;
        align-items: center;
        z-index: 9999;
      }

      .unique-modal-content-12345 {
        position: relative;
        background: #fff;
        padding: 2rem;
        border-radius: 8px;
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
        max-width: 400px;
        width: 100%;
        text-align: center;
      }

        .unique-close-btn-12345 {
      position: absolute;
      top: 10px;
      right: 10px;
      background: transparent;
      border: none;
      font-size: 1.2rem; /* Slightly smaller font size */
      cursor: pointer;
      color: #333;
      width: 32px; /* Set width for round shape */
      height: 32px; /* Set height for round shape */
      border-radius: 50%; /* Make it round */
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .unique-close-btn-12345:hover {
      background: rgba(0, 0, 0, 0.1); /* Add a subtle hover effect */
    }

      .unique-modal-title-12345 {
        margin-bottom: 1.5rem;
        font-size: 1.5rem;
        color: #333;
      }

      .unique-form-group-12345 {
        margin-bottom: 1rem;
        text-align: left;
      }

      .unique-label-12345 {
        display: block;
        margin-bottom: 0.5rem;
        font-size: 0.875rem;
        color: #666;
      }

      .unique-input-12345 {
        width: 100%;
        padding: 0.5rem;
        border: 1px solid #ccc;
        border-radius: 4px;
        font-size: 1rem;
      }

      .unique-submit-btn-12345 {
        padding: 0.75rem 1.5rem;
        background: #ff5a5f;
        color: #fff;
        border: none;
        border-radius: 4px;
        font-size: 1rem;
        cursor: pointer;
        margin-top: 1rem;
      }

      .unique-submit-btn-12345:hover {
        background: #ff5a5f;
      }
    `}
      </style>
    </div>
  );
};

const AddressModal = ({
  isVisible,
  onClose,
  onSubmit,
  userData,
  selectedPrice,
  uzer,
  apartment,
}) => {
  const [address, setAddress] = useState("");
  const [suggestions, setSuggestions] = useState([]);
  const [selectedLatLng, setSelectedLatLng] = useState(null);
  const ukznHowardCoordinates = L.latLng(-29.8667, 30.9784); // Coordinates for Ukzn Howard

  console.log("Apartment to PDF", uzer);
  const handleAddressChange = async (e) => {
    const query = e.target.value;
    setAddress(query);

    if (query.length > 2) {
      const apiKey = "f4c51dd4a35e4aeda050b373039cfc83"; // Replace with your API key
      const url = `https://api.geoapify.com/v1/geocode/autocomplete?text=${encodeURIComponent(
        query
      )}&filter=countrycode:za&limit=5&apiKey=${apiKey}`;

      try {
        const response = await fetch(url);
        const data = await response.json();
        const filteredSuggestions = data.features.map((feature) => {
          const { housenumber, street, suburb } = feature.properties;
          const displayName = `${housenumber || ""} ${street || ""}, ${
            suburb || ""
          }`.trim();
          return {
            ...feature,
            display_name: displayName,
            lat: feature.geometry.coordinates[1],
            lon: feature.geometry.coordinates[0],
          };
        });
        setSuggestions(filteredSuggestions);
      } catch (error) {
        console.error("Error fetching suggestions:", error);
        setSuggestions([]);
      }
    } else {
      setSuggestions([]);
    }
  };

  const handleSuggestionClick = (suggestion) => {
    setAddress(suggestion.display_name);
    setSelectedLatLng(L.latLng(suggestion.lat, suggestion.lon));
    setSuggestions([]);
  };
  const getShortRoomIdentifier = (address) => {
    return address.split(",")[0]; // Extracts the first part of the address
  };

  const generatePDF = async () => {
    if (!uzer || !address) {
      alert("User data or address is missing. Cannot generate PDF.");
      return;
    }
    // Check if distance exceeds 2.5 km
    if (selectedLatLng) {
      const distance = selectedLatLng.distanceTo(ukznHowardCoordinates);
      if (distance > 2500) {
        Swal.fire({
          title: "Distance Alert",
          text: "The selected address is more than 2.5 km away from Ukzn Howard University. PDF cannot be downloaded.",
          icon: "warning",
        });
        return; // Prevent PDF download
      }
    }
    try {
      const response = await fetch(
        "https://jaesstudenthomes.co.za/2STUDENT%20HOMES%20BOOKING%20FORM%202025.pdf"
      );
      if (!response.ok) {
        throw new Error("Failed to fetch PDF template.");
      }

      const pdfBytes = await response.arrayBuffer();
      const pdfDoc = await PDFDocument.load(pdfBytes);
      const firstPage = pdfDoc.getPages()[0];

      // Utility function to format name
      const formatName = (fullName) => {
        const nameParts = fullName.split(" ");
        const initials = nameParts
          .slice(0, -1)
          .map((name) => name[0].toUpperCase() + ".")
          .join(""); // Get initials for all but the last part
        const surname = nameParts[nameParts.length - 1]; // The last part is the surname
        return `${initials} ${surname}`;
      };
      const totalCost = 450.0; // Define the amount
      const formattedTotal = `R${totalCost.toFixed(2)}`;

      // Draw the total amount on the PDF
      firstPage.drawText(formattedTotal, {
        x: 405,
        y: 35,
        size: 10,
        color: rgb(0, 0, 0),
      });
      firstPage.drawText(getShortRoomIdentifier(address), {
        x: 405,
        y: 50,
        size: 9,
        color: rgb(0, 0, 0),
      });
      // Apply the formatName function
      const formattedName = formatName(`${uzer.Name} ${uzer.Surname}`);

      // Populate the PDF with user data
      firstPage.drawText(formattedName, {
        x: 170,
        y: 65,
        size: 12,
        color: rgb(0, 0, 0),
      });
      firstPage.drawText(`${uzer.Name} ${uzer.Surname}`, {
        x: 230,
        y: 535,
        size: 12,
        color: rgb(0, 0, 0),
      });
      firstPage.drawText(uzer.IdNumber || uzer.PassportNumber, {
        x: 230,
        y: 510,
        size: 12,
        color: rgb(0, 0, 0),
      });
      firstPage.drawText(getShortRoomIdentifier(address), {
        x: 180,
        y: 790,
        size: 12,
        color: rgb(0, 0, 0),
      });
      firstPage.drawText("X", {
        x: 385,
        y: 490,
        size: 12,
        color: rgb(0, 0, 0),
      });
      firstPage.drawText(getShortRoomIdentifier(address) || "N/A", {
        x: 230,
        y: 465,
        size: 12,
        color: rgb(0, 0, 0),
      });
      firstPage.drawText(uzer.Email, {
        x: 265,
        y: 440,
        size: 12,
        color: rgb(0, 0, 0),
      });
      firstPage.drawText(uzer.PhoneNumber, {
        x: 255,
        y: 415,
        size: 12,
        color: rgb(0, 0, 0),
      });
      firstPage.drawText(new Date().toLocaleDateString("en-GB"), {
        x: 170,
        y: 85,
        size: 12,
        color: rgb(0, 0, 0),
      });

      // Save the PDF and download
      const pdfDataUri = await pdfDoc.saveAsBase64({ dataUri: true });
      const link = document.createElement("a");
      link.href = pdfDataUri;
      link.download = `Booking_Form_${Date.now()}.pdf`;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (error) {
      console.error("Error generating PDF:", error);
    }
  };

  const handleSubmit = () => {
    if (!address.trim()) {
      alert("Please enter your address.");
      return;
    }

    if (selectedLatLng) {
      const distance = selectedLatLng.distanceTo(ukznHowardCoordinates);
      if (distance > 2500) {
        Swal.fire({
          title: "Distance Alert",
          text: "The selected address is more than 2.5 km away from Ukzn Howard University.",
          icon: "warning",
        });
        return;
      }
    }

    onSubmit(address);
    onClose();
  };

  if (!isVisible) return null;

  return (
    <>
      <div className="lightning-modal-overlay">
        <div className="lightning-modal-content">
          <h2 className="modal-heading">Enter Your Address</h2>
          <input
            type="text"
            placeholder="Enter your address"
            value={address}
            onChange={handleAddressChange}
            className="address-input"
          />
          <ul className="suggestions-list">
            {suggestions.map((suggestion, index) => (
              <li
                key={index}
                onClick={() => handleSuggestionClick(suggestion)}
                className="suggestion-item"
              >
                {suggestion.display_name}
              </li>
            ))}
          </ul>

          <div className="payment-info">
            <h3 className="payment-heading">Payment Details</h3>
            <div className="payment-row">
              <span className="payment-label">Admin fee:</span>
              <span className="payment-value">
                {apartment?.BuildingName ? "R250.00" : "R450.00"}
              </span>
            </div>
            <div className="payment-row">
              <span className="payment-label">Per Month:</span>
              <span className="payment-value">R550.00</span>
            </div>
          </div>

          <div className="action-buttons">
            <button onClick={onClose} className="cancel-btn">
              Cancel
            </button>
            <button onClick={handleSubmit} className="submit-btn">
              Pay Now
            </button>
          </div>
          <div className="action-buttons">
            <button onClick={generatePDF} className="eft-btn">
              Pay via EFT
            </button>
          </div>
        </div>
      </div>

      <style>{`
    .lightning-modal-overlay {
      background-color: rgba(0, 0, 0, 0.7);
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      z-index: 1000;
    }
    
    .lightning-modal-content {
      background-color: #fff;
      width: 400px;
      padding: 20px;
      border-radius: 12px;
      font-family: 'Inter', sans-serif;
      box-shadow: 0 8px 24px rgba(0, 0, 0, 0.15);
    }
    
    .modal-heading {
      font-size: 18px;
      font-weight: 600;
      margin-bottom: 15px;
      color: #333;
      text-align: center;
    }
    
    .address-input {
      width: 100%;
      padding: 10px;
      border: 1px solid #e0e0e0;
      border-radius: 8px;
      margin-bottom: 12px;
      font-size: 13px;
      box-sizing: border-box;
      transition: border-color 0.3s ease;
    }
    
    .address-input:focus {
      border-color: #ff5a5f;
      outline: none;
    }
    
    .suggestions-list {
      list-style: none;
      padding: 0;
      margin: 0 0 15px 0;
      border: 1px solid #e0e0e0;
      border-radius: 8px;
      overflow: hidden;
      max-height: 120px;
      overflow-y: auto;
    }
    
    .suggestion-item {
      padding: 10px;
      border-bottom: 1px solid #e0e0e0;
      cursor: pointer;
      font-size: 13px;
      transition: background-color 0.3s ease;
    }
    
    .suggestion-item:last-child {
      border-bottom: none;
    }
    
    .suggestion-item:hover {
      background-color: #f5f5f5;
    }
    
    .payment-info {
      background-color: #f9f9f9;
      padding: 15px;
      border-radius: 8px;
      margin-bottom: 15px;
      font-size: 13px;
      color: #333;
      border: 1px solid #e0e0e0;
    }
    
    .payment-heading {
      font-size: 14px;
      font-weight: 600;
      margin-bottom: 12px;
      text-align: center;
    }
    
    .payment-row {
      display: flex;
      justify-content: space-between;
      margin-bottom: 8px;
    }
    
    .payment-label {
      font-weight: 500;
      color: #666;
    }
    
    .payment-value {
      font-weight: 500;
      color: #333;
    }
    
    .action-buttons {
      display: flex;
      justify-content: space-between;
      gap: 8px;
      margin-bottom: 8px;
    }
    
    .cancel-btn,
    .submit-btn,
    .eft-btn {
      padding: 10px 16px;
      font-size: 13px;
      border: none;
      border-radius: 8px;
      cursor: pointer;
      transition: background-color 0.3s ease;
      flex: 1;
      text-align: center;
    }
    
    .cancel-btn {
      background-color: #e0e0e0;
      color: #333;
    }
    
    .cancel-btn:hover {
      background-color: #ccc;
    }
    
    .submit-btn {
      background-color: #ff5a5f;
      color: #fff;
    }
    
    .submit-btn:hover {
      background-color: #e54a4f;
    }
    
    .eft-btn {
      background-color: #ff5a5f;
      color: #fff;
    }
    
    .eft-btn:hover {
      background-color: #e54a4f;
    }
  `}</style>
    </>
  );
};

function UserDashboard() {
  const [bookings, setBookings] = useState([]);
  const [payments, setPayments] = useState([]);
  const [profile, setProfile] = useState({});
  const [showDropdown, setShowDropdown] = useState(false);
  const [fetchid, setFetchId] = useState(null);
  const { userId } = useAuth();
  const [error, setError] = useState("");
  const [apartment, setApartment] = useState(null);

  const [uzer, setUzer] = useState(null);
  const [creditAmount, setCreditAmount] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [showimageModal, setShowimageModal] = useState(false);
  const [enteredAmount, setEnteredAmount] = useState(null);
  const navigate = useNavigate();
  const [latestInfo, setLatestInfo] = useState(null);
  const [latestPaymentTrack, setLatestPaymentTrack] = useState(null);
  const [showCheckoutModal, setShowCheckoutModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [showAddressModal, setShowAddressModal] = useState(false);
  const [daysPaidFor, setDaysPaidFor] = useState(0);

  const [fundingOption, setFundingOption] = useState("");
  const [paymentData, setPaymentData] = useState(null);
  const [walletData, setWalletData] = useState(null); // Added state for wallet data
  const [bookedTransports, setBookedTransports] = useState([]); // Define the state for booked transports
  const [result, setResult] = useState(null);

  useEffect(() => {
    const fetchTransportData = async () => {
      try {
        const response = await fetch(
          `https://backendjaes.passiontrials.co.za/transport_monthly_payment/all/${userId}`
        );
        const data = await response.json();

        if (response.ok) {
          // Check if any record has DaysPaidFor greater than 0
          const hasActiveDays = data.some((record) => record.DaysPaidFor > 0);

          if (hasActiveDays) {
            // If any record has DaysPaidFor > 0, sum all active days
            const totalDaysPaidFor = data.reduce(
              (total, record) =>
                total + (record.DaysPaidFor > 0 ? record.DaysPaidFor : 0),
              0
            );
            setDaysPaidFor(totalDaysPaidFor); // Set the total active days
          } else {
            // If all records have DaysPaidFor = 0, do not allow processing
            setDaysPaidFor(0);
          }
        } else {
          setDaysPaidFor(0); // Default to 0 if no data found
        }
      } catch (error) {
        console.error("Error fetching transport data:", error);
        setDaysPaidFor(0); // Default to 0 in case of an error
      } finally {
        setLoading(false); // Stop loading spinner
      }
    };

    fetchTransportData();
  }, [userId]);
  useEffect(() => {
    if (apartment) {
      // Log the values to see what's in FaceImage and ID_Image
      console.log(
        "Checking apartment images:",
        apartment.FaceImage,
        apartment.ID_Image
      );

      // Check for both null and empty string values
      const faceImageExists =
        apartment.FaceImage &&
        typeof apartment.FaceImage === "string" &&
        apartment.FaceImage.trim() !== "";
      const idImageExists =
        apartment.ID_Image &&
        typeof apartment.ID_Image === "string" &&
        apartment.ID_Image.trim() !== "";

      // Show modal only if either image is missing (null or empty string)
      if (!faceImageExists || !idImageExists) {
        setShowimageModal(true); // Open modal if images are missing (null or empty string)
        console.log("Modal opened because FaceImage or ID_Image is missing");
      } else {
        setShowimageModal(false); // Close modal if both images are present
        console.log("Modal closed because both images are present");
      }
    }
  }, [apartment]);
  useEffect(() => {
    // Fetch user data and check if images are missing
    const fetchUser = async () => {
      try {
        const response = await fetch(
          `https://backendjaes.passiontrials.co.za/user_reserve/${userId}`
        );
        const data = await response.json();

        if (data && data.length > 0) {
          const userData = data[0];
          setUzer(userData); // Set apartment data in state
          setUzer(userData);

          // Check if either FaceImage or ID_Image is missing
          const faceImageExists =
            userData.FaceImage && userData.FaceImage.trim() !== "";
          const idImageExists =
            userData.ID_Image && userData.ID_Image.trim() !== "";

          // If any image is missing, show the modal
          if (!faceImageExists || !idImageExists) {
            setShowimageModal(true); // Open modal if images are missing
          } else {
            setShowimageModal(false); // Close modal if both images are present
          }
        } else {
          console.log("No user data found");
        }
      } catch (error) {
        console.error("Error fetching user data:", error);
        setError("Failed to load user data");
      }
    };

    fetchUser();
  }, [userId]); // The effect depends on userId
  useEffect(() => {
    // Fetch user data and check if images are missing
    const fetchUserDetails = async () => {
      try {
        const response = await fetch(
          `https://backendjaes.passiontrials.co.za/user_full_details/${userId}`
        );
        const data = await response.json();

        if (data && data.length > 0) {
          const userData = data[0];
          setApartment(userData); // Set apartment data in state

          // Check if either FaceImage or ID_Image is missing
          const faceImageExists =
            userData.FaceImage &&
            typeof userData.FaceImage === "string" &&
            userData.FaceImage.trim() !== "";
          const idImageExists =
            userData.ID_Image &&
            typeof userData.ID_Image === "string" &&
            userData.ID_Image.trim() !== "";

          setFundingOption(
            data[0].TypeOfFunding.toLowerCase().replace(" ", "-")
          );
          // If any image is missing, show the modal
          if (!faceImageExists || !idImageExists) {
            setShowimageModal(true); // Open modal if images are missing
          } else {
            setShowimageModal(false); // Close modal if both images are present
          }
        } else {
          console.log("No user data found");
        }
      } catch (error) {
        console.error("Error fetching user data:", error);
        setError("Failed to load user data");
      }
    };

    fetchUserDetails();
  }, [userId]); // The effect depends on userId
  const handleImageUpload = (faceImage, idImage) => {
    // Create a FormData object to store the images
    const formData = new FormData();
    formData.append("FaceImage", faceImage);
    formData.append("ID_Image", idImage);

    // Make a POST request to the new update endpoint
    fetch(`https://backendjaes.passiontrials.co.za/update_user_images/${userId}`, {
      method: "POST",
      body: formData,
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.message === "User images updated successfully") {
          alert("Images uploaded successfully!");

          // Update the apartment state with new image paths
          setApartment((prevState) => ({
            ...prevState,
            FaceImage: data.FaceImagePath,
            ID_Image: data.ID_ImagePath,
          }));

          setShowModal(false); // Close the modal after successful upload
        } else {
          alert("Failed to upload images. Please try again.");
        }
      })
      .catch((error) => {
        console.error("Error uploading images:", error);
        alert("Failed to upload images. Please try again.");
      });
  };
  useEffect(() => {
    const fetchTransportData = async () => {
      try {
        const response = await fetch(
          `https://backendjaes.passiontrials.co.za/api/transportmonthly/${userId}`
        );

        if (!response.ok) {
          throw new Error("Failed to fetch transport monthly data");
        }

        const data = await response.json();

        if (data && Array.isArray(data)) {
          console.log("Fetched transport data:", data);
          setBookedTransports(data); // Update state
        } else {
          console.log("No transport data found");
          setBookedTransports([]); // Ensure state is an empty array
        }
      } catch (error) {
        console.error("Error fetching transport data:", error);
      }
    };

    fetchTransportData();
  }, [userId]);

  const formatDate = (dateString) => {
    const options = { weekday: "short", month: "short", day: "numeric" };
    const date = new Date(dateString);
    return date.toLocaleDateString("en-US", options); // Return the formatted date
  };

  let theFirst = null;
  let showButtons = null;
  let testCount = null;
  let informedMessage = null;
  let tc = 0;
  let systemInitiated = null;
  const systemProcessCallRef = useRef(false);
  let stopper = 0;

  const wrapperRef = useRef(null);

  ///// this controls the buttons that are shown
  theFirst = latestInfo?.PayDate;

  // theFirst = new Date(theFirst).toLocaleDateString('en-US', { day: 'numeric' });
  //  showButtons = theFirst >= 15 && theFirst <= 31;
  /////

  useOutsideAlerter(wrapperRef, () => setShowDropdown(false));

  useEffect(() => {
    fetch(`https://backendjaes.passiontrials.co.za/apartment-check/${userId}`, {
      headers: { "Content-Type": "application/json" },
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error("Failed to get a match with user");
        }
        return response.json();
      })
      .then((data) => {
        if (data) {
          setFetchId(data);
          console.log("this is the id for the room: ", data);
        } else {
          console.log("No apartment associated with this user");
        }
      })
      .catch((err) => {
        console.error("Error:", err);
        setError(err.message);
      });
  }, []);
  const [showDashboard, setShowDashboard] = useState(true);

  // const nextPaymentDate = new Date(new Date().setDate(new Date().getDate() + 30)); // Assuming 30 days till next payment
  // const currentDate = new Date();
  // const daysLeft = Math.ceil((nextPaymentDate - currentDate) / (1000 * 60 * 60 * 24));
  //eeei
  useEffect(() => {
    const fetchApartmentAndAdminDetails = async () => {
      try {
        const response = await fetch(
          `https://backendjaes.passiontrials.co.za/apartment_admin_details/${userId}`
        );
        const data = await response.json();

        if (data && data.length > 0) {
          setApartment(data[0]);

          // Check AdminPackage
          if (data[0].AdminPackage === "Basic") {
            setShowDashboard(false); // Hide the dashboard
          } else {
            setShowDashboard(true); // Show the dashboard
          }
        } else {
          console.log("No apartment and admin data found");
          setShowDashboard(false);
        }
      } catch (error) {
        console.error("Error fetching apartment and admin details:", error);
        setError("Failed to load apartment and admin data");
        setShowDashboard(false); // Hide the dashboard in case of an error
      }
    };
    const fetchPaymentData = async () => {
      try {
        const response = await fetch(
          `https://backendjaes.passiontrials.co.za/get_payment/${userId}`
        );
        const data = await response.json();

        if (response.ok && data.paymentDetails.length > 0) {
          console.log("Data structure from API:", data.paymentDetails[0]); // Check the structure
          setPaymentData(data.paymentDetails[0]); // Set the first item directly
        } else {
          setPaymentData(null);
        }
      } catch (error) {
        console.error("Error fetching payment data:", error);
        setPaymentData(null);
      }
    };

    if (userId) {
      fetchPaymentData();
    }
    fetchApartmentAndAdminDetails();
  }, [userId]);

  useEffect(() => {
    //  fetchApartmentDetails();
    if (!fetchid) return;
    fetch(`https://backendjaes.passiontrials.co.za/user_full_details/${userId}`, {
      headers: { "Content-Type": "application/json" },
    })
      .then((response) => response.json())
      .then((data) => {
        if (data) {
          console.log(data[0]);
          setApartment(data[0]);
        } else {
          console.log("No apartement data found here");
        }
      })
      .catch((error) => {
        console.error("Error", error);
        setError("Failed to load user data");
      });

    fetch(`https://backendjaes.passiontrials.co.za/latest_record/${userId}/${fetchid}`, {
      headers: { "Content-Type": "application/json" },
    })
      .then((response) => response.json())
      .then((data) => {
        if (data) {
          console.log("latest record", data);
          setLatestInfo(data);
        } else {
          console.log("No valid payment record found");
        }
      })
      .catch((error) => {
        console.error("Error", error);
        setError("Failed to load latest record");
      });

    fetch(`https://backendjaes.passiontrials.co.za/latest_payment_tracking/${userId}`, {
      headers: { "Content-Type": "application/json" },
    })
      .then((response) => response.json())
      .then((data) => {
        if (data) {
          console.log("latest payment track record", data);
          setLatestPaymentTrack(data);
        } else {
          console.log("No valid payment tracking record found");
        }
      })
      .catch((error) => {
        console.error("Error with tracking", error);
        setError("Failed to load latest pay tracking record");
      });

    fetch(`https://backendjaes.passiontrials.co.za/wallet/${userId}`, {
      headers: { "Content-Type": "application/json" },
    })
      .then((response) => response.json())
      .then((data) => {
        if (data && data.length > 0) {
          console.log("Wallet data:", data);
          setWalletData(data[0]); // Assuming we want the latest wallet record
        } else {
          console.log("No wallet data found");
        }
      })
      .catch((error) => {
        console.error("Error fetching wallet data:", error);
        setError("Failed to load wallet data");
      });
  }, [userId, fetchid]);

  // function fetchApartmentDetails() {
  //     const apartmentDetails = {
  //         name: "Luxury Beach House",
  //         imageUrl: "https://picsum.photos/200/300?random=30"
  //     };

  //     setApartment(apartmentDetails);
  // }
  const nextPayDate = latestInfo?.PayNextDate;
  console.log(`non converted :${nextPayDate}`);
  const nextPayDay = new Date(nextPayDate).toLocaleDateString("en-US", {
    day: "numeric",
  });
  const converted = nextPayDate ? new Date(nextPayDate) : null;
  console.log(`converted :${converted}`);

  //    nextPaymentDate.setDate(new Date(nextPaymentDate.getFullYear(), nextPaymentDate.getMonth() + 1, 0).getDate());

  //converted.setDate(new Date(converted.getFullYear(), converted.getMonth() + 1, 0).getDate());
  const dateNow = new Date();
  const daysOfStay = converted
    ? Math.ceil((converted - dateNow) / (1000 * 60 * 60 * 24))
    : null;
  console.log(
    "this is the converted date",
    converted,
    "this is the current date",
    dateNow
  );



  const allChecker = async () => {

    try {
      const response = await fetch(`https://backendjaes.passiontrials.co.za/check-user/${userId}`);
      if (!response.ok) {
        throw new Error('Failed to check for data');
      }

      const data = await response.json();
      console.log('checker data ', data);
      setResult(data);
    } catch (err) {
      setError(err.message || 'Something went wrong');
    } finally {
      setLoading(false);
    }

  };
  useEffect(() => {
    if (userId) {
      allChecker();

    }
  }, [userId]);

  // if (!apartment) {
  //     return <div>Loading</div>
  // }
  const systemProcess = async () => {
    //intricate function i will come back to ,

    // if (systemInitiated) {

    let newDate = latestInfo.PayNextDate;

    let converted = new Date(newDate);

    converted.setMonth(converted.getMonth() + 1, 1);
    let formatedDate = converted.toLocaleDateString("en-US", {
      day: "numeric",
      month: "long",
      year: "numeric",
    });

    const systemRecord = await fetch("https://backendjaes.passiontrials.co.za/payment_tb", {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        UserId: latestInfo.UserId,
        ApartmentId: latestInfo.ApartmentId,
        PayDate: latestInfo.PayNextDate,
        PayNextDate: formatedDate,
        PayAmount: latestInfo.PayableRent,
        PayNote: "System Initiated",
        PaymentStatus: "OVERDUE",
        PaymentType: "Rent",
        PayAdvancePeriod: "15",
        PayGracePeriod: "3",
        PayMethod: "No Payment Attempt",
        Paid: true,
        PaidInAdvance: false,
        PaidInAdvanceDate: "N/A",
        IsGracePeriod: true,
        ChosenPlan: latestInfo.ChosenPlan,
        ServiceFee: latestInfo.ServiceFee,
        PayableRent: latestInfo.PayableRent,
        RentCost: latestInfo.RentCost,
      }),
    });

    // }
  };

  if (latestInfo) {
    theFirst = new Date(latestInfo.PayDate).toLocaleDateString("en-US", {
      day: "numeric",
    });
    const currentD = new Date(dateNow).toLocaleDateString("en-US", {
      day: "numeric",
    });
    showButtons =
      (theFirst >= 1 && theFirst <= 31) || (currentD >= 1 && currentD <= 31);
    //showButtons = true; for testing
    testCount = latestInfo?.PayNote == "Checkout Month";
    tc = latestInfo?.PayNote == "Monthly rent"; //has no html code to go with yet

    let convertedDate = new Date(latestInfo.PayNextDate);
    let currentDate = new Date();

    systemInitiated = convertedDate < currentDate;
    // if (daysOfStay < 0) {

    //     console.log('the condition is true');
    //     if (latestInfo?.PayNote == 'System Initiated') {
    //         systemProcess();
    //         console.log('the record of the system is recorded');
    //     }
    //     //
    // }
  }

  useEffect(() => {
    if (latestInfo) {
      let convertedDate = new Date(latestInfo.PayNextDate);
      let currentDate = new Date();

      systemInitiated = convertedDate < currentDate;

      if (systemInitiated && daysOfStay < 0) {
        console.log("outside the function");
        if (latestInfo.PayNote == "Monthly rent") {
          systemProcess();
          console.log("the function is called");
        }
      }
    }
  }, [latestInfo]);

  // if (daysOfStay ==)
  informedMessage = latestInfo?.PaymentStatus == "OVERDUE";

  const toggleDropdown = (e) => {
    e.stopPropagation();
    setShowDropdown(!showDropdown);
  };
  const backToDash = () => {
    navigate("/UserDashboard");
  };

  const checkOut = () => {
    // Check if DaysPaidFor is less than 0

    // Proceed with the remaining balance check
    if (walletData?.DaysPaidFor > 0) {
      // Show the checkout modal if the remaining balance is 0 or less
      setShowCheckoutModal(true);
    } else {
      alert(
        `You cannot checkout without settling outstanding payment for the month of ${latestPaymentTrack?.MonthAndYearPaidFor}`
      );
      return;
    }
  };

  const confirmCheckout = () => {
    setShowCheckoutModal(false);
    setLoading(true); // Start the loader

    // Simulate delay before proceeding with checkout
    setTimeout(() => {
      setLoading(false); // Stop the loader
      navigate(`/checkOut?payid=${userId}`);
    }, 7000);
  };

  const handleModalClose = () => {
    setShowCheckoutModal(false);
  };
  // Calculate number of days in the current month
  const getDaysInMonth = (year, month) => {
    return new Date(year, month + 1, 0).getDate();
  };

  // Get the current date and determine the number of days in the current month
  const currentDate = new Date();
  const currentMonth = currentDate.getMonth(); // 0 = January, 11 = December
  const currentYear = currentDate.getFullYear();
  const daysInMonth = getDaysInMonth(currentYear, currentMonth); // Get the number of days in the current month

  // Calculate daily pay based on apartment rent
  const rentAmount = apartment?.ApartmentPrice || 0;
  const dailyPay = rentAmount / daysInMonth; // Calculate daily pay
  const handleModalSubmit = (amount) => {
    // Set the entered amount and close the modal
    setEnteredAmount(amount);
    setShowModal(false); // Close the modal

    // Navigate to the recurringPayment page with the given state
    navigate("/recurringPayment", {
      state: {
        amount,
        apartment,
        nextPayDate,
        latestInfo,
        latestPaymentTrack,
        dailyPay,
      },
    });
  };

  const goToPayment = () => {
    const amountToPay = apartment?.ApartmentPrice; // Fetch the apartment price

    if (!amountToPay) {
      alert("Apartment price is not available.");
      return;
    }

    setShowModal(true); // Open the modal

    // Navigate to the recurringPayment page directly with the fixed apartment price
    navigate("/recurringPayment", {
      state: {
        amount: amountToPay,
        apartment,
        nextPayDate,
        latestInfo,
        latestPaymentTrack,
        dailyPay,
      },
    });
  };

  if (!apartment && !uzer) {
    return (
      <div style={{ marginTop: "109px" }}>
        <h2>You have not booked an apartment yet.</h2>
        <Link to="/allapartments">Click here to book an apartment</Link>
      </div>
    );
  }
  // if (!showDashboard) {
  //     return (
  //         <div style={{ marginTop: '109px' }}>
  //             <h2>This dashboard is not available for your account.</h2>
  //             <Link to="/allapartments">Click here to explore apartments</Link>
  //         </div>
  //     );
  // }

  if (loading) {
    return <Loader />; // Show only the loader while loading is true
  }
  const goToTransport = () => {
    if (fundingOption === "nsfas" || daysPaidFor > 0) {
      // Allow NSFAS students or users with paid days to navigate
      navigate(`/transport`, {
        state: {
          buildingName:
            apartment?.BuildingName || bookedTransports?.[0]?.Building,
          userId,
        },
      });
    } else {
      // Show an alert if the user doesn't have enough days paid for
      alert(
        "You need to have an active transport subscription to access this service."
      );
    }
  };

  const goToServicebox = () => {
    if (apartment?.BuildingName || bookedTransports?.[0]?.Building) {
      navigate(`/ServiceBox`, {
        state: {
          buildingName:
            apartment?.BuildingName || bookedTransports?.[0]?.Building,
          userId,
          name: apartment?.Name || uzer?.Name,
          surname: apartment?.Surname || uzer?.Surname || "",
          CreatedBy: apartment?.CreatedBy || "",
          BuildingID: apartment?.BuildingId || "",
        },
      });
    } else {
      alert("No building information available for service box.");
    }
  };

  const handleTransitPay = async () => {
    try {
      // Fetch the user's transport record
      const response = await fetch(
        `https://backendjaes.passiontrials.co.za/transport_monthly_payment/check/${userId}`
      );
      const data = await response.json();

      if (data.exists) {
        // User exists in transport records
        let apartmentOrAddress =
          apartment?.BuildingName ||
          data.Building ||
          "TransportOnlyTransaction";
        navigate("/recurringTransport", {
          state: {
            amount: 550,
            apartment: apartmentOrAddress,
            address: apartmentOrAddress,
          },
        });
      } else {
        // New user
        if (apartment?.BuildingName) {
          // If BuildingName exists, use it as the address
          navigate("/recurringTransport", {
            state: {
              amount: 250,
              apartment: apartment.BuildingName,
              address: apartment.BuildingName,
            },
          });
        } else {
          // If no BuildingName, show the address modal
          setShowAddressModal(true);
        }
      }
    } catch (error) {
      console.error("Error checking transport payment:", error);
      alert("Failed to check transport payment status. Please try again.");
    }
  };

  const handleAddressSubmit = async (address) => {
    try {
      // Save the address and check user data
      console.log("Address entered:", address);

      let apartmentOrAddress = apartment?.BuildingName || address; // Use address if no BuildingName exists
      let amount = 450; // Default amount for new users without an apartment

      if (apartment && uzer) {
        amount = 550; // Higher amount for users with an apartment
      }

      // Navigate to recurringTransport with the calculated amount
      navigate("/recurringTransport", {
        state: {
          amount,
          apartment: apartmentOrAddress,
          address: apartmentOrAddress,
        },
      });
    } catch (error) {
      console.error("Error handling address submission:", error);
      alert("Failed to process address submission. Please try again.");
    }
  };

  const handleRibbonValue = () => {
    if (fundingOption && apartment) {
      // Define excluded roles
      const excludedRoles = ["Admin", "Super Admin", "Media", "Transport"];

      // Check if the role is NOT in the excluded roles
      if (!excludedRoles.includes(apartment.Role)) {
        if (fundingOption === "nsfas") {
          return "NSFAS student";
        }
        if (fundingOption === "self-funded") {
          return "Self Funded student";
        }
        if (fundingOption === "bursary") {
          return "Bursary student";
        }
      } else {
        return "Staff";
      }
    }

    return ""; // Default return
  };

  return (
    <div>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          padding: "20px",
        }}
      >
        <ImageUploadModal
          isVisible={showimageModal} // This will control whether the modal is shown
          onClose={() => setShowimageModal(false)}
          onUpload={handleImageUpload}
        />

        <PaymentModal
          isVisible={showModal}
          onClose={() => setShowModal(false)}
          onSubmit={handleModalSubmit}
        />
        <CheckoutModal
          isVisible={showCheckoutModal}
          onClose={handleModalClose}
          onConfirm={confirmCheckout}
        />
      </div>

      <Outlet />
      {fundingOption === "nsfas" ? (
        <body class="dashboard-page">
          <h1 className="welcome-message">
            Hi there,{" "}
            <span className="name-highlight">
              {apartment?.Name || uzer?.Name || "Guest"}{" "}
              <span className="surname">
                {apartment?.Surname || uzer?.Surname || ""}
              </span>
            </span>
          </h1>

          <style>
            {`
            .surname {
                display: inline;
            }
    
            @media (max-width: 768px) {
                .surname {
                    display: none;
                }
            }
        `}
          </style>

          <Link to="/Event_Ticket_Student">
            {/* <button class="button-primary">Buy Event Ticket</button> */}
          </Link>
          <div class="dashboard">
            <div class="card first-card">
              <div
                style={{
                  position: "absolute",
                  top: "0",
                  right: "0",
                  backgroundColor: "#F8463F",
                  backgroundImage: `url('https://media.istockphoto.com/id/2074939734/photo/the-black-and-silver-are-light-gray-with-white-the-gradient-is-the-surface-with-templates.jpg?s=2048x2048&w=is&k=20&c=Lkl4n-mEtmLpU6FWy8h81YrHfy3jBg_MaMHkkmSQkrc=')`,
                  color: "white",
                  padding: "5px 20px",
                  fontSize: "12px",
                  fontWeight: "bold",
                  transform: "rotate(0deg)",
                  transformOrigin: "top right",
                  zIndex: "10",
                }}
              >
                {handleRibbonValue()}
              </div>
              <img
                style={{ zIndex: "0" }}
                src="https://backendjaes.passiontrials.co.za/src/Building_images/IMG_20200725_132724.jpg"
                alt="Account Image"
                class="card-bg-img"
              />

              {/* {walletData && (
                <h2 style={{ color: "white" }}>
                  Next paymenfdft due: {walletData.DaysPaidFor} days
                </h2>
              )} */}
              <h2 style={{ color: "white" }}>
                {systemInitiated ? "it's overdue" : ""}
              </h2>
              <small style={{ color: "white" }}>
                {apartment?.BuildingName
                  ? `${apartment.BuildingName} ${apartment.ApartmentType || ""}`
                  : bookedTransports?.[0]?.Building
                  ? `${bookedTransports[0].Building}`
                  : "No building information available"}
              </small>

              <div class="card-buttons">
                <button class="button-secondary" onClick={checkOut}>
                  Checkout
                </button>
              </div>
            </div>

            <div class="card" onClick={goToTransport}>
              <img
                style={{ zIndex: "0" }}
                src="/transport--new.jpg"
                alt="Account Image"
                class="card-bg-img"
              />
              <div
                style={{
                  position: "absolute",
                  top: 0,
                  left: 0,
                  right: 0,
                  bottom: 0,
                  backgroundColor: "rgba(0, 0, 0, 0.7)", // Adjust opacity as needed
                  zIndex: "1",
                }}
                className="dimming-overlay"
              ></div>
              <h2 style={{ color: "white" }}>Transport</h2>

              <small style={{ color: "white" }}>
                {Array.isArray(bookedTransports) &&
                bookedTransports.length > 0 ? (
                  <div>
                    <table
                      style={{
                        width: "75%",
                        borderCollapse: "",
                        borderRadius: "5px",
                        overflow: "hidden",
                      }}
                    >
                      {/* <thead>
              <tr style={{ backgroundColor: '#f8f8f8', textAlign: 'left' }}>
                <th style={{ padding: '10px' }}>Date</th>
                <th style={{ padding: '10px' }}>Time</th>
                <th style={{ padding: '10px' }}>Direction</th>
                <th style={{ padding: '10px' }}>Institution</th>
                <th style={{ padding: '10px' }}>Building</th>
              </tr>
            </thead> */}
                      <tbody
                        style={{
                          width: "75%",
                          borderCollapse: "",
                          borderRadius: "5px",
                          overflow: "hidden",
                        }}
                      >
                        {bookedTransports.slice(0, 2).map((booking, index) => (
                          <tr
                            key={index}
                            style={{
                              borderBottom: "1px solid #000",
                              textAlign: "left",
                              overflow: "hidden",
                            }}
                          >
                            <td style={{ padding: "10px", color: "red" }}>
                              {formatDate(booking.transport_date)}
                            </td>
                            <td style={{ padding: "10px", color: "red" }}>
                              {booking.transport_time}
                            </td>
                            <td style={{ padding: "10px", color: "red" }}>
                              {booking.direction}
                            </td>
                            {/* <td style={{ padding: '10px' }}>{booking.institution}</td> */}
                            {/* <td style={{ padding: '10px' }}>{booking.building_name}</td> */}
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                ) : (
                  <p>No transports booked yet.</p>
                )}
                Book your ride to school
              </small>
            </div>

            <Link to="/SingleUserPayment">
              <div class="card">
                <img
                  style={{ zIndex: "0" }}
                  src="/Online-shopping.jpg"
                  alt="Account Image"
                  class="card-bg-img"
                />
                <div
                  style={{
                    position: "absolute",
                    top: 0,
                    left: 0,
                    right: 0,
                    bottom: 0,
                    backgroundColor: "rgba(0, 0, 0, 0.7)", // Adjust opacity as needed
                    zIndex: "1",
                  }}
                  className="dimming-overlay"
                ></div>
                <h2 style={{ color: "white" }}>Payment History</h2>

                <small style={{ color: "white" }}>
                  {/* Use Reference: 076BWN-R01
            <br/>
    Pay into the following account:<br/>
    JAES Properties<br/>
    FNB<br/>
    Savings Account<br/>
    Acc#: 62793916192
    <hr/>
                <br/> */}
                  Your invoices are here
                </small>
              </div>
            </Link>

            <Link to="/profile">
              <div class="card">
                <img
                  style={{ zIndex: "0" }}
                  src="https://images.pexels.com/photos/3765177/pexels-photo-3765177.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1"
                  alt="Account Image"
                  class="card-bg-img"
                />
                <div
                  style={{
                    position: "absolute",
                    top: 0,
                    left: 0,
                    right: 0,
                    bottom: 0,
                    backgroundColor: "rgba(0, 0, 0, 0.7)", // Adjust opacity as needed
                    zIndex: "1",
                  }}
                  className="dimming-overlay"
                ></div>
                <h2 style={{ color: "white" }}>Profile</h2>
                <small style={{ color: "white" }}>
                  Update your personal information
                </small>
              </div>
            </Link>

            <div class="card" onClick={goToServicebox}>
              <img
                style={{ zIndex: "0" }}
                src="https://starfieldconsulting.nl/wp-content/uploads/elementor/thumbs/pexels-yan-krukov-8867474-scaled-peek3pzknc3hl3712gn5ohxfq1oah5jmyp50swwhiw.jpg"
                alt="Account Image"
                class="card-bg-img"
              />
              <div
                style={{
                  position: "absolute",
                  top: 0,
                  left: 0,
                  right: 0,
                  bottom: 0,
                  backgroundColor: "rgba(0, 0, 0, 0.7)", // Adjust opacity as needed
                  zIndex: "1",
                }}
                className="dimming-overlay"
              ></div>
              <h2 style={{ color: "white" }}>Service Box</h2>
              <small style={{ color: "white" }}>Drop us your complaints</small>
            </div>
          </div>
        </body>
      ) : (
        <body class="dashboard-page">
          <h1 className="welcome-message">
            Hi there,{" "}
            <span className="name-highlight">
              {apartment?.Name || uzer?.Name || "Guest"}{" "}
              <span className="surname">
                {apartment?.Surname || uzer?.Surname || ""}
              </span>
            </span>
          </h1>

          <style>
            {`
        .surname {
            display: inline;
        }

        @media (max-width: 768px) {
            .surname {
                display: none;
            }
        }
    `}
          </style>

          <Link to="/Event_Ticket_Student">
            {/* <button class="button-primary">Buy Event Ticket</button> */}
          </Link>
          <div class="dashboard">
            <div class="card first-card">
              <div
                style={{
                  position: "absolute",
                  top: "0",
                  right: "0",
                  backgroundColor: "#F8463F",
                  backgroundImage: `url('https://media.istockphoto.com/id/2074939734/photo/the-black-and-silver-are-light-gray-with-white-the-gradient-is-the-surface-with-templates.jpg?s=2048x2048&w=is&k=20&c=Lkl4n-mEtmLpU6FWy8h81YrHfy3jBg_MaMHkkmSQkrc=')`,
                  color: "white",
                  padding: "5px 20px",
                  fontSize: "12px",
                  fontWeight: "bold",
                  transform: "rotate(0deg)",
                  transformOrigin: "top right",
                  zIndex: "10",
                }}
              >
                {handleRibbonValue()}
              </div>
              <img
                style={{ zIndex: "0" }}
                src="https://backendjaes.passiontrials.co.za/src/Building_images/IMG_20200725_132724.jpg"
                alt="Account Image"
                class="card-bg-img"
              />

              {walletData && (
                <h2 style={{ color: "white" }}>
                  Next payment due: {walletData.DaysPaidFor} days
                </h2>
              )}
              <h2 style={{ color: "white" }}>
                {systemInitiated ? "it's overdue" : ""}
              </h2>
              <small style={{ color: "white" }} className="building-info">
                {apartment?.BuildingName
                  ? `${apartment.BuildingName} ${apartment.ApartmentType || ""}`
                  : bookedTransports?.[0]?.Building
                  ? `${bookedTransports[0].Building}`
                  : "No building information available"}
              </small>

              <style>{`
  .building-info {
    color: blue;
    position: relative;
  }

  @media (max-width: 768px) {
    .building-info {
      position: relative;
    color: white;
      bottom: 17px; /* Move 10px from bottom */
         text-align: right; /* Align text to the right */
    }
  }
`}</style>

              <div class="card-buttons" style={{ display: "" }}>
                {!showDashboard ? (
                  <p>Contact your landlord to renew</p>
                ) : (
                  <>
                    {" "}
                    <button class="button-primary" onClick={goToPayment}>
                      Renew
                    </button>
                    <button class="button-secondary" onClick={checkOut}>
                      Checkout
                    </button>
                  </>
                )}
              </div>
            </div>
            <div class="card">
              <img
                style={{ zIndex: "0" }}
                src="/transport--new.jpg"
                alt="Account Image"
                class="card-bg-img"
              />
              <div
                onClick={goToTransport}
                style={{
                  position: "absolute",
                  top: 0,
                  left: 0,
                  right: 0,
                  bottom: 0,
                  backgroundColor: "rgba(0, 0, 0, 0.7)", // Adjust opacity as needed
                  zIndex: "1",
                }}
                className="dimming-overlay"
              ></div>
              <h2 style={{ color: "white" }} onClick={goToTransport}>
                Transport
              </h2>
              <small style={{ color: "white" }}>
                {daysPaidFor > 0 ? (
                  <>
                    {" "}
                    <p>Transport is active. {daysPaidFor} days remaining.</p>
                    <span>
                      <br /> Book your ride to University
                    </span>{" "}
                  </>
                ) : (
                  <>


                  {result?.userIdExists == true &&
                    result?.tables.some(entry => entry.table == 'eftBooking' && entry.paidTransport == true) ? ( // Check if PaidTransport is true for eftBooking
                    <>
                      <p>your booking is still being processed</p>

                    </>
                  ) : (
                    <>
                      <button
                        onClick={() => handleTransitPay()}
                        className="apply-transport-btn"
                      >
                        Apply for Transport
                      </button>
                    </>
                  )
                  }

                </>
                )}{" "}
              </small>
            </div>
            <style>{`
  .apply-transport-btn {
    padding: 10px;
    width: 60%;
    font-size: 14px; /* Default font size for larger screens */
    background-color: #ff5a5f;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    z-index: 30000;
    display: inline-block;
    transition: all 0.3s ease-in-out;
  }

  .apply-transport-btn:hover {
    background-color: #ff2a3f; /* Slightly darker shade on hover */
  }

  @media (max-width: 768px) {
    .apply-transport-btn {
      width: 60%; /* Button spans full width on smaller screens */
      font-size: 12px; /* Adjusted font size for mobile */
      padding: 8px; /* Adjusted padding for better fit */
      text-align: center; /* Center align text */
    }
  }
`}</style>
            <Link to="/SingleUserPayment">
              <div class="card">
                <img
                  style={{ zIndex: "0" }}
                  src="/Online-shopping.jpg"
                  alt="Account Image"
                  class="card-bg-img"
                />
                <div
                  style={{
                    position: "absolute",
                    top: 0,
                    left: 0,
                    right: 0,
                    bottom: 0,
                    backgroundColor: "rgba(0, 0, 0, 0.7)", // Adjust opacity as needed
                    zIndex: "1",
                  }}
                  className="dimming-overlay"
                ></div>
                <h2 style={{ color: "white" }}>Payment History</h2>

                <small style={{ color: "white" }}>
                  {/* Use Reference: 076BWN-R01
        <br/>
Pay into the following account:<br/>
JAES Properties<br/>
FNB<br/>
Savings Account<br/>
Acc#: 62793916192
<hr/>
            <br/> */}
                  Your invoices are here
                </small>
              </div>
            </Link>

            <Link to="/profile">
              <div class="card">
                <img
                  style={{ zIndex: "0" }}
                  src="https://images.pexels.com/photos/3765177/pexels-photo-3765177.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1"
                  alt="Account Image"
                  class="card-bg-img"
                />
                <div
                  style={{
                    position: "absolute",
                    top: 0,
                    left: 0,
                    right: 0,
                    bottom: 0,
                    backgroundColor: "rgba(0, 0, 0, 0.7)", // Adjust opacity as needed
                    zIndex: "1",
                  }}
                  className="dimming-overlay"
                ></div>
                <h2 style={{ color: "white" }}>Profile</h2>
                <small style={{ color: "white" }}>
                  Update your personal information
                </small>
              </div>
            </Link>
            {!showDashboard ? (
              <div class="card">
                <img
                  style={{ zIndex: "0" }}
                  src="https://starfieldconsulting.nl/wp-content/uploads/elementor/thumbs/pexels-yan-krukov-8867474-scaled-peek3pzknc3hl3712gn5ohxfq1oah5jmyp50swwhiw.jpg"
                  alt="Account Image"
                  class="card-bg-img"
                />
                <div
                  style={{
                    position: "absolute",
                    top: 0,
                    left: 0,
                    right: 0,
                    bottom: 0,
                    backgroundColor: "rgba(0, 0, 0, 0.7)", // Adjust opacity as needed
                    zIndex: "1",
                  }}
                  className="dimming-overlay"
                ></div>
                <h2 style={{ color: "white" }}>Service Box</h2>
                <small style={{ color: "white", fontWeight: "bold" }}>
                  Service Box cannot be accessed because your building is in the
                  Package.
                </small>
              </div>
            ) : (
              <div class="card" onClick={goToServicebox}>
                <img
                  style={{ zIndex: "0" }}
                  src="https://starfieldconsulting.nl/wp-content/uploads/elementor/thumbs/pexels-yan-krukov-8867474-scaled-peek3pzknc3hl3712gn5ohxfq1oah5jmyp50swwhiw.jpg"
                  alt="Account Image"
                  class="card-bg-img"
                />
                <div
                  style={{
                    position: "absolute",
                    top: 0,
                    left: 0,
                    right: 0,
                    bottom: 0,
                    backgroundColor: "rgba(0, 0, 0, 0.7)", // Adjust opacity as needed
                    zIndex: "1",
                  }}
                  className="dimming-overlay"
                ></div>
                <h2 style={{ color: "white" }}>Service Box</h2>
                <small style={{ color: "white" }}>
                  Drop us your complaints
                </small>
              </div>
            )}
          </div>
          <AddressModal
            isVisible={showAddressModal}
            onClose={() => setShowAddressModal(false)}
            onSubmit={(address) => handleAddressSubmit(address)}
            uzer={uzer} // Pass the data as a prop
            apartment={apartment}
          />
           <div>
            {result && result?.userIdExists == true && result?.tables.some(entry => entry.table == 'eftBooking'  || entry.table == 'nsfasBooking') ? (
              <div
                // style={{
                //   position: 'relative',
                //   top: '0',
                //   left: '0',
                //   width: '100vw',
                //   overflow: 'hidden',
                //   height: '50px',
                //   backgroundColor: '#fff',
                //   zIndex: 1000, // Ensure it appears above other elements
                // }}
              >
                <p
                  style={{
                    
                    color: 'black',
                  }}
                >
                  BOOKING IN PROGRESS
                </p>
                <style>{`
        @keyframes slide-horizontal {
          0% {
            transform: translateX(100%);
          }
          100% {
            transform: translateX(-100%);
          }
        }
      `}</style>
              </div>
            ) : (
              <p></p>
            )}
          </div>
        </body>
      )}
    </div>
  );
}
export default UserDashboard;
